import { render, staticRenderFns } from "./oferta-form.vue?vue&type=template&id=201e7fdb&scoped=true"
import script from "./oferta-form.vue?vue&type=script&lang=js"
export * from "./oferta-form.vue?vue&type=script&lang=js"
import style0 from "./oferta-form.vue?vue&type=style&index=0&id=201e7fdb&prod&lang=css"
import style1 from "./oferta-form.vue?vue&type=style&index=1&id=201e7fdb&prod&scoped=true&lang=css"
import style2 from "./oferta-form.vue?vue&type=style&index=2&id=201e7fdb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "201e7fdb",
  null
  
)

export default component.exports
import moment from 'moment';
import DocumentoOffline from "./db/DocumentoOffline";
import { EventBus } from "./EventBus";
import EXIF from 'exif-js';

var Utils = {
    minutosEntreBackups: 10,
    diasBackups: 10,
    timeoutResetBackup: null,
    sobreimprimir: false,
    posterior2Dic2024: function (fecha) {
        // si fecha tiene valor y es  tipo string, la convertimos en Date
        if (typeof fecha === 'string') {
            fecha = new Date(fecha);
        }
        return !fecha || fecha >= new Date(2024, 11, 2);
    },
    onPageMounted: function () {
        if (window.location.href.indexOf("?reloadPage") > -1) {
            window.location.href = window.location.href.split('?')[0];
            window.location.reload();
        }
    },
    padZeros: function (input, maxLength) {
        if (maxLength === void 0) { maxLength = 0; }
        return ("0000" + input).slice(-maxLength);
    },
    cleanObj: function(obj) {
        for (var propName in obj) { 
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
    },
    getDireccionStr(direccion) {
        if (direccion) {
          return (direccion.direccion || '') + ' ' + (direccion.cp || '') + ' ' + (direccion.poblacion || '') + ' ' + (direccion.pais || '');
        } else {
          return '';
        }
    },
    getUser() {
        if (window.app && window.app.data && window.app.data.user && window.app.data.user.username) {
            return window.app.data.user;
        } else {
            var user = localStorage.getItem("user");
            if (typeof user == "undefined" || !user) { 
                return null;
            } else {
                user = JSON.parse(user);
                if (typeof user == "undefined" || !user) {
                    return null;
                } else {
                    return user;
                }
            }
        }
    },
    getToken() {
        var session = localStorage.getItem("session");
        if (typeof session != "undefined" && session) {
            var sessionObj = JSON.parse(session);
            if (sessionObj && sessionObj.token) {
                return sessionObj.token;
            }
        }
        return "";
    },
    orderDataByTabIndex: function (a, b) {
        if (a.tabIndex < b.tabIndex) {
          return -1;
        } else if (a.tabIndex > b.tabIndex) {
          return 1;
        } else {
          return 0;
        }
    },
    getMenu() {
        const self = this;
        const user = self.getUser();
        if (user) {
            return user.menu;
        } else {
            return null;
        }
    },
    userLoaded() {
        const self = this;
        return !!self.getUser();
    },
    isSync: function(config) {
        var momentVar = moment;
        return (config && config.syncDate && config.dispositivo && momentVar().diff(config.syncDate,'days') < 1);
    },
    focusNextElement(event, padre) {
        var self = this;
        event.preventDefault();
        if (event.target.type == 'textarea') {
            return;
        }
        var focusable = padre.querySelectorAll('input,textarea,div[contentEditable=true],span[contentEditable=true]');
        var focusableOK = [];
        for (let idx = 0; idx < focusable.length; idx++) {
            const element = focusable.item(idx);
            if (element.type != 'checkbox' && element.type != 'radio') {
                focusableOK.push(element);
            }
        }
        if (event.target.tabIndex && event.target.tabIndex > 0) {
            focusableOK.sort(self.orderDataByTabIndex);
        }
        var next = null;
        var idxNext = focusableOK.indexOf(event.target);
        if (idxNext > -1) {
            idxNext++;
        }
        if (idxNext > -1) {
            next = focusableOK[idxNext];
            if (next){
                next.focus();
                return next;
            }

        }
    },
    getProperty(name) {
        let properties = localStorage.getItem("properties");
        if (typeof properties == "undefined" || !properties) { 
            return null;
        }
        properties = JSON.parse(properties);
        if (typeof properties == "undefined" || !properties || !properties.length) {
            return null;
        }
        let p = properties.filter(p => p.nombre == name)[0];
        return p ? p.valor : null;
	},
    writeFile(fileName, dataObj, append = false) {
        var rootDir = "Documents/";
        var filePath = rootDir + fileName;

        if (!window.requestFileSystem) {
            return;
        }

        window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, function (fs) {
            // TODO Gestionar directorios
            var folders = fileName.split("/");
            var folderToCreate = rootDir;
            for(var i=0; i<folders.length-1;i++){
                folderToCreate += folders[i] + "/";
                fs.root.getDirectory(folderToCreate, { create: true, exclusive: false }, function (fileEntry) {
                }, function(err) {
                    console.error("Error al crear directorio", err);
                });
            }
            
            fs.root.getFile(filePath, { create: true, exclusive: false }, function (fileEntry) {
                fileEntry.createWriter(function (fileWriter) {
                    if(append && fileWriter.length){
                        fileWriter.seek(fileWriter.length);
                    }
                    fileWriter.write(dataObj);
                });
            }, function(err) {
                console.error("Error al crear fichero", err);
            });
        });
    },
    appendFile(fileName, text){
        Utils.writeFile(fileName, text, true);
    },
    logInspeccion(inspeccion, nuevoFichero = false) {
        if (!inspeccion.formato) {
            return;
        }
        var timestamp = moment().format("YYYYMMDDHHmmss"),
            insp = inspeccion.id,
            provincia = inspeccion.direccionInspeccion && inspeccion.direccionInspeccion.provincia ? inspeccion.direccionInspeccion.provincia : "00",
            formato = inspeccion.formato.codigo.replaceAll(/\s/g,""),
            fichero = 'SIMECAL/' + (insp ? `${formato}/${provincia}/${insp}/${timestamp}.json` : `${formato}/${provincia}/${timestamp}.json`);
        // console.log(inspeccion);
        if (inspeccion.sincronizado) {
            return;
        }
        if (Utils.currentLogs[formato] && Utils.currentLogs[formato][provincia] && Utils.currentLogs[formato][provincia][insp]) {
            if (!nuevoFichero) {
                fichero = Utils.currentLogs[formato][provincia][insp];
            }
        } else {
            if(!Utils.currentLogs[formato]) {
                Utils.currentLogs[formato] = {};
            }
            if(!Utils.currentLogs[formato][provincia]){
                Utils.currentLogs[formato][provincia] = {};
            }
            Utils.currentLogs[formato][provincia][insp] = fichero;
            Utils.timeoutResetBackup = setTimeout(function(){
                delete Utils.currentLogs[formato][provincia][insp];
            }, Utils.minutosEntreBackups*60*1000);
        }
        Utils.writeFile(fichero, inspeccion);
    },
    log(line) {
        var date = moment().format("YYYYMMDD"),
            timestamp = moment().format("DD/MM/YYYY HH:mm:ss"),
            logFile = `SIMECAL/Logs/${date}.txt`;
        Utils.appendFile(logFile, `${timestamp} - ${line}\n`);
    },
    currentLogs: {},
    moveInspeccionToBorrar(inspeccion, localId) {
        if (!inspeccion.formato) {
            return;
        }
        var today = moment().format("YYYYMMDD"),
            insp = localId,
            provincia = inspeccion.direccionInspeccion && inspeccion.direccionInspeccion.provincia ? inspeccion.direccionInspeccion.provincia : "00",
            formato = inspeccion.formato.codigo.replaceAll(/\s/g, ""),
            newName = inspeccion.codigo ? inspeccion.codigo.replaceAll("/", "_") : localId;
        
        if (insp) {
            var folder = `Documents/SIMECAL/${formato}/${provincia}/${insp}`;
            var borrarFolder = `Documents/SIMECAL/Borrar/${today}/`;
            Utils.moveFile(folder, borrarFolder, newName);
        }
    },
    moveFile(baseFile, targetFile, newName) {
        return new Promise(async (resolve, reject) => {
            if (!window.requestFileSystem) {
                resolve();
                return;
            }
            window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, async function (fs) {
                var folders = targetFile.split("/");
                var folderToCreate = "";
                await DocumentoOffline.createFolders(folderToCreate, folders, fs);
                if (baseFile.startsWith("file://")) {
                    window.resolveLocalFileSystemURL(baseFile, function (baseDirEntry) {
                        fs.root.getDirectory(targetFile, { create: true, exclusive: false }, function (targetDirEntry) {
                            baseDirEntry.moveTo(targetDirEntry, newName,
                                function (res) {
                                    console.log("Fichero movido correctamente", res);
                                    resolve(res)
                                },
                                function (err) {
                                    console.error(err);
                                    reject(err);
                                });
                        }, function (err) {
                            console.error(err);
                            reject(err);
                        });
                    }, function (err) {
                        console.error(err);
                        reject(err);
                    });
                } else {
                    fs.root.getDirectory(baseFile, { create: false, exclusive: false }, function (baseDirEntry) {
                        fs.root.getDirectory(targetFile, { create: true, exclusive: false }, function (targetDirEntry) {
                            baseDirEntry.moveTo(targetDirEntry, newName,
                                function (res) {
                                    console.log("Fichero movido correctamente", res);
                                    resolve(res)
                                },
                                function (err) {
                                    console.error(err);
                                    reject(err);
                                });
                        }, function (err) {
                            console.error(err);
                            reject(err);
                        });
                    }, function (err) {
                       console.error(err);
                       reject(err);
                    });
                }
            });
        });
    },
    removeBackups() {
        return new Promise(async (resolve, reject) => {
            var dayRemove = moment().add(Utils.diasBackups * -1,"day").format("YYYYMMDD");
            var borrarFolder = `Documents/SIMECAL/Borrar`;
            var logsFolder = `Documents/SIMECAL/Logs`;
            var p = Promise.resolve();
            if (!window.requestFileSystem) {
                resolve();
                return;
            }
            window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, function (fs) {
                p = p.then(_ => new Promise(function(resolve, reject) {
                    fs.root.getDirectory(borrarFolder, { create: true, exclusive: false }, function (borrarEntry) {
                        let readerDir = borrarEntry.createReader();
                        readerDir.readEntries(function (entries) {
                            entries.forEach(entry => {
                                if (entry.isDirectory) {
                                    let nameEntry = entry.name;
                                    if (nameEntry < dayRemove) {
                                        entry.removeRecursively();
                                    }
                                }
                            });
                            resolve();
                        },function (err) {
                            console.error(err);
                            reject(err);
                        })
                        
                    }, function (err) {
                       console.error(err);
                       reject(err);
                    });
                }));
                p = p.then(_ => new Promise(function(resolve, reject) {
                    fs.root.getDirectory(logsFolder, { create: true, exclusive: false }, function (borrarEntry) {
                        let readerDir = borrarEntry.createReader();
                        readerDir.readEntries(function (entries) {
                            entries.forEach(entry => {
                                if (entry.isFile) {
                                    let nameEntry = entry.name.split('.')[0];
                                    if (nameEntry < dayRemove) {
                                        entry.remove();
                                    }
                                }
                            });
                            resolve();
                        },function (err) {
                            console.error(err);
                            reject(err);
                        })
                        
                    }, function (err) {
                       console.error(err);
                       reject(err);
                    });
                }));
                p.then(resolve).catch(reject);
            });
        });
    },
    getHistoricoInspeccion(inspeccion) {
        return new Promise(async (resolve, reject) => {
            var insp = inspeccion.id,
                provincia = inspeccion.direccionInspeccion && inspeccion.direccionInspeccion.provincia ? inspeccion.direccionInspeccion.provincia : "00",
                formato = inspeccion.formato.codigo.replaceAll(/\s/g,""),
                folder = `Documents/SIMECAL/${formato}/${provincia}/${insp}`;
            
            if (!window.requestFileSystem) {
                resolve();
                return;
            }
            
            window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, function (fs) {
                fs.root.getDirectory(folder, { create: false, exclusive: false }, function (inspDir) {
                    let readerDir = inspDir.createReader();
                    readerDir.readEntries(function (entries) {
                        let historico = [];
                        var p = Promise.resolve();
                        entries.forEach(entry => {
                            if (entry.isFile) {
                                p = p.then(_ => new Promise(function (resolve, _) {
                                    entry.getMetadata(function (m) {
                                        historico.push({
                                            file: entry.name,
                                            date: moment(m.modificationTime).format("DD/MM/YYYY HH:mm")
                                        });
                                        resolve();
                                    }, function () {
                                        historico.push({
                                            file: entry.name,
                                            date: moment(entry.name.split()[0], "YYYYMMDDHHmmss").format("DD/MM/YYYY HH:mm")
                                        });
                                        resolve();
                                    })
                                }));
                            }
                        });
                        p.then( _ => resolve(historico.reverse())).catch(reject);
                    },function (err) {
                        console.error(err);
                        reject(err);
                    })
                    
                }, function (err) {
                    console.error(err);
                    reject(err);
                });
            });
        });
    },
    getHistItem(inspeccion, file) {
        return new Promise(async (resolve, reject) => {
            var insp = inspeccion.id,
                provincia = inspeccion.direccionInspeccion && inspeccion.direccionInspeccion.provincia ? inspeccion.direccionInspeccion.provincia : "00",
                formato = inspeccion.formato.codigo.replaceAll(/\s/g,""),
                fichero = `Documents/SIMECAL/${formato}/${provincia}/${insp}/${file}`;
            
            if (!window.requestFileSystem) {
                resolve();
                return;
            }
            
            window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, function (fs) {
                fs.root.getFile(fichero, { create: false, exclusive: false }, function (fileEntry) {
                    fileEntry.file(function (file) {
                        var reader = new FileReader();
                        reader.onloadend = function(evt) {
                            console.log("read success");
                            console.log(evt.target.result);
                            resolve(evt.target.result);
                        };
                        reader.readAsText(file);
                    }, function (err) {
                        console.error(err);
                        reject(err);
                    })
                }, function (err) {
                    console.error(err);
                    reject(err);
                });
            });
        });
    },
    executeTask: async function (timeLimit, task, failureValue) {
      let timeout;
      const timeoutPromise = new Promise((resolve, reject) => {
          timeout = setTimeout(() => {
              resolve(failureValue);
          }, timeLimit);
      });
      const response = await Promise.race([task, timeoutPromise]);
      if(timeout){ //the code works without this but let's be safe and clean up the timeout
          clearTimeout(timeout);
      }
      return response;
    },
    getInspeccionPath(path, inspeccion) {
        const insp = inspeccion.id,
            provincia = inspeccion.direccionInspeccion && inspeccion.direccionInspeccion.provincia ? inspeccion.direccionInspeccion.provincia : "00",
            formato = inspeccion.formato && inspeccion.formato.codigo ? inspeccion.formato.codigo.replaceAll(/\s/g, "") : '00',
            completeFilename = 'SIMECAL/' + (insp ? `${formato}/${provincia}/${insp}/${path}` : `${formato}/${provincia}/${path}`);
        
        return completeFilename;
    },
    grabarAudio(rform, name, path, setFunction) {
        navigator.device.capture.captureAudio(
            function (mediafiles) {
                Utils.onCaptureMedia(mediafiles, { rform, name, path, setFunction });
            },
            Utils.onCaptureError,
            { limit: 1 }
        );
    },
    capturarFoto(rform, name, path, setFunction) {
        EventBus.$emit("capturarFoto", Utils.onCaptureMedia, { rform, name, path, setFunction, storeToFile: true });
    },
    grabarVideo(rform, name, path, setFunction) {
        EventBus.$emit("grabarVideo", Utils.onCaptureMedia, { rform, name, path, setFunction, storeToFile: true });
    },
    onCaptureMedia: async function (mediaFiles, { rform, name, path, setFunction }) {
        for (let i = 0; i < mediaFiles.length; i++) {
            let url = mediaFiles[i];
            if (typeof url == 'object') {
                url = url.fullPath;    
            }
            // let extension = url.split("/").slice(-1).join("/").split(".").slice(-1)[0];
            var docsForm = rform.getValue(name);
            if (!docsForm) {
                docsForm = [];
                var nameSplit = name.split(".");
                let lastName = nameSplit[nameSplit.length - 1];
                var nameMenos1 = nameSplit.slice(0, -1).join(".");
                var docsFormMenos1 = rform.getValue(nameMenos1);
                setFunction(!docsFormMenos1 ? rform.formData : docsFormMenos1, lastName, docsForm);
            }
            // const numFileName = (!docsForm || !docsForm.length ? 0 : Math.max(...docsForm.map(d => parseInt(d.nombre.split(']')[0].replace('[', '') || "0", 10) || 0))) + 1;
            
            // const filename = `[${numFileName}]_${url.split("/").slice(-1).join("").split(".").slice(0, -1).join(".")}`
            var documento = {
                nombre: url.split("/").slice(-1).join("").split(".").slice(0, -1).join("."),
                extension: url.split("/").slice(-1).join("/").split(".").slice(-1)[0],
            };

            const timestamp = moment().format("YYYYMMDDHHmmss");

            var rootDir = "Documents/";
            var filePath = rootDir + path;

            if (!window.requestFileSystem) {
                return;
            }
            let basePath = url;
            if (!basePath.startsWith("file://")) basePath = "file://" + basePath;

            if (documento.extension == 'jpg') {
                window.cordova.plugins.imagesaver.saveImageToGallery(basePath, onSaveImageSuccess, onSaveImageError);
                function onSaveImageSuccess() {
                    console.log('--------------success');
                };
                function onSaveImageError(error) {
                    console.log('--------------error: ' + error);
                };
            };

            const movedFile = await Utils.moveFile(basePath, filePath, `${timestamp}_${documento.nombre + "." + documento.extension}`);
            console.log("movedFile", movedFile);
            
            documento.offlinePath = movedFile.fullPath;
            documento._localURL = movedFile.nativeURL;
            // Sobreimprimir fecha cuando se nos diga
            if (documento.extension == 'jpg') {
                // sobreimprimir fecha a la imagen
                Utils.sobreimprimirFecha(documento._localURL, documento.offlinePath);
            }
            documento.alreadySaved = true;
            docsForm.push(documento);
        }
    },
    onCaptureError(error) {
        alert(
            error.message
        );
    },
    // imgSrc será una url o una imagen base64 o algo que pueda ser interpretado por un Image
    sobreimprimirFecha(imgSrc, offlinePath, fecha) {
        if (!Utils.sobreimprimir) { return; }
        // vamos a obtener el tamaño de la imagen
        var img = new Image();
        img.onload = function() {
            var canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            ctx.font = 'bold 20px Arial';
            ctx.fillStyle = 'red';
            EXIF.getData(img, function() {
                if (!fecha) {
                    fecha = EXIF.getTag(this, "DateTimeOriginal");
                }
                if (!fecha) {fecha = moment().format("DD/MM/YYYY HH:mm:ss");}
                ctx.fillText(fecha, 10, 50);
                var base64 = canvas.toDataURL();
                console.log(base64);
                console.log(offlinePath);
                DocumentoOffline.saveBase64(base64, offlinePath);
            });
        };
        img.src = imgSrc;
    },
    stringToColor: function (str) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var c = (hash & 0x00FFFFFF)
            .toString(16)
            .toUpperCase();
        return "#" + "00000".substring(0, 6 - c.length) + c;
    },
    getNestedProperty: function(obj, path) {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    },
    meses() {
        return [
            { value: 1, text: "Enero" },
            { value: 2, text: "Febrero" },
            { value: 3, text: "Marzo" },
            { value: 4, text: "Abril" },
            { value: 5, text: "Mayo" },
            { value: 6, text: "Junio" },
            { value: 7, text: "Julio" },
            { value: 8, text: "Agosto" },
            { value: 9, text: "Septiembre" },
            { value: 10, text: "Octubre" },
            { value: 11, text: "Noviembre" },
            { value: 12, text: "Diciembre" }
        ];
    },
    getMes(mes) {
        return this.meses().filter(m => m.value == mes)[0].text;
    },
}

export default Utils;
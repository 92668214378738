const messages = {
    es: {
        atras: 'Atrás',
        cerrar: 'Cerrar',
        aceptar: 'Aceptar',
        searchBarBuscar: 'Buscar...',
        searchBarSinResultados: 'Sin Resultados...',
        Enero: 'Enero',
        Febrero: 'Febrero',
        Marzo: 'Marzo',
        Abril: 'Abril',
        Mayo: 'Mayo',
        Junio: 'Junio',
        Julio: 'Julio',
        Agosto: 'Agosto',
        Septiembre: 'Septiembre',
        Octubre: 'Octubre',
        Noviembre: 'Noviembre',
        Diciembre: 'Diciembre',
        common: {
            nueva: 'Nueva',
            nuevo: 'Nuevo',
            cerrar: 'Cerrar',
            campoVacio: 'Campo Vacío',
            campoRelleno: 'Campo Relleno',
            fechaVacia: 'Fecha Vacía',
            fechaRellena: 'Fecha Rellena',
            mesActual: 'Mes Actual',
            yes: 'Sí',
            no: 'No',
            desde: 'Desde',
            hasta: 'Hasta',
            buscar: 'Buscar',
            anadir: 'Añadir',
            cargar: 'Cargar',
            ver: 'Ver',
            editar: 'Editar',
            duplicar: 'Duplicar',
            guardar: 'Guardar',
            aceptar: 'Aceptar',
            guardarComo: 'Guardar Como',
            eliminar: 'Eliminar',
            expandir: 'Expandir',
            borrar: 'Borrar',
            cancelar: 'Cancelar',
            compartir: 'Compartir',
            filtro: 'Filtro',
            nombre: 'Nombre',
            bloqueado: 'Bloqueado',
            desbloqueado: 'Desbloqueado',
            move: 'Mover',
            copy: 'Copiar',
            paste: 'Pegar',
            any: 'Cualquiera',
            actualizarTabla: 'Actualizar datos de tabla',
            exportarExcel: 'Exportar a Excel',
            consultasFavoritas: 'Consultas Favoritas',
            firstSearch: 'Realice una búsqueda para obtener resultados',
            firstSearchTipoEstatico: 'Elija una consulta para obtener resultados',
            noResults: 'La búsqueda no ha dado resultados',
            configurarColumnas: 'Configurar columnas',
            consultaGuardada: 'Consulta Guardada correctamente',
            consultaBorrada: 'Consulta Borrada correctamente',
            consultaCompartida: 'Consulta Compartida correctamente',
            empleados: 'Empleados',
            personas: 'Personas',
            persona: 'Persona',
            empresa: 'Empresa',
            filtrar: 'Filtrar',
            cleanFilter: 'Limpiar Filtro',
            configurarFiltros: 'Configurar Filtros',
            guardarConsulta: 'Guardar Consulta',
            sharedConsulta: 'Compartir Consulta',
            sobreescribir: 'Sobreescribir',
            cambiosmasivos: 'Cambios Masivos',
            actualizarMasivamente: 'Actualizar masivamente',
            desmarcarSeleccionados: 'Desmarcar Seleccionados',
            marcarTodos: 'Marcar todos los visibles',
            configurarAgregaciones: 'Configurar Agregaciones',
            ver: 'Ver',
            sin: 'Sin',
            borrarFiltro: "Borrar Filtro",
            today: "Hoy",
            cambiarFiltros: "Cambiar Filtros",
            consultasComandos: "Elija una consulta",
            seleccionar: "Seleccionar",
        },
        main_menu: {
            inventarios: 'Inventario',
            tareas: 'Tareas',
            tipostarea: 'Tipos de Tarea',
            planificacioninspectores: 'Planif. Inspectores',
            ajustes: 'Ajustes',
            inicio: 'Inicio',
            clientes: 'Clientes',
            proveedores: 'Proveedores',
            items: 'Items',
            equipos: 'Equipos',
            materias: 'Materias',
            componentesproteccion: 'Componentes Protección',
            provincias: 'Provincias',
            municipios: 'Municipios',
            comunidades: 'Comunidades Autónomas',
            paises: 'Paises',
            ofertas: 'Ofertas',
            pedidos: 'Pedidos',
            calendario: 'Calendario',
            listadocalendarioDIA: 'Resumen Día',
            listadocalendarioSEMANA: 'Resumen Semana',
            listadocalendarioMES: 'Resumen Mes',
            lineaspedidos: 'Líneas de Pedidos',
            inspecciones: 'Expedientes',
            formatos: 'Formatos',
            albaranes: 'Albaranes',
            facturas: 'Facturas',
            marcas: 'Marcas',
            modelos: 'Modelos',
            coches: 'Coches',
            conflictos: 'Incidencias',
            usuarios: 'Usuarios',
            configuracion: 'Configuración',
            gruposcliente: 'Grupos de Clientes',
            gruposproveedor: 'Grupos de Proveedores',
            empresas: 'Empresas',
            tiposequipo: 'Tipos de Equipo',
            tipositem: 'Tipos de Item',
            lineasnegocio: 'Líneas de Negocio',
            tipodocumento: 'Tipos de Documentos',
            delegaciones: 'Delegaciones',
            centrostrabajo: 'Centros de Trabajo',
            evaluadorjs: 'Evaluador JS',
            servicios: 'Servicios',
            combos: 'Opciones personalizadas',
            documentos: 'Documentos',
            fichaje: 'Fichajes',
            departamentos: 'Departamentos',
            empleados: 'Empleados',
            empresasmantenedoras: 'Empresas Mantenedoras',
            condicionespago: 'Condiciones de Pago',
            viaspago: 'Vías de Pago',
            listasprecios: 'Listas de Precios',
            cerrar_sesion: 'Cerrar sesión',
            notificaciones: 'Notificaciones',
            informes: 'Informes',
            email_data: 'Emails',
            documentacion: 'Documentación',
        },
        notificaciones: {
            notificaciones: 'Notificaciones',
            nuevo: 'Nueva notificación',
            usuario: 'Usuario',
            titulo: 'Título',
            mensaje: 'Mensaje',
            urlInterna: 'URL Interna',
            urlExterna: 'URL Externa',
            enviarATodos: 'Enviar a todos'

        },
        tipostarea: {
            nuevo: 'Nuevo Tipo de Tarea',
            nombre: 'Nombre',
            guardado_ok: 'Tipo de Tarea guardada correctamente',
            tipostarea: 'Tipo de Tarea',
            editar: 'Editar',
            codigo: 'Código'

        },
        tarea: {
            nuevo: 'Nueva Tarea',
            guardado_ok: 'Tarea guardada correctamente',
            editar: 'Editar',
            tarea: 'Tarea',
            codigo: 'Código',
            descripcion: 'Descripción',
            empleado: 'Empleado',
            comentarios: 'Comentarios',
            comentariosPlanificacion: 'Comentarios Planificación',
            tipoTarea: 'Tipo de Tarea',
            fecha: 'Fecha',
            hora_inicio: 'Hora Inicio',
            hora_final: 'Hora Final',
            estado: 'Estado',
            tiempoEstimado: 'Tiempo Estimado (Minutos)',
            importe: 'Importe',
            numeroPedido: 'Nº Pedido',
            numeroLineaPedido: 'Nº Línea Pedido',
            numeroOferta: 'Nº Oferta',
            codigoCliente: 'Cod. Cliente',
            codigoOportunidad: 'Cod. Oportunidad',
            codigoCampania: 'Cod. Campaña'
        },
        campania: {
            nuevo: 'Nueva Campaña',
            add: 'Añadir',
            guardado_ok: 'Campaña guardada correctamente',
            editar: 'Editar',
            campania: 'Campaña',
            codigo: 'Código',
            nombre: 'Nombre',
            estrategia: 'Estrategia',
            fechaInicial: 'Fecha Inicial',
            fechaFinal: 'Fecha Final',
            responsable: 'Responsable',
            clientes: 'Clientes',
            ofertas: 'Ofertas',
            pedidos: 'Pedidos',
            lineasPedidos: 'Lineas de pedido',
            procesos: 'Procesos de venta',
            objetivosComercial: 'Objetivos por comercial',
            documentos: 'Documentos',
            cargarClientes: 'Cargar clientes',
            cargarOfertas: 'Cargar ofertas',
            cargarPedidos: 'Cargar pedidos',
            cargarLineasPedidos: 'Cargar líneas de pedidos',
            cerrar: 'Cerrar',
            orden: 'Orden',
            tipoTarea: 'Tipo de tarea',
            numeroTareas: 'Número de tareas',
            ponderacion: 'Ponderación',
            comercial: 'Comercial',
            numOportunidades: 'Nº Oportunidades',
            vntc: 'VNTC',
            vntoc: 'VNTOC',
            vntcc: 'VNTCC',
            vntocr: 'VNTOCR',
            vntccr: 'VNTCCR',
            tareasRealizadas: 'Tareas Realizadas',
            oportunidades: 'Oportunidades',
            updateData: 'Actualizar datos',
        },
        oportunidad: {
            nuevo: 'Nueva Oportunidad',
            guardado_ok: 'Oportunidad guardada correctamente',
            editar: 'Editar',
            oportunidad: 'Oportunidad',
            codigo: 'Código',
            gestor: 'Gestor',
            estado: 'Estado',
            cliente: 'Cliente',
            oferta: 'Oferta',
            pedido: 'Pedido',
            lineaPedido: 'Línea de pedido',
            documentos: 'Documentos',
            documentosOrigen: 'Documentos origen',
            cargarCliente: 'Cargar cliente',
            cargarOferta: 'Cargar oferta',
            numOferta: 'Nº oferta',
            cargarPedido: 'Cargar pedido',
            numPedido: 'Nº pedido',
            cargarLineaPedido: 'Cargar línea de pedido',
            numLinea: 'Nº línea',
        },
        textos: {
            forzarPaginaNueva: 'Forzar texto en nueva página',
            alInicio: 'Mostrar al inicio',
        },
        inventario: {
            nuevo: 'Nuevo Inventario',
            guardado_ok: 'Inventario guardado correctamente',
            editar: 'Editar',
            inventario: 'Inventario',
            codigo: 'Código',
            lineanegocio: 'Línea Negocio',
            nombre: 'Nombre',
            marca: 'Marca',
            modelo: 'Modelo',
            descripcion: 'Descripción',
            empleados: 'Empleados',
            fechaalta: 'Fecha de alta',
            fechabaja: 'Fecha de baja'
        },
        componentes: {
            direccion: {
                codigo_postal: 'Código Postal',
                direccion: 'Dirección',
                poblacion: 'Población',
                provincia: 'Provincia',
                comunidad: 'Comunidad Autónoma',
                pais: 'País',
            },
            cuenta: {
                pais: 'País',
                nombreBanco: 'Nombre Banco',
                bic: 'BIC / SWIFT',
                nombreCuenta: 'Nombre Cuenta',
                codigoBanco: 'Código Banco',
                sucursal: 'Sucursal',
                claveControl: 'Clave Control',
                cuenta: 'Cuenta',
                iban: 'IBAN',
                idMandato: 'Id Mandato',
                fechaFirma: 'Fecha Firma',
            },
            comprobacion: {
                instrucciones: 'Escribe aquí las instrucciones...',
                peso: "Peso",
            },
            caracteristicas: {
                opciones: 'Opciones',
                vincularValorInspeccion: 'Vincular valor de inspección',
                valorVinculado: 'Valor vinculado',
                importarOpciones: 'Importar opciones',
                importar: 'Importar',
                grupoOpciones: 'Grupo de opciones'
            }
        },
        perfil: {
            perfil: 'Perfil',
            cambiar_pass: 'Cambiar Contraseña',
            new_pass: 'Nueva Contraseña',
            repeat_new_pass: 'Repita Nueva Contraseña',
            actual_pass: 'Contraseña Actual',
            cliente_contacto: 'Contacto',
            nombre: 'Nombre',
            firma: 'Añadir firma',
        },
        usuarios: {
            login: {
                usuario: 'Usuario',
                contrasena: 'Contraseña',
                entrar: 'Entrar',
                olvide_mi_contrasena: 'Olvidé mi contraseña'
            },
            usuarios: {
                usuarios: 'Usuarios'
            },
            usuario_form: {
                username: 'Username',
                nombre: 'Nombre',
                apellidos: 'Apellidos',
                telefono: 'Teléfono',
                email: 'Email',
                iniciales: 'Iniciales',
                roles: 'Roles',
                usuario: 'Usuario',
                editar_usuario: 'Editar usuario',
                nuevo_usuario: 'Nuevo usuario',
                usuario_guardado: 'Usuario guardado',
                rol: 'Rol',
                clientes_asociados: 'Clientes asociados',
                cliente: 'Cliente',
                add: 'Añadir',
                inspector_data: 'Datos de inspector',
                foto: 'Seleccionar Foto',
                firma: 'Firma',
                select_firma: 'Seleccionar Firma'
            }
        },
        facturas: {
            facturas: 'Facturas',
            numero: 'Número Factura',
            estado: 'Estado',
            cliente: 'Cliente',
            fecha_emision: 'Fecha Emisión',
            fecha_vencimiento: 'Fecha Vencimiento',
            subtotal: 'Subtotal',
            totalIva: 'Total IVA',
            totalFactura: 'Total Factura',
            lineas: 'Líneas',
            desde: 'Desde',
            hasta: 'Hasta',
            generar: 'Generar Factura',
            a_quien_facturar: 'A Quién realizar las facturas',
            facturar_hasta_dia: 'Facturar hasta el día:',
            que_facturar: 'Qué Facturar',
            comoFacturar: 'Facturar a nombre de:',
            envio_email_automatico: 'Enviar email con la factura automáticamente a los clientes',
            factura_guardada: 'Factura Guardada',
            concepto: 'Concepto',
            codigo_inspeccion: 'Expediente',
            codigo_pedido: 'Pedido',
            matricula: 'Matrícula',
            bastidor: 'Bastidor',
            total: 'Total',
            tipoIva: 'Tipo IVA',
            precio_unitario: 'Precio Unitario',
            cantidad: 'Cantidad',
            factura: 'Factura',
            editar_factura: 'Editar Factura',
            motivo_cancel: 'Motivo Cancelación',
            facturas_generadas: 'Facturas Generadas',
            abierta: 'Abierta',
            cerrada: 'Cerrada',
            cancelada: 'Cancelada',
            fechaEmision: 'F. Emisión',
            fechaVencimiento: 'F. Vencimiento',
            fechaFactura: 'F. Factura',
            editar: 'Editar',
            eliminar: 'Eliminar',
            cancelar: 'Cancelar',
            importeaplicado: 'Importe aplicado',
            saldovencido: 'Saldo vencido',
            pregunta_cancelar: "¿Está seguro de que desea cancelar esta factura?",
            guardado_ok: 'Factura guardada correctamente',
        },
        finanzas: {
            viaPago: 'Vía de pago',
            cuenta: 'Cuenta',
            plazoDias: 'Plazo de días',
            adelantoCobro: 'Adelanto de cobro',
        },
        items: {
            items: 'Items',
            equipos: 'Equipos',
            item: 'Item',
            equipo: 'Equipo',
            editar: 'Editar',
            nuevo: 'Nuevo',
            codigo: 'Código',
            nombre: 'Nombre',
            cliente: 'Cliente',
            instalacion: 'Instalación',
            formato: 'Formato',
            desde: 'Desde',
            hasta: 'Hasta',
            fecha_ultima_revision: 'Fecha Últ. Rev.',
            fecha_proxima_revision: 'Fecha Próx. Rev.',
            fechaProximaVerificacion: 'Fecha Prox. Verif.',
            fechaProximaCalibracion: 'Fecha Prox. Calib.',
            fechaBaja: 'Fecha Baja',
            fechaAlta: 'Fecha Alta',
            delegacion: 'Delegación',
            empleadosAsignados: 'Empleados Asignados',
            tipo: 'Tipo',
            materia: 'Materia',
            materiaAplica: 'Materia a la que aplica',
            formatosAplica: 'Formatos a los que aplica',
            item_guardado: 'Item guardado correctamente',
            equipo_guardado: 'Equipo guardado correctamente',
            icAdministrador: 'IC Administrador',
            icInstalador: 'IC Instalador',
            icMantenedor: 'IC Mantenedor',
            icAsociacion: 'IC Asociación',
            administrador: 'Administrador',
            instalador: 'Instalador',
            mantenedor: 'Mantenedor',
            asociacion: 'Asociación',
            unidad_movil: 'Unidad Móvil',
            mostrarSiCaducado: 'Mostrar al inspector aunque este caducado',
            diasAvisoEmail: 'Días para Avisar por email',
            tipoEquipo: 'Tipo Equipo',
        },
        equipos: {
            nuevo: 'Nuevo'

        },
        materias: {
            materias: 'Materias',
            materia: 'Materia',
            editar: 'Editar',
            nuevo: 'Nueva',
            codigo: 'Código',
            codigoExpediente: 'Código Expediente',
            nombre: 'Nombre',
            percentilAuditoria: 'Porcentaje de auditoría Manual',
            materia_guardada: 'Materia guardada correctamente'
        },
        gruposcliente: {
            gruposcliente: 'Grupos de Cliente',
            grupocliente: 'Grupo de Cliente',
            editar: 'Editar',
            nuevo: 'Nuevo',
            codigo: 'Código',
            nombre: 'Nombre',
            guardado_ok: 'Grupo guardado correctamente'
        },
        gruposproveedor: {
            gruposproveedor: 'Grupos de Proveedores',
            grupoproveedor: 'Grupo de Proveedor',
            editar: 'Editar',
            nuevo: 'Nuevo',
            codigo: 'Código',
            nombre: 'Nombre',
            guardado_ok: 'Grupo guardado correctamente'
        },
        empresas: {
            empresas: 'Empresas',
            empresa: 'Empresa',
            editar: 'Editar',
            nuevo: 'Nueva',
            codigo: 'Código',
            nombre: 'Nombre',
            importelimiteautoaprobacion: 'Importe máximo para autoaprobación',
            guardado_ok: 'Empresa guardada correctamente'
        },
        tipositem: {
            tipositem: 'Tipos de Item',
            tipoitem: 'Tipo de Item',
            tiposequipo: 'Tipos de Equipo',
            tipoequipo: 'Tipo de Equipo',
            editar: 'Editar',
            nuevo: 'Nueva',
            codigo: 'Código',
            nombre: 'Nombre',
            guardado_ok: 'Tipo guardado correctamente',
            plazoCalibrado: 'Plazo Calibrado (Dias)',
            plazoVerificacion: 'Plazo Verificación (Dias)',
            plazoRevision: 'Plazo Revisión (Dias)',
        },
        lineas: {
            subservicio: 'Sub-Servicio'
        },
        lineasnegocio: {
            lineasnegocio: 'Líneas de Negocio',
            lineanegocio: 'Línea de Negocio',
            editar: 'Editar',
            nuevo: 'Nueva',
            codigo: 'Código',
            nombre: 'Nombre',
            guardado_ok: 'Línea de Negocio guardada correctamente',
            empresaasociada: 'Empresa Asociada'
        },
        condicionespago: {
            condicionespago: 'Condiciones de Pago',
            condicionpago: 'Condición de Pago',
            editar: 'Editar',
            nuevo: 'Nueva',
            codigo: 'Código',
            nombre: 'Nombre',
            guardado_ok: 'Condición de Pago guardada correctamente',
            viaPago: 'Vía de pago',
            cuenta: 'Cuenta',
            plazoDias: 'Plazo (días)',
            activa: 'Activa',
        },
        viaspago: {
            viaspago: 'Vías de Pago',
            viapago: 'Vía de Pago',
            editar: 'Editar',
            nuevo: 'Nueva',
            codigo: 'Código',
            nombre: 'Nombre',
            tipo: 'Tipo',
            guardado_ok: 'Vía de Pago guardada correctamente'
        },
        listasprecios: {
            listasprecios: 'Listas de Precios',
            listaprecios: 'Lista de Precios',
            editar: 'Editar',
            nuevo: 'Nueva',
            codigo: 'Código',
            nombre: 'Nombre',
            tipo: 'Tipo',
            guardado_ok: 'Lista de Precios guardada correctamente'
        },
        delegaciones: {
            delegaciones: 'Delegaciones',
            delegacion: 'Delegación',
            editar: 'Editar',
            nuevo: 'Nueva',
            codigo: 'Código',
            nombre: 'Nombre',
            descripcion: 'Descripción',
            guardado_ok: 'Delegación guardada correctamente',
            empresaasociada: 'Empresa Asociada',
            responsableequipos: 'Responsable de los equipos',
            preavisoverificacion: 'Preaviso para verificación equipos (días)',
            preavisocalibrado: 'Preaviso para calibrado de equipos (días)'
        },
        centrostrabajo: {
            centrostrabajo: 'Centros de Trabajo',
            centrotrabajo: 'Centro de Trabajo',
            add: 'Añadir',
            editar: 'Editar',
            nuevo: 'Nuevo',
            codigo: 'Código',
            nombre: 'Nombre',
            direccion: 'Dirección',
            guardado_ok: 'Centro de Trabajo guardado correctamente',
            delegacion: 'Delegacion Asociada',
            canalescomunicacion: 'Canales de Comunicación',
            tipo: 'Tipo',
            valor: 'Valor',
            telefono: 'Teléfono',
            telefono_movil: 'Teléfono Móvil',
            fax: 'Fax',
            email: 'Email',
            web: 'Web',
            provincia: 'Provincia'
        },
        evaluadorjs: {
            evaluadoresjs: 'Evaluadores JS',
            evaluadorjs: 'Evaluador JS',
            add: 'Añadir',
            editar: 'Editar',
            nuevo: 'Nuevo',
            nombre: 'Nombre',
            js: 'Código JS',
            ambito: 'Ámbito',
            guardado_ok: 'Evaluador JS guardado correctamente'
        },
        empleados: {
            activo: 'Activo',
            empleados: 'Empleados',
            empleado: 'Empleado',
            fecha_alta: 'Fecha de alta',
            fecha_baja: 'Fecha de baja',
            add: 'Añadir',
            editar: 'Editar Empleado',
            nuevo: 'Nuevo',
            username: 'Username',
            nombre: 'Nombre',
            email: 'Email',
            activo: 'Activo',
            fecha_alta: 'Fecha Alta',
            fecha_baja: 'Fecha Baja',
            dni: 'DNI',
            telefono: 'Teléfono',
            rol: 'Rol',
            oficina: 'Oficina',
            codEmpresa: 'Empresa',
            codLineaNegocio: 'Línea Negocio',
            codDelegacion: 'Delegación',
            codCentroTrabajo: 'Centro Trabajo',
            codDepartamento: 'Departamento',
            servicios: 'Servicios',
            crearuser: 'Crear Usuario de aplicación',
            createdUser: 'Usuario de App',
            guardado_ok: 'Empleado guardado correctamente',
            auditoria_manual: 'Auditar manualmente',
            colegas: 'También se ocupa de lo de ...'
        },
        servicios: {
            servicios: 'Servicios',
            exclusiones: 'Exclusiones',
            vigencia: 'Vigencia (meses)',
            servicio: 'Servicio',
            objeto: 'Objeto',
            alcance: 'Alcance',
            normativa: 'Normativa',
            preparacionitem: 'Preparación del Item',
            adicional1: 'Texto adicional 1',
            adicional2: 'Texto adicional 2',
            add: 'Añadir',
            editar: 'Editar Servicio',
            nuevo: 'Nuevo Servicio',
            userText: 'Texto de usuario (Campo que se muestra en líneas de oferta, pedido y factura)',
            codigo: 'Código',
            nombre: 'Nombre',
            materia: 'Materia',
            precios: 'Precios',
            precioBasico: 'Precio Básico',
            porcSegunda: '% Segunda',
            blocPorcSegundas: 'Bloquea % Segunda',
            porcMinSegunda: '% Mínima Segunda',
            unidadInspeccion: 'Unidad Inspección',
            servicioUnidadExtra: 'Servicio de la Unidad Extra',
            tiempo: 'Tiempo Estimado (Minutos)',
            tiempoCampo: 'Tiempo Estimado Campo (Minutos)',
            tiempoOficina: 'Tiempo Estimado Oficina  (Minutos)',
            formatos: 'Formatos',
            formato: 'Formato',
            listaPrecios: 'Lista de Precios',
            precio: 'Precio',
            moneda: 'Moneda',
            tasas: 'Tasas',
            subservicios: 'Sub-Servicios',
            textos: 'Textos',
            orden: 'Orden del texto',
            titulo: 'Título',
            html: 'Texto',
            obligatorio: 'Obligatorio',
            sololectura: 'No modificable',
            tasaDefecto: 'Tasa Defecto',
            tasaDefecto2: 'Tasa Defecto 2as',
            comunidad: 'Comunidad',
            valor: 'Valor',
            valor2as: 'Valor 2as',
            cualificaciones: 'Cualificaciones',
            tiempoEstimado: 'Tiempo Estimado (Minutos)',
            codCuentaMayor: 'Código Cuenta Mayor',
            guardado_ok: 'Servicio guardado correctamente',
            textoDocumentacionDefecto: 'Texto Documentación Defecto',
            textoDocumentacion: 'Texto Documentación',
            textosDocumentacion: 'Textos Documentación',
            exportarTexto: {
                title: 'Exportar Texto a otros servicios',
                exportar: 'Enviar a',
                eliminar: 'Eliminar de',
                servicios: 'Servicios',
                confirmar: '¿Está seguro de que desea exportar el texto a los servicios seleccionados?',
                confirmacion: 'Dar a OK para enviar el texto a los servicios seleccionados. Se reemplazará el texto actual si existe o se creará en caso de no existir.',
                confirmarEliminar: '¿Está seguro de que desea eliminar el texto de los servicios seleccionados?',
                confirmacionEliminar: 'Dar a OK para eliminar el texto de los servicios seleccionados.',
                exportado: 'Texto exportado correctamente',
                errorServicios: 'Debe seleccionar al menos un servicio para exportar el texto',
            }

        },
        combos: {
            combos: 'Opciones personalizadas',
            combo: 'Grupo de opciones',
            editar: 'Editar',
            nuevo: 'Nuevo grupo',
            nombre: 'Nombre de grupo',
            tipo: 'Tipo',
            tipos: {
                simple: 'Simple',
                texto: 'Texto',
                html: 'HTML',
                numero: 'Número'
            },
            cantidad_opciones: 'Cantidad de opciones',
            opciones: 'Opciones',
            nombre_opcion: 'Texto',
            codigo_opcion: 'Código',
            sin_opciones: '** No hay ninguna opción definida en el grupo',
            nueva_opcion: 'Nueva opción',
            combo_guardado: 'Grupo de opciones guardado correctamente'
        },
        documentos: {
            documentos: 'Documentos',
            documento: 'Documento',
            editar: 'Editar',
            nuevo: 'Nuevo documento',
            tiposDocumento: 'Tipos de documento',
            tipoDocumento: 'Tipo de documento',
            path: 'Ruta',
            nombre: 'Nombre',
            archivo: 'Archivo',
            plantilla: 'Plantilla',
            importarPlantilla: 'Importar plantilla',
            descargarPDFReferencias: 'Descargar PDF de referencias',
            expedientePrueba: 'Expediente de prueba',
            ofertaPrueba: 'Oferta de prueba',
            lineaPrueba: 'Linea de pedido de prueba',
            pruebaOfertaCorreo: 'Enviar correo de prueba de oferta',
            pruebaOportunidadCorreo: 'Enviar correo de prueba de oportunidad',
            probarPlantilla: 'Probar plantilla',
            fichero_existe_confirmacion: 'Guardar el fichero sobreescribirá el fichero que existe actualmente en la ruta indicada. ¿Desea continuar?',
            alerta_sin_fichero: 'No se puede guardar sin haber incluido un archivo.',
            correoPruebaEnviado: 'Se ha enviado el correo de prueba a la dirección: ',
            nueva_opcion: 'Nueva opción',
            documento_guardado: 'Documento guardado correctamente',
            camposPdf: {
                tipo: 'Tipo',
                nombre: 'Campo',
                valor: 'Valor',
                valoresEspeciales: 'Valores especiales',
            }
        },
        departamentos: {
            departamentos: 'Departamentos',
            departamento: 'Departamento',
            editar: 'Editar',
            nuevo: 'Nuevo',
            codigo: 'Código',
            nombre: 'Nombre',
            responsable: 'Responsable',
            guardado_ok: 'Departamento guardado correctamente',
            centrotrabajo: 'Centro de Trabajo'
        },
        empresasmantenedoras: {
            empresasmantenedoras: 'Empresas Mantenedoras',
            empresamantenedora: 'Empresa Mantenedora',
            editar: 'Editar',
            nuevo: 'Nueva',
            codigo: 'Código',
            nombre: 'Nombre',
            direccion: 'Dirección',
            guardado_ok: 'Empresa Mantenedora guardada correctamente'
        },
        formatos: {
            formatos: 'Formatos',
            formato: 'Formato',
            editar: 'Editar',
            nuevo: 'Nuevo',
            version: 'Versión',
            versionSi: "Versión",
            versionNo: "Original",
            inspeccionable: 'Inspeccionable',
            tipomedida: 'Con Tipo de Medida a Adoptar',
            condicionado: 'Condicionado',
            nuevoFormato: 'Nuevo Formato',
            codigo: 'Código',
            nombre: 'Nombre',
            fechaDesde: 'Aplica desde',
            fechaHasta: 'Aplica hasta',
            materia: 'Materia',
            descripcion: 'Descripción',
            auditoria: 'Auditoría',
            duplicar: 'Duplicar',
            nuevoCodigo: 'Nuevo Código',
            comprobaciones: 'Comprobaciones',
            condiciones: 'Condiciones',
            anexos: 'Anexos',
            anexo: 'Anexo',
            add: 'Añadir',
            ARITMETICO: 'Aritméticos',
            COMPARACION: 'Comparación',
            LOGICO: 'Lógicos',
            PARENTESIS: 'Paréntesis',
            valores: 'Valores',
            funciones: 'Funciones',
            cuando: 'Cuando',
            entonces: 'Entonces',
            tipo: 'Tipo',
            aplicaEn: 'Aplica en',
            activa: 'Activa',
            datosGenerales: 'Datos Generales',
            textoCategoriasDefectos: 'Texto Categorías Defectos',
            defectoLeve: 'Defecto Leve',
            defectoGrave: 'Defecto Grave',
            defectoMuyGrave: 'Defecto Muy Grave',
            codigoExpediente: 'Código Expediente',
            mensajeAMostrar: 'Mensaje a mostrar cuando NO cumple',
            formato_guardado: 'Formato guardado correctamente',
            precargas: 'Precargas',
            subformatos: 'Subformatos',
            nombre_precarga: 'Nombre de la precarga',
            comprobacion: 'Comprobación',
            valores: 'Valores',
            mensaje_nueva_precarga: '** No se ha configurado ningún perfil de precargas',
            nueva_precarga: 'Nueva precarga',
            modelosPlantilla: 'Modelos de informe',
            nueva_plantilla: 'Nuevo  modelo informe',
            nombre_plantilla: 'Nombre del informe',
            mensaje_nueva_plantilla: '** Elija documentos de plantilla de informe y toma de datos',
            noCondicionesPlantilla: '** No hay comprobaciones de características asociadas al informe',
            noAnexosPlantilla: '** No hay anexos asociados al informe',
            resultadoInspeccion: 'Resultado de inspección',
            calificacion: 'Calificación',
            resultadoInspeccionFavorable: 'Favorable',
            resultadoInspeccionNoFavorable: 'No favorable',
            tipoInspeccion: 'Tipo de inspección',
            tipoInspeccionPrimera: 'Primeras',
            tipoInspeccionSegundas: 'Segundas',
            anadirValor: 'Añadir valor',
            anadirCondicion: 'Añadir condición',
            anadirAnexo: 'Añadir anexo',
            certificado: 'Certificado',
            tomaDatos: 'Toma de datos',
            exportarComprobaciones: 'Exportar comprobaciones y defectos',
            importarComprobaciones: 'Importar comprobaciones y defectos',
            importacionComprobaciones: 'Fichero a importar',
            periodicidadMeses: 'Periodicidad (Meses)',
            provincias: 'Provincias',
            comprobacionesEnPlantillas: 'Copiar comprobaciones en plantilla',
            materiaEnItem: 'Usar materia en el ID del item',
            tiempoInspMin: 'Tiempo de inspección mínima (minutos)',
            tiempoInspMax: 'Tiempo de inspección máxima (minutos)',
        },
        componenteproteccion: {
            componentesproteccion: 'Componentes Protección',
            componenteproteccion: 'Componente Protección',
            editar: 'Editar',
            nuevo: 'Nueva',
            codigo: 'Código',
            tipo: 'Tipo',
            nombre: 'Nombre',
            componenteproteccion_guardado: 'Componente Protección guardado correctamente'
        },
        provincias: {
            provincias: 'Provincias',
            provincia: 'Provincia',
            editar: 'Editar',
            nuevo: 'Nueva',
            codigo: 'Código',
            nombre: 'Nombre',
            provincia_guardada: 'Provincia guardada correctamente'
        },
        municipios: {
            municipios: 'Municipios',
            municipio: 'Municipio',
            editar: 'Editar',
            nuevo: 'Nuevo',
            codigo: 'Código',
            cp: 'Código Postal',
            nombre: 'Nombre',
            poblacion: 'Población',
            municipio_guardado: 'Municipio guardado correctamente', 
            provincia: 'Provincia', 
            comunidad: 'Comunidad', 
            añadir: 'Añadir'
        },
        comunidades: {
            comunidades: 'Comunidades Autónomas',
            comunidad: 'Comunidad Autónoma',
            proveedor: 'Proveedor',
            editar: 'Editar',
            nuevo: 'Nueva',
            codigo: 'Código',
            nombre: 'Nombre',
            provincias: 'Provincias',
            comunidad_guardada: 'Comunidad Autónoma guardada correctamente',
            servicioTasas: 'Servicio de Tasas',
            codCuentaMayorTasas: 'Código Cuenta Mayor Tasas',
            plazos: {
                tipo_plazo: 'Tipo de Plazo',
                tipo_inspeccion: 'Tipo de Inspección',
                plazo: 'Plazo (días)',
                materias: 'Materias',
                serviciosExcluir: 'Servicios a excluir',
                calificaciones_inspeccion: 'Calificaciones de Inspección',
            },
            calificacionesExcluir: 'Calificaciones a excluir de nuevas primeras'
        },
        paises: {
            paises: 'Paises',
            pais: 'País',
            editar: 'Editar',
            nuevo: 'Nueva',
            codigo: 'Código',
            nombre: 'Nombre',
            pais_guardado: 'País guardado correctamente'
        },
        tiposdocumentos: {
            tiposdocumentos: 'Tipos de documentos',
            tipodocumento: 'Tipo de documento',
            editar: 'Editar',
            nuevo: 'Nueva',
            tipo: 'Tipo',
            nombre: 'Nombre',
            tipodocumento_guardado: 'Tipo de documento guardado correctamente'
        },
        pedidos: {
            conforme: 'Conforme',
            reparto: 'Reparto',
            pedidos: 'Pedidos',
            contratacion: 'Contratación',
            terceros: 'Subc. + Com.',
            totalTasas: 'Total Tasas',
            lineaspedidos: 'Líneas de Pedidos',
            pedido: 'Pedido',
            numero: 'Número',
            nombre: 'Nombre',
            cliente: 'Cliente',
            codigo: 'Código',
            codigoCliente: 'Código Cliente',
            nombreCliente: 'Nombre Cliente',
            estado: 'Estado',
            estadoLinea: 'Estado Línea',
            estadoPedido: 'Estado Pedido',
            fechaPedido: 'Fecha Pedido',
            fechaContratacion: 'Fecha Contratación',
            fechaEjecucion: 'Fecha Ejecución',
            fechaPlanificacion: 'Fecha Planificación',
            editar: 'Editar Pedido',
            nuevo: 'Nuevo Pedido',
            empresa: 'Empresa',
            delegacion: 'Delegación',
            centrotrabajo: 'Centro de Trabajo',
            departamento: 'Departamento',
            lineaNegocio: 'Línea de Negocio',
            empleado: 'Empleado',
            usuarioEjecucion: 'Usuario Ejecución',
            tipoPedido: 'Tipo Pedido',
            moneda: 'Moneda',
            condicionPago: 'Condiciones de Pago',
            viaPago: 'Vía de Pago',
            lineas: 'Líneas',
            add: 'Añadir',
            creado: 'Creado',
            ejecucion: 'En Ejecución',
            planificado: 'Planificado',
            ejecutado: 'Ejecutado',
            facturado: 'Facturado',
            cancelado: 'Cancelado',
            cerrado: 'Cerrado',
            empleadoContratacion: 'Empleado Contratación',
            creada: 'Creada',
            abierta: 'Abierta',
            cerrada: 'Cerrada',
            cancelada: 'Cancelada',
            tipoLinea: 'Tipo de Línea',
            servicio: 'Servicio',
            instalacion: 'Instalación',
            item: 'Item',
            texto: 'Texto',
            precio: 'Precio Unitario',
            porcIVA: 'Porcentaje IVA',
            porcDescuento: 'Porcentaje Dto',
            porcSegunda: 'Porcentaje Segunda',
            importeSegunda: 'Importe Segunda',
            importe: 'Importe',
            cantidad: 'Cantidad',
            tiempoEstimadoOficina: 'Tiempo Estimado Oficina (Minutos)',
            tiempoEstimadoCampo: 'Tiempo Estimado Campo (Minutos)',
            tiempo: 'Tiempo (Minutos)',
            comentarios: 'Comentarios',
            comentariosLineaCerrada: 'Comentarios en línea cerrada',
            anyoEjecucion: 'Año Ejecución',
            numeroOferta: 'Número Oferta',
            codigoOferta: 'Código Oferta',
            subcontratacion: 'Subcontratación',
            comision: 'Comisión',
            proveedor: 'Proveedor',
            codigoOferta: 'Código Oferta',
            administrador: 'Administrador',
            instalador: 'Instalador',
            mantenedor: 'Mantenedor',
            asociacion: 'Asociación',
            codCuentaMayor: 'Código Cuenta Mayor',
            codNormaReparto: 'Código Norma Reparto',
            lineaEjecutable: 'Línea Ejecutable',
            totalSubcontratacion: 'Total Subcontratación',
            totalComision: 'Total Comisión',
            totalTerceros: 'Total Terceros',
            subtotal: 'Subtotal',
            totalIVA: 'IVA',
            totalSinIVA: 'Total sin IVA',
            total: 'Total',
            totales: 'Totales',
            datosLinea: 'Datos Línea',
            tasas: 'Tasas',
            num: 'Nº',
            seleccionar: 'Seleccionada',
            linea: 'Línea',
            inspector: 'Inspector',
            inspectorSupervisado: 'Inspector supervisado',
            supervisadoFormacion: 'Supervisado en formación',
            observador: 'Observador',
            supervisor: 'Supervisor',
            inspectores: 'Inspectores',
            observaciones: 'Observaciones',
            fecha_visita: 'Fecha Visita',
            hora_primera_visita: 'Hora Primera Visita',
            rol: 'Rol',
            direccionQuedar: 'Dirección donde quedar con el cliente',
            posicion: 'Posición',
            direccion: 'Dirección',
            descripcion: 'Descripción',
            hora_inicio: 'Hora Inicio',
            desde: 'Desde',
            hasta: 'Hasta',
            guardado_ok: 'Pedido guardado correctamente',
            siguiente: 'Siguiente',
            anterior: 'Anterior',
            cerrar: 'Cerrar',
            nuevaInstalacion: 'Nueva Instalación',
            nuevoItem: 'Nuevo Item',
            direccionInspeccion: 'Dirección Inspección',
            tiempos: 'Tiempos',
            importes: 'Importes',
            impuestos: 'Impuestos',
            colaboradores: 'Colaboradores',
            resumen: 'Resumen',
            planificar: 'Planificar Pedido',
            verOferta: 'Ver Oferta',
            eliminar: 'Borrar Pedido',
            gestionPlanificacion: 'Gestión Planificación',
            guardar: 'Guardar',
            segundas_ok: 'Línea de 2as creada correctamente',
            nuevaprimera_ok: 'Línea de 1as creada correctamente',
            vinculacion_ok: 'Línea vinculada correctamente',
            facturar: 'Facturar Pedido',
            lineaspedido: {
                peticion_documentacion: 'Petición de Documentación',
                plantillaEmail: 'Plantilla Email',
                item: 'Item',
                formacrear: 'Donde crear la nueva línea',
                pedidoAsociar: 'Indicar el pedido al que se creará la nueva línea',
                pedidoNuevaPrimera: 'Pedido de nueva primera',
                numeroLineaNuevaPrimera: 'Número de línea de nueva primera',
                pedidoAnteriorNegativa: 'Pedido de anterior negativa',
                numeroLineaAnteriorNegativa: 'Número de línea de anterior negativa',
            }
        },
        ofertas: {
            contratacion: 'Contratación',
            terceros: 'Subc. + Com.',
            ofertas: 'Ofertas',
            telefono: 'Teléfono',
            telefonoMovil: 'Teléfono Móvil',
            aprobar: 'Aprobar Oferta',
            aprobadaOK: 'Oferta aprobada correctamente',
            oferta: 'Oferta',
            numero: 'Número',
            numeroSAP: 'Número SAP',
            resumen: 'Resumen',
            nuevoItem: 'Nuevo Item',
            adjuntarAceptacion: 'Adjuntar Aceptación',
            verAceptacion: 'Ver Documento Aceptación',
            contactocliente: 'Contacto del Cliente',
            emailcontacto: 'Email del Contacto',
            cambio_estado_ok: 'Se ha realizado el cambio de estado correctamente',
            guardado_public: 'Se han registrado correctamente sus comentarios.',
            formTitle: 'Formulario de aceptación de Oferta',
            enviar: 'Enviar Automáticamente',
            enviadaManual: 'Oferta ya enviada a cliente',
            comprobarFirmaCliente: 'Comprobar Firma de Cliente',
            finalizar: 'Finalizar',
            volverEditar: 'Volver a Edición',
            dni: 'DNI',
            iban: 'IBAN',
            imprimir: 'Imprimir',
            acciones: 'Acciones',
            descargar: 'Descargar',
            loading: 'Cargando Oferta... ',
            nuevaInstalacion: 'Nueva Instalación',
            aceptacionOferta: 'Aceptación de Oferta',
            seleccionar: 'Seleccionar',
            anterior: 'Anterior',
            siguiente: 'Siguiente',
            cerrar: 'Cerrar',
            guardar: 'Guardar',
            crearLinea: 'Añadir Línea',
            tiempos: 'Tiempos',
            importes: 'Importes',
            impuestos: 'Impuestos',
            direccionInspeccion: 'Dirección Inspeccion',
            colaboradores: 'Colaboradores',
            num: 'Nº',
            nombre: 'Nombre',
            cliente: 'Cliente',
            codigo: 'Código',
            codigoCliente: 'Código Cliente',
            nombreCliente: 'Nombre Cliente',
            estado: 'Estado',
            fechaOferta: 'Fecha Oferta',
            fechaVencimiento: 'Fecha Vencimiento',
            editarOferta: 'Editar Oferta',
            editar: 'Editar',
            eliminar: 'Eliminar',
            crearPedido: 'Crear Pedido',
            nuevo: 'Nueva Oferta',
            empresa: 'Empresa',
            delegacion: 'Delegación',
            centrotrabajo: 'Centro de Trabajo',
            departamento: 'Departamento',
            lineaNegocio: 'Línea de Negocio',
            empleado: 'Empleado Contratación',
            tipoOferta: 'Tipo Oferta',
            moneda: 'Moneda',
            condicionPago: 'Condiciones de Pago',
            viaPago: 'Vía de Pago',
            cuenta: 'Cuenta',
            plazoDias: 'Plazo (días)',
            lineas: 'Líneas',
            linea: 'Línea',
            add: 'Añadir',
            creada: 'Creada',
            abierta: 'Abierta',
            cerrada: 'Cerrada',
            cancelada: 'Cancelada',
            tipoLinea: 'Tipo de Línea',
            servicio: 'Servicio',
            instalacion: 'Instalación',
            item: 'Item',
            texto: 'Texto',
            textos: 'Textos personalizados',
            orden: 'Orden de presentación del texto',
            titulo: 'Título del texto',
            html: 'Texto a mostrar en la oferta',
            precio: 'Precio Unitario',
            presupuestoConIVA: 'Generar presupuesto con IVA',
            porcIVA: 'Porcentaje IVA',
            porcDescuento: 'Porcentaje Dto',
            porcSegunda: 'Porcentaje Segunda',
            importeSegunda: 'Importe Segunda',
            tasas: 'Tasas por unidad',
            servicioTasas: 'Servicio de Tasas',
            importe: 'Importe Línea',
            cantidad: 'Cantidad',
            cantidadCrear: 'Cantidad Crear Pedido',
            tiempoEstimadoOficina: 'T. Est. Oficina (Minutos)',
            tiempoEstimadoCampo: 'T. Est. Campo (Minutos)',
            comentarios: 'Comentarios',
            verbal: 'Verbal',
            normalizada: 'Normalizada',
            ordinaria: 'Ordinaria',
            marco: 'MARCO',
            contrato: 'Contrato',
            tipo: 'Tipo Oferta',
            subcontratacion: 'Subcontratación',
            comision: 'Comisión',
            proveedor: 'Proveedor',
            codigoOferta: 'Código Oferta',
            administrador: 'Administrador',
            instalador: 'Instalador',
            mantenedor: 'Mantenedor',
            asociacion: 'Asociación',
            totalOferta: 'Total Oferta',
            totalSubcontratacion: 'Total Subcontratación',
            totalTerceros: 'Total Terceros',
            totalTasas: 'Total Tasas',
            totalComision: 'Total Comisión',
            subtotal: 'Subtotal',
            totalIVA: 'IVA',
            total: 'Total',
            totalSinIVA: 'Total sin IVA',
            totales: 'Totales',
            datosLinea: 'Datos Línea',
            instalacion_creada: 'Instalación creada',
            contacto_creado: 'Contacto creado',
            item_creado: 'Item creado',
            aceptacion_ok: 'Documentos de Aceptación Adjuntados',
            extension_ok: 'Oferta Extendida correctamente',
            guardado_ok: 'Oferta guardada correctamente',
            objeto: 'Objeto',
            alcance: 'Alcance',
            personalymedios: 'Personal y Médios',
            descripciontrabajo: 'Descripción del Trabajo',
            requisitosprevios: 'Requisitos previos',
            compromisosimecal: 'Compromiso Simecal',
            plazos: 'Plazos',
            condicionesespecificas: 'Condiciones específicas',
            subcontrataciontexto: 'Detalles de subcontratación',
            comisionproveedor: 'Proveedor Comisión',
            comisionunitaria: 'Comisión Unitaria',
            contratacion: 'Contratación',
            elegir_cliente_lineas: 'Elija un cliente para poder añadir líneas',
            elegir_cliente_condiciones_pago: 'Elija un cliente para poder seleccionar condiciones de pago',
            motivoRechazo: 'Motivo de rechazo',
            motivoCancelacion: 'Motivo de cancelación',
            extenderPlazo: 'Extender Plazo',
            precioUnitario: 'Precio Unitario',
            emailComunicaciones: 'Email comunicaciones',
            titular: 'Titular',
            popupRechazo: {
                title: 'Oferta rechazada por el cliente',
                rechazar: 'Marcar como rechazada',
                indicarMotivoRechazo: 'Indicar Motivo de rechazo'
            },
            popupCancelar: {
                title: 'Oferta cancelada',
                cancelar: 'Marcar como cancelada',
                indicarMotivoCancelacion: 'Indicar Motivo de cancelación',
                verMotivoCancelacion: 'Ver motivo de cancelación'
            },
            popuHistoricoEstados: {
                historicoEstados: 'Histórico de estados',
                usuario: 'Usuario',
                fecha: 'Fecha'
            },
            gastos: {
                previsionGastos: 'Previsión de gastos',
                kilometros: 'Kilómetros',
                peajes: 'Peajes',
                parking: 'Parking',
                desayuno: 'Desayuno',
                comida: 'Comida',
                cena: 'Cena',
                hotel: 'Hotel',
                finDeSemana: 'Fin de semana',
                horasNocturnas: 'Horas nocturnas',
            }
        },
        albaranes: {
            malo: 'Reportar error de sincronización',
            albaranes: 'Albaranes',
            new: 'Nuevo Albarán',
            codigo: 'Código',
            cliente: 'Cliente',
            expedientes: 'Expedientes',
            expedientes_incluir: 'Expedientes a incluir',
            fecha: 'Fecha',
            anterior: 'Anterior',
            siguiente: 'Siguiente',
            resumen: 'Resumen',
            guardar: 'Guardar',
            descargar: 'Descargar',
            eliminar: 'Eliminar',
            sincronizar: 'Sincronizar',
            sincronizado: 'Sincronizado',
            sin_albaranes: 'Sin Albaranes',
            numero_albaran: 'Nº de albarán',
            fecha_albaran: 'Fecha albarán',
            servicio: 'Servicio',
            domicilio: 'Domicilio',
            cantidad: 'Cantidad',
            firmado: 'Firmado',
            firmante: 'Firmante',
            nif: 'NIF',
            nombre: 'Nombre',
            provincia: 'Provincia',
            firmado: 'Firmado',
            cargar_albaran_firmado: 'Cargar albarán firmado',
            albaran_cargado: 'Albarán cargado',
        },
        inspeccionesOffline: {
            visualizar: 'Visualizar',
            imprimir: 'Imprimir',
            previsualizar: 'Previsualizar',
            eliminar: 'Eliminar',
            eliminarSeleccion: 'Eliminar selección',
            editar: 'Editar',
            inspeccionar: 'Realizar Inspección',
            sincronizar: 'Sincronizar',
            inspecciones_pendientes: 'Pendientes',
            inspecciones_anteriores: 'Sincronizados',
            guardar_cliente: 'Guardar cliente',
            nuevo_cliente_guardado: 'Cliente creado',
            documentacion: 'Ver Documentos del Pedido'
        },
        inspecciones: {
            inspecciones: {
                inspecciones: 'Expedientes',
                sin_cita: 'Sin cita',
                sin_inspecciones_anteriores: 'Sin expedientes anteriores',
                sin_inspecciones_pendientes: 'Sin expedientes pendientes',
                continuar_sin_pedido: 'Continuar sin pedido',
                malo: 'Reportar expediente',
                nuevo: 'Nuevo expediente manual'
            },
            inspeccion_form: {
                ya_inspeccionado: 'Expediente ya inspeccionado',
                auditoria: 'Auditar manualmente',
                aprobadoenvio: 'Aprobado paso a proceso de entrega',
                inspectores: 'Inspectores',
                add: 'Añadir',
                inspector: 'Inspector',
                rol: 'Rol',
                anyo: 'Año',
                albaran: 'Albarán',
                formato: 'Formato',
                codigoFormato: 'Código Formato',
                provincia: 'Provincia',
                usuario: 'Usuario',
                fecha: 'Fecha',
                cerrar: 'Cerrar',
                historicoEstados: 'Histórico Estado',
                fecha_inspeccion: 'Fecha',
                fecha_limite_correccion: 'Fecha límite de corrección',
                fecha_prox_inspeccion: 'Fecha de próxima inspección',
                fecha_renovacion: 'Fecha de renovación',
                inicio: 'Inicio',
                fin: 'Fin',
                hora_inicio: 'Hora inicio',
                hora_final: 'Hora final',
                sesiones_trabajo: 'Sesiones de trabajo automáticas',
                sesiones_trabajo_manuales: 'Sesiones de trabajo manuales',
                sesiones_tpi: 'Sesiones TPI',
                hora_inicio_real: 'Hora inicio real',
                hora_final_real: 'Hora final real',
                direccion_inicio: 'Dirección inicio',
                direccion_fin: 'Dirección fin',
                editar_inspeccion: 'Editar Expediente',
                nueva_inspeccion: 'Nuevo Expediente',
                inspeccion_guardada: 'Expediente guardado con éxito',
                formato_asignado: 'Formato asignado a la inspección',
                inspeccion: 'Expediente',
                tipo_inspeccion: 'Tipo Expediente',
                conflictos: 'Incidencias',
                no_conflictos: 'Este Expediente no tiene Incidencias',
                justificantes: 'Justificantes',
                documentos: 'Documentos',
                codigo: 'Código',
                codigoExpAnterior: 'Código Expediente Anterior',
                codigoExpSiguiente: 'Código Expediente Siguiente',
                pedido: 'Pedido',
                cliente: 'Cliente',
                nif: "NIF",
                calificacion: 'Calificación',
                estado: 'Estado',
                descargarCertificado: 'Descargar certificado',
                descargarCertificadoBETA: 'Descargar certificado (Beta)',
                descargarHojaDatos: 'Descargar Hoja de Datos',
                descargarTomaDatos: 'Descargar hoja de toma de datos',
                fecha_solicitada: 'Fecha Solicitada',
                fecha_solicitada_desde: 'Fecha Solicitada Desde',
                fecha_solicitada_hasta: 'Fecha Solicitada Hasta',
                fecha_planificada: 'Fecha Planificada',
                fecha_visita: 'Fecha Visita',
                finalizar: 'Finalizar inspección',
                errorescorregidos: 'Errores corregidos',
                firmainspector: 'Firmar el certificado de inspección',
                adjuntadocumentosinfirma: 'Adjuntar certificado sin firma digital',
                adjuntadocumentofirmado: 'Adjuntar certificado con firma digital',
                autofirma: 'Autofirma',
                hora: 'Hora',
                fecha_inspeccion: 'Fecha Inspección',
                direccion: 'Dirección',
                cp: 'C.P.',
                poblacion: 'Población',
                pais: 'País',
                tipo_pedido: 'Tipo Pedido',
                comentario_inspector: 'Comentario Inspector',
                comentario_resolucion: 'Comentario Resolución',
                resolucion: 'Resolución',
                resultadosAuditoria: 'Ver errores de la auditoría',
                caracteristicas: 'Características',
                no_caracteristicas: 'No hay Características',
                comprobaciones: 'Comprobaciones',
                no_comprobaciones: 'No hay Comprobaciones',
                inspector: 'Inspector',
                con_conflictos: 'Con Incidencias',
                fecha_creacion: 'Fecha Creación Pedido',
                desde: 'Desde',
                hasta: 'Hasta',
                duracion_estimada: 'Duración estimada',
                duracion: 'Duración',
                importe: 'Importe',
                fecha_visita_new: 'Nueva Fecha Visita',
                inspector_new: 'Nuevo Inspector',
                hora_new: 'Nueva Hora',
                importe_new: 'Nuevo Importe',
                motivo: 'Motivo cambio fecha',
                motivo_rechazo: 'Motivo rechazo',
                confirmacion_aceptacion_justificantes: '¿Seguro que quiere aceptar los justificantes y marcar la cerfificación como buena?',
                tratamiento_ionizado: 'Tratamiento Ionizado',
                observaciones: 'Observaciones',
                equipos: 'Equipos',
                linea: 'Línea Pedido',
                sin_incidencias: 'Sin incidencias',
                refreshEnvio: 'Refrescar estado de firma',
                bloqueado: 'Expediente bloqueado',
                auditoria_manual: 'Con auditoría manual',
                tipoMedidaDefecto: 'Tipo de Medida a Adoptar',
                servicio: 'Servicio',
                item: 'Item',
                codigoPedido: 'Código pedido',
                numeroPedido: 'Número pedido',
                numeroLinea: 'Línea',
                materia: 'Materia',
                materiaCodExp: "Cod Exp Materia",
                forzarPrecargas: "Forzar precargas",
                sync: 'Sincronizar',
                comprobar_reglas: 'Auditar',
            },
            popupauditoria: {
                erroresAuditoria: 'Lista de errores de auditoría',
                descripcionerror: 'Descripción del error detectado'
            },
            popup_certificado: {
                certificadofirmado: 'Adjuntar Certificado firmado por el inspector',
                certificadosinfirmar: 'Adjuntar Certificado sin firmar por el inspector',
                anadircertificadofirmado: 'Añadir certificado firmado',
                anadircertificadosinfirma: 'Añadir certificado'
            }
        },
        conflictos: {
            conflictos: {
                conflictos: 'Incidencias'
            },
            conflicto_form: {
                editar_conflicto: 'Editar incidencia',
                nuevo_conflicto: 'Nueva incidencia',
                conflicto_guardado: 'Incidencia guardada con éxito',
                conflicto: 'Incidencia',
                codigoInspeccion: 'Código Expediente',
                tipo_conflicto: 'Tipo Incidencia',
                fecha_conflicto: 'Fecha Incidencia',
                fecha_resolucion: 'Fecha Resolución',
                cliente: 'Cliente',
                inspeccion: 'Expediente',
                resolucion: 'Resolución',
                comentarios: 'Comentarios inspector',
                comentarios_resolucion: 'Comentarios resolucion',
                comentarios_rechazo: 'Comentarios rechazo',
                matricula: 'Matrícula',
                bastidor: 'Bastidor',
                modelo: 'Modelo',
                marca: 'Marca',
                coche: 'Coche',
                estandar: 'Estándar',
                subestandar: 'Sub-Estándar',
                documentos_adjuntos: 'Documentos Adjuntos',
                desde: 'Desde',
                hasta: 'Hasta'
            }
        },
        coches: {
            coches: {
                coches: 'Coches'
            },
            coche_form: {
                matricula: 'Matrícula',
                bastidor: 'Bastidor',
                modelo: 'Modelo',
                marca: 'Marca',
                editar_coche: 'Editar coche',
                nuevo_coche: 'Nuevo coche',
                coche_guardado: 'Coche guardado',
                coche: 'Coche'
            }
        },
        marcas: {
            marcas: {
                marcas: 'Marcas'
            },
            marca_form: {
                nombre: 'Nombre',
                marca: 'Marca',
                editar_marca: 'Editar marca',
                nuevo_marca: 'Nueva marca',
                marca_guardada: 'Marca guardada',
                marca: 'Marca'
            }
        },
        modelos: {
            modelos: {
                modelos: 'Modelos'
            },
            modelo_form: {
                nombre: 'Nombre',
                modelo: 'Modelo',
                editar_modelo: 'Editar modelo',
                nuevo_modelo: 'Nuevo modelo',
                modelo_guardado: 'Modelo guardado',
                modelo: 'Modelo',
                marca: 'Marca'
            }
        },
        clientes: {
            codCliente: 'Cod. Cli.',
            clientes: 'Clientes',
            nombre: 'Nombre',
            codigo: 'Código',
            nif: 'NIF',
            tipoCliente: 'Tipo de cliente',
            ubicacion: 'Ubicación',
            eMail: 'Email',
            razonSocial: 'Razón Social',
            clientePadre: 'Cliente padre',
            codigo_postal: 'Código postal',
            direccion: 'Dirección',
            poblacion: 'Población',
            provincia: 'Provincia',
            pais: 'País',
            cliente: 'Cliente',
            editar_cliente: 'Editar cliente',
            nuevo_cliente: 'Nuevo cliente',
            cliente_guardado: 'Cliente guardado',
            hora_apertura: 'Hora Apertura',
            hora_cierre: 'Hora Cierre',
            direccion_facturacion: 'Dirección Facturación',
            direccion_envio: 'Dirección Envío',
            configuraciones: 'Configuraciones',
            tiempo: 'Tiempo defecto',
            precio: 'Precio',
            formato_factura: 'Formato en Factura',
            tipo_configuracion: 'Tipo Configuración',
            tipo_inspeccion: 'Tipo Expediente',
            valor: 'Valor',
            matricula: 'Matrícula',
            bastidor: 'Bastidor',
            certificacion_1: 'Certificaciones de 1as',
            certificacion_2: 'Certificaciones de 2as',
            certificacion_M: 'Certificaciones de Marca',
            imagen: 'Seleccionar Imagen',
            marcas: 'Marcas',
            add: 'Añadir',
            marca: 'Marca',
            alcance: 'Alcance',
            formato: 'Formato',
            tipo: 'Tipo',
            valor: 'Valor',
            telefono: 'Teléfono',
            telefono_movil: 'Teléfono Móvil',
            fax: 'Fax',
            email: 'Email',
            web: 'Web',
            alias: 'Alias',
            direcciones: 'Direcciones',
            enviar_a: 'ENVIO',
            apellidos: 'Apellidos',
            posicion: 'Posición',
            contactos: 'Contactos',
            activo: 'Activo',
            empresaMantenedora: 'Empresa Mantenedora',
            potencial: 'Cliente Potencial',
            acronimo: 'Acrónimo',
            grupocliente: 'Grupo Cliente',
            listaPrecios: 'Lista de Precios',
            condicionesPago: 'Condiciones de Pago',
            condicionPago: 'Condición de Pago',
            viaPago: 'Vía de Pago',
            viasPago: 'Vías de Pago',
            recargoEquivalencia: 'Recargo Equivalencia',
            impuestoDiferido: 'Impuesto Diferido',
            codigoOperacion347: 'Código Operación 347',
            operacionAseguradora347: 'Operación Aseguradora 347',
            impuestos: 'Impuestos',
            fechaCaptacion: 'Fecha Captación',
            comunidadAutonoma: 'Comunidad Autónoma',
            moneda: 'Moneda',
            contactoDefecto: 'Contacto Defecto',
            direccionDefectoFacturas: 'Direccion Facturación Defecto',
            direccionDefectoEnvio: 'Direccion Envío Defecto',
            viaPagoDefecto: 'Vía Pago Defecto',
            servicios: 'Servicios',
            servicio: 'Servicio',
            destinatario_factura: 'FACTURACION',
            canalescomunicacion: 'Canales de Comunicación',
            iban: 'IBAN',
            clienteIndirecto: 'Cliente Indirecto',
            responsable: 'Responsable',
            empleadoVentas: 'Empleado de ventas',
            fechasPago: 'Fechas de pago',
            titular: {
                title: 'Datos del titular',
                nombre: 'Nombre',
                apellidos: 'Apellidos',
                nif: 'NIF'
            }
        },
        proveedores: {
            proveedores: 'Proveedores',
            nombre: 'Nombre',
            codigo: 'Código',
            nif: 'NIF',
            tipoProveedor: 'Tipo de proveedor',
            ubicacion: 'Ubicación',
            eMail: 'Email',
            razonSocial: 'Razón social',
            clientePadre: 'Cliente padre',
            codigo_postal: 'Código postal',
            direccion: 'Dirección',
            poblacion: 'Población',
            provincia: 'Provincia',
            pais: 'País',
            proveedor: 'Proveedor',
            editar: 'Editar Proveedor',
            nuevo: 'Nuevo Proveedor',
            proveedor_guardado: 'Proveedor guardado',
            hora_apertura: 'Hora Apertura',
            hora_cierre: 'Hora Cierre',
            direccion_facturacion: 'Dirección Facturación',
            direccion_envio: 'Dirección Envío',
            configuraciones: 'Configuraciones',
            tiempo: 'Tiempo defecto',
            precio: 'Precio',
            formato_factura: 'Formato en Factura',
            tipo_configuracion: 'Tipo Configuración',
            tipo_inspeccion: 'Tipo Expediente',
            valor: 'Valor',
            matricula: 'Matrícula',
            bastidor: 'Bastidor',
            certificacion_1: 'Certificaciones de 1as',
            certificacion_2: 'Certificaciones de 2as',
            certificacion_M: 'Certificaciones de Marca',
            imagen: 'Seleccionar Imagen',
            marcas: 'Marcas',
            add: 'Añadir',
            marca: 'Marca',
            alcance: 'Alcance',
            formato: 'Formato',
            tipo: 'Tipo',
            valor: 'Valor',
            telefono: 'Teléfono',
            telefono_movil: 'Teléfono Móvil',
            fax: 'Fax',
            email: 'Email',
            web: 'Web',
            alias: 'Alias',
            direcciones: 'Direcciones',
            enviar_a: 'ENVIO',
            apellidos: 'Apellidos',
            posicion: 'Posición',
            contactos: 'Contactos',
            activo: 'Activo',
            empresaMantenedora: 'Empresa Mantenedora',
            potencial: 'Cliente Potencial',
            acronimo: 'Acrónimo',
            grupoproveedor: 'Grupo Proveedor',
            listaPrecios: 'Lista de Precios',
            condicionPago: 'Condición de Pago',
            viaPago: 'Vía de Pago',
            viasPago: 'Vías de Pago',
            recargoEquivalencia: 'Recargo Equivalencia',
            impuestoDiferido: 'Impuesto Diferido',
            codigoOperacion347: 'Código Operación 347',
            operacionAseguradora347: 'Operación Aseguradora 347',
            impuestos: 'Impuestos',
            fechaCaptacion: 'Fecha Captación',
            comunidadAutonoma: 'Comunidad Autónoma',
            moneda: 'Moneda',
            contactoDefecto: 'Contacto Defecto',
            direccionDefectoFacturas: 'Direccion Facturación Defecto',
            direccionDefectoEnvio: 'Direccion Envío Defecto',
            viaPagoDefecto: 'Vía Pago Defecto',
            servicios: 'Servicios',
            servicio: 'Servicio',
            destinatario_factura: 'FACTURACION',
            canalescomunicacion: 'Canales de Comunicación'
        },

        configuracion: {
            configuracion: 'Configuración',
            politica_privacidad: 'Política de privacidad',
            tema_oscuro: 'Tema oscuro',
            idioma: {
                idioma: 'Idioma',
                espanol: 'Español',
                ingles: 'Inglés'
            }
        },

        rlist: {
            pregunta_eliminar_registro: "¿Está seguro de que desea eliminar este registro?",
            pregunta_eliminar_registros: "¿Está seguro de que desea eliminar estos registros?",
            registro_eliminado: "Registro eliminado con éxito",
            n_registros_seleccionados: "{count} registro seleccionado | {count} registros seleccionados",
            n_registros: "{count} registro | {count} registros"
        },
        rform: {
            error_subiendo_archivos: "Error subiendo archivos",
            error_validacion_formulario: "Hay errores en el formulario"
        },
        repartos: {
            eliminar: 'Eliminar',
            ejecucion: "Reparto de Ejecución",
            contratacion: "Reparto de Contratación",
            deshacerTodos: "Deshacer todos los cambios",
            deshacer: "Deshacer cambios en esta línea",
            duplicarArriba: "Copiar reparto a líneas anteriores",
            duplicarAbajo: "Copiar reparto a líneas posteriores",
            anadir: "Añadir línea de reparto",
            equitativo: "Repartir equitativamente",
            eliminar: "Eliminar línea de reparto"
        },
        planificacion: {
            fechaPlanif: 'Fecha inspección'
        },
        inspeccion_sin_pedido : {
            servicio: 'Servicio',
            fechaVisita: 'Fecha Visita',
            direccion: 'Dirección',
            comentariosPlanificacion: 'Comentarios planificación',
            tiempoEstimadoCampo: 'Tiempo Estimado Campo (Minutos)',
            cliente: 'Cliente'
        },
        informes: {
            title: 'Informes'
        },
        registroemail: {
            title: 'Registro de Emails',
            registroemail: 'Registro de Emails',
            from: 'De',
            to: 'Para',
            cc: 'CC',
            cco: 'CCO',
            subject: 'Asunto',
            body: 'Cuerpo',
            attachments: 'Adjuntos',
            fechaEnvio: 'Fecha Envío',
        },
        listadoCalendario: {
            calcularResumen: 'Calcular Resumen',
        },
        'Nuevo': 'Nuevo',
        'Editar': 'Editar',
        'Eliminar': 'Eliminar',
        'Tareas': 'Tareas',
        'e-Mails': 'e-Mails',
        'Ofertas': 'Ofertas',
        'Pedidos': 'Pedidos',
        'Número Pedido': 'Número Pedido',
        'Código Tarea': 'Código Tarea',
        'Estado': 'Estado',
        'Tipo Email': 'Tipo Email',
        'Generar oferta': 'Generar oferta',
        'Marcar como Perdida': 'Marcar como Perdida',
        'Enviar e-mail': 'Enviar e-mail',
        'Historico de cambios': 'Historico de cambios',
        'Nombre Campaña': 'Nombre Campaña',
        'Código Cliente': 'Código Cliente',
        'Empleado Ventas Cliente': 'Empleado Ventas Cliente',
        'Lin. Pedido': 'Lin. Pedido',
        'Nº Oferta': 'Nº Oferta',
        'Nombre Cliente': 'Nombre Cliente',
        'Responsable Cliente': 'Responsable Cliente',
    },
    // Traducir literales a inglés
}

export default messages;
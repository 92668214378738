import ListadoCalendarios from './listadoCalendario.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/listadocalendario/DIA/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('listadocalendarios', ['ADMIN', 'INSPECTOR'], ListadoCalendarios, { tipoLinea: 'DIA' }),
        keepAlive: true
    },{
        path: '/listadocalendario/SEMANA/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('listadocalendarios', ['ADMIN', 'INSPECTOR'], ListadoCalendarios, { tipoLinea: 'SEMANA' }),
        keepAlive: true
    },{
        path: '/listadocalendario/MES/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('listadocalendarios', ['ADMIN', 'INSPECTOR'], ListadoCalendarios, { tipoLinea: 'MES' }),
        keepAlive: true
    },
];
export default routes;
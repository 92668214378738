<template>
<f7-page :page-content="true">
  <r-form
    ref="rform"
    class="page"
    classContent="page-content full-height"
    :itemId="idData"
    @save="onSave"
    @loadItem="loadItem"
    :readonly="readonly"
    :dbAdapter="Pedido"
    :documentoAdapter="Documento"
    :defaultData="{inspectores:[], logistica: {}, lineas:[], fechaPedido: new Date(), anyoEjecucion: new Date().getFullYear(), activo: true, adelantoCobro: false}"
    :beforeSave="beforeSave"
  >
    <!-- Top Navbar -->
    <template v-slot:before="{rform}">
      <f7-navbar :back-link="$t('atras')">
        <f7-nav-title v-if="idData && !readonly" sliding>{{$t('pedidos.editar')}}</f7-nav-title>
        <f7-nav-title v-else-if="idData" sliding>{{$t('pedidos.pedido')}}</f7-nav-title>
        <f7-nav-title v-else sliding>{{$t('pedidos.nuevo')}}</f7-nav-title>
        <f7-nav-right>
          <f7-button
            v-if="!readonly"
            fill
            icon-f7="floppy_disk"
            text="Guardar"
            class="display-flex"
            @click="rform.saveItem()"
          ></f7-button>
          <template
            slot="right"
            v-if="idData && readonly"
          >
            <div class="menu-item menu-item-dropdown bg-color-primary">
              <div class="menu-item-content"><i class="f7-icons">ellipsis_vertical</i></div>
              <div class="menu-dropdown menu-dropdown-right bg-color-primary">
                <div class="menu-dropdown-content bg-color-primary text-align-right">
                  <div class="menu-dropdown-item menu-close pointer" @click="readonly=false">{{$t('pedidos.editar')}}</div>
                  <div class="menu-dropdown-item menu-close pointer" v-if="rform.formData && rform.formData.estado && rform.formData.estado.estado != 'CANCELADO'" @click="planificar(rform.formData.lineas, id)">{{$t('pedidos.planificar')}}</div>
                  <div class="menu-dropdown-item menu-close pointer" v-if="rform.formData && rform.formData.estado && rform.formData.estado.estado != 'CANCELADO'" @click="planificarTPI(rform.formData.lineas, id)">Planificar TPI</div>
                  <div class="menu-dropdown-item menu-close pointer" v-if="rform.formData && rform.formData.idOferta" @click="$f7.views.current.router.navigate('/ofertas/view/' + rform.formData.idOferta + '/')">{{$t('pedidos.verOferta')}}</div>
                  <div class="menu-dropdown-item menu-close pointer" v-if="readonly" @click.prevent="enviarEmail = true">{{$t('Enviar e-mail')}}</div>
                  <div class="menu-dropdown-item menu-close pointer" v-if="rform.formData" @click="tipoReparto = 'repContratacion'; popuprepartoopened = true;">{{$t('repartos.contratacion')}}</div>
                  <div class="menu-dropdown-item menu-close pointer" v-if="rform.formData" @click="tipoReparto = 'repEjecucion'; popuprepartoopened = true;">{{$t('repartos.ejecucion')}}</div>
                  <div class="menu-dropdown-divider"></div>
                  <div v-roles="['ADMIN','ADMINISTRACION']" v-if="rform && rform.formData && facturable(rform.formData)">
                    <div class="menu-dropdown-item menu-close pointer text-color-yellow" @click="facturar()">{{$t('pedidos.facturar')}}</div>
                    <div class="menu-dropdown-divider"></div>
                  </div>
                  <div class="menu-dropdown-item menu-close pointer text-color-red" @click="deleteItem">{{$t('pedidos.eliminar')}}</div>
                </div>
              </div>
            </div>
          </template>
          <template slot="right" v-if="idData && !readonly">
            <div class="menu-item menu-item-dropdown bg-color-primary">
              <div class="menu-item-content"><i class="f7-icons">ellipsis_vertical</i></div>
              <div class="menu-dropdown menu-dropdown-right bg-color-primary">
                <div class="menu-dropdown-content bg-color-primary text-align-right">
                  <div class="menu-dropdown-item menu-close pointer" v-if="rform.formData" @click="tipoReparto = 'repContratacion'; popuprepartoopened = true;">{{$t('repartos.contratacion')}}</div>
                  <div class="menu-dropdown-item menu-close pointer" v-if="rform.formData" @click="tipoReparto = 'repEjecucion'; popuprepartoopened = true;">{{$t('repartos.ejecucion')}}</div>
                </div>
              </div>
            </div>
          </template>
        </f7-nav-right>
      </f7-navbar>
    </template>
    <!-- Tabs -->
    <template v-slot:default="{rform}">
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
        <div name="Datos Generales">
          <f7-link
            @click="showDetalle = !showDetalle"
            :tooltip="showDetalle ? 'Ocultar Detalle' : 'Mostrar Detalle'"
            :icon-f7="showDetalle ? 'chevron_compact_up' : 'chevron_compact_down'"
            :title="showDetalle ? 'Ocultar Detalle' : 'Mostrar Detalle'"
            style="padding-left: 7px;font-size: 10px;float: right;"
          ></f7-link>
        </div>
        <f7-card-content v-show="showDetalle">
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="100">
              <f7-row>
                <div class="label-group" :class="{'col-100': !readonly, 'col-50': readonly}">Datos Generales</div>
                <f7-button
                  class="col-20"
                  v-noroles="['ENAC', 'PRODUCCION']"
                  icon-f7="calendar_circle"
                  @click="popupTareaEditable = true; popupTareasOpened = true; LineaPedido = null;"
                  v-if="readonly && id && $refs.rform && $refs.rform.formData && $refs.rform.formData.numero && !!!$refs.rform.formData.lineaExceso"
                >Crear tarea</f7-button>
                <f7-button
                  v-if="readonly || $refs.rform.formData.lineaExceso"
                  class="col-20"
                  v-noroles="['ENAC', 'PRODUCCION']"
                  icon-f7="rectangle_stack_person_crop_fill"
                  @click="crearOportunidad"
                >Crear oportunidad</f7-button>
                <r-input
                  v-noroles="['ENAC', 'PRODUCCION']"
                  class="col-100 medium-25"
                  :form="rform"
                  type="check"
                  :name="'activo'"
                  label="Activo"
                ></r-input>
                <r-input
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('pedidos.numero')"
                  :form="rform"
                  type="text"
                  name="numero"
                  v-if="idData"
                  :readonly="true"
                ></r-input>
                <r-input
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('pedidos.codigo')"
                  :form="rform"
                  type="text"
                  name="codigo"
                  :readonly="true"
                ></r-input>
                <r-autocomplete
                  :dbAdapter="Cliente"
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('pedidos.cliente')"
                  :form="rform"
                  name="cliente"
                  labelProp="codigoNombre"
                  :minLength="3"
                  :shownFields="['razonSocial', 'direcciones', 'direccionDefectoFacturas', 'direccionDefectoEnvio', 'condPago', 'viaPagoDefecto', 'listaPrecios', 'contactos']"
                  :readonly="true"
                  :async="true"
                ></r-autocomplete>
                <r-autocomplete
                  v-if="clienteSelected"
                  key="contactoCliente"
                  :ref="'contactoCliente'"
                  :dbAdapter="ContactoCliente"
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('ofertas.contactocliente')"
                  :form="rform"
                  :name="'contactoCliente'"
                  labelProp="alias"
                  infoText="Cree un contacto nuevo para este cliente"
                  @change="changeContacto"
                  :filter="clienteSelected ? {clienteId: rform.formData.cliente.id} : undefined"
                  historico=""
                >
                  <f7-link
                    v-if="!rform.formData.contactoCliente"
                    slot="inner-end"
                    popup-open=".popup-contacto"
                    class="input-suffix-btn"
                    icon-f7="plus_app_fill"
                  ></f7-link>
                </r-autocomplete>
                <r-input
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('ofertas.emailcontacto')"
                  :form="rform"
                  type="text"
                  name="emailContacto"
                  key="emailContacto"
                ></r-input>
                <r-input
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('ofertas.telefono')"
                  :form="rform"
                  type="text"
                  name="telefono"
                  key="telefono"
                ></r-input>
                <r-input
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('ofertas.telefonoMovil')"
                  :form="rform"
                  type="text"
                  name="telefonoMovil"
                  key="telefonoMovil"
                ></r-input>
                <r-input
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('pedidos.numeroOferta')"
                  :form="rform"
                  type="number"
                  name="numeroOferta"
                  :readonly="true"
                ></r-input>
                <r-autocomplete
                  :dbAdapter="Oferta"
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('pedidos.codigoOferta')"
                  :form="rform"
                  name="codOferta"
                  labelProp="codigo"
                  :shownFields="['codigo']"
                  :detailUrl="'#!/' + 'ofertas' + '/view/' + rform.formData.idOferta + '/'"
                  :minLength="3"
                  :readonly="true"
                  :async="true"
                ></r-autocomplete>
                  <!-- :shownFields="['razonSocial', 'direcciones', 'direccionDefectoFacturas', 'direccionDefectoEnvio', 'condPago', 'viaPagoDefecto', 'listaPrecios', 'contactos']"
                <r-input
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('pedidos.codigoOferta')"
                  :form="rform"
                  name="codOferta"
                  :readonly="true"
              ></r-input> -->
                <r-input
                  class="col-100 medium-25"
                  :label="$t('pedidos.moneda')"
                  :form="rform"
                  type="select"
                  name="codMoneda"
                  :hidden="true"
                >
                  <option selected value="EUR">Euro</option>
                </r-input>
                <r-input
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('pedidos.estado')" 
                  :form="rform"
                  type="select"
                  name="estado.estado"
                >
                  <option value="CREADO">{{$t('pedidos.creado')}}</option>
                  <option value="PLANIFICADO">{{$t('pedidos.planificado')}}</option>
                  <option value="EN_EJECUCION">{{$t('pedidos.ejecucion')}}</option>
                  <option value="EJECUTADO">{{$t('pedidos.ejecutado')}}</option>
                  <option value="FACTURADO">{{$t('pedidos.facturado')}}</option>
                  <option value="CANCELADO">{{$t('pedidos.cancelado')}}</option>
                  <option value="CERRADO">{{$t('pedidos.cerrado')}}</option>
                </r-input>
                <r-autocomplete
                  v-if="idData"
                  :dbAdapter="Empleado"
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('pedidos.empleadoContratacion')"
                  :form="rform"
                  name="contratacion.codEmpleado"
                  labelProp="nombre"
                  idProp="username"
                  idFieldDetail="id"
                  prefixProp="username"
                ></r-autocomplete>
                <r-autocomplete
                  v-if="idData"
                  :dbAdapter="Empleado"
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('pedidos.usuarioEjecucion')"
                  :form="rform"
                  name="camposUsuario.cpEj"
                  labelProp="nombre"
                  idProp="username"
                  prefixProp="username"
                  idFieldDetail="id"
                ></r-autocomplete>
                <r-input
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('pedidos.fechaContratacion')"
                  :form="rform"
                  type="datepicker"
                  name="fechaContratacion"
                  :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>

                <r-input
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('pedidos.fechaPedido')"
                  :form="rform"
                  type="datepicker"
                  name="fechaPedido"
                  :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>
                <r-input
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('pedidos.fechaEjecucion')"
                  :form="rform"
                  type="datepicker"
                  name="fechaEjecucion"
                  :readonly="true"
                  :overrideReadonly="true"
                  :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>
                <r-input
                  class="col-100 medium-25"
                  :label="$t('pedidos.conforme')"
                  :form="rform"
                  type="select"
                  :readonly="true"
                  name="camposUsuario.conforme"
                >
                  <option selected :value="null"></option>
                  <option value="S">Sí</option>
                  <option value="N">No</option>
                </r-input>
                <span class="col-100 medium-25"></span>
                <r-input
                  class="col-100 medium-25"
                  :label="$t('pedidos.tipoPedido')"
                  :form="rform"
                  type="select"
                  name="tipoPedido"
                  :readonly="true"
                  :hidden="true"
                >
                  <option selected value="GENERAL">General</option>
                  <option selected value="AUTOMATICO">Automático</option>
                  <option selected value="IMPORTADO">Importado</option>
                </r-input>
                

                <r-input
                  v-noroles="['ENAC', 'PRODUCCION']"
                  class="col-100 medium-50"
                  floating-label 
                  :label="$t('pedidos.comentarios')"
                  :form="rform"
                  type="text"
                  name="comentarios"
                ></r-input>
                <r-autocomplete
                  v-if="rform.formData.oportunidad"
                  :dbAdapter="Campania"
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('campania.campania')"
                  :form="rform"
                  name="oportunidad.campania"
                  labelProp="codigoNombre"
                  :minLength="3"
                  :shownFields="['codigoNombre', 'codigo', 'nombre', 'estrategia', 'fechaInicial', 'fechaFinal', 'responsable']"
                  :async="true"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-autocomplete>
                <r-autocomplete
                  v-if="rform.formData.oportunidad"
                  :dbAdapter="Oportunidad"
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('oportunidad.oportunidad')"
                  :form="rform"
                  name="oportunidad"
                  labelProp="codigo"
                  :shownFields="['codigo', 'campania', 'gestor']"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-autocomplete>
                <r-autocomplete
                  v-if="rform.formData.origenDeOportunidad"
                  :dbAdapter="Campania"
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('Origen de campaña')"
                  :form="rform"
                  name="origenDeOportunidad.campania"
                  labelProp="codigoNombre"
                  :minLength="3"
                  :shownFields="['codigoNombre', 'codigo', 'nombre', 'estrategia', 'fechaInicial', 'fechaFinal', 'responsable']"
                  :async="true"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-autocomplete>
                <r-autocomplete
                  v-if="rform.formData.origenDeOportunidad"
                  :dbAdapter="Oportunidad"
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('Origen de oportunidad')"
                  :form="rform"
                  name="origenDeOportunidad"
                  labelProp="codigo"
                  :shownFields="['codigo', 'campania', 'gestor']"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-autocomplete>
              </f7-row>
            </f7-col>
            <f7-col width="100" medium="33">
              <f7-row no-gap>
                <div class="label-group col-100 text-color-red">{{$t('pedidos.contratacion')}}</div>
                <f7-list-input
                  class="col-100 medium-33 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('pedidos.subtotal')"
                  type="number"
                  step=".01"
                  key="pedidos.subtotal"
                  :value="getTotalesPedido(rform.formData ? rform.formData.lineas : undefined)['subtotal']"
                ></f7-list-input>
                <f7-list-input
                  class="col-100 medium-33 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('pedidos.totalIVA')"
                  type="number"
                  step=".01"
                  key="pedidos.totalIVA"
                  :value="getTotalesPedido(rform.formData ? rform.formData.lineas : undefined)['iva']"
                ></f7-list-input>
                <f7-list-input
                  class="col-100 medium-33 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('pedidos.total')"
                  key="pedidos.total"
                  type="number"
                  step=".01"
                  :value="getTotalesPedido(rform.formData ? rform.formData.lineas : undefined)['total']"
                ></f7-list-input>
              </f7-row>
            </f7-col>
            <f7-col width="100" medium="33">
              <f7-row no-gap>
                <div class="label-group col-100 text-color-red">{{$t('pedidos.terceros')}}</div>
                <f7-list-input
                  class="col-100 medium-33 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('pedidos.subtotal')"
                  type="number"
                  key="pedidos.subtotal.proveedor"
                  step=".01"
                  :value="getTotalesPedido(rform.formData ? rform.formData.lineas : undefined)['subtotalTerceros']"
                ></f7-list-input>
                <f7-list-input
                  class="col-100 medium-33 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('pedidos.totalIVA')"
                  type="number"
                  key="pedidos.totalIVA.proveedor"
                  step=".01"
                  :value="getTotalesPedido(rform.formData ? rform.formData.lineas : undefined)['ivaTerceros']"
                ></f7-list-input>
                <f7-list-input
                  class="col-100 medium-33 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('pedidos.total')"
                  key="pedidos.total.proveedor"
                  type="number"
                  step=".01"
                  :value="getTotalesPedido(rform.formData ? rform.formData.lineas : undefined)['totalTerceros']"
                ></f7-list-input>
              </f7-row>
            </f7-col>
            <f7-col width="100" medium="33">
              <f7-row no-gap>
                <div class="label-group col-100 text-color-red">{{$t('pedidos.totales')}}</div>
                <f7-list-input
                  class="col-100 medium-25 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('pedidos.subtotal')"
                  type="number"
                  key="pedidos.subtotal.con.proveedor"
                  step=".01"
                  :value="getTotalesPedido(rform.formData ? rform.formData.lineas : undefined)['subtotalConTerceros']"
                ></f7-list-input>
                <f7-list-input
                  class="col-100 medium-25 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('pedidos.totalTasas')"
                  type="number"
                  key="pedidos.totalTasas"
                  step=".01"
                  :value="getTotalesPedido(rform.formData ? rform.formData.lineas : undefined)['totalTasas']"
                ></f7-list-input>
                <f7-list-input
                  class="col-100 medium-25 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('pedidos.totalIVA')"
                  type="number"
                  key="pedidos.totalIVA.con.proveedor"
                  step=".01"
                  :value="getTotalesPedido(rform.formData ? rform.formData.lineas : undefined)['ivaConTerceros']"
                ></f7-list-input>
                <f7-list-input
                  class="col-100 medium-25 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('pedidos.total')"
                  type="number"
                  key="pedidos.total.con.proveedor"
                  step=".01"
                  :value="getTotalesPedido(rform.formData ? rform.formData.lineas : undefined)['totalConTasasConTerceros']"
                ></f7-list-input>
              </f7-row>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>
      <div class="margin-horizontal">
        <f7-segmented raised class="elevation-6 margin-horizontal width-100">
          <f7-button fill tab-link="#tab-lineas" tab-link-active>Líneas</f7-button>
          <f7-button fill tab-link="#tab-direcciones">Direcciones</f7-button>
          <f7-button fill tab-link="#tab-finanzas">Pago y Finanzas</f7-button>
          <f7-button fill tab-link="#tab-documentos">Documentación</f7-button>
          <f7-button v-noroles="['ENAC', 'PRODUCCION']" fill tab-link="#tab-tareas">Tareas</f7-button>
          <f7-button fill tab-link="#tab-oportunidades">Oportunidades</f7-button>
          <f7-button fill tab-link="#tab-emails">e-Mails</f7-button>

        </f7-segmented>
      </div>
      <f7-tabs class="full-height" style="overflow: auto;">
        <f7-tab id="tab-lineas" tab-active>
          <!-- Líneas -->

          <div v-if="rform && rform.formData" style="z-index: 30" class="accordion-list">
            <!-- botón para mandar a borrar las líneas seleccionadas -->

            <f7-button
              v-if="rform.formData && rform.formData.lineas && rform.formData.lineas.find(l => l.deleteSelected)"
              style="z-index: 10000;position: fixed;top: 80px;left:50%;transform: translateX(-50%);width:50%;color:white;box-shadow: 0px 0px 10px 0px black;"
              fill
              color="red"
              class="display-flex"
              icon-f7="paintbrush"
              text="Borrar líneas seleccionadas"
              @click="borrarLineas"
            />
            <ul class="padding-horizontal">
              <li
                class="accordion-item row margin-vertical border-3"
                :class="{ 
                  'border-color-teal' : item.estado && ( item.estado.estado == 'EJECUTADA' || item.estado && item.estado.estado == 'PROCESO_ENTREGA' || item.estado.estado == 'ENTREGADA'|| item.estado.estado == 'FACTURADA' ||  item.estado.estado == 'COBRADA'),
                  'border-color-blue' : item.tipoLinea == 'TASAS' || item.tipoLinea == 'SUBCONTRATACION' || item.tipoLinea == 'COMISION', 
                  'border-color-green' : item.estado && item.estado.estado == 'PLANIFICADA',
                  'border-color-orange' : (!item.estado || item.estado.estado != 'PLANIFICADA') && lineasEjecutable[index],
                  'border-color-red' : (!item.estado || item.estado.estado != 'PLANIFICADA') && !lineasEjecutable[index],
                  'data-delete-selected': item.deleteSelected
                  }"
                v-for="(item,index) in rform.formData.lineas"
                :key="'linea-'+index"
                :id="'linea-'+item.numero"
              >
              <!-- <a class="col-100 item-content item-link" href="#"> -->
                <div class="item-inner col-100">

                  <f7-card 
                    class="no-margin data-linea"
                  >
                    <div 
                      class="col-100 medium-25 item-content item-input item-input-with-value over-card"
                      >
                        <r-badge :color="getColorBadge(item, lineasEjecutable[index])" :text="getTextoBadge(item, lineasEjecutable[index])"></r-badge>
                        <r-badge v-if="item.lineStatus && item.lineStatus=='C'" color="gray" text="Línea cerrada"></r-badge>
                        <r-badge v-if="item.estadoExpediente" :color="getColorBadgeExp(item)" :text="'E.Exp: ' + getTextoBadgeExp(item)"></r-badge>
                        <r-badge v-if="item.repContratacion && item.repContratacion[0] && item.repContratacion[0].fecha" color="yellow" :text="'F.R.Cont: ' + DateUtils.dateFormat(item.repContratacion[0].fecha, 'DD/MM/YYYY')"></r-badge>
                        <r-badge v-if="item.fechaPlanificacion" color="blue" :text="'F.Plani: '+ DateUtils.dateFormat(item.fechaPlanificacion, 'DD/MM/YYYY')"></r-badge>
                        <r-badge v-if="item.usuarioPlanificador" color="lime	" :text="'U.Plani: '+ item.usuarioPlanificador"></r-badge>
                        <r-badge v-if="item.camposUsuario && item.camposUsuario.fechaEjecucion" color="blue" :text="'F.Ej: ' + DateUtils.dateFormat(item.camposUsuario.fechaEjecucion, 'DD/MM/YYYY')"></r-badge>
                        <r-badge v-if="item.camposUsuario && item.camposUsuario.trabCm" :color="item.camposUsuario.trabCm == 'S' ? 'green': 'red'" :text="'TPC: ' + item.camposUsuario.trabCm"></r-badge>
                        <r-badge v-if="item.repEjecucion && item.repEjecucion[0] && item.repEjecucion[0].fecha" color="blue" :text="'F.R.Ej: ' + DateUtils.dateFormat(item.repEjecucion[0].fecha, 'DD/MM/YYYY')"></r-badge>
                        <r-badge v-if="item.repEjecucion && item.repEjecucion[0] && item.repEjecucion[0].usuario" color="orange" :text="'U.R.Ej: ' + item.repEjecucion[0].usuario"></r-badge>
                        <r-badge v-if="item.camposUsuario && item.camposUsuario.fPrevTPO" color="blue" :text="'F.Prev.TPI: '+ DateUtils.dateFormat(item.camposUsuario.fPrevTPO, 'DD/MM/YYYY')"></r-badge>
                        <r-badge v-if="item.camposUsuario && item.camposUsuario.trabOf" :color="item.camposUsuario.trabOf == 'S' ? 'green': 'red'" :text="'TPI: ' + item.camposUsuario.trabOf"></r-badge>
                        <r-badge v-if="item.fechaInsp" color="blue" :text="'F. Insp: '+ DateUtils.dateFormat(item.fechaInsp, 'DD/MM/YYYY')"></r-badge>
                        <r-badge v-if="item.fechaFirmaInspector" color="blue" :text="'F. Inf: '+ DateUtils.dateFormat(item.fechaFirmaInspector, 'DD/MM/YYYY')"></r-badge>
                        <r-badge v-if="item.camposUsuario && item.camposUsuario.conforme" :color="item.camposUsuario.conforme == 'S' ? 'green': 'red'" :text="'Conf: ' + item.camposUsuario.conforme"></r-badge>
                        <r-badge v-if="item.fechaTramitacionEICI" color="blue" :text="'F. Tram EICI: '+ DateUtils.dateFormat(item.fechaTramitacionEICI, 'DD/MM/YYYY')"></r-badge>
                        <r-badge v-if="item.usuarioTramitador" color="orange" :text="'Us. Tram: '+ item.usuarioTramitador"></r-badge>
                        <r-badge v-if="item.facturas && item.facturas[0] && item.facturas[0].fechaEmision" color="teal" :text="'F. Fact: '+ DateUtils.dateFormat(item.facturas[0].fechaEmision, 'DD/MM/YYYY')"></r-badge>
                        <r-badge v-if="item.facturas && item.facturas[0] && item.facturas[0].fechaPago" color="teal" :text="'F. Cobro: '+ DateUtils.dateFormat(item.facturas[0].fechaPago, 'DD/MM/YYYY')"></r-badge>
                        <template v-if="getLineaSegundas(item)">
                          <r-badge color="lime" :text="'Lín de 2ª: ' + getLineaSegundas(item).numero" border-color="black"></r-badge>
                          <r-badge v-if="getLineaSegundas(item).camposUsuario && getLineaSegundas(item).camposUsuario.trabCm" :color="getLineaSegundas(item).camposUsuario.trabCm == 'S' ? 'green': 'red'" :text="'TPC 2ª: ' + getLineaSegundas(item).camposUsuario.trabCm" border-color="black"></r-badge>
                          <r-badge v-if="getLineaSegundas(item).camposUsuario && getLineaSegundas(item).camposUsuario.trabOf" :color="getLineaSegundas(item).camposUsuario.trabOf == 'S' ? 'green': 'red'" :text="'TPI 2ª: ' + getLineaSegundas(item).camposUsuario.trabOf" border-color="black"></r-badge>
                          <r-badge v-if="getLineaSegundas(item).codigoExpediente" :color="getColorExpediente(getLineaSegundas(item))" :text="getLineaSegundas(item).codigoExpediente" border-color="black"></r-badge>
                          <r-badge v-if="getLineaSegundas(item).camposUsuario && getLineaSegundas(item).camposUsuario.feEnvExp" color="blue" :text="'F.E.Ex 2ª: ' + DateUtils.dateFormat(getLineaSegundas(item).camposUsuario.feEnvExp, 'DD/MM/YYYY')" border-color="black"></r-badge>
                          <r-badge v-if="getLineaSegundas(item)" :color="getLineaSegundas(item).corDef ? 'green' : 'red'" :text="'Cor Def 2ª: ' + (getLineaSegundas(item).corDef ? 'S' : 'N')" border-color="black"></r-badge>
                        </template>
                        <template v-else-if="item.idPedidoSegundas">
                          <r-badge class="pointer" color="purple" :text="'Ped de 2ª: ' + item.numeroPedidoSegundas" border-color="black"
                            @click="onOpenUrl('#!/pedidos/view/' + item.idPedidoSegundas + '/')"
                          ></r-badge>
                          <r-badge color="lime" :text="'Lín de 2ª: ' + item.numeroLineaSegundas" border-color="black"></r-badge>
                        </template>
                    </div>
                    <!-- <r-input
                      class="col-100 medium-25 over-card"
                      :form="rform"
                      type="check"
                      :readonly="true"
                      :overrideReadonly="true"
                      :name="'lineas.' + index + '.lineaEjecutable'"
                    ></r-input> -->
                    <f7-list class="list-form">
                      <ul>
                        <f7-row no-gap class="padding-vertical-half first-col">
                          <f7-list-input
                            class="col-100 medium-5"
                            floating-label
                            :label="$t('pedidos.num')"
                            :value="item.numero"
                            v-tooltip="$t('pedidos.num')+'<br/>'+item.numero"
                            autocomplete="off"
                            :readonly="true"
                          ></f7-list-input>
                          <f7-list-input
                            class="col-100 medium-10"
                            floating-label
                            :label="$t('pedidos.item')"
                            :value="item.item ? item.item.nombre : ''"
                            v-tooltip="$t('pedidos.item')+'<br/>'+ (item.item ? item.item.nombre : '')"
                            autocomplete="off"
                            :readonly="true"
                          ></f7-list-input>
                          <f7-list-input
                            class="col-100 medium-10"
                            floating-label
                            :label="'RAE'"
                            :value="item.rae"
                            v-tooltip="'RAE<br/>'+ (item.rae)"
                            autocomplete="off"
                            :readonly="true"
                          ></f7-list-input>
                          <f7-list-input
                            class="col-100 medium-20"
                            floating-label
                            :label="$t('pedidos.servicio')"
                            :value="item.servicio ? (item.servicio.userText || (item.servicio.codigo + ' - ' + item.servicio.nombre)) : ''"
                            v-tooltip="$t('pedidos.servicio')+'<br/>'+ (item.servicio ? (item.servicio.userText || (item.servicio.codigo + ' - ' + item.servicio.nombre)) : '')"
                            autocomplete="off"
                            :readonly="true"
                          >
                          </f7-list-input>
                          <f7-list-input
                            class="col-100 medium-5"
                            floating-label
                            :label="$t('pedidos.cantidad')"
                            :value="item.cantidad"
                            v-tooltip="$t('pedidos.cantidad')+'<br/>'+item.cantidad"
                            autocomplete="off"
                            :readonly="true"
                          >
                          </f7-list-input>
                          <f7-list-input
                            class="col-100 medium-10 text-color-red"
                            :readonly="true"
                            floating-label
                            :label="$t('pedidos.contratacion')"
                            type="number"
                            step=".01"
                            v-tooltip="$t('pedidos.contratacion')+'<br/>'+totalesLineas[index]['subtotal']"
                            :value="totalesLineas[index]['subtotal']"
                          ></f7-list-input>
                          <f7-list-input
                            class="col-100 medium-10 text-color-red"
                            :readonly="true"
                            v-noroles="['ENAC', 'PRODUCCION']"
                            floating-label
                            :label="$t('pedidos.terceros')"
                            type="number"
                            step=".01"
                            v-tooltip="$t('pedidos.terceros')+'<br/>'+totalesLineas[index]['subtotalTerceros']"
                            :value="totalesLineas[index]['subtotalTerceros']"
                          ></f7-list-input>
                          <f7-list-input
                            class="col-100 medium-10 text-color-red"
                            :readonly="true"
                            floating-label
                            :label="$t('pedidos.totalTasas')"
                            type="number"
                            step=".01"
                            v-tooltip="$t('pedidos.totalTasas')+'<br/>'+totalesLineas[index]['totalTasas']"
                            :value="totalesLineas[index]['totalTasas']"
                          ></f7-list-input>
                          <f7-list-input
                            class="col-100 medium-10 text-color-red"
                            :readonly="true"
                            floating-label
                            :label="$t('pedidos.totalIVA')"
                            type="number"
                            step=".01"
                            v-tooltip="$t('pedidos.totalIVA')+'<br/>'+totalesLineas[index]['ivaConTerceros']"
                            :value="totalesLineas[index]['ivaConTerceros']"
                          ></f7-list-input>
                          <f7-list-input
                            class="col-100 medium-10 text-color-red"
                            :readonly="true"
                            floating-label
                            :label="$t('pedidos.subtotal')"
                            type="number"
                            step=".01"
                            v-tooltip="$t('pedidos.subtotal')+'<br/>'+totalesLineas[index]['totalSinTasasConTercerosSinIVa']"
                            :value="totalesLineas[index]['totalSinTasasConTercerosSinIVa']"
                          ></f7-list-input>
                        </f7-row>
                      </ul>
                    </f7-list>
                    <div 
                      class="col-100 medium-25 item-content item-input item-input-with-value sub-card"
                      >
                      <div 
                        >
                          <r-badge v-if="item.tiempoEstimadoCampo" color="blue" :text="'TPCE: ' + (item.tiempoEstimadoCampo || '-') + ' min'"></r-badge>
                          <r-badge v-if="item.tiempoEstimadoOficina" color="blue" :text="'TPIE: ' + (item.tiempoEstimadoOficina || '-') + ' min'"></r-badge>
                          <r-badge v-if="item.tiempoEstimadoCampo && item.tiempoEstimadoOficina" color="blue" :text="'TPE: ' + ((item.tiempoEstimadoOficina || 0) + (item.tiempoEstimadoCampo || 0)) + ' min'"></r-badge>
                          <r-badge v-if="item.tiempoRealCampo" color="purple" :text="'TPCR: ' + (item.tiempoRealCampo || '-') + ' min'"></r-badge>
                          <r-badge v-if="item.tiempoRealOficina" color="purple" :text="'TPIR: ' + (item.tiempoRealOficina || '-') + ' min'"></r-badge>
                          <r-badge v-if="item.tiempoRealCampo && item.tiempoRealOficina" color="purple" :text="'TPR: ' + ((item.tiempoRealOficina || 0) + (item.tiempoRealCampo || 0)) + ' min'"></r-badge>
                          <r-badge v-if="((item.tiempoRealCampo || 0) + (item.tiempoRealOficina || 0)) && ((item.tiempoEstimadoCampo || 0) + (item.tiempoEstimadoOficina || 0))" color="orange" :text="'TPR - TPE: ' + (((item.tiempoRealCampo || 0) + (item.tiempoRealOficina || 0)) - ((item.tiempoEstimadoCampo || 0) + (item.tiempoEstimadoOficina || 0))) + ' min'"></r-badge>
                      </div>
                      <div 
                        >
                          <r-badge v-if="item.fechaLimiteEmision" color="yellow" :text="'F.Lim.Emi: '+ DateUtils.dateFormat(item.fechaLimiteEmision, 'DD/MM/YYYY')"></r-badge>
                          <r-badge v-if="item.camposUsuario && item.camposUsuario.feEnvExp" color="yellow" :text="'F.E.Exp: '+ DateUtils.dateFormat(item.camposUsuario.feEnvExp, 'DD/MM/YYYY')"></r-badge>
                          <r-badge v-if="item.fechaLimiteTramitacion" color="orange" :text="'F.Lim.Tra: '+ DateUtils.dateFormat(item.fechaLimiteTramitacion, 'DD/MM/YYYY')"></r-badge>
                          <r-badge v-if="item.procesoEntrega && item.procesoEntrega.entregaIndustria && item.procesoEntrega.entregaIndustria.fecha" color="orange" :text="'F.Env Ind: '+ DateUtils.dateFormat(item.procesoEntrega.entregaIndustria.fecha, 'DD/MM/YYYY')"></r-badge>
                          <r-badge v-if="item.fechaLimitePagoTasas" color="teal" :text="'F.Lim.Tasas: '+ DateUtils.dateFormat(item.fechaLimitePagoTasas, 'DD/MM/YYYY')"></r-badge>
                          <r-badge v-if="item.procesoEntrega && item.procesoEntrega.pagoTasas && item.procesoEntrega.pagoTasas.fecha" color="teal" :text="'F.Pag.Tasas: '+ DateUtils.dateFormat(item.procesoEntrega.pagoTasas.fecha, 'DD/MM/YYYY')"></r-badge>
                          <r-badge v-if="item.fechaLimiteCorrecion" color="purple" :text="'F.Lim.Corr: '+ DateUtils.dateFormat(item.fechaLimiteCorrecion, 'DD/MM/YYYY')"></r-badge>
                          <r-badge v-if="item.proxInsp" color="purple" :text="'F.Prox.Insp: '+ DateUtils.dateFormat(item.proxInsp, 'DD/MM/YYYY')"></r-badge>
                          <r-badge v-if="item.fechaRenovacion" color="purple" :text="'F. Renovacion: '+ DateUtils.dateFormat(item.fechaRenovacion, 'DD/MM/YYYY')"></r-badge>
                          <r-badge v-if="item.fechaSincronizacion" color="lime" :text="'F. Sincr.: '+ DateUtils.dateFormat(item.fechaSincronizacion, 'DD/MM/YYYY')"></r-badge>
                          <r-badge v-if="item.fechaComunicacionIndustria" color="orange" :text="'FCI: ' + DateUtils.dateFormat(item.fechaComunicacionIndustria, 'DD/MM/YYYY')"></r-badge>
                      </div>
                    </div>

                    <f7-link
                      v-if="!item.lineaExceso"
                      v-tooltip="'Documentos'"
                      class="margin-half link-inline-row-2 link-inline-0"
                      icon-f7="doc_on_doc_fill"
                      :color="item.documentacionCompleta ? 'teal' : (item.documentacionRecibida ? 'green' : (item.documentacionSolicitada ? 'orange' : null)) "
                      @click="indexLinea = index; popupDocumentosOpened = true;"
                    ></f7-link>
                    <f7-link
                      v-tooltip="'Crear Exp.Manual'"
                      class="margin-half link-inline-row-2 link-inline-1"
                      v-if="readonly && (!item.idExpediente || (item.estadoExpediente && item.estadoExpediente == 'PLANIFICADO')) && !item.lineaExceso"
                      :icon-f7="'hand_raised'"
                      @click="crearExpManualConLinea(idData, item.numero)"
                    ></f7-link>
                    <f7-link
                      v-tooltip="'Desplanificar Línea'"
                      class="margin-half link-inline-row-2 link-inline-2"
                      v-if="readonly && item.idExpediente && (item.estadoExpediente == 'PLANIFICADO' || item.estadoExpediente == 'INSPECCIONADO' || item.estadoExpediente == 'CREADO') && !item.lineaExceso"
                      icon-f7="car_fill"
                      color="red"
                      @click="desplanificar(idData, item.numero)"
                    ></f7-link>
                    <f7-link
                      external
                      v-tooltip="'Ver Expediente '+ (item.codigoExpediente ? item.codigoExpediente : '' )"
                      v-if="item.idExpediente"
                      class="margin-half link-inline link-inline-0"
                      :icon-f7="item.expedienteManual ? 'doc_plaintext' : 'checkmark_seal'"
                      :color="getColorExpediente(item)"
                      :href="'#!/inspecciones/view/' + item.idExpediente + '/'"
                    ></f7-link>
                    <f7-link
                      v-if="readonly && !item.idExpediente && !item.lineaExceso"
                      v-tooltip="'Crear expediente sin planificar'"
                      class="margin-half link-inline link-inline-0"
                      icon-f7="rays"
                      color="blue"
                      @click="crearExpedienteSinPlanificar(rform.formData.id,item.numero)"
                    ></f7-link>
                    

                    <f7-link
                      external
                      v-if="item.idPedidoNuevaPrimera && !item.lineaExceso"
                      v-tooltip="getDatosInspeccionSiguiente(item)"
                      class="margin-half link-inline"
                      :class="'link-inline-3 color-green'"
                      :style="getStyleByItem(item)"
                      icon-material="repeat_one_on"
                      :href="item.idPedidoNuevaPrimera ? '#!/pedidos/view/' + item.idPedidoNuevaPrimera + '/' : null"
                      :key="'nuevaPrimera-siguiente-'+index"
                    ></f7-link>


                    <f7-link
                      v-tooltip="'Editar Línea'"
                      v-if="!readonly"
                      @click="$set($refs.rformNew, 'formData', item); $set($refs.rformNew, 'history', getHistory(item,index));"
                      class="margin-half link-inline"
                      :class="'link-inline-1'"
                      icon-f7="pencil_circle_fill"
                      popup-open=".popupnew"
                    ></f7-link>
                    <f7-link
                      v-if="readonly"
                      v-tooltip="'Ver Línea'"
                      @click="$set($refs.rformNew, 'formData', item); $set($refs.rformNew, 'history', getHistory(item,index));llamarOnBlur2a($refs.rformNew.formData)"
                      class="margin-half link-inline"
                      :class="'link-inline-1'"
                      icon-f7="info_circle_fill"
                      popup-open=".popupnew"
                      :key="'verLinea-'+index"
                    ></f7-link>
                    <f7-link
                      v-noroles="['ENAC', 'PRODUCCION']"
                      v-tooltip="'Desglosar Línea'"
                      v-if="!rform.readonly && rform.formData && item.cantidad > 1 && item.tipoLinea == 'LINEA' && item.lineStatus"
                      @click="desglosarLinea(item)"
                      class="margin-half link-inline"
                      :class="'link-inline-4'"
                      icon-f7="rectangle_on_rectangle"
                    ></f7-link>
                    <f7-link
                      external
                      v-tooltip="getNumLineaPrimeras(item)"
                      v-if="item.idExpedienteRelacionado && !item.lineaExceso"
                      class="margin-half link-inline"
                      :class="'link-inline-2'"
                      icon-material="looks_two"
                      :href="item.idPedido1as ? '#!/pedidos/view/' + item.idPedido1as + '/' : null"
                    ></f7-link>

                    <f7-link
                      external
                      v-if="item.idExpedienteAnteriorNegativo && !item.lineaExceso"
                      v-tooltip="getDatosInspeccionAnterior(item)"
                      class="margin-half link-inline"
                      :class="'link-inline-2'"
                      style="color: red;"
                      icon-material="looks_one"
                      :href="item.numeroPedidoAnteriorNegativa ? '#!/pedidos/view/' + item.idPedidoAnteriorNegativa + '/#linea-' + item.numeroLineaAnteriorNegativa : null"
                    ></f7-link>
                    <f7-link
                      v-tooltip="'Crear Línea de segundas'"
                      v-if="item.idExpediente && rform.readonly && item.cantidad == 1 && item.calificacion && !getLineaSegundas(item) && (item.calificacion == 'DESFAVORABLE' || item.calificacion == 'FAVORABLE_CON_DEFECTOS' || item.calificacion == 'FAVORABLE_CON_REPARO' || (item.calificacion == 'NEGATIVO' && !tieneInspeccionAnterior(item))) && !tieneSiguienteLineaPedido(item) && !item.lineaExceso"
                      @click="crearSegundas(item.numero)"
                      class="margin-half link-inline"
                      :class="{'link-inline-3': item.idExpedienteRelacionado, 'link-inline-2': !item.idExpedienteRelacionado}"
                      icon-f7="goforward_plus"
                    ></f7-link>
                    <f7-link
                      v-tooltip="'Crear Línea de Nueva Primera'"
                      v-if="item.idExpediente && item.calificacion == 'NEGATIVO' && tieneInspeccionAnterior(item) && rform.readonly && item.cantidad == 1 && !tieneSiguienteLineaPedido(item) && !item.lineaExceso"
                      @click="abrirPopupCrearNuevaPrimera(item)"
                      class="margin-half link-inline"
                      :class="'link-inline-3 color-red'"
                      icon-f7="goforward_plus"
                    ></f7-link>
                    <f7-link



                      v-tooltip="'Vincular Línea de Nueva Primera'"
                      v-if="item.idExpediente && item.calificacion == 'NEGATIVO' && tieneInspeccionAnterior(item) && rform.readonly && item.cantidad == 1 && !tieneSiguienteLineaPedido(item) && !item.lineaExceso"
                      @click="abrirPopupVincularNuevaPrimera(item)"
                      class="margin-half link-inline-row-3 link-inline-2 color-red"
                      icon-f7="link_circle"
                    ></f7-link>
                    <f7-link
                      v-tooltip="'Vincular Línea anterior negativa'"
                      v-else-if="!tieneInspeccionAnterior(item) && rform.readonly && !item.lineaExceso"
                      @click="abrirPopupVincularNegativaAnterior(item)"
                      class="margin-half link-inline-row-3 link-inline-2 color-blue"
                      icon-f7="link_circle"
                    ></f7-link>

                    <f7-link
                      v-tooltip="'Desvincular Líneas anterior negativa y nueva primera'"
                      v-if="rform.readonly && (item.idPedidoAnteriorNegativa || item.idPedidoNuevaPrimera) && !item.lineaExceso"
                      @click="desvincularNuevaPrimeraNegativaAnterior(item)"
                      class="margin-half link-inline-row-3 link-inline-3 color-blue"
                      icon-f7="scissors"
                    ></f7-link>





                    <f7-link
                      v-tooltip="'Borrar línea de segundas'"
                      v-if="!item.idExpediente && rform.readonly && item.idExpedienteRelacionado && (!item.lineStatus || item.lineStatus !='C') && !item.lineaExceso"
                      @click="marcarSegundas(item)"
                      class="margin-half link-inline link-inline-trash"
                      icon-f7="trash"
                      color="red"
                      :key="'borrarSegundas-'+index"
                    ></f7-link>
                    <f7-link
                      v-tooltip="'Borrar nueva primera'"
                      v-else-if="!item.idExpediente && rform.readonly&& item.idExpedienteAnteriorNegativo && (!item.lineStatus || item.lineStatus !='C') && !item.lineaExceso"
                      @click="marcarNuevaPrimera(item)"
                      class="margin-half link-inline link-inline-trash"
                      icon-f7="trash"
                      color="red"
                      :key="'borrarNuevaPrimera-'+index"
                    ></f7-link>
                    <f7-link
                      v-roles="['ADMIN', 'DTYCAL']"
                      v-tooltip="'Borrar línea'"
                      v-else-if="!item.idExpediente && rform.readonly && (!item.lineStatus || item.lineStatus !='C') && !item.lineaExceso"
                      @click="marcarSegundas(item)"
                      class="margin-half link-inline link-inline-trash"
                      icon-f7="trash"
                      color="red"
                      :key="'borrarLoQueSea-'+index"
                    ></f7-link>
                    
                    <!-- tercera línea de iconos -->
                    <!-- Comentado por ahora, porque se van a mandar estos correos desde el listado de líneas de pedidos -->
                    <!-- <f7-link
                      v-tooltip="'Enviar email'"
                      class="margin-half link-inline-row-3 link-inline-0"
                      icon-f7="envelope_fill"
                      @click="indexLinea = index; popupEnviarEmailOpened = true;"
                      v-if="readonly"
                    ></f7-link> -->
                    
                    <f7-link
                      v-tooltip="'Ver emails'"
                      class="margin-half link-inline-row-3 link-inline-1"
                      icon-f7="equal_square"
                      @click="indexLinea = index; popupVerEmailsOpened = true;"
                      v-if="readonly && !item.lineaExceso"
                    ></f7-link>
                    <f7-link
                      v-if="readonly && id && $refs.rform && $refs.rform.formData && $refs.rform.formData.numero && !item.lineaExceso"
                      v-tooltip="'Crear oportunidad'"
                      class="margin-half link-inline-row-4 link-inline-0"
                      v-noroles="['ENAC', 'PRODUCCION']"
                      icon-f7="rectangle_stack_person_crop_fill"
                      @click="crearOportunidadLinea(item)"
                    ></f7-link>
                    <f7-link
                      v-if="readonly && id && $refs.rform && $refs.rform.formData && $refs.rform.formData.numero && !item.lineaExceso"
                      v-tooltip="'Crear tarea'"
                      class="margin-half link-inline-row-4 link-inline-1"
                      v-noroles="['ENAC', 'PRODUCCION']"
                      icon-f7="calendar_circle"
                      @click="crearTareaLinea(item)"
                    ></f7-link>
                  </f7-card>
              </div>
              <template v-if="item.comentarios || item.comentariosLineaCerrada || getComentariosDireccion(item)">
                <div v-if ="getComentariosDireccion(item)" v-noroles="['ENAC', 'PRODUCCION']" class="item-inner col-100">
                  <f7-card class="no-margin linea-texto linea-comentario-direccion">
                      {{getComentariosDireccion(item)}}
                  </f7-card>
                </div>
                <div v-noroles="['ENAC', 'PRODUCCION']" class="item-inner col-100" 
                  v-for="(texto,index2) in getLineasTexto(item.comentarios)"
                  :key="'linea-'+index+'texto'+index2">
                  <f7-card class="no-margin linea-texto">
                      {{texto}}
                  </f7-card>
                </div>
                <div v-noroles="['ENAC', 'PRODUCCION']" class="item-inner col-100" 
                  v-for="(texto,index2) in getLineasTexto(item.comentariosLineaCerrada)"
                  :key="'lineaCerrada-'+index+'texto'+index2">
                  <f7-card class="no-margin linea-texto linea-comentario-cerrada">
                      {{texto}}
                  </f7-card>
                </div>
              </template>
              <!-- </a> -->
              </li>
            </ul>
          </div>
          <!-- Líneas -->
          <f7-block-title  v-if="$refs.rform && $refs.rform.formData" style="z-index: 30">
            {{$t('pedidos.lineas')}}
            <!-- Botón de añadir línea que se active con la tecla Ctrl+l -->
            <f7-link v-if="!rform.readonly && rform.formData" @click="crearLinea">{{$t('pedidos.add')}}</f7-link>
          </f7-block-title>
        </f7-tab>
        <f7-tab id="tab-direcciones">
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <f7-list class="list-form">
                <f7-row>
                  <div class="label-group col-100">Dirección Envío</div>
                  <r-direccion :form="rform" :name="'logistica.direccionEnvio'"></r-direccion>
                  <div class="label-group col-100">Dirección Facturación</div>
                  <r-direccion :form="rform" :name="'logistica.direccionFacturacion'"></r-direccion>
                  <f7-block-title class="col-100">
                    Emails Envío Facturas
                    <f7-link v-if="!readonly" @click="addEmail('emailsFacturas')">{{$t('pedidos.add')}}</f7-link>
                    <f7-link
                      v-if="readonly && rform.formData && rform.formData.cliente && rform.formData.cliente.emailsFacturas && rform.formData.cliente.emailsFacturas.length"
                      external
                      v-tooltip="'Enviar Email'"
                      icon-f7="envelope_badge_fill"
                      :href="'mailto:' + rform.formData.cliente.emailsFacturas.join(';')"
                    ></f7-link>
                  </f7-block-title>
                  <div class="form-caja margin-horizontal" v-if="rform.formData.cliente">
                    <div
                      class="col-100"
                      v-for="(email, index) in rform.formData.cliente.emailsFacturas"
                      :key="'emailFactura-' + index"
                    >
                      <r-input
                        class="col-100"
                        outline
                        floating-label
                        :label="'Correo electrónico'"
                        :form="rform"
                        type="text"
                        :clearButton="false"
                        :placeholder="'Introduzca un correo electrónico válido'"
                        :name="'cliente.emailsFacturas.' + index"
                        :validation="{isValid: validator.isEmail, errorMessage: '* Introduzca un correo electrónico válido'}"
                      >
                        <f7-link
                          v-if="!readonly"
                          v-tooltip="'Borrar'"
                          slot="inner-end"
                          @click="$delete(rform.formData.cliente.emailsFacturas, index);"
                          class="input-suffix-btn"
                          icon-f7="trash"
                          style="top: initial;right: 15px;"
                        ></f7-link>
                      </r-input>
                    </div>
                  </div>
                  <f7-block-title class="col-100">
                    Emails Envío Informes
                    <f7-link v-if="!readonly" @click="addEmail('emailsCertificados')">{{$t('pedidos.add')}}</f7-link>
                    <f7-link
                      v-if="readonly && rform.formData && rform.formData.cliente && rform.formData.cliente.emailsCertificados && rform.formData.cliente.emailsCertificados.length"
                      external
                      v-tooltip="'Enviar Email'"
                      icon-f7="envelope_badge_fill"
                      :href="'mailto:' + rform.formData.cliente.emailsCertificados.join(';')"
                    ></f7-link>
                  </f7-block-title>
                  <div class="form-caja margin-horizontal" v-if="rform.formData.cliente">
                    <div
                      class="col-100"
                      v-for="(email, index) in rform.formData.cliente.emailsCertificados"
                      :key="'emailCertificado-' + index"
                    >
                      <r-input
                        class="col-100"
                        outline
                        floating-label
                        :label="'Correo electrónico'"
                        :form="rform"
                        type="text"
                        :clearButton="false"
                        :placeholder="'Introduzca un correo electrónico válido'"
                        :name="'cliente.emailsCertificados.' + index"
                        :validation="{isValid: validator.isEmail, errorMessage: '* Introduzca un correo electrónico válido'}"
                      >
                        <f7-link
                          v-if="!readonly"
                          v-tooltip="'Borrar'"
                          slot="inner-end"
                          @click="$delete(rform.formData.cliente.emailsCertificados, index);"
                          class="input-suffix-btn"
                          icon-f7="trash"
                          style="top: initial;right: 15px;"
                        ></f7-link>
                      </r-input>
                    </div>
                  </div>
                </f7-row>
              </f7-list>
            </f7-card-content>
          </f7-card>
        </f7-tab>
        <f7-tab id="tab-finanzas" v-if="rform.formData && rform.formData.finanzas">
          <!-- Pago y Finanzas -->
          <!-- <f7-block-title  v-if="$refs.rform && $refs.rform.formData" style="z-index: 30">
                {{$t('pedidos.f')}}
                <f7-link v-if="!rform.readonly && rform.formData" @click="rform.formData.contactos.push({activo:true})">{{$t('pedidos.add')}}</f7-link>
          </f7-block-title>-->
          
          <tab-finanzas
            ref="tabFinanzas"
            :readonly="readonly"
            :rform="rform" 
          ></tab-finanzas>
        </f7-tab>
        <f7-tab id="tab-documentos">
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <div v-if="rform.formData.documentos && !rform.formData.documentos.length > 0">El pedido no tiene documentos</div>
              <f7-list class="list-form">
                <ul>
                  <r-files 
                    :form="rform" 
                    name="documentos"
                    :documentoAdapter="Documento"
                  ></r-files>
                </ul>
              </f7-list>
            </f7-card-content>
          </f7-card>
        </f7-tab>
        <f7-tab id="tab-tareas" v-noroles="['ENAC', 'PRODUCCION']" v-if="$refs.rform && $refs.rform.formData && $refs.rform.formData.numero">
          <r-dynamic-list
            ref="rlist"
            :primary="['id']"
            :dbAdapter="Tarea"
            :tableCode="'TAREASPEDIDO'"
            :tableMode="true"
            :virtualListParams="{ height: 32, rowsBefore: 60, rowsAfter: 60 }"
            class="card full-height data-table"
            classContent="card-content full-height"
            :controlsWidth="100"
            :searchOnInit="true"
            :shownFields="['numeroPedido']"
            :mandatoryFilter ="{ 'numeroPedido': $refs.rform.formData.numero }"
            :funcShowCheck="_ => { return false; }"
            :massiveActions="false"
          >
            <template v-slot:optionsColumn="{ item }">
                <f7-link icon-f7="eye" color="green" @click="popupTareaEditable = false; tareaId = item.id; popupTareasOpened = true"></f7-link>
                <f7-link icon-f7="square_pencil" color="blue" @click="popupTareaEditable = true; tareaId = item.id; popupTareasOpened = true"></f7-link>
                <f7-link icon-f7="trash" color="red" @click="$refs.rlist.deleteItem(item.id)"></f7-link>
            </template>
          </r-dynamic-list>
        </f7-tab>
        <f7-tab id="tab-emails" v-if="$refs.rform && $refs.rform.formData && $refs.rform.formData.codigo">
          <r-dynamic-list
            ref="rlist"
            :primary="['id']"
            :dbAdapter="RegistroEmail"
            :tableCode="'REGISTROEMAILTABS'"
            :tableMode="true"
            :virtualListParams="{ height: 32, rowsBefore: 60, rowsAfter: 60 }"
            class="card full-height data-table"
            classContent="card-content full-height"
            :controlsWidth="50"
            :searchOnInit="true"
            :shownFields="['to','datos']"
            :mandatoryFilter ="{ 'datos##codigoPedido': $refs.rform.formData.codigo }"
            :funcShowCheck="_ => { return false; }"
            :massiveActions="false"
            useView="vista_RegistroEmails"
          >
            <template v-slot:optionsColumn="{ item }">
                <f7-link icon-f7="eye" color="green" @click="popupEmailEditable = false; emailId = item.id; popupEmailOpened = true"></f7-link>
            </template>
          </r-dynamic-list>
        </f7-tab>
        <f7-tab id="tab-oportunidades" v-noroles="['ENAC', 'PRODUCCION']" v-if="$refs.rform && $refs.rform.formData && $refs.rform.formData.codigo">
          <r-dynamic-list
            ref="rlist"
            :primary="['id']"
            :dbAdapter="Oportunidad"
            :tableCode="'OPORTUNIDADTAB'"
            :tableMode="true"
            :virtualListParams="{ height: 32, rowsBefore: 60, rowsAfter: 60 }"
            class="card full-height data-table"
            classContent="card-content full-height"
            :controlsWidth="50"
            :searchOnInit="true"
            :shownFields="['pedido.codigo']"
            :mandatoryFilter ="{ 'pedido##codigo': $refs.rform.formData.codigo }"
            :funcShowCheck="_ => { return false; }"
            :massiveActions="false"
          >
            <template v-slot:optionsColumn="{ item }">
                <f7-link external v-tooltip="$t('common.ver') + ' Oportunidad'" icon-f7="eye" color="green" :href="`#!/oportunidades/view/${item.id}/`"></f7-link>
            </template>
          </r-dynamic-list>
        </f7-tab>
      </f7-tabs>
      <f7-popup ref="popupDocumentos" class="popup-documentos" tablet-fullscreen v-if="popupDocumentosOpened" :opened="true" @popup:closed="indexLinea = -1; popupDocumentosOpened = false" @popup:opened="crearDocumentosConNombre">
        <f7-page class="content-documentos">
          <f7-navbar title="Documentos de la línea">
            <f7-nav-right>
              <f7-link popup-close>Cerrar</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <f7-card class="elevation-3 padding-vertical-half padding-right w-100" >
            <f7-card-content>
              <f7-list class="list-form">
                <ul>
                  <f7-row>
                    <f7-col width="100" medium="25">
                      <r-input
                        class="col-100 medium-25"
                        :form="rform"
                        type="check"
                        :name="'lineas.' + indexLinea + '.documentacionRecibida'"
                        label="Doc. Recibida"
                      ></r-input>
                      <r-input
                        class="col-100 medium-25"
                        :form="rform"
                        type="check"
                        :name="'lineas.' + indexLinea + '.documentacionCompleta'"
                        label="Doc. Completa"
                      ></r-input>
                      <r-input
                        class="col-100 medium-25"
                        floating-label
                        outline
                        :form="rform"
                        type="datepicker"
                        :name="'lineas.' + indexLinea + '.fechaSolicitudDocumentacion'"
                        label="Fec. Solicitud Doc."
                        :calendar-params="{ closeOnSelect: true, routableModals: false, dateFormat: { year: 'numeric', month: '2-digit', day: '2-digit' } }"
                      ></r-input>
                    </f7-col>
                    <f7-col width="100" medium="75">
                      <r-input
                        class="col-100 medium-75"
                        outline
                        floating-label
                        :form="rform"
                        type="textarea"
                        :name="'lineas.' + indexLinea + '.documentosComentarios'"
                        label="Comentarios"
                      ></r-input>
                    </f7-col>
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
          </f7-card>

          <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
          <f7-card class="elevation-3 padding-vertical-half w-100" v-if="rform.formData.lineas[indexLinea].documentos && rform.formData.lineas[indexLinea].documentos.length">
            <f7-card-content>
              <f7-list class="list-form">
                <ul>
                  <f7-row>
                    <f7-col width="100" medium="100">
                      <r-files 
                        :itemHeight="70"
                        :form="rform" 
                        :name="'lineas.' + indexLinea + '.documentos'"
                        :documentoAdapter="Documento"
                      ></r-files>
                    </f7-col>
                  </f7-row>
                </ul>
              </f7-list>
              <f7-row>
                <f7-col width="100" medium="100">
                  <f7-button
                      v-if="$refs.rform.formData.estado.estado == 'ENVIADA' && rform.formData.documentos && rform.formData.documentos.length"
                      fill
                      icon-f7="cloud_upload_fill"
                      text="Adjuntar Aceptación"
                      class="display-flex margin-vertical margin-left"
                      color="green"
                      @click="rform.saveItem()"
                    ></f7-button>
                </f7-col>
              </f7-row>
            </f7-card-content>
          </f7-card>

          <!-- Lista de documentos con nombre -->
          <!-- <div>
            <f7-link
              v-if="!rform.readonly"
              class="flex"
              @click="nuevaDocumentacionConNombre(indexLinea)"
              icon-f7="plus_app_fill"
            ></f7-link>
          </div> -->
          <f7-card 
            v-for="(lineadcn, iddn) in getDocumentosConNombreOrdenados(rform.formData.lineas[indexLinea].documentosConNombre)" 
            class="elevation-3 padding-vertical-half w-32"  
            :key="'lineas.' + indexLinea + '.documentosConNombre' + iddn"
          >
            <f7-card-content>
              <f7-list class="list-form">
              <ul>
                <f7-row>
                  <f7-col width="100" medium="100">
                    <r-input
                      class="col-100 medium-100"
                      outline
                      floating-label
                      :form="rform"
                      type="text"
                      :name="'lineas.' + indexLinea + '.documentosConNombre.' + lineadcn.codigo + '.nombre'"
                      label="Nombre"
                      :readonly="!rform.formData.lineas[indexLinea].documentosConNombre[lineadcn.codigo].editable"
                      :overrideReadonly="true"
                    ></r-input>
                    <r-files 
                      :itemHeight="70"
                      :form="rform" 
                      @loadFile="documentoCargado(indexLinea)"
                      :name="'lineas.' + indexLinea + '.documentosConNombre.' + lineadcn.codigo + '.documentos'"
                      :documentoAdapter="Documento"
                    ></r-files>
                    <f7-button
                      v-if="!rform.readonly && rform.formData.lineas[indexLinea].documentosConNombre[lineadcn.codigo].editable"
                      small
                      color="red"
                      icon-f7="trash" 
                      v-tooltip="'Eliminar'"
                      @click="eliminarDocumentosConNombre(rform.formData.lineas[indexLinea].documentosConNombre, indexLinea, lineadcn.codigo)"
                    ></f7-button>
                  </f7-col>
                </f7-row>
              </ul>
              </f7-list>
            </f7-card-content>
          </f7-card>
          <!-- </div> -->
        </f7-page>
      </f7-popup>
      <f7-popup class="popupreparto" v-if="popuprepartoopened" :opened="true" @popup:closed="popuprepartoopened = false;" >
        <f7-page v-if="rform && rform.formData">
            <f7-navbar>
              <f7-nav-left>
                <f7-link popup-close> {{readonly ? 'Cerrar' : 'Guardar'}}</f7-link>
              </f7-nav-left>
              <f7-nav-title v-if="tipoReparto == 'repEjecucion'">
                {{$t('repartos.ejecucion')}}
              </f7-nav-title>
              <f7-nav-title v-else>
                  {{$t('repartos.contratacion')}}
              </f7-nav-title>
          </f7-navbar>
                <f7-button
                v-if="!readonly"
                color="blue"
                @click="repartoDeshacerTodos(tipoReparto)"
              >{{$t('repartos.deshacerTodos')}}</f7-button> 
            <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
              <f7-card-content>
                <f7-row no-gap class="list list-form no-hairlines">
                  <f7-col width="100" medium="100">
                        <div :class="{'linOK' : isRepartoOK(linea, tipoReparto), 'linKO' : !isRepartoOK(linea, tipoReparto)}" v-for="(linea, idx) in rform.formData.lineas" :key="(tipoReparto == 'cont' ? 'recont.' : 'reejec.') + linea.numero"> 
                          <div id="block_container">
                            <div id="block1">
                              Línea: {{linea.numero}}</div> <div id="block2">  Importe: {{linea.totalSinIVASinTasasConDescuento}} </div>
                              <div id="block3">
                                <f7-link 
                                  v-if="!rform.readonly && rform.formData && rform.formData.lineas" 
                                  v-tooltip="$t('repartos.anadir')"
                                  fill
                                  icon-f7="plus_app"
                                  icon-size="14"
                                  color="green"
                                  class="margin-right-half"
                                  @click="addReparto(tipoReparto, linea, false)"
                                  ></f7-link>
                                <f7-link
                                  v-if="!readonly"
                                  v-tooltip="$t('repartos.duplicarAbajo')"
                                  fill
                                  icon-f7="arrow_down_to_line"
                                  icon-size="14"
                                  color="orange"
                                  class="margin-right-half"
                                  @click="duplicarRepartoAbajo(tipoReparto, linea, idx)"
                                ></f7-link>
                                <f7-link
                                  v-if="!readonly"
                                  v-tooltip="$t('repartos.duplicarArriba')"
                                  fill
                                  icon-f7="arrow_up_to_line"
                                  icon-size="14"
                                  color="pink"
                                  class="margin-right-half"
                                  @click="duplicarRepartoArriba(tipoReparto, linea, idx)"
                                ></f7-link>
                                <f7-link
                                  v-if="!readonly"
                                  v-tooltip="$t('repartos.equitativo')"
                                  fill
                                  icon-f7="percent"
                                  icon-size="14"
                                  color="purple"
                                  class="margin-right-half"
                                  @click="repartoEquitativo(tipoReparto, linea)"
                                ></f7-link>
                                <f7-link
                                  v-if="!readonly"
                                  v-tooltip="$t('repartos.deshacer')"
                                  fill
                                  icon-f7="arrow_counterclockwise"
                                  icon-size="14"
                                  color="blue"
                                  class="margin-right-half"
                                  @click="repartoDeshacer(tipoReparto, idx)"
                                ></f7-link> 
                              </div>
                            </div> 
                          <div v-for="(rep, idxrep) in linea[tipoReparto]" :key="rep.code"> 
                            <f7-row v-if="linea[tipoReparto]">
                              <r-autocomplete class="col-20" :dbAdapter="Empleado" label="Usuario" :name="'lineas.' + idx + '.' + tipoReparto + '.' + idxrep + '.usuario'" :form="rform" labelProp="username" idProp="username" idFieldDetail="id" :showNavigationLink="false"> </r-autocomplete>
                              <r-input @change="onChangePorcentajeRep(tipoReparto, linea, idxrep)" class="col-20" label="Porcentaje" type="number" :name="'lineas.' + idx + '.' + tipoReparto + '.' + idxrep + '.porcentaje'" :form="rform"> </r-input>
                              <r-input class="col-20" label="Fecha" type="datepicker" :name="'lineas.' + idx + '.' + tipoReparto + '.' + idxrep + '.fecha'" :form="rform"> </r-input>
                              <r-input class="col-20" label="Importe Reparto" type="number" :name="'lineas.' + idx + '.' + tipoReparto + '.' + idxrep + '.importeReparto'" :form="rform" :readonly="true"> </r-input>
                              <f7-link
                                v-if="!readonly"
                                v-tooltip="$t('repartos.eliminar')"
                                fill
                                icon-f7="trash"
                                icon-size="14"
                                color="red"
                                class="margin-right-half"
                                @click="deleteReparto(tipoReparto, linea, idxrep)"
                              ></f7-link>          
                            </f7-row>
                          </div>
                        </div>
                  </f7-col> 
                </f7-row> 

              </f7-card-content>
            </f7-card>
        </f7-page>  
      </f7-popup>


      <f7-popup ref="popupSegundas"  swipe-to-close v-if="popupSegundasOpened" :opened="true"  @popup:close="popupSegundasOpened = false" >
      <f7-page v-if="rform && rform.formData">
        <f7-navbar title="Usu Ejec Segundas">
          <f7-nav-right>
            <f7-link popup-close>{{$t('common.cerrar')}}</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <r-form 
          ref="rformSegundas" 
          @save="crearSegundasConUsuEjec()"
          :dbAdapter="null"
          classContent="full-height"
          :readonly="false"
          :defaultData="{usuEjecSegundas:''}"
        >
          <template v-slot:default="{ rform }">
            <f7-card
              class="elevation-3 padding-vertical-half padding-right"
            >
              <f7-card-content>
                <f7-list class="list-form">
                    <f7-row>
                      <f7-col width="100" medium="100">
                        <r-autocomplete
                          v-if="idData"
                          :dbAdapter="Empleado"
                          class="col-100 medium-25"
                          floating-label
                          :label="$t('pedidos.usuarioEjecucion')"
                          :form="rform"
                          name="usuEjecSegundas"
                          labelProp="nombre"
                          idProp="username"
                          prefixProp="username"
                          idFieldDetail="id"
                        ></r-autocomplete>
                      </f7-col>
                    </f7-row>
                </f7-list>
              </f7-card-content>
              <f7-row>
                <f7-col width="100" medium="100">
                  <f7-button
                      fill
                      icon-f7="disk"
                      :text="$t('common.guardar')"
                      class="display-flex margin-vertical"
                      color="green"
                      @click="rform.saveItem()"
                    ></f7-button>
                </f7-col>
              </f7-row>
 
            </f7-card>
          </template>
        </r-form>
      </f7-page>
    </f7-popup>





    <f7-popup ref="popupNuevaPrimera"  swipe-to-close v-if="popupNuevaPrimeraOpened" :opened="true"  @popup:close="popupNuevaPrimeraOpened = false" >
      <f7-page v-if="rform && rform.formData">
        <f7-navbar title="Creación de línea de 'Nueva Primera'">
          <f7-nav-right>
            <f7-link popup-close>{{ $t('common.cerrar') }}</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <r-form 
          ref="rformNuevaPrimera"
          @save="crearNuevaPrimera"
          :dbAdapter="null"
          classContent="full-height"
          :readonly="false"
          :defaultData="{ idPedido: '', numeroLinea: '', formaCrear: '', pedidoAsociar: '' }"
        >
          <template v-slot:default="{ rform }">
            <f7-card
              class="elevation-3 padding-vertical-half padding-right"
            >
              <f7-card-content>
                <f7-list class="list-form">
                    <f7-row>
                      <f7-col width="100" medium="100">
                        <r-input
                          floating-label
                          outline
                          :label="$t('pedidos.lineaspedido.formacrear')" 
                          :form="rform"
                          type="select"
                          name="formaCrear"
                          :clear-button="false"
                        >
                          <option 
                            v-for="forma in formasCrearLinea"
                            :value="forma.value"
                            :key="forma.value"
                          >{{forma.name}}</option>
                        </r-input>
                      </f7-col>
                      <f7-col width="100" medium="100" v-if="rform.formData.formaCrear == 'ASOCIAR'">
                        <r-input
                          floating-label
                          outline
                          :label="$t('pedidos.lineaspedido.pedidoAsociar')" 
                          :form="rform"
                          type="text"
                          name="pedidoAsociar"
                          :clear-button="true"
                        ></r-input>
                      </f7-col>
                    </f7-row>
                </f7-list>
              </f7-card-content>
              <f7-row>
                <f7-col width="100" medium="100">
                  <f7-button
                      fill
                      icon-f7="disk"
                      :text="$t('common.guardar')"
                      class="display-flex margin-vertical"
                      color="green"
                      @click="rform.saveItem()"
                    ></f7-button>
                </f7-col>
              </f7-row>

            </f7-card>
          </template>
        </r-form>
      </f7-page>
    </f7-popup>





    <f7-popup ref="popupVincularNuevaPrimera"  swipe-to-close v-if="popupVincularPrimeraOpened" :opened="true"  @popup:close="popupVincularPrimeraOpened = false" >
      <f7-page v-if="rform && rform.formData">
        <f7-navbar title="Vinculación de línea de 'Nueva Primera'">
          <f7-nav-right>
            <f7-link popup-close>{{ $t('common.cerrar') }}</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <r-form 
          ref="rformNuevaPrimera"
          @save="vincularNuevaPrimera"
          :dbAdapter="null"
          classContent="full-height"
          :readonly="false"
          :defaultData="{ pedidoAnteriorNegativa: pedidoAnteriorNegativa, numeroLineaAnteriorNegativa: numeroLineaAnteriorNegativa, pedidoNuevaPrimera: '', numeroLineaNuevaPrimera: '', devolver: 'NUEVA_PRIMERA' }"
        >
          <template v-slot:default="{ rform }">
            <f7-card
              class="elevation-3 padding-vertical-half padding-right"
            >
              <f7-card-content>
                <f7-list class="list-form">
                    <f7-row>
                      <f7-col width="100" medium="100" >
                        <r-input
                          floating-label
                          outline
                          :label="$t('pedidos.lineaspedido.pedidoNuevaPrimera')" 
                          :form="rform"
                          type="text"
                          name="pedidoNuevaPrimera"
                          :clear-button="true"
                        ></r-input>
                        <r-input
                          floating-label
                          outline
                          :label="$t('pedidos.lineaspedido.numeroLineaNuevaPrimera')" 
                          :form="rform"
                          type="number"
                          name="numeroLineaNuevaPrimera"
                          :clear-button="true"
                        ></r-input>
                      </f7-col>
                    </f7-row>
                </f7-list>
              </f7-card-content>
              <f7-row>
                <f7-col width="100" medium="100">
                  <f7-button
                      fill
                      icon-f7="disk"
                      :text="$t('common.guardar')"
                      class="display-flex margin-vertical"
                      color="green"
                      @click="rform.saveItem()"
                    ></f7-button>
                </f7-col>
              </f7-row>

            </f7-card>
          </template>
        </r-form>
      </f7-page>
    </f7-popup>





    <f7-popup ref="popupVincularAnteriorNegativa"  swipe-to-close v-if="popupVincularAnteriorNegativaOpened" :opened="true"  @popup:close="popupVincularAnteriorNegativaOpened = false" >
      <f7-page v-if="rform && rform.formData">
        <f7-navbar title="Vinculación de línea anterior negativa a una de 'Nueva Primera'">
          <f7-nav-right>
            <f7-link popup-close>{{ $t('common.cerrar') }}</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <r-form 
          ref="rformVincularAnteriorNegativa"
          @save="vincularAnteriorNegativa"
          :dbAdapter="null"
          classContent="full-height"
          :readonly="false"
          :defaultData="{ pedidoAnteriorNegativa: '', numeroLineaAnteriorNegativa: '', pedidoNuevaPrimera: pedidoNuevaPrimera, numeroLineaNuevaPrimera: numeroLineaNuevaPrimera, devolver: 'ANTERIOR_NEGATIVA' }"
        >
          <template v-slot:default="{ rform }">
            <f7-card
              class="elevation-3 padding-vertical-half padding-right"
            >
              <f7-card-content>
                <f7-list class="list-form">
                    <f7-row>
                      <f7-col width="100" medium="100" >
                        <r-input
                          floating-label
                          outline
                          :label="$t('pedidos.lineaspedido.pedidoAnteriorNegativa')" 
                          :form="rform"
                          type="text"
                          name="pedidoAnteriorNegativa"
                          :clear-button="true"
                        ></r-input>
                        <r-input
                          floating-label
                          outline
                          :label="$t('pedidos.lineaspedido.numeroLineaAnteriorNegativa')" 
                          :form="rform"
                          type="number"
                          name="numeroLineaAnteriorNegativa"
                          :clear-button="true"
                        ></r-input>
                      </f7-col>
                    </f7-row>
                </f7-list>
              </f7-card-content>
              <f7-row>
                <f7-col width="100" medium="100">
                  <f7-button
                      fill
                      icon-f7="disk"
                      :text="$t('common.guardar')"
                      class="display-flex margin-vertical"
                      color="green"
                      @click="rform.saveItem()"
                    ></f7-button>
                </f7-col>
              </f7-row>

            </f7-card>
          </template>
        </r-form>
      </f7-page>
    </f7-popup>




    <f7-popup tablet-fullscreen ref="popupVerEmails" class="popup-ver-emails" v-if="popupVerEmailsOpened" :opened="true"  @popup:close="popupVerEmailsOpened = false" swipe-to-close>
      <f7-page>
        <f7-navbar :title="'Emails de la línea' + $refs.rform.formData.lineas[indexLinea].numero">
          <f7-nav-right>
            <f7-link popup-close>Cerrar</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-card
          class="elevation-3 padding-vertical-half padding-right"
        >
        <!-- rList Table CARD-->
          <r-dynamic-list
            ref="rlist"
            :dbAdapter="RegistroEmail"
            :tableCode="'REGISTROEMAILLINEA'"
            :primary="['id']"
            :tableMode="true"
            :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
            class="card full-height data-table"
            classContent="card-content full-height"
            :shownFields="['datos']"
            :massiveActions="false"
            :mandatoryFilter ="{'datos##codigoPedido': $refs.rform.formData.codigo, 'datos##lineasPedido##numero': $refs.rform.formData.lineas[indexLinea].numero}"
            :funcShowCheck="_ => {return false;}"
            :controlsWidth="45"
            :searchOnInit="true"
            useView="vista_RegistroEmails"
          >
            <template v-slot:optionsColumn="{item}">
              <f7-link icon-f7="eye" color="green" external :href="getEmailUrl(item)"></f7-link>
              <!-- <f7-link v-tooltip="$t('common.editar')" icon-f7="square_pencil" color="blue" :href="`/lineasHorasGama/edit/${item.id}/`"></f7-link> -->
              <!-- <f7-link v-if="!readonly" v-tooltip="$t('common.eliminar')" icon-f7="trash" color="red" @click="rlist.deleteItem(item.id);"></f7-link> -->
            </template>
          </r-dynamic-list>
        </f7-card>
      </f7-page>
    </f7-popup>
    <f7-popup ref="popupEnviarEmail" class="popup-enviar-email" v-if="popupEnviarEmailOpened" :opened="true" @popup:open="recuperarPlantillas"  @popup:close="popupEnviarEmailOpened = false" swipe-to-close>
      <f7-page>
        <f7-navbar title="Enviar Email">
          <f7-nav-right>
            <f7-link popup-close>Cerrar</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-card
          class="elevation-3 padding-vertical-half padding-right"
        >
          <r-form 
            ref="rformEnviarEmail" 
            @save="onSaveEnviarEmail"
            :dbAdapter="null"
            classContent="full-height"
            :readonly="false"
            :hidePreloaderOnSave="false"
            :defaultData="{email: $refs.rform.formData.lineas[indexLinea].emailComunicaciones || $refs.rform.formData.emailContacto}"
          >
            <template v-slot:default="{ rform }">
              <f7-card class="elevation-3 padding-vertical-half padding-right">
                <f7-card-content>
                  <f7-list class="list-form">
                    <ul>
                      <f7-row>
                        <f7-col width="100" medium="100">
                          <r-input
                            class="col-100"
                            floating-label
                            outline
                            label="Enviar a (Varios separados por ;)"
                            :form="rform"
                            name="email"
                          />
                          <r-input
                            class="col-100 medium-25"
                            floating-label
                            :label="$t('pedidos.lineaspedido.plantillaEmail')" 
                            :form="rform"
                            type="select"
                            name="plantillaEmail"
                            :clear-button="false"
                            @change="chargePlantillaEmail"
                          >
                            <option value="SIN">Sin plantilla</option>
                            <option 
                              v-for="plantilla in plantillas"
                              :value="plantilla.path"
                              :key="plantilla.path"
                            >{{plantilla.nombre}}</option>
                          </r-input>
                          <r-input
                            class="col-100"
                            floating-label
                            outline
                            label="Asunto"
                            :form="rform"
                            name="asunto"
                          />
                          <r-rich-text-input
                            v-if="rform.formData.plantillaEmail"
                            ref="rRichTextInput"
                            class="col-100"
                            floating-label
                            label="Cuerpo del email"
                            :form="rform"
                            :name="'cuerpo'"
                            :readonly="readonlyPlantilla"
                            :overrideReadonly="true"
                          ></r-rich-text-input>
                        </f7-col>
                      </f7-row>
                    </ul>
                  </f7-list>
                </f7-card-content>
              </f7-card>
              <f7-row>
                <f7-col width="100" medium="100">
                  <f7-button
                      fill
                      icon-f7="disk"
                      text="Enviar"
                      class="display-flex margin-vertical margin-left"
                      color="green"
                      @click="rform.saveItem()"
                    ></f7-button>
                </f7-col>
              </f7-row>
            </template>
          </r-form>
        </f7-card>
      </f7-page>
    </f7-popup>

    <Facturar ref="facturar"/>

    </template>
    
  </r-form>
  <r-form
    ref="rformInstalacion"
    v-slot="{rform}"
    :itemId="null"
    @save="onSaveInstalacion"
    :readonly="readonly"
    :dbAdapter="Instalacion"
    :beforeSave="beforeSaveInstalacion"
  >
    <f7-popup ref="popupInstalacion" class="popup-instalacion" swipe-to-close>
      <f7-page>
        <f7-navbar title="Instalación">
          <f7-nav-right>
            <f7-link popup-close>Cerrar</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
        <f7-card class="elevation-3 padding-vertical-half padding-right">
          <f7-card-content>
            <f7-list class="list-form">
              <ul>
                <f7-row>
                  <f7-col width="100" medium="100">
                    <r-input
                      class="col-50"
                      floating-label
                      :label="$t('clientes.codigo')"
                      :form="rform"
                      type="text"
                      name="codigo"
                    ></r-input>
                    <r-input
                      class="col-50"
                      floating-label
                      :label="$t('clientes.nombre')"
                      :form="rform"
                      type="text"
                      name="nombre"
                    ></r-input>
                  </f7-col>
                </f7-row>
              </ul>
            </f7-list>
          </f7-card-content>
          <f7-row>
            <f7-col width="100" medium="100">
              <f7-button
                fill
                icon-f7="save"
                text="Guardar Instalación"
                class="display-flex margin-left"
                color="green"
                @click="$refs.rformInstalacion.saveItem()"
              ></f7-button>
            </f7-col>
          </f7-row>
        </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>
  </r-form>
  <r-form
    ref="rformItem"
    v-slot="{rform}"
    :itemId="null"
    @save="onSaveItem"
    :readonly="readonly"
    :dbAdapter="Item"
    :beforeSave="beforeSaveItem"
  >
    <f7-popup ref="popupItem" class="popup-item" swipe-to-close>
      <f7-page>
        <f7-navbar title="Item">
          <f7-nav-right>
            <f7-link popup-close>Cerrar</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
        <f7-card class="elevation-3 padding-vertical-half padding-right">
          <f7-card-content>
            <f7-list class="list-form">
              <ul>
                <f7-row>
                  <f7-col width="100" medium="100">
                    <r-input
                      floating-label
                      :label="$t('clientes.codigo')"
                      :form="rform"
                      type="text"
                      name="codigo"
                    ></r-input>
                    <r-input
                      floating-label
                      :label="$t('clientes.nombre')"
                      :form="rform"
                      type="text"
                      name="nombre"
                    ></r-input>
                  </f7-col>
                </f7-row>
              </ul>
            </f7-list>
          </f7-card-content>
          <f7-row>
            <f7-col width="100" medium="100">
              <f7-button
                fill
                icon-f7="save"
                text="Guardar Item"
                class="display-flex margin-left"
                color="green"
                @click="$refs.rformItem.saveItem()"
              ></f7-button>
            </f7-col>
          </f7-row>
        </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>
    
  </r-form>
  <f7-popup ref="popupnew" class="popupnew" @popup:close="indexLinea = -1; step = 1; if ($refs.rformNew) {$refs.rformNew.clear();} ">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>{{$t('pedidos.cerrar')}}</f7-link>
        </f7-nav-left>
        <f7-nav-title>
          {{$t('pedidos.linea')}} - {{step}}/{{maxStep}}
        </f7-nav-title>
      </f7-navbar>
      <r-form 
        v-if="clienteSelected"
        ref="rformNew" 
        classContent="full-height"
        @save="onNewLine"
        :readonly="readonly"
      >
        <template v-slot:default="{rform}">
          <div class="display-inline-block text-align-center margin-top">
            <f7-button
              v-if="verBotonAnterior(rform)"
              fill
              color="red"
              class="display-inline-block margin-horizontal"
              @click="step--"
              style="color: white;"
            >{{$t('pedidos.anterior')}}</f7-button>
            <f7-button
              v-if="verBotonSiguiente(rform)"
              fill
              color="primary"
              class="display-inline-block margin-horizontal"
              @click="step++"
            >{{$t('pedidos.siguiente')}}</f7-button>
            <f7-button
              v-if="verBotonGuardar(rform)"
              fill
              color="primary"
              class="display-inline-block margin-horizontal"
              @click="rform.saveItem()"
            >{{$t('pedidos.guardar')}}</f7-button>
            <f7-button
              v-if="verBotonCerrar(rform)"
              fill
              color="primary"
              class="display-inline-block margin-horizontal"
              popup-close=".popupnew"
            >{{$t('pedidos.cerrar')}}</f7-button>
          </div>
          <template v-if="step == 1">
            <div class="list" style="z-index: 4;">
              <ul>
                <r-input
                  v-if="id"
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('pedidos.fechaEjecucion')"
                  :form="rform"
                  type="datepicker"
                  name="camposUsuario.fechaEjecucion"
                  :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>
                <r-input
                  v-if="id"
                  class="col-100 medium-50"
                  floating-label
                  label="Fecha de planificación"
                  :form="rform"
                  type="datepicker"
                  name="fechaPlanificacion"
                  :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>
                <r-input
                  v-if="id"
                  class="col-100 medium-50"
                  label="TPC Línea"
                  :form="rform"
                  type="select"
                  name="camposUsuario.trabCm"
                >
                  <option selected :value="null"></option>
                  <option value="S">Sí</option>
                  <option value="N">No</option>
                </r-input>
                <r-input
                  v-if="id"
                  class="col-100 medium-50"
                  label="TPI Línea"
                  :form="rform"
                  type="select"
                  name="camposUsuario.trabOf"
                >
                  <option selected :value="null"></option>
                  <option value="S">Sí</option>
                  <option value="N">No</option>
                </r-input>
                <r-input
                  v-if="id"
                  class="col-100 medium-50"
                  floating-label
                  label="Fecha prevista TPI"
                  :form="rform"
                  type="datepicker"
                  name="camposUsuario.fPrevTPO"
                  :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>
                <r-input
                  v-if="id"
                  class="col-100 medium-50"
                  :label="$t('pedidos.conforme')"
                  :form="rform"
                  type="select"
                  name="camposUsuario.conforme"
                >
                  <option selected :value="null"></option>
                  <option value="S">Sí</option>
                  <option value="N">No</option>
                </r-input>

                <f7-row class="col-100">
                  <f7-col width="50" medium="50">
                    <r-input
                      v-if="id"
                      class="col-50 medium-50"
                      floating-label
                      outline
                      label="Fecha Límite de emisión"
                      :form="rform"
                      type="datepicker"
                      name="fechaLimiteEmision"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                  </f7-col>
                  <f7-col width="50" medium="50">
                    <r-input
                      v-if="id"
                      class="col-100 medium-50"
                      floating-label
                      label="Fecha de envío del expediente"
                      :form="rform"
                      type="datepicker"
                      name="camposUsuario.feEnvExp"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                  </f7-col>
                </f7-row>
                <f7-row class="col-100">
                  <f7-col width="50" medium="50">
                    <r-input
                      v-if="id"
                      class="col-50 medium-50"
                      floating-label
                      outline
                      label="Fecha Límite de tramitación"
                      :form="rform"
                      type="datepicker"
                      name="fechaLimiteTramitacion"
                      :calendar-params="{ closeOnSelect: true, routableModals: false, dateFormat: { year: 'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                  </f7-col>
                  <f7-col width="50" medium="50">
                    <r-input
                      v-if="id"
                      class="col-100 medium-50"
                      floating-label
                      outline
                      label="Fecha de tramitación en industria"
                      :form="rform"
                      type="datepicker"
                      name="procesoEntrega.entregaIndustria.fecha"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                  </f7-col>
                </f7-row>
                <f7-row class="col-100">
                  <f7-col width="50" medium="50">
                    <r-input
                      v-if="id"
                      class="col-50 medium-50"
                      floating-label
                      outline
                      label="Fecha Límite de pago de tasas"
                      :form="rform"
                      type="datepicker"
                      name="fechaLimitePagoTasas"
                      :calendar-params="{ closeOnSelect: true, routableModals: false, dateFormat: { year: 'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                  </f7-col>
                  <f7-col width="50" medium="50">
                    <r-input
                      v-if="id"
                      class="col-100 medium-50"
                      floating-label
                      outline
                      label="Fecha de pago de tasas"
                      :form="rform"
                      type="datepicker"
                      name="procesoEntrega.pagoTasas.fecha"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                  </f7-col>
                </f7-row>
                <f7-row>
                  <f7-col width="35" medium="35">
                    <r-input
                      v-if="id"
                      floating-label
                      outline
                      label="Fecha Límite de corrección"
                      :form="rform"
                      type="datepicker"
                      name="fechaLimiteCorrecion"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                  </f7-col>
                  <f7-col width="35" medium="35">
                    <r-input
                      v-if="id"
                      floating-label
                      outline
                      label="Fecha próxima inspección"
                      :form="rform"
                      type="datepicker"
                      name="proxInsp"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                      :readonly="true"
                      :overrideReadonly="true"
                    ></r-input>
                  </f7-col>
                  <f7-col width="35" medium="35">
                    <r-input
                      v-if="id"
                      floating-label
                      outline
                      label="Fecha renovación"
                      :form="rform"
                      type="datepicker"
                      name="fechaRenovacion"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                  </f7-col>
                </f7-row>
                <f7-row>
                  <f7-col width="50" medium="50">
                    <r-input
                      v-if="id"
                      floating-label
                      outline
                      label="Fecha Com. Industria"
                      :form="rform"
                      type="datepicker"
                      name="fechaComunicacionIndustria"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                  </f7-col>
                  <f7-col width="50" medium="50">
                    <r-autocomplete
                      v-if="id"
                      :dbAdapter="Empleado"
                      floating-label
                      label="Persona Com. Industria"
                      :form="rform"
                      name="personaComunicacionIndustria"
                      labelProp="nombre"
                      idProp="username"
                      prefixProp="username"
                      idFieldDetail="id"
                    ></r-autocomplete>
                  </f7-col>
                </f7-row>
                <f7-row>
                  <f7-col width="25" medium="25">
                    <div></div>
                  </f7-col>
                  <f7-col width="25" medium="25">
                    <div></div>
                  </f7-col>
                </f7-row>


                <r-input
                  v-if="id"
                  class="col-100 medium-50"
                  floating-label
                  label="Fecha tramitación EICI"
                  :form="rform"
                  type="datepicker"
                  name="fechaTramitacionEICI"
                  :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>
                <r-input
                  v-if="id"
                  class="col-100 medium-50"
                  floating-label
                  label="Usuario tramitador"
                  :form="rform"
                  name="usuarioTramitador"
                ></r-input>
                <r-input
                  v-if="id"
                  class="col-100 medium-25"
                  :form="rform"
                  type="check"
                  name="corDef"
                  label="Cor. Def."
                ></r-input>
                <r-input
                  v-if="id"
                  class="col-100 medium-25"
                  :form="rform"
                  type="datepicker"
                  name="fechaCorDef"
                  label="Fecha Cor. Def."
                  :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>
                <r-input
                  v-if="id"
                  class="col-100 medium-50"
                  label="Com. Cor. Def."
                  :form="rform"
                  type="textarea"
                  name="comCorDef"
                ></r-input>
                <r-input
                  v-if="id"
                  class="col-100 medium-50"
                  :label="$t('pedidos.gestionPlanificacion')"
                  :form="rform"
                  type="textarea"
                  name="gestionPlanificacion"
                ></r-input>
                <r-input
                  v-if="id && !rform.formData.idPedido1as"
                  class="col-100 medium-50"
                  :label="'Num. Pedido 1as'"
                  :form="rform"
                  type="number"
                  name="numeroPedido1as"
                ></r-input>
                <r-input
                  v-if="id && !rform.formData.idPedido1as"
                  class="col-100 medium-50"
                  :label="'Línea de 1as'"
                  :form="rform"
                  type="number"
                  name="numeroLinea1as"
                ></r-input>
              </ul>
            </div>
          </template>
          <template v-if="step == 2">
            <f7-block-title>{{$t('ofertas.titular')}}</f7-block-title>
            <div class="list">
              <ul class="row">
                <r-input
                  class="col-50 medium-50"
                  floating-label
                  :label="$t('clientes.nif')"
                  :form="rform"
                  :name="'nifTitular'"
                ></r-input>
                <r-input
                  class="col-50 medium-50"
                  floating-label
                  :label="$t('ofertas.titular')"
                  :form="rform"
                  :name="'titular'"
                ></r-input>
              </ul>
            </div>
            <f7-block-title>{{$t('pedidos.direccionInspeccion')}}</f7-block-title>
            <div class="list">
              <ul class="row">
                <r-direccion
                  :form="rform"
                  :name="'direccionInspeccion'"
                  :guardarCodigos="true"
                  autocomplete="off"
                >
                </r-direccion>
              </ul>
            </div>
            <f7-block-title>{{$t('Contacto')}}</f7-block-title>
            <div class="list">
              <ul class="row">
                <r-input
                  class="col-100 medium-100"
                  floating-label
                  :label="$t('Persona de contacto para la inspección')"
                  :form="rform"
                  :name="'contacto'"
                ></r-input>
                <r-input
                  class="col-100 medium-100"
                  floating-label
                  :label="$t('Teléfono 1')"
                  :form="rform"
                  :name="'telefono1'"
                ></r-input>
                <r-input
                  class="col-100 medium-100"
                  floating-label
                  :label="$t('Teléfono 2')"
                  :form="rform"
                  :name="'telefono2'"
                ></r-input>
                <r-input
                  class="col-100 medium-100"
                  floating-label
                  :label="$t('El titular ha comunicado que quiere presenciar la inspección')"
                  :form="rform"
                  type="check" 
                  :name="'titularPresencial'"
                ></r-input>
              </ul>
            </div>
            <f7-block-title>{{$t('ofertas.emailComunicaciones')}}</f7-block-title>
            <div class="list">
              <ul class="row">
                <r-input
                  class="col-100 medium-100"
                  floating-label
                  :label="$t('ofertas.emailComunicaciones')"
                  :form="rform"
                  :name="'emailComunicaciones'"
                ></r-input>
              </ul>
            </div>
          </template>
          <template v-if="step == 3">
            <f7-block-title>{{$t('pedidos.servicio')}}</f7-block-title>
            <div class="list" style="z-index: 2;">
              <ul>
                <r-autocomplete
                  key="servicio"
                  :dbAdapter="Servicio"
                  class="col-100"
                  :form="rform"
                  :name="'servicio'"
                  labelProp="nombre"
                  prefixProp="codigo"
                  :shownFields="['codigo', 'materia', 'precios', 'tasas', 'subservicios', 'tasaDefecto', 'codCuentaMayor', 'tiempoEstimadoCampo', 'tiempoEstimadoOficina', 'activo', 'userText','porcSegunda','porcMinSegunda','blocPorcSegundas','unidadInspeccion','idServicioUnidadExtra']"
                  :filter="{filter:{activo: true}}"
                  @change="changeServicio(rform.formData)"
                  :readonly_="readonly || rform.formData.estado.estado !== 'SIN_PLANIFICAR'"
                  :readonly="!lineaEditable(rform.formData)"
                ></r-autocomplete>
              </ul>
            </div>
            <f7-block-title>{{$t('pedidos.tiempos')}}</f7-block-title>
            <div class="list">
              <ul class="row">
                <r-input
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('pedidos.tiempoEstimadoCampo')"
                  :form="rform"
                  :name="'tiempoEstimadoCampo'"
                  :readonly="!lineaEditable(rform.formData)"
                  type="number"
                ></r-input>
                <r-input
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('pedidos.tiempoEstimadoOficina')"
                  :form="rform"
                  :name="'tiempoEstimadoOficina'"
                  :readonly="!lineaEditable(rform.formData)"
                  type="number"
                ></r-input>
              </ul>
              <ul class="row" v-noroles="['ENAC', 'PRODUCCION']" v-if="rform.formData.tiempoRealCampo || rform.formData.tiempoRealOficina">
                <r-input
                  v-if="rform.formData.tiempoRealCampo"
                  class="col-100 medium-50"
                  floating-label
                  label="Tiempo Real Campo"
                  :form="rform"
                  :name="'tiempoRealCampo'"
                  :readonly="true"
                  :overrideReadonly="true"
                  type="number"
                ></r-input>
                <r-input
                  v-if="rform.formData.tiempoRealOficina"
                  class="col-100 medium-50"
                  floating-label
                  label="Tiempo Real Oficina"
                  :form="rform"
                  :name="'tiempoRealOficina'"
                  :readonly="true"
                  :overrideReadonly="true"
                  type="number"
                ></r-input>
              </ul>
            </div>            <f7-block-title>{{$t('ofertas.gastos.previsionGastos')}}</f7-block-title>
            <div class="list">
              <ul class="row">
                <r-input
                  class="col-100 medium-30"
                  floating-label
                  :label="$t('ofertas.gastos.kilometros')"
                  :form="rform"
                  :name="'previsionGastos.kilometros'"
                  type="number"
                ></r-input>
                <r-input
                  class="col-100 medium-30"
                  floating-label
                  :label="$t('ofertas.gastos.peajes')"
                  :form="rform"
                  :name="'previsionGastos.peajes'"
                  type="number"
                ></r-input>
                <r-input
                  class="col-100 medium-30"
                  floating-label
                  :label="$t('ofertas.gastos.parking')"
                  :form="rform"
                  :name="'previsionGastos.parking'"
                  type="number"
                ></r-input>
              </ul>
              <ul class="row">
                <r-input
                  class="col-100 medium-30"
                  floating-label
                  :label="$t('ofertas.gastos.desayuno')"
                  :form="rform"
                  :name="'previsionGastos.desayuno'"
                  type="number"
                ></r-input>
                <r-input
                  class="col-100 medium-30"
                  floating-label
                  :label="$t('ofertas.gastos.comida')"
                  :form="rform"
                  :name="'previsionGastos.comida'"
                  type="number"
                ></r-input>
                <r-input
                  class="col-100 medium-30"
                  floating-label
                  :label="$t('ofertas.gastos.cena')"
                  :form="rform"
                  :name="'previsionGastos.cena'"
                  type="number"
                ></r-input>
              </ul>
              <ul class="row">
                <r-input
                  class="col-100 medium-30"
                  floating-label
                  :label="$t('ofertas.gastos.hotel')"
                  :form="rform"
                  :name="'previsionGastos.hotel'"
                  type="number"
                ></r-input>
                <r-input
                  class="col-100 medium-30"
                  floating-label
                  :label="$t('ofertas.gastos.finDeSemana')"
                  :form="rform"
                  :name="'previsionGastos.finDeSemana'"
                  :key="'previsionGastos.finDeSemana'"
                  type="check"
                ></r-input>
                <r-input
                  class="col-100 medium-30"
                  floating-label
                  :label="$t('ofertas.gastos.horasNocturnas')"
                  :form="rform"
                  :name="'previsionGastos.horasNocturnas'"
                  :key="'previsionGastos.horasNocturnas'"
                  type="check"
                ></r-input>
              </ul>
            </div>
            <f7-block-title>Características principales del trabajo a realizar</f7-block-title>
            <div class="list">
              <ul class="row">
                <r-input
                  class="col-100 medium-100"
                  floating-label
                  label="Caract. Pral. Trabajo Realizar"
                  :form="rform"
                  :name="'trabajoRealizar'"
                  :readonly="!lineaEditable(rform.formData)"
                ></r-input>
              </ul>
            </div>
            <f7-block-title v-if="$refs.rformNew && $refs.rformNew.formData && $refs.rformNew.formData.servicio && $refs.rformNew.formData.servicio.unidadInspeccion">{{$refs.rformNew.formData.servicio.unidadInspeccion}}</f7-block-title>
            <div class="list" v-if="$refs.rformNew && $refs.rformNew.formData && $refs.rformNew.formData.servicio && $refs.rformNew.formData.servicio.unidadInspeccion">
              <ul class="row">
                <r-input
                  class="col-50 medium-50"
                  floating-label
                  :label="'Nº '+$refs.rformNew.formData.servicio.unidadInspeccion+' OFERTA'"
                  :form="rform"
                  type="number"
                  :name="'numUnidadesOfertadas'"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-input>
                <r-input
                  class="col-50 medium-50"
                  floating-label
                  :label="'Nº '+$refs.rformNew.formData.servicio.unidadInspeccion+' INSPECCIÓN'"
                  :form="rform"
                  type="number"
                  :name="'numUnidadesInspeccionadas'"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-input>
              </ul>
            </div>
            
            <f7-block-title >RAE</f7-block-title>
            <div class="list no-hairlines">
              <ul class="row">
                <r-input
                  class="col-50 medium-50"
                  floating-label
                  :label="'Nº RAE'"
                  :form="rform"
                  :name="'rae'"
                ></r-input>
              </ul>
            </div>

          </template>
          <template v-if="step == 4">
            <f7-block-title>{{$t('pedidos.importes')}}</f7-block-title>
            <div class="list">
              <ul class="row">
                <r-input
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('pedidos.precio')"
                  :form="rform"
                  :name="'precioUnitario'"
                  type="number"
                  step=".01"
                  :readonly="!lineaEditable(rform.formData)"
                  @change="onBlurPrecioUnitario(rform.formData)"
                ></r-input>
                <r-input
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('pedidos.cantidad')"
                  :form="rform"
                  :name="'cantidad'"
                  type="number"
                  :readonly="!lineaEditable(rform.formData)"
                ></r-input>
                <r-input
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('pedidos.porcDescuento')"
                  :form="rform"
                  :name="'porcDescuento'"
                  type="number"
                  :readonly="!lineaEditable(rform.formData)"
                ></r-input>
                <r-input
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('pedidos.porcSegunda')" 
                  :form="rform"
                  :name="'porcSegunda'"
                  type="number"
                  :readonly="!lineaEditable(rform.formData)"
                  @change="onBlurPorc2a(rform.formData)"
                ></r-input>
                <r-input
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('ofertas.importeSegunda')" 
                  :form="rform"
                  :name="'importeSegunda'"
                  type="number"
                  step=".01"
                  :readonly="!lineaEditable(rform.formData)"
                  @change="onBlurImporte2a(rform.formData)"
                ></r-input>
              </ul>
            </div>
            <f7-block-title>{{$t('pedidos.impuestos')}}</f7-block-title>
            <div class="list">
              <ul class="row">
                <r-input
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('pedidos.tasas')"
                  :form="rform"
                  :name="'tasas'"
                  type="number"
                  :readonly="!lineaEditable(rform.formData)"
                  step=".01"
                ></r-input>
                <r-input
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('pedidos.porcIVA')"
                  :form="rform"
                  :name="'porcIVA'"
                  :readonly="!lineaEditable(rform.formData)"
                  type="number"
                ></r-input>
              </ul>
            </div>
            <f7-block-title class="disabled">{{$t('pedidos.importe')}}</f7-block-title>
            <f7-list>
              <f7-list-input
                class="col-100 medium-25 text-color-red disabled"
                :readonly="true"
                type="number"
                step=".01"
                :value="getTotalesLinea(rform.formData)['total']"
              ></f7-list-input>
            </f7-list>
          </template>


          <template v-if="step == 5">
            <f7-block-title v-if="!rform.readonly" >Prefiltro de items</f7-block-title>
            <div class="list" style="z-index: 5;">
              <ul>
                <r-input
                  v-if="!rform.readonly"
                  fill
                  class="col-25 medium-25 small-100"
                  label="Buscar item por materia"
                  :form="rform"
                  type="check"
                  name="porMateria"
                  @change="onchangefilter"
                ></r-input>
                <r-input
                  v-if="!rform.readonly"
                  fill
                  class="col-25 medium-25 small-100"
                  label="Buscar por el cliente de la oferta"
                  :form="rform"
                  type="check"
                  name="porCliente"
                  @change="onchangefilter"
                ></r-input>
                <r-input
                  v-if="!rform.readonly"
                  fill
                  class="col-25 medium-25 small-100"
                  label="Buscar por dirección de inspección"
                  :form="rform"
                  type="check"
                  name="porDireccion"
                  @change="onchangefilter"
                ></r-input>
                <r-input
                  v-if="!rform.readonly"
                  class="col-100"
                  floating-label
                  label="Identificador del elemento a inspeccionar"
                  :form="rform"
                  type="text"
                  name="identificador"
                  @blur="onchangefilter"
                  @keyup.enter.native="onchangefilter"
                ></r-input>
              </ul>
            </div>
            <f7-block-title>{{$t('pedidos.lineaspedido.item')}}</f7-block-title>
            <div class="list" style="z-index: 2;">
              <ul>
                <r-autocomplete
                  v-if="rform.readonly || (rform.formData.item && rform.formData.item.id) || rform.formData.porCliente || rform.formData.porDireccion || rform.formData.porMateria || rform.formData.identificador"
                  key="item"
                  :ref="'item'"
                  :dbAdapter="Item"
                  class="col-100"
                  floating-label
                  :form="rform"
                  :name="'item'"
                  labelProp="nombre"
                  prefixProp="codigo"
                  :shownFields="['codigo', 'codigoInstalacion', 'direccion', 'administrador', 'instalador', 'mantenedor', 'asociacion']"
                  :readonly="!$refs.rform || !$refs.rform.formData || !$refs.rform.formData.cliente || !$refs.rform.formData.cliente.id"
                  :filter="calcularFiltroItem()"
                  :source="sourceItem"
                  @change="changeItem(rform.formData)"
                  :beforeChange="beforeChangeItem"
                >
                </r-autocomplete>
              </ul>
            </div>
          </template>

          
          <template v-if="step == 6">
            <p v-roles="['ENAC', 'PRODUCCION']" class="margin-half">Sección en desarrollo</p>
            <f7-block-title v-noroles="['ENAC', 'PRODUCCION']">{{$t('pedidos.colaboradores')}}</f7-block-title>
            <div v-noroles="['ENAC', 'PRODUCCION']" class="list">
              <ul class="row">
                <r-autocomplete
                  :dbAdapter="Cliente"
                  class="col-100"
                  floating-label
                  :label="$t('pedidos.administrador')"
                  :form="rform"
                  :name="'administrador'"
                  labelProp="codigoNombre"
                  :idProp="null"
                  idFieldDetail="id"
                  :shownFields="['codigo', 'razonSocial']"
                  :minLength="3"
                  :async="true"
                  :readonly="!lineaEditable(rform.formData)"
                ></r-autocomplete>
                <r-autocomplete
                  :dbAdapter="Cliente"
                  class="col-100"
                  floating-label
                  :label="$t('pedidos.instalador')"
                  :form="rform"
                  :name="'instalador'"
                  labelProp="codigoNombre"
                  :idProp="null"
                  idFieldDetail="id"
                  :shownFields="['codigo', 'razonSocial']"
                  :minLength="3"
                  :async="true"
                  :readonly="!lineaEditable(rform.formData)"
                ></r-autocomplete>
                <r-autocomplete
                  :dbAdapter="Cliente"
                  class="col-100"
                  floating-label
                  :label="$t('pedidos.mantenedor')"
                  :form="rform"
                  :name="'mantenedor'"
                  labelProp="codigoNombre"
                  :idProp="null"
                  idFieldDetail="id"
                  :shownFields="['codigo', 'razonSocial']"
                  :minLength="3"
                  :async="true"
                  :readonly="!lineaEditable(rform.formData)"
                ></r-autocomplete>
                <r-autocomplete
                  :dbAdapter="Cliente"
                  class="col-100"
                  floating-label
                  :label="$t('pedidos.asociacion')"
                  :form="rform"
                  :name="'asociacion'"
                  labelProp="codigoNombre"
                  :idProp="null"
                  idFieldDetail="id"
                  :shownFields="['codigo', 'razonSocial']"
                  :minLength="3"
                  :async="true"
                  :readonly="!lineaEditable(rform.formData)"
                ></r-autocomplete>
              </ul>
            </div>
          </template>
          <template v-if="step == 7">
           <p v-roles="['ENAC', 'PRODUCCION']" class="margin-half">Sección en desarrollo</p>
           <div v-noroles="['ENAC', 'PRODUCCION']">
            <f7-block-title>{{$t('pedidos.comision')}}</f7-block-title>
              <div class="list">
                <ul class="row">
                  <r-autocomplete
                    :dbAdapter="Cliente"
                    class="col-100 medium-50"
                    floating-label
                    :label="$t('pedidos.comision')"
                    :form="rform"
                    :name="'comision.codigo'"
                    labelProp="codigoNombre"
                    idProp="codigo"
                    idFieldDetail="id"
                    :shownFields="['codigo', 'razonSocial']"
                    :minLength="3"
                    :async="true"
                    :readonly="!lineaEditable(rform.formData)"
                  ></r-autocomplete>
                  <r-input
                    class="col-100 medium-50"
                    floating-label
                    :label="$t('pedidos.codigoOferta')"
                    :form="rform"
                    :readonly="!lineaEditable(rform.formData)"
                    :name="'comision.codigoOferta'"
                  ></r-input>
                  <r-input
                    class="col-100 medium-50"
                    floating-label
                    :label="$t('pedidos.precio')"
                    :form="rform"
                    type="number"
                    :readonly="!lineaEditable(rform.formData)"
                    step=".01"
                    :name="'comision.precio'"
                  ></r-input>
                  <r-input
                    class="col-100 medium-50"
                    floating-label
                    :label="$t('pedidos.porcIVA')"
                    :form="rform"
                    type="number"
                    :readonly="!lineaEditable(rform.formData)"
                    :name="'comision.porcIVA'"
                  ></r-input>
                </ul>
              </div>
              <f7-block-title class="disabled">{{$t('pedidos.totalComision')}}</f7-block-title>
              <f7-list>
                <f7-list-input
                  class="col-100 medium-25 text-color-red disabled"
                  :readonly="true"
                  type="number"
                  step=".01"
                  :value="getTotalesLinea(rform.formData)['totalComision']"
                ></f7-list-input>
              </f7-list>
              <f7-block-title>{{$t('pedidos.subcontratacion')}}</f7-block-title>
              <div class="list">
                <ul class="row">
                  <r-autocomplete
                    :dbAdapter="Cliente"
                    class="col-100 medium-50"
                    floating-label
                    :label="$t('pedidos.proveedor')"
                    :form="rform"
                    :name="'proveedor.codigo'"
                    labelProp="codigoNombre"
                    idProp="codigo"
                    idFieldDetail="id"
                    :shownFields="['codigo', 'razonSocial']"
                    :minLength="3"
                    :async="true"
                    :readonly="!lineaEditable(rform.formData)"
                  ></r-autocomplete>
                  <r-input
                    class="col-100 medium-50"
                    floating-label
                    :label="$t('pedidos.codigoOferta')"
                    :form="rform"
                    :readonly="!lineaEditable(rform.formData)"
                    :name="'proveedor.codigoOferta'"
                  ></r-input>
                  <r-input
                    class="col-100 medium-50"
                    floating-label
                    :label="$t('pedidos.precio')"
                    :form="rform"
                    type="number"
                    step=".01"
                    :readonly="!lineaEditable(rform.formData)"
                    :name="'proveedor.precio'"
                  ></r-input>
                  <r-input
                    class="col-100 medium-50"
                    floating-label
                    :label="$t('pedidos.porcIVA')"
                    :form="rform"
                    type="number"
                    :readonly="!lineaEditable(rform.formData)"
                    :name="'proveedor.porcIVA'"
                  ></r-input>
                </ul>
              </div>
              <f7-block-title class="disabled">{{$t('pedidos.totalSubcontratacion')}}</f7-block-title>
              <f7-list>
                <f7-list-input
                  class="col-100 medium-25 text-color-red disabled"
                  :readonly="true"
                  type="number"
                  step=".01"
                  :value="getTotalesLinea(rform.formData)['totalProveedor']"
                ></f7-list-input>
              </f7-list>
            </div>
          </template> 
          <template v-if="step == 8">
            <p v-roles="['ENAC', 'PRODUCCION']" class="margin-half">Sección en desarrollo</p>
            <f7-block-title v-noroles="['ENAC', 'PRODUCCION']">{{$t('pedidos.comentarios')}}</f7-block-title>
            <f7-block-title v-noroles="['ENAC', 'PRODUCCION']">Para que salgan lineas de texto separadas en sap, separar por ##</f7-block-title>
            <div class="list" v-noroles="['ENAC', 'PRODUCCION']">
              <ul class="row">
                <r-input
                  class="col-100"
                  :form="rform"
                  :name="'comentarios'"
                  type="textarea"
                  :readonly="!lineaEditable(rform.formData)"
                ></r-input>
              </ul>
            </div>
            <f7-block-title v-noroles="['ENAC', 'PRODUCCION']">{{$t('pedidos.comentariosLineaCerrada')}}</f7-block-title>
            <div class="list" v-noroles="['ENAC', 'PRODUCCION']">
              <ul class="row">
                <r-input
                  class="col-100"
                  :form="rform"
                  :name="'comentariosLineaCerrada'"
                  type="textarea"
                  :readonly="false"
                ></r-input>
              </ul>
            </div>
          </template>
          <template v-if="step == 9">
            <p v-roles="['ENAC', 'PRODUCCION']" class="margin-half">Sección en desarrollo</p>
            <div v-noroles="['ENAC', 'PRODUCCION']">
              <f7-block-title>{{$t('pedidos.resumen')}}</f7-block-title>
              <f7-block-title class="disabled">{{$t('pedidos.contratacion')}}</f7-block-title>
              <div class="list">
                <ul class="row">
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.subtotal')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['subtotal']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.totalIVA')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['iva']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.total')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['total']"
                  ></f7-list-input>
                </ul>
              </div>
              <f7-block-title class="disabled"></f7-block-title>
              <div class="list">
                <ul class="row">
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.totalTasas')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['totalTasas']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.porcSegunda')"
                    type="number"
                    step=".01"
                    :value="rform.formData.porcSegunda"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.importeSegunda')"
                    type="number"
                    step=".01"
                    :value="rform.formData.importeSegunda"
                  ></f7-list-input>
                  
                </ul>
              </div>
              <f7-block-title class="disabled">{{$t('pedidos.subcontratacion')}}</f7-block-title>
              <div class="list">
                <ul class="row">
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.subtotal')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['subtotalProveedor']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.totalIVA')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['ivaProveedor']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.total')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['totalProveedor']"
                  ></f7-list-input>
                </ul>
              </div>
              <f7-block-title class="disabled">{{$t('pedidos.comision')}}</f7-block-title>
              <div class="list">
                <ul class="row">
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.subtotal')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['subtotalComision']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.totalIVA')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['ivaComision']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.total')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['totalComision']"
                  ></f7-list-input>
                </ul>
              </div>
              <f7-block-title class="disabled">{{$t('ofertas.totales')}}</f7-block-title>
              <div class="list">
                <ul class="row">
                  <f7-list-input
                    class="col-100 medium-25 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.subtotal')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['subtotalConTerceros']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-25 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.totalTasas')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['totalTasas']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-25 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.totalIVA')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['ivaConTerceros']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-25 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.total')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['totalConTasasConTerceros']"
                  ></f7-list-input>
                </ul>
              </div>
            </div>
          </template>
          <div class="display-inline-block text-align-center">
            <f7-button
              v-if="verBotonAnterior(rform)"
              fill
              color="red"
              class="display-inline-block margin-horizontal"
              @click="step--"
              style="color: white;"
            >{{$t('pedidos.anterior')}}</f7-button>
            <f7-button
              v-if="verBotonSiguiente(rform)"
              fill
              color="primary"
              class="display-inline-block margin-horizontal"
              @click="step++"
            >{{$t('pedidos.siguiente')}}</f7-button>
            <f7-button
              v-if="verBotonGuardar(rform)"
              fill
              color="primary"
              class="display-inline-block margin-horizontal"
              @click="rform.saveItem()"
            >{{$t('pedidos.guardar')}}</f7-button>
            <f7-button
              v-if="verBotonCerrar(rform)"
              fill
              color="primary"
              class="display-inline-block margin-horizontal"
              popup-close=".popupnew"
            >{{$t('pedidos.cerrar')}}</f7-button>
          </div>
        </template>
      </r-form>
    </f7-page>
  </f7-popup>

  <f7-popup ref="popupTarea" swipe-to-close :opened="true" v-if="popupTareasOpened" @popup:closed="popupTareasOpened = false; tareaId = null">
    <tarea-form :id="tareaId" linea="" :editable="popupTareaEditable" @close="$refs.popupTarea.close();"  
      :defaultData="{idCliente: $refs.rform.formData.cliente.id, razonSocialCliente: $refs.rform.formData.cliente.razonSocial, codigoCliente: $refs.rform.formData.codCliente, codOferta: $refs.rform.formData.codOferta, idOferta: $refs.rform.formData.idOferta, numeroOferta: $refs.rform.formData.numeroOferta, numeroPedido: $refs.rform.formData.numero, numeroLineaPedido: LineaPedido?LineaPedido.numero:null, estado: 'REALIZADA', fechaFecha: new Date(), fechaHora: new Date()}" :esPopup="true"
    ></tarea-form>
  </f7-popup>
  <envio-mail
    v-if="enviarEmail"
    ref="enviarEmail"
    :selected="filasSeleccionadas()"
    @email-enviado="enviarEmail = false"
  ></envio-mail>
</f7-page>
</template>
<style>

#block_container
{
    display: flex;
}
#block1
{
    margin-left: 20px;
    font-weight: bold
}
#block2
{
    margin-left: 20px;
}

#block3
{
    margin-left: 20px;
}

.linKO
{
 border-style: solid;
 border-color: red;  
 border-radius: 20px;
 margin: 2px;
}
.linOK
{
 border-style: solid;
 border-color: green;  
 border-radius: 20px;
 margin: 2px;
}

.over-card label.disabled {
  opacity: 1 !important;
}
.sub-card label.disabled {
  opacity: 1 !important;
}
.form-caja .item-input-wrap {
  margin-right: 20px;
}
.content-documentos .page-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
<style scoped>
.w-48 {
  width: 48%;
}
.w-32 {
  width: 32%;
}
.w-100 {
  width: 100%;
}
.data-linea {
  padding-top: 20px;
  padding-right: 20px;
}
.linea-texto{
  border: 2px solid gray;
  padding-left: 10px;
}
.linea-comentario-cerrada {
  border: 2px solid rgb(102, 71, 26);
}
.linea-comentario-direccion {
  border: 2px solid rgb(245, 191, 111);
}
.form-caja {
  min-height: 30px;
  border: 1px solid gray;
  border-radius: var(--f7-input-outline-border-radius);
  padding-bottom: 7px;
}
.form-caja div {
  font-size: 12px;
  color: gray;
}
.menu-item-dropdown .menu-item-content:after {
  content: inherit !important;
}
.over-card {
  position: absolute;
  top: -13px;
  z-index: 3000;
  left: 12px;
  max-width: 90%;
}
.sub-card {
  /* position: relative; */
  /* z-index: 3000; */
  /* left: 12px; */
  max-width: 90%;
  margin-left: 12px;
  /* bottom: 3px; */
}
ul.no-padding ul {
  padding: 0 !important;
}
li.accordion-item .card, li.accordion-item .card ul, .border-3, li.accordion-item .accordion-item-content {
  border-radius: 20px;
}
.border-3 {
  border: 3px solid;
}
.label-group {
  margin-top: 12px;
  margin-left: 20px;
  font-weight: bold;
}
.label-group-inspeccion {
  margin-top: 30px;
}
label.btn {
  padding: 1px;
  cursor: pointer;
  border: 1px solid;
}
.link-inline {
  position: absolute;
  top:0;
}
.link-inline-row-2 {
  position: absolute;
  top:25px;
}
.link-inline-row-3 {
  position: absolute;
  top:50px;
}
.link-inline-row-4 {
  position: absolute;
  top:75px;
}
.link-inline-0 {
  right:0;
}
.link-inline-1 {
  right:25px;
}
.link-inline-2 {
  right:50px;
}
.link-inline-3 {
  right:75px;
}
.link-inline-4 {
  right:100px;
}

.link-inline-trash {
  right:-30px;
}
.color-red {
  color: red;
}

.color-green {
  color: green;
}
.color-blue {
  color: blue;
}
/* .first-col {
  padding-left: 20px;
}
.first-col:before {
    font-family: 'framework7-core-icons';
    position: absolute;
    top: 50%;
    font-size: 20px;
    color: var(--f7-list-chevron-icon-color);
    pointer-events: none;
    content: var(--f7-accordion-chevron-icon-down);
    margin-top: -4px;
    line-height: 8px;
    transform: translateX(-50%);
    left: calc((var(--f7-list-chevron-icon-area) + var(--f7-list-item-padding-horizontal)) / 2 + var(--f7-safe-area-left));
}
.accordion-item-opened .first-col:before {
    content: var(--f7-accordion-chevron-icon-up);
    width: 14px;
    height: 8px;
    margin-top: -4px;
    line-height: 8px;
} */
</style>
<style scoped>
.tab-link-active .item-media,
.accordion-item-opened .accordion-item-toggle .item-media {
  color: var(--f7-theme-color) !important;
}

.label-radio {
  width: var(--f7-radio-size);
  text-align: center;
}
label.radio.disabled,
label.item-checkbox.disabled {
  opacity: 1 !important;
}
.radio input[type="radio"]:not(:checked) ~ * {
  opacity: 0.2;
}

.page-content::after {
  content: "";
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
.list ul {
  background: rgba(255, 255, 255, 0.7);
}

.tab-link-active {
  background-color: var(--f7-theme-color-shade) !important;
}

.accordion-item {
  background-color: #f3f3f3;
}
.accordion-item-content {
  background-color: white;
}

.data-delete-selected {
  filter: grayscale(90%);
}

</style>
<script>
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rRichTextInput from "./../../components/rRichTextInput.vue";
import rBadge from './../../components/rBadge';
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rAutocompleteM2m from "./../../components/rAutocompleteM2m.vue";
import rDireccion from "./../../components/rDireccion.vue";
import rCuenta from "./../../components/rCuenta.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import tabFinanzas from "../../components/tabFinanzas.vue";
import rDynamicList from "./../../components/rDynamicList.vue";
import EnvioMail from "./components/envio-mail.vue";
import Oferta from "./../../js/db/Oferta.js";
import Pedido from "./../../js/db/Pedido.js";
import Item from "./../../js/db/Item.js";
import Formato from "./../../js/db/Formato.js";
import Empleado from "./../../js/db/Empleado.js";
import Empresa from "./../../js/db/Empresa.js";
import Delegacion from "./../../js/db/Delegacion.js";
import CentroTrabajo from "./../../js/db/CentroTrabajo.js";
import Departamento from "./../../js/db/Departamento.js";
import LineaNegocio from "./../../js/db/LineaNegocio.js";
import ListaPrecio from "./../../js/db/ListaPrecio.js";
import CondicionPago from "./../../js/db/CondicionPago.js";
import ViaPago from "./../../js/db/ViaPago.js";
import Instalacion from "./../../js/db/Instalacion.js";
import Comunidad from "./../../js/db/Comunidad.js";
import Servicio from "./../../js/db/Servicio.js";
import Cliente from "./../../js/db/Cliente.js";
import Factura from "./../../js/db/Factura.js";
import Inspeccion from "./../../js/db/Inspeccion.js";
import LineaPedido from "./../../js/db/LineaPedido.js";
import validator from "validator";
import ContactoCliente from "./../../js/db/ContactoCliente.js";
import Documento from "./../../js/db/Documento.js";
import RegistroEmail from "./../../js/db/RegistroEmail.js";
import DateUtils from "./../../js/DateUtils.js";
import Utils from "./../../js/Utils.js";
import DocumentoGestion from "./../../js/db/DocumentoGestion.js";
import Property from "./../../js/db/Property.js";
import Facturar from "./components/Facturar.vue";
import Combos from '../../js/db/Combos';
import Tarea from "./../../js/db/Tarea.js";
import TareaForm from "./../tareas/tarea-form.vue";
import RegistroEmailForm from "./../registroemail/registroemail-form.vue";
import Campania from "./../../js/db/Campania.js";
import Oportunidad from "./../../js/db/Oportunidad.js";
import JSONPath from 'jsonpath';


export default {
  components: {
    rForm,
    rInput,
    rBadge,
    rTimepicker,
    rFiles,
    rAutocomplete,
    rAutocompleteM2m,
    rDireccion,
    rCuenta,
    rRichTextInput,
    tabFinanzas,
    rDynamicList,
    EnvioMail,
    Facturar,
    TareaForm,
    RegistroEmailForm
  },
  data: function() {
    var self = this;
    return {
      DateUtils,
      Utils,
      validator,
      Oferta,
      Pedido,
      Item,
      Formato,
      Empleado,
      Empresa,
      Delegacion,
      CentroTrabajo,
      Departamento,
      LineaNegocio,
      ListaPrecio,
      CondicionPago,
      ViaPago,
      Instalacion,
      Comunidad,
      Servicio,
      Cliente,
      ContactoCliente,
      Documento,
      Inspeccion,
      LineaPedido,
      DocumentoGestion,
      RegistroEmail,
      Campania,
      Oportunidad,
      Property,
      Combos,
      readonly: !this.editable,
      idData: null,
      indexLinea: -1,
      step: 1,
      maxStep: 9,
      clienteSelected: false,
      condicionesPagoActivas: [],
      rform: null,
      popuprepartoopened: false,
      tipoReparto: null,
      initLineas: [],
      viaPagoSeleccionada: null,
      popupSegundasOpened: false,
      popupEnviarEmailOpened: false,
      popupVerEmailsOpened: false,
      popupDocumentosOpened: false,
      mostrarDocumentosConNombre: false,
      plantillas: [],
      readonlyPlantilla: false,
      formasCrearLinea: [],
      datosLineaPopupNueva: null,
      popupNuevaPrimeraOpened: false,
      popupVincularPrimeraOpened: false,
      pedidoAnteriorNegativa: null,
      numeroLineaAnteriorNegativa: null,
      popupVincularAnteriorNegativaOpened: false,
      pedidoNuevaPrimera: null,
      numeroLineaNuevaPrimera: null,
      lineasBorrar: {},
      Tarea,
      popupTareasOpened: false,
      popupTareaEditable: false,
      tareaId: null,
      enviarEmail: false,
      showDetalle: true
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    totalesLineas: function() {
      var self = this;
      if(!self.$refs.rform || !self.$refs.rform.formData){
        return [];
      }
      return self.$refs.rform.formData.lineas.map(linea => {
        var totales = self.getTotalesLinea(linea);
        return totales;
      });
    },
    lineasEjecutable: function() {
      var self = this;
      if(!self.$refs.rform || !self.$refs.rform.formData){
        return [];
      }
      return self.$refs.rform.formData.lineas.map(linea => {
        var res = true;
        // Debe tener Item
        // if (!linea.item || !linea.item.id) {
        //   res = false;
        // }
        // Debe tener Servicio
        if (!linea.servicio || (!linea.servicio.id && !linea.servicio.codigo)) {
          res = false;
        }
        if (linea.tipoLinea != 'LINEA') {
          res = false;
        }
          return res;
      });
    },
    condicionPagoSeleccionada: function() {
      var self = this;
      if(!self.$refs.rform || !self.$refs.rform.formData){
        return null;
      }
      var rform = self.$refs.rform.formData;
      var condicionesSeleccionadas = self.condicionesPagoActivas.filter(condicion => condicion.viaPago.codigo == rform.viaPago.codigo
        && condicion.cuenta == rform.cuenta && condicion.plazoDias == rform.plazoDias);
      if (!condicionesSeleccionadas.length){
        return null;
      }
      return condicionesSeleccionadas[0];
    },
    condicionesPagoPosibles: function() {
      var self = this;
      return self.getCondicionesPagoPosibles();
    },
    viaPagoSeleccionadaReadonly: function() {
      var self = this;
      return !(self.viaPagoSeleccionada && self.viaPagoSeleccionada.codigo);
    },
  },
  beforeDestroy() {},
  methods: {
    crearTareaLinea(linea) {
      const self = this;
      self.$set(self,'LineaPedido', linea);
      self.popupTareaEditable = true; 
      self.popupTareasOpened = true;
      // self.$f7.dialog.confirm('¿Está seguro de que desea crear una tarea?', 'Crear tarea', () => {
      //   Oportunidad.save({
      //     data: {pedido: {id: self.$refs.rform.formData.id}, lineaPedido: {numero: linea.numero}, gestor: Utils.getUser().username}
      //   }).then(res => {
      //     self.$f7.dialog.alert("Creada tarea " + res.codigo);
      //     window.open('#!/tareas/view/' + res.id + '/', "_blank");
      //   });
      // });
    },
    crearOportunidadLinea(linea) {
      const self = this;
      self.$f7.dialog.confirm('¿Está seguro de que desea crear una oportunidad?', 'Crear oportunidad', () => {
        Oportunidad.save({
          data: {pedido: {id: self.$refs.rform.formData.id}, lineaPedido: {numero: linea.numero}, gestor: Utils.getUser().username}
        }).then(res => {
          self.$f7.dialog.alert("Creada oportunidad " + res.codigo);
          window.open('#!/oportunidades/view/' + res.id + '/', "_blank");
        });
      });
    },
    crearOportunidad() {
      const self = this;
      self.$f7.dialog.confirm('¿Está seguro de que desea crear una oportunidad?', 'Crear oportunidad', () => {
        Oportunidad.save({
          data: {pedido: {id: self.$refs.rform.formData.id}, gestor: Utils.getUser().username}
        }).then(res => {
          self.$f7.dialog.alert("Creada oportunidad " + res.codigo);
          window.open('#!/oportunidades/view/' + res.id + '/', "_blank");
        });
      });
    },
    getComentariosDireccion: function(item) {
      const self = this;
      let text = "";
      if (item.titular) {
        text = item.titular;
      }
      if (item.nifTitular) {
        text = text + ' NIF: ' + item.nifTitular;
      }
      if (item.direccionInspeccion) {
        text = text + " (" + self.getDireccionStr(item.direccionInspeccion) + ")";
      }
      return text;
    },
    getDireccionStr(direccion) {
      if (direccion) {
        if (direccion.direccionStr) {
          return direccion.direccionStr;
        }
        return (direccion.direccion || '') + ' ' + (direccion.cp || '') + ' ' + (direccion.poblacion || '') + ' (' + (direccion.pais || '') + ')';
      } else {
        return '';
      }
    },
    documentoCargado(indexLinea) {
      const self = this;
      self.$set(self.$refs.rform.formData.lineas[indexLinea], "documentacionRecibida", true);
    },
    getDocumentosConNombreOrdenados(documentosConNombre) {
      if (documentosConNombre) {
        return Object.values(documentosConNombre).sort((a, b) => { return a.orden - b.orden });
      } else {
        return [];
      }

    },
    getEmailUrl(item) {
      const self = this;
      var currentUrl = new URL(window.location.href);
      return currentUrl.pathname + ('#!/' + `registroemail/view/${item.id}/`);
    },
    onSaveEnviarEmail (dataEnviar) {
      const self = this;
      const app = self.$f7;
      let data = [];
      data.push({
        idPedido: self.idData,
        numeroLinea: self.$refs.rform.formData.lineas[self.indexLinea].numero,
        email: dataEnviar.email,
        asunto: dataEnviar.asunto,
        cuerpo: dataEnviar.cuerpo,
        plantillaEmail: dataEnviar.plantillaEmail
      });
      app.preloader.show();
      LineaPedido.enviarEmail(data).then(res => {
        app.preloader.hide();
        app.dialog.alert("Emails enviados");
        self.$refs.rformEnviarEmail.clear();
        if (self.$refs.popupEnviarEmail) {
          self.$refs.popupEnviarEmail.close();
        }
      }).catch(e => {
        if (typeof e == "string") {
          e = {
            message: e
          };
        }
        app.dialog.alert("Error enviando emails" + e.message);
      }).then(_ => {
        app.preloader.hide();
      });
    },
    recuperarPlantillas: function() {
      var self = this;
      var app = self.$f7;
      self.$refs.rformEnviarEmail.formData.plantillaEmail = "SIN";
      DocumentoGestion.getListaDocumentosPorTipo("EMAIL").then(data => {
        self.plantillas = data.list.filter(l=>l.nombre.endsWith("_linea"));
      });
    },
    chargePlantillaEmail: function(e) {
      var self = this;
      var app = self.$f7;
      if(typeof e == 'object') {
        let formData = self.$refs.rformEnviarEmail.formData;
        self.readonlyPlantilla = false;
        if(!formData.plantillaEmail || formData.plantillaEmail == '') {
          self.$set(formData, "cuerpo", "");
        } else if(formData.plantillaEmail == 'SIN') {
          self.$set(formData, "cuerpo", "<html><body></body></html>");
        } else {
          Documento.getDocumento(formData.plantillaEmail).then(data => {
            const dec = new TextDecoder("utf-8");
            let html = dec.decode(data);
            console.log(self.$refs.rformEnviarEmail.formData.plantillaEmail);
            console.log(html);
            self.readonlyPlantilla = true;
            self.$set(formData, "cuerpo", html);
          });
        }
      } 
      
    },
    desplanificar(idPedido, numLinea) {
      const self = this;
      const app = self.$f7;
      app.dialog.confirm(
        "¿Está seguro que desea desplanificar la linea seleccionada?",
        "Advertencia",
        function() {
          
          app.preloader.show();
          Pedido.desplanificar({data: {idPedido: idPedido, numeroLinea: numLinea}}).then(res => {
            self.$refs.rform.formData = res;
            app.toast
              .create({
                icon: '<i class="f7-icons">checkmark</i>',
                text: 'Línea desplanificada correctamente',
                position: "center",
                destroyOnClose: true,
                closeTimeout: 2000
              })
              .open();
          }).catch(function(e) {
            if (typeof e == "string") {
              e = {
                message: e
              };
            }
            app.dialog.alert(e.message);
          }).finally(function(e) {
            app.preloader.hide();
          });

          
        }
      );
    },
    crearExpManualConLinea: function(idPedido, numLinea) {
      const self = this;
      const app = self.$f7;
      var jsonData = {idPedido: idPedido, numLinea: numLinea};
      jsonData = Object.values(jsonData);
         app.views.current.router.navigate(
          "/inspecciones/new/?jsonData=" + JSON.stringify(jsonData)
        );
    },
    crearExpedienteSinPlanificar: function(idPedido, numLinea) {
      const self = this;
      const app = self.$f7;
      app.dialog.confirm(
        "¿Está seguro que desea crear un expediente sin planificar para la linea seleccionada?",
        "Advertencia",
        function() {
          app.preloader.show();
          Inspeccion.post(
            {data: {
                idPedido: idPedido, 
                numerosLinea: [{numero: numLinea}], 
                inspectores: [], 
                observaciones: "Creación de expediente sin planificación."
              }
            }
          ).then(res => {
            self.$refs.rform.formData = res;
            app.toast
              .create({
                icon: '<i class="f7-icons">checkmark</i>',
                text: 'Expediente creado correctamente',
                position: "center",
                destroyOnClose: true,
                closeTimeout: 2000
              })
              .open();
          }).catch(function(e) {
            if (typeof e == "string") {
              e = {
                message: e
              };
            }
            app.dialog.alert(e.message);
          }).finally(function(e) {
            app.preloader.hide();
          });
        }
      );
    },
    onOpenUrl(url) {
      const self = this;
      window.open(url);
    },
    lineaEditable(linea) {
      const self = this;
      if (self.readonly) {
        return false;
      }
      if (linea && linea.estado && !(linea.estado.estado == 'FACTURADA' || linea.estado.estado == 'COBRADA') && linea.lineStatus && linea.lineStatus == 'O') {
        return true;
      }
      return false;
    },
    getLineaSegundas: function(linea) {
      const self = this;
      if (!linea || !linea.idExpediente) {
        return false;
      }
      return self.$refs.rform.formData.lineas.filter(l => l.idExpedienteRelacionado == linea.idExpediente)[0];
    },
    tieneSiguienteLineaPedido: function(linea) {
      const self = this;
      // Si tiene una línea de segundas, esta será la siguiene inspección
      if (self.getLineaSegundas(linea)) {
        return true;
      }
      // Si no tiene línea de segundas, miramos si tiene una línea de nueva primera, para lo que vamos a mirar los campos de nueva primera de la "línea anterior"
      if(linea.idPedidoNuevaPrimera) {
        return true;
      }
      return false;
    },
    tieneInspeccionAnterior: function(linea) {
      const self = this;
      // Si tiene idExpedienteRelacionado o idExpedienteAnteriorNegativo, diremos que tiene expediente anterior
      return linea && (linea.idExpedienteRelacionado || linea.idExpedienteAnteriorNegativo);
    },
    getNumLineaPrimeras: function (linea) {
      const self = this;
      if (linea.idPedido1as) {
        
        return "Línea de primeras: Pedido: " + linea.numeroPedido1as + " nº " + linea.numeroLinea1as; 
      }
      var codLinea = linea.codigoLinea;

      var parteIzda = codLinea && codLinea.split(".") != null ? codLinea.split(".")[0] : null; 
      var parte2 = parteIzda != null ?  parteIzda.split("-") : null;
      var numLineaPrimeras = parte2 != null ? parte2[1] : null;

      return "Línea de primeras: nº " + ( numLineaPrimeras != null ? numLineaPrimeras : ""); 

    },
    getDatosInspeccionAnterior: function (linea) {
      const self = this;
      if (linea.numeroPedidoAnteriorNegativa) {
        return "Línea anterior: Pedido: " + linea.numeroPedidoAnteriorNegativa + " nº " + linea.numeroLineaAnteriorNegativa; 
      }
      return "Línea anterior: nº " + linea.numeroLineaAnteriorNegativa; 
    },
    getDatosInspeccionSiguiente: function (linea) {
      const self = this;

      if (linea.numeroPedidoNuevaPrimera) {
        return "Línea siguiente: Pedido: " + linea.numeroPedidoNuevaPrimera + " nº " + linea.numeroLineaNuevaPrimera; 
      }
      return "Línea siguiente: nº " + linea.numeroLineaNuevaPrimera; 
    },
    getStyleByItem: function (linea) {
      const self = this;
      let color = "black";
      try {
        // Si la calificación del expediente nueva primera es favorable, el color será verde
        if (linea.datosExpedienteNuevaPrimera && linea.datosExpedienteNuevaPrimera.calificacion == 'FAVORABLE') {
          color = "green";
        } else 
        // Si la calificación del expediente nueva primera es desfavorable, el color será rojo
        if (linea.datosExpedienteNuevaPrimera && linea.datosExpedienteNuevaPrimera.calificacion == 'NEGATIVO') {
          color = "red";
        }
      } catch (e) {
        console.log("ERROR: " + e);
      }
      return "color: " + color + ";"; 
    },
    crearSegundas: function (numeroLinea) {
      var self = this;
      // self.popupSegundasOpened = true;
      // self.$refs.rform.formData.numeroLineaSegundas = numeroLinea;
      app.dialog.confirm(
        "¿Está seguro que quiere crear la línea de 2as seleccionada?",
        "Advertencia",
        function() {
          self.crearSegundasConUsuEjec(numeroLinea);
        }
      );
    },
    borrarSegundas: function (numeroLinea) {
      var self = this;
      const app = self.$f7;
      app.dialog.confirm(
        "¿Está seguro que quiere eliminar la línea seleccionada?",
        "Advertencia",
        function() {
          app.preloader.show();
          var data = {
            idPedido: self.$refs.rform.formData.id,
            numeroLinea: numeroLinea,
          };
          Pedido.borrarSegundas({data: data})
            .then(function(res) {
              self.$refs.rform.formData = res;
              app.toast
              .create({
                icon: '<i class="f7-icons">checkmark</i>',
                text: 'Línea borrada correctamente',
                position: "center",
                destroyOnClose: true,
                closeTimeout: 2000
              })
              .open();
            }).catch(function(e) {
              if (typeof e == "string") {
                e = {
                  message: e
                };
              }
              app.dialog.alert(e.message);
            }).finally(function(e) {
              app.preloader.hide();
            });
        }
      );

    },
    

    crearSegundasConUsuEjec: function(numeroLinea = null) {
      const self = this;
      if (self.$refs.popupSegundas) {
        self.$refs.popupSegundas.close();
      }
      const app = self.$f7;
      app.preloader.show();
      var data = {
        idPedido: self.$refs.rform.formData.id,
        numeroLinea: numeroLinea == null ? self.$refs.rform.formData.numeroLineaSegundas : numeroLinea,
        usuEjecSegundas: (self.$refs.rformSegundas && self.$refs.rformSegundas.formData) ? self.$refs.rformSegundas.formData.usuEjecSegundas : null
      };
      Pedido.crearSegundas({data: data})
        .then(function(res) {
          self.$refs.rform.formData = res;
          app.toast
          .create({
            icon: '<i class="f7-icons">checkmark</i>',
            text: self.$t("pedidos.segundas_ok"),
            position: "center",
            destroyOnClose: true,
            closeTimeout: 2000
          })
          .open();
        }).catch(function(e) {
          if (typeof e == "string") {
            e = {
              message: e
            };
          }
          app.dialog.alert(e.message);
        }).finally(function(e) {
          app.preloader.hide();
        });
    },
    abrirPopupVincularNuevaPrimera: function(linea) {
      const self = this;
      self.datosLineaPopupAnteriorNegativa = linea;
      self.pedidoAnteriorNegativa = self.$refs.rform.formData.numero?self.$refs.rform.formData.numero:self.$refs.rform.formData.codigo;
      self.numeroLineaAnteriorNegativa = linea.numero;
      self.popupVincularPrimeraOpened = true;
    },
    abrirPopupVincularNegativaAnterior: function(linea) {
      const self = this;
      self.datosLineaPopupNuevaPrimera = linea;
      self.pedidoNuevaPrimera = self.$refs.rform.formData.numero?self.$refs.rform.formData.numero:self.$refs.rform.formData.codigo;
      self.numeroLineaNuevaPrimera = linea.numero;
      self.popupVincularAnteriorNegativaOpened = true;
    },
    abrirPopupCrearNuevaPrimera: function(linea) {
      const self = this;
      self.datosLineaPopupNueva = linea;
      self.popupNuevaPrimeraOpened = true;
    },
    desvincularNuevaPrimeraNegativaAnterior: function(linea) {
      const self = this;
      const app = self.$f7;
      let datosCreacion = {};
      if(linea.numeroPedidoAnteriorNegativa) {
        datosCreacion = {
          pedidoAnteriorNegativa: linea.numeroPedidoAnteriorNegativa,
          numeroLineaAnteriorNegativa: linea.numeroLineaAnteriorNegativa,
          pedidoNuevaPrimera: self.$refs.rform.formData.numero?self.$refs.rform.formData.numero:self.$refs.rform.formData.codigo,
          numeroLineaNuevaPrimera: linea.numero,
          devolver: 'NUEVA_PRIMERA'
        } 
      } else {
        datosCreacion = {
          pedidoAnteriorNegativa: self.$refs.rform.formData.numero?self.$refs.rform.formData.numero:self.$refs.rform.formData.codigo,
          numeroLineaAnteriorNegativa: linea.numero,
          pedidoNuevaPrimera: linea.numeroPedidoNuevaPrimera,
          numeroLineaNuevaPrimera: linea.numeroLineaNuevaPrimera,
          devolver: 'ANTERIOR_NEGATIVA'
        } 
      }
      app.dialog.confirm(
        "¿Está seguro de que quiere desvincular la línea "+datosCreacion.numeroLineaNuevaPrimera+" de nuevas primeras del pedido "+datosCreacion.pedidoNuevaPrimera+" de la línea anterior negativa "+datosCreacion.numeroLineaAnteriorNegativa+" del pedido "+datosCreacion.pedidoAnteriorNegativa+"?",
        "Advertencia",
        function() {
          app.preloader.show();
          var data = datosCreacion;
          Pedido.desvincularNuevaPrimera({data: data})
            .then(function(res) {
              app.toast
              .create({
                icon: '<i class="f7-icons">checkmark</i>',
                text: self.$t("pedidos.vinculacion_ok"),
                position: "center",
                destroyOnClose: true,
                closeTimeout: 2000
              })
              .open();
              // Refrescamos el pedido actual
              self.$refs.rform.formData = res;
            }).catch(function(e) {
              if (typeof e == "string") {
                e = {
                  message: e
                };
              }
              app.dialog.alert(e.message);
            }).then(function(e) {
              app.preloader.hide();
            });
        }
      );
    },
    vincularNuevaPrimera: function(datosCreacion) {
      const self = this;
      const app = self.$f7;
      app.dialog.confirm(
        "¿Está seguro de que quiere vincular la línea de nuevas primeras indicada con la línea " + datosCreacion.numeroLineaAnteriorNegativa + " del pedido actual?",
        "Advertencia",
        function() {
          app.preloader.show();
          var data = datosCreacion;
          Pedido.vincularNuevaPrimera({data: data})
            .then(function(res) {
              if (self.$refs.popupVincularNuevaPrimera) {
                self.$refs.popupVincularNuevaPrimera.close();
              }
              app.toast
              .create({
                icon: '<i class="f7-icons">checkmark</i>',
                text: self.$t("pedidos.vinculacion_ok"),
                position: "center",
                destroyOnClose: true,
                closeTimeout: 2000
              })
              .open();
              // Refrescamos el pedido actual
              self.$refs.rform.formData = res;
            }).catch(function(e) {
              if (typeof e == "string") {
                e = {
                  message: e
                };
              }
              app.dialog.alert(e.message);
            }).then(function(e) {
              app.preloader.hide();
            });
        }
      );
    },
    vincularAnteriorNegativa: function(datosCreacion) {
      const self = this;
      const app = self.$f7;
      app.dialog.confirm(
        "¿Está seguro de que quiere vincular la línea anterior negativa indicada con la línea " + datosCreacion.numeroLineaAnteriorNegativa + " del pedido actual, que pasará a ser de 'nueva primera'?",
        "Advertencia",
        function() {
          app.preloader.show();
          var data = datosCreacion;
          Pedido.vincularNuevaPrimera({data: data})
            .then(function(res) {
              if (self.$refs.popupVincularAnteriorNegativa) {
                self.$refs.popupVincularAnteriorNegativa.close();
              }
              app.toast
              .create({
                icon: '<i class="f7-icons">checkmark</i>',
                text: self.$t("pedidos.vinculacion_ok"),
                position: "center",
                destroyOnClose: true,
                closeTimeout: 2000
              })
              .open();
              // Refrescamos el pedido actual
              self.$refs.rform.formData = res;
            }).catch(function(e) {
              if (typeof e == "string") {
                e = {
                  message: e
                };
              }
              app.dialog.alert(e.message);
            }).then(function(e) {
              app.preloader.hide();
            });
        }
      );
    },
    crearNuevaPrimera: function(datosCreacion) {
      const self = this;
      const app = self.$f7;
      app.dialog.confirm(
        "¿Está seguro de que quiere crear la línea de nuevas primeras para la línea seleccionada?",
        "Advertencia",
        function() {
          app.preloader.show();
          var data = {
            idPedido: self.$refs.rform.formData.id,
            numeroLinea: self.datosLineaPopupNueva.numero,
            formaCrear: datosCreacion.formaCrear,
            pedidoAsociar: datosCreacion.pedidoAsociar
          };
          Pedido.crearNuevaPrimera({data: data})
            .then(function(res) {
              if (self.$refs.popupNuevaPrimera) {
                self.$refs.popupNuevaPrimera.close();
              }
              app.toast
              .create({
                icon: '<i class="f7-icons">checkmark</i>',
                text: self.$t("pedidos.nuevaprimera_ok"),
                position: "center",
                destroyOnClose: true,
                closeTimeout: 2000
              })
              .open();
              // Navegar a la nueva primera '#!/pedidos/view/' + res.id
              if(self.idData != res.id) {
                app.views.current.router.navigate("/pedidos/view/" + res.id + "/");
              } else {
                self.$refs.rform.formData = res;
              }
            }).catch(function(e) {
              if (typeof e == "string") {
                e = {
                  message: e
                };
              }
              app.dialog.alert(e.message);
            }).then(function(e) {
              app.preloader.hide();
            });
        }
      );
    },
    borrarNuevaPrimera: function (numeroLinea) {
      var self = this;
      const app = self.$f7;
      app.dialog.confirm(
        "¿Está seguro que quiere eliminar la línea seleccionada?",
        "Advertencia",
        function() {
          app.preloader.show();
          // Vamos a obtener el id del pedio de la línea anterior para poder borrarla, que será la línea con número de línea == numeroLinea
          var data = {
            idPedido: self.$refs.rform.formData.id,
            numeroLinea: numeroLinea,
          };
          Pedido.borrarNuevaPrimera({data: data})
            .then(function(res) {
              self.$refs.rform.formData = res;
              app.toast
              .create({
                icon: '<i class="f7-icons">checkmark</i>',
                text: 'Línea borrada correctamente',
                position: "center",
                destroyOnClose: true,
                closeTimeout: 2000
              })
              .open();
            }).catch(function(e) {
              if (typeof e == "string") {
                e = {
                  message: e
                };
              }
              app.dialog.alert(e.message);
            }).finally(function(e) {
              app.preloader.hide();
            });
        }
      );
    },
    marcarNuevaPrimera: function (item) {
      var self = this;
      const app = self.$f7;
      // si la línea ya está dentro de la lista de lineasBorrar.lineasNuevasPrimeras, la sacamos de la lista y marcamos el item como deleteSelected=false
      if (self.lineasBorrar.lineasNuevasPrimeras && self.lineasBorrar.lineasNuevasPrimeras.includes(item.numero)) {
        self.lineasBorrar.lineasNuevasPrimeras = self.lineasBorrar.lineasNuevasPrimeras.filter(l => l != item.numero);
        self.$set(item, "deleteSelected", false);
      } else {
        // si la línea no está dentro de la lista de lineasBorrar.lineasNuevasPrimeras, la añadimos a la lista y marcamos el item como deleteSelected=true
        if (!self.lineasBorrar.lineasNuevasPrimeras) {
          self.lineasBorrar.lineasNuevasPrimeras = [];
        }
        self.lineasBorrar.lineasNuevasPrimeras.push(item.numero);
        self.$set(item, "deleteSelected", true);
      }
    },
    marcarSegundas: function (item) {
      var self = this;
      const app = self.$f7;
      // si la línea ya está dentro de la lista de lineasBorrar.lineasSegundas, la sacamos de la lista y marcamos el item como deleteSelected=false
      if (self.lineasBorrar.lineasSegundas && self.lineasBorrar.lineasSegundas.includes(item.numero)) {
        self.lineasBorrar.lineasSegundas = self.lineasBorrar.lineasSegundas.filter(l => l != item.numero);
        self.$set(item, "deleteSelected", false);
      } else {
        // si la línea no está dentro de la lista de lineasBorrar.lineasSegundas, la añadimos a la lista y marcamos el item como deleteSelected=true
        if (!self.lineasBorrar.lineasSegundas) {
          self.lineasBorrar.lineasSegundas = [];
        }
        self.lineasBorrar.lineasSegundas.push(item.numero);
        self.$set(item, "deleteSelected", true);
      }
    },

    borrarLineas: function () {
      var self = this;
      const app = self.$f7;
      app.dialog.confirm(
        "¿Está seguro que quiere eliminar las líneas seleccionadas?",
        "Advertencia",
        function() {
          app.preloader.show();
          // Vamos a enviar el objeto lineasBorrar, que es un objeto con las lineas a borrar, con el idPedido y los numeros de las líneas
          var data = self.lineasBorrar;
          Pedido.borrarLineas({data: data})
            .then(function(res) {
              self.$refs.rform.formData = res;
              app.toast
              .create({
                icon: '<i class="f7-icons">checkmark</i>',
                text: 'Líneas borradas correctamente',
                position: "center",
                destroyOnClose: true,
                closeTimeout: 2000
              })
              .open();
            }).catch(function(e) {
              if (typeof e == "string") {
                e = {
                  message: e
                };
              }
              app.dialog.alert(e.message);
            }).finally(function(e) {
              app.preloader.hide();
            });
        }
      );
    },



    getLineasTexto: function(comentarios) {
      if(!comentarios) {
        return [];
      }
      return comentarios.split("##").map(c => c.trim());
    },
    addEmail: function(listName) {
      var self = this;
      var arr = self.$refs.rform.formData.cliente[listName] || [];
      arr.push("");
      self.$set(self.$refs.rform.formData.cliente, listName, arr);
    },
    desglosarLinea: function (lineaADesglosar) {
      var self = this;
      var pedido = self.$refs.rform.formData;
      var cantidad = lineaADesglosar.cantidad;

      var nuevoImpLinea = lineaADesglosar.totalSinIVASinTasasConDescuento/cantidad;
      lineaADesglosar.totalSinIVASinTasasConDescuento = nuevoImpLinea;
      var j;

      if (lineaADesglosar.repContratacion && lineaADesglosar.repContratacion.length > 0 ) {


        for (j = 0; j < lineaADesglosar.repContratacion.length; j++) {
          
          var porcC = lineaADesglosar.repContratacion[j].porcentaje;
          lineaADesglosar.repContratacion[j].importeLinea = nuevoImpLinea;
          lineaADesglosar.repContratacion[j].importeReparto = nuevoImpLinea*porcC/100;
        }
      }

      if (lineaADesglosar.repEjecucion && lineaADesglosar.repEjecucion.length > 0 ) {

        for (j = 0; j < lineaADesglosar.repEjecucion.length; j++) {
          
          var porcE = lineaADesglosar.repEjecucion[j].porcentaje;
          lineaADesglosar.repEjecucion[j].importeLinea = nuevoImpLinea;
          lineaADesglosar.repEjecucion[j].importeReparto = nuevoImpLinea*porcE/100;
        }
      }

      if (lineaADesglosar.facturas && lineaADesglosar.facturas.length > 0 ) {
        for (j = 0; j < lineaADesglosar.facturas.length; j++) {

          lineaADesglosar.facturas[j].importe = lineaADesglosar.facturas[j].importe / cantidad;
          lineaADesglosar.facturas[j].importeConIVA = lineaADesglosar.facturas[j].importeConIVA / cantidad;
          lineaADesglosar.facturas[j].totalIVA = lineaADesglosar.facturas[j].totalIVA / cantidad;
          lineaADesglosar.facturas[j].importeAplicado = lineaADesglosar.facturas[j].importeAplicado / cantidad;
          lineaADesglosar.facturas[j].saldoVencido = lineaADesglosar.facturas[j].saldoVencido / cantidad;
        }
      }


      var i = cantidad - 1;
      while (i) {
        var nuevaLinea = JSON.parse(JSON.stringify( Object.assign({}, lineaADesglosar, {numero: self.getMaxNumeroLineas(pedido.lineas), cantidad: 1})));
        nuevaLinea.historicoEstados = null;
        nuevaLinea.codigoLinea = pedido.codigo+"-"+nuevaLinea.numero+".1";
        nuevaLinea.idExpediente = null;
        nuevaLinea.idExpedienteRelacionado = null;
        nuevaLinea.codigoExpediente = null;
        nuevaLinea.codigoAlbaran = null;
        nuevaLinea.fechaInsp = null;
        nuevaLinea.fechaRenovacion = null;
        nuevaLinea.fechaFirmaInspector = null;
        nuevaLinea.inspectores = null;
        nuevaLinea.calificacion = null;
        nuevaLinea.procesoEntrega = null;
        nuevaLinea.planificacion = null;
        nuevaLinea.fechaPlanificacion = null;
        nuevaLinea.fechaVencimiento = null;
        nuevaLinea.estadoExpediente = null;
        nuevaLinea.datosExpedientePrimeras = null;
        nuevaLinea.datosExpedienteSegundas = null;


        if (nuevaLinea.repContratacion && nuevaLinea.repContratacion.length > 0 ) {

          for (j = 0; j < nuevaLinea.repContratacion.length; j++) {
            
            nuevaLinea.repContratacion[j].code = null;
            nuevaLinea.repContratacion[j].name = null;
          }
        }

        if (nuevaLinea.repEjecucion && nuevaLinea.repEjecucion.length > 0 ) {

          for (j = 0; j < nuevaLinea.repEjecucion.length; j++) {
            
            nuevaLinea.repEjecucion[j].code = null;
            nuevaLinea.repEjecucion[j].name = null;
          }
        }

        if (nuevaLinea.facturas && nuevaLinea.facturas.length > 0 ) {

            for (j = 0; j < nuevaLinea.facturas.length; j++) {

          nuevaLinea.facturas[j].numLineaPedido = nuevaLinea.numero;
          
        }
      }
        
        // nuevaLinea.repContratacion = null;
        // nuevaLinea.repEjecucion = null;
       
        
        nuevaLinea.camposUsuario = JSON.parse(JSON.stringify(Object.assign({}, lineaADesglosar.camposUsuario)));
        self.$refs.rform.formData.lineas.push(nuevaLinea);
        i--;  
      }
      lineaADesglosar.cantidad = 1;
    },
    crearLinea: function () {
      var self = this;
      var pedido = self.$refs.rform.formData;
      
      var nuevaLinea = {
        activa: true,
        cantidad: 1,
        lineStatus: "O",
        estado: {estado: "SIN_PLANIFICAR"},
        precioUnitario: 0,
        porcDescuento: 0,
        porcIVA: 0,
        tasas: 0,
        importe: 0,
        numero: self.getMaxNumeroLineas(pedido.lineas),
      };
      nuevaLinea.codigoLinea = pedido.codigo+"-"+nuevaLinea.numero+".1";
      self.$refs.rform.formData.lineas.push(nuevaLinea);
    },
    getMaxNumeroLineas: function(lineas) {
      var maxNumero = -1;
      if (lineas && lineas.length) {
        lineas.forEach(l => {
          if (l.numero > maxNumero) {
            maxNumero = l.numero;
          }
        });
      }
      return maxNumero + 1;
    },
    isRepartoOK: function(linea, tipo) {
      var self = this;
      var reparto = linea[tipo];
      var totRep = 0;
      var totImpRep = 0;
      var repCero = 0;
      var usuEmpty = 0;
      var fechaEmpty = 0;

      if (!reparto || reparto.length == 0) {
        return true;
      }
      for (var j = 0; j < reparto.length; j++) {
        totRep+= reparto[j].porcentaje;
        totImpRep+= parseFloat(reparto[j].importeReparto);

        if (reparto[j].porcentaje == 0) {
          repCero+=1;
        }
        if (!reparto[j].usuario) {
          usuEmpty+=1;
        }
        if (!reparto[j].fecha) {
          fechaEmpty+=1;
        }
      }
      if (totRep >= 99 && totRep <= 100 && repCero == 0 && usuEmpty == 0 && fechaEmpty == 0 && totImpRep >= linea.totalSinIVASinTasasConDescuento*0.99 && totImpRep <= linea.totalSinIVASinTasasConDescuento ) {
        return true;
      } else {
        return false;
      }
    },
    repartoDeshacer(tipo, idx) {
      var self = this;
      self.$set(self.$refs.rform.formData.lineas[idx], tipo, JSON.parse(JSON.stringify(self.initLineas[idx][tipo])));
    },
    repartoDeshacerTodos(tipo) {
      var self = this;
      for (var j = 0; j < self.$refs.rform.formData.lineas.length; j++ ) {
        self.$set(self.$refs.rform.formData.lineas[j], tipo, JSON.parse(JSON.stringify(self.initLineas[j][tipo])));
      }
    },
    deleteReparto(tipo, linea, idxrep) {
      var self = this;
      var reparto = linea[tipo];
      reparto.splice(idxrep,1);
      // if (reparto.length == 0) {
      //   self.addReparto(tipo, linea, true);
      // }
    },
    repartoEquitativo : function (tipo, linea) {
      var self = this;
      var reparto = linea[tipo];
      for (var j = 0; j < reparto.length; j++) {
        reparto[j].porcentaje = parseFloat((100/reparto.length).toFixed(2).replaceAll(",","."));
        reparto[j].importeReparto = (reparto[j].porcentaje / 100 * linea.totalSinIVASinTasasConDescuento).toFixed(2)
      }
    },
    duplicarRepartoAbajo: function (tipo, linea, idx) {
      var self = this;
      var reparto = linea[tipo];
      var pedido = self.$refs.rform.formData;
      for (var j = idx+1; j < pedido.lineas.length; j++) {
        self.$set(self.$refs.rform.formData.lineas[j], tipo, JSON.parse(JSON.stringify(reparto)));
      }  
    },
    duplicarRepartoArriba: function (tipo, linea, idx) {
      var self = this;
      var reparto = linea[tipo];
      var pedido = self.$refs.rform.formData;
      for (var j = idx-1; j > -1; j--) {
        self.$set(self.$refs.rform.formData.lineas[j], tipo, JSON.parse(JSON.stringify(reparto)));
      }  
    },
    addReparto: function (tipo, linea, todo) {
      var self = this;
      var pedido = self.$refs.rform.formData;
      if (!linea[tipo] || !linea[tipo].length ) {
        self.$set(linea, tipo, []);
        todo = true;
      }
      if (tipo == 'repEjecucion') {
        linea[tipo].push({usuario: linea.camposUsuario.cpEj, porcentaje: (todo == true ? 100 : 0), importeReparto: (todo == true ? linea.totalSinIVASinTasasConDescuento : 0 ), fecha: linea.camposUsuario.fechaEjecucion});
      }
      else {
        linea[tipo].push({usuario: pedido.contratacion.codEmpleado , porcentaje: (todo == true ? 100 : 0), importeReparto: (todo == true ? linea.totalSinIVASinTasasConDescuento : 0 ), fecha: pedido.fechaContratacion});
      }
    },
    onChangePorcentajeRep: function (tipo, linea, idx) {
      var self = this;
      var reparto = linea[tipo];
      reparto[idx].importeReparto = (reparto[idx].porcentaje / 100 * linea.totalSinIVASinTasasConDescuento).toFixed(2);
    },
    onBlurPrecioUnitario: function(formData) {
      var self = this;
      self.onBlurPorc2a(formData);
    },
    llamarOnBlur2a: function (formData) {
      var self = this;
      if (formData.porcSegunda) {
        self.onBlurPorc2a(formData);
      }
      else if (formData.importeSegunda) {
        self.onBlurImporte2a(formData);
      }
    }, 
    onBlurPorc2a: function(formData) {
      var self = this;
      var importeUnitario = formData.precioUnitario;
      var porc2a = formData.porcSegunda;
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,      
        maximumFractionDigits: 2,
        useGrouping: false
      });
      if (!!importeUnitario && importeUnitario > 0 && !!porc2a && porc2a > 0) {
        self.$set(formData, "importeSegunda", formatter.format((importeUnitario / 100) * porc2a));
      } else {
        self.$set(formData, "importeSegunda", 0);
      }
    },
    onBlurImporte2a: function(formData) {
      var self = this;
      var importeUnitario = formData.precioUnitario;
      var importe2a = formData.importeSegunda;
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,      
        maximumFractionDigits: 2,
        useGrouping: false
      });
      if (!!importe2a && importe2a > 0 && !!importeUnitario && importeUnitario > 0) {
        self.$set(formData, "porcSegunda", formatter.format((importe2a * 100) / importeUnitario));
      } else {
        self.$set(formData, "porcSegunda", 0);
      }
    },
    onNewLine: function(nuevaLinea) {
      const self = this;
      if (self.$refs.rform.formData.lineas.filter(l => l.numero == nuevaLinea.numero).length) {
          var linea = self.$refs.rform.formData.lineas.filter(l => l.numero == nuevaLinea.numero)[0];
          self.$set(self.$refs.rform.formData.lineas, self.$refs.rform.formData.lineas.indexOf(linea), nuevaLinea);
      } else {
        self.$refs.rform.formData.lineas.push(nuevaLinea);
      }
      if (self.$refs.popupnew) {
        self.$refs.popupnew.close();
      }
    },
    verBotonSiguiente: function(rform) {
      const self = this;

      if (self.step >= self.maxStep || !rform || !rform.formData) {
        return false;
      }
      if (self.readonly) {
        return true;
      }
      if (self.step == 1) {
        return true;
      }
      if (self.step == 2) {
        return true;
      }
      if (self.step == 3) {
        return rform.formData.servicio && rform.formData.servicio.id;
      }

      return true;
    },
    verBotonAnterior: function(rform) {
      const self = this;

      if (self.step <= 1 || !rform || !rform.formData) {
        return false;
      }
      if (self.step == 3) {
        return !rform.formData.firma;
      }

      return true;
    },
    verBotonGuardar: function(rform) {
      const self = this;

      return self.lineaEditable(rform.formData);
    },
    verBotonCerrar: function(rform) {
      const self = this;

      return self.step == self.maxStep && rform && !self.lineaEditable(rform.formData);
    },
    planificar: function(lineas, idPedido) {
      var self = this;
      var app = self.$f7;
      var jsonData = [{
        idPedido: idPedido,
        numeroLineas: lineas.filter((linea, idx) => linea.tipoLinea && linea.tipoLinea != 'TASAS' && linea.tipoLinea != 'SUBCONTRATACION' && linea.tipoLinea != 'COMISION' && linea.estado && (linea.estado.estado == 'CREADO_PEDIDO' || linea.estado.estado == 'SIN_PLANIFICAR' || linea.estado.estado == 'PLANIFICADA') && self.lineasEjecutable[idx]).map(linea => linea.numero)
      }]
      app.views.current.router.navigate(
        "/pedidos/planificar/?jsonData=" + JSON.stringify(jsonData)
      );
    },
    planificarTPI: function(lineas, idPedido) {
      var self = this;
      var app = self.$f7;
      var jsonData = [{
        idPedido: idPedido,
        numeroLineas: lineas.filter((linea, idx) => linea.tipoLinea && linea.tipoLinea != 'TASAS' && linea.tipoLinea != 'SUBCONTRATACION' && linea.tipoLinea != 'COMISION').map(linea => linea.numero)
      }]
      app.views.current.router.navigate(
        "/pedidos/planificar-tpi/?jsonData=" + JSON.stringify(jsonData)
      );
    },
    beforeSaveItem: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var formData = data;
      formData.clienteId = self.$refs.rform.formData.cliente.id;
      formData.codigoInstalacion = self.$refs.rformNew.formData.codigoInstalacion;
      resolve(formData);
    },
    onSaveItem: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rformItem.clear();
      if (self.$refs.popupItem) {
        self.$refs.popupItem.close();
      }
      if (Array.isArray(self.$refs.item)) {
        self.$refs.item.forEach(i => i.reload());
      } else if (self.$refs.item) {
        self.$refs.item.reload();
      }
      if (self.$refs.rformNew && self.$refs.rformNew.formData) {
        self.$set(self.$refs.rformNew.formData, 'item', res);
      }
      app.toast
        .create({
          text: self.$t("ofertas.item_creado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    beforeSaveInstalacion: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var formData = data;
      formData.clienteId = self.$refs.rform.formData.cliente.id;
      resolve(formData);
    },
    onSaveInstalacion: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rformInstalacion.clear();
      if (self.$refs.popupInstalacion) {
        self.$refs.popupInstalacion.close();
      }
      if (Array.isArray(self.$refs.instalacion)) {
        self.$refs.instalacion.forEach(i => i.reload());
      } else if (self.$refs.instalacion) {
        self.$refs.instalacion.reload();
      }
      if (self.$refs.rformNew && self.$refs.rformNew.formData) {
        self.$set(self.$refs.rformNew.formData, 'codigoInstalacion', res.codigo);
      }
      app.toast
        .create({
          text: self.$t("ofertas.instalacion_creada"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    getTotalesPedido: function(lineas) {
      var self = this;
      var totales = {
        descuento: 0,
        totalSinDto: 0,
        subtotal: 0,
        iva: 0,
        totalTasas: 0,
        total: 0,
        subtotalProveedor: 0,
        ivaProveedor: 0,
        totalProveedor: 0,

        subtotalComision: 0,
        ivaComision: 0,
        totalComision: 0,

        subtotalTerceros: 0,
        ivaTerceros: 0,
        totalTerceros: 0,

        subtotalConTerceros: 0,
        ivaConTerceros: 0,
        totalConTerceros: 0,
        totalConTasasConTerceros: 0,
        totalSinTasasConTercerosSinIVa: 0
      };
      if(!lineas || lineas.length <= 0) {
        return totales;
      }
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,      
        maximumFractionDigits: 2,
        useGrouping: false
      });
      lineas.forEach(linea => {
        var totalesLinea = self.getTotalesLinea(linea);
        for (const t in totales) {
          totales[t] = formatter.format(parseFloat(totales[t]) + parseFloat(totalesLinea[t]));
        }
      });
      return totales;
    },
    getTotalesLinea: function(linea) {
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,      
        maximumFractionDigits: 2,
        useGrouping: false
      });
      var cantidad = linea.cantidad;
      var dtoUnitario = linea.precioUnitario / 100 * linea.porcDescuento;
      var descuento = dtoUnitario * cantidad;
      var totalSinDto = linea.precioUnitario * cantidad;
      var totalTasas = linea.tasas * cantidad;
      var subtotal = totalSinDto - descuento;
      var iva = subtotal / 100 * linea.porcIVA;
      var total = subtotal + iva;
      
      // Proveedor
      var proveedor = linea.proveedor || {precio: 0, porcIVA: 21};
      var subtotalProveedor = proveedor.precio * cantidad;
      var ivaProveedor = subtotalProveedor / 100 * proveedor.porcIVA;
      var totalProveedor = subtotalProveedor + ivaProveedor;
      // Comision
      var comision = linea.comision || {precio: 0, porcIVA: 21};
      var subtotalComision = comision.precio * cantidad;
      var ivaComision = subtotalComision / 100 * comision.porcIVA;
      var totalComision = subtotalComision + ivaComision;

      var subtotalTerceros = subtotalProveedor + subtotalComision;
      var ivaTerceros = ivaProveedor + ivaComision;
      var totalTerceros = totalProveedor + totalComision;


      var totales = {
        descuento: formatter.format(descuento),
        totalSinDto: formatter.format(totalSinDto),
        subtotal: formatter.format(subtotal),
        iva: formatter.format(iva),
        totalTasas: formatter.format(totalTasas),
        total: formatter.format(total),
        subtotalProveedor: formatter.format(subtotalProveedor),
        ivaProveedor: formatter.format(ivaProveedor),
        totalProveedor: formatter.format(totalProveedor),

        subtotalComision: formatter.format(subtotalComision),
        ivaComision: formatter.format(ivaComision),
        totalComision: formatter.format(totalComision),

        subtotalTerceros: formatter.format(subtotalTerceros),
        ivaTerceros: formatter.format(ivaTerceros),
        totalTerceros: formatter.format(totalTerceros),

        subtotalConTerceros: formatter.format(subtotal + subtotalProveedor + subtotalComision  ),
        ivaConTerceros: formatter.format(iva + ivaProveedor + ivaComision),
        totalConTerceros: formatter.format(total + totalProveedor + totalComision),
        totalConTasasConTerceros: formatter.format(total + totalProveedor + totalComision + totalTasas),
        totalSinTasasConTercerosSinIVa: formatter.format(total + totalProveedor + totalComision - iva - ivaProveedor - ivaComision)
      };
      
      return totales;
    },
    beforeSave: function(data, resolve, reject) {
      var self = this;
      var pedido = self.$refs.rform.formData;
      var app = self.$f7;
      for (var j = 0; j < pedido.lineas.length; j++) {
        if (!self.isRepartoOK(pedido.lineas[j],'repContratacion') || !self.isRepartoOK(pedido.lineas[j],'repEjecucion')) {
          reject("Por favor, revise los repartos.");
          return;
        }
        // comprobamos que todas las líneas tengan servicio asociado
        if (!pedido.lineas[j].servicio) {
          reject("Por favor, seleccione un servicio para todas las líneas.");
          return;
        }
      }
      for (const idx in data.lineas) {
        var linea = data.lineas[idx];
        linea.importe = self.totalesLineas[idx]["subtotal"];
      }
      resolve(data);
    },
    changeContacto: function() {
      var self = this;
      var app = self.$f7;
      var contactocliente = self.$refs.contactoCliente.selected;
      if (contactocliente && contactocliente.canalesComunicacion) {
        contactocliente.canalesComunicacion.filter(canal=>canal.tipo == "EMAIL").forEach(canal => {
          self.$set(self.$refs.rform.formData, 'emailContacto', canal.valor);
        });
      }
    },
    changeServicio: function(linea) {
      var self = this;
      if (!linea.precioUnitario && linea.servicio) {
        var precio = undefined;
        if (linea.servicio) {
          let serv = linea.servicio;
          // informamos los campos de la línea vinculados al servicio
          self.$set(linea, 'subservicio', undefined);
          var precio = undefined;
          if (serv.porcSegunda && !linea.porcSegunda && !linea.importeSegunda) {
            self.$set(linea, 'porcSegunda', linea.servicio.porcSegunda);
          }
          self.$set(linea, 'porcMinSegunda', linea.servicio.porcMinSegunda);
          self.$set(linea, 'blocPorcSegundas', linea.servicio.blocPorcSegundas);
        }
        if (linea.servicio && linea.servicio.precios && linea.servicio.precios.length > 0) {
          var listaPrecios = self.$refs.cliente.selected
            ? self.$refs.cliente.selected.listaPrecios
            : undefined;
          if (listaPrecios) {
            precio = linea.servicio.precios.filter(
              s => s.listaPrecios == listaPrecios
            )[0];
          }
          if (!precio) {
            precio = linea.servicio.precios[0];
          }
        }
        if (precio) {
          self.$set(
            linea,
            "precioUnitario",
            precio.precioUnidad
          );
        }
      }
      if (!linea.tasas && linea.servicio) {
        var tasa = undefined;
        if (linea.servicio.tasas && linea.servicio.tasas.length > 0) {
          
          var provincia = linea.direccionInspeccion ? linea.direccionInspeccion.provincia : undefined;
          if (provincia) {
            var tasaDTO = linea.servicio.tasas.filter(s => s.provincia == provincia)[0];
            tasa = tasaDTO ? tasaDTO.valor : undefined;
          }
          if (!tasa) {
            tasa = linea.servicio.tasaDefecto;
          }
        } else {
          tasa = linea.servicio.tasaDefecto;
        }
        if (tasa) {
          self.$set(linea, 'tasas', tasa);
        }
      }
      if (
        !linea.tiempoEstimadoCampo &&
        linea.servicio &&
        linea.servicio.tiempoEstimadoCampo
      ) {
        self.$set(
          linea,
          "tiempoEstimadoCampo",
          linea.servicio.tiempoEstimadoCampo
        );
      }
      if (
        !linea.tiempoEstimadoOficina &&
        linea.servicio &&
        linea.servicio.tiempoEstimadoOficina
      ) {
        self.$set(
          linea,
          "tiempoEstimadoOficina",
          linea.servicio.tiempoEstimadoOficina
        );
      }
      if (
        !linea.codCuentaMayor &&
        linea.servicio &&
        linea.servicio.codCuentaMayor
      ) {
        self.$set(
          linea,
          "codCuentaMayor",
          linea.servicio.codCuentaMayor
        );
      }
    },
    beforeChangeItem: function (itemNuevo) {
      var self = this;
      var itemViejo = self.$refs.rformNew.formData.item;
      if (typeof itemViejo != 'undefined' && itemViejo != null){
        itemViejo.codigo = null;
        itemViejo.nombre = null;
        itemViejo.codInstalacion = null;
        itemViejo.id = null;
        itemNuevo = Object.assign({}, itemViejo, itemNuevo);
      }
      return itemNuevo;

    },
    changeItem: function(linea) {
      var self = this;
      if (!linea.direccionInspeccion && linea.item && linea.item.direccion) {
          self.$set(self.$refs.rformNew.formData, 'direccionInspeccion', linea.item.direccion);
      }
      if (!linea.codigoInstalacion && linea.item && linea.item.codigoInstalacion) {
          self.$set(self.$refs.rformNew.formData, 'codigoInstalacion', linea.item.codigoInstalacion);
      }
      if (linea.item && linea.item.administrador) {
          self.$set(self.$refs.rformNew.formData, 'administrador', linea.item.administrador);
      }
      if (linea.item && linea.item.instalador) {
          self.$set(self.$refs.rformNew.formData, 'instalador', linea.item.instalador);
      }
      if (linea.item && linea.item.mantenedor) {
          self.$set(self.$refs.rformNew.formData, 'mantenedor', linea.item.mantenedor);
      }
      if (linea.item && linea.item.asociacion) {
          self.$set(self.$refs.rformNew.formData, 'asociacion', linea.item.asociacion);
      }
    },
    loadItem: function(res) {
      const self = this;
      self.$set(self.$refs.rform, "formData", res);
      if (!res.lineas || res.lineas == null) {
        self.$set(self.$refs.rform.formData, "lineas", []);
      } else {
        self.$set(self.$refs.rform.formData, "lineas", self.getSortedLineas(res.lineas));
      }

      // cargamos las finanzas, aunque si metemos todo el res al formData no lo veo necesario.
      if(!res.finanzas) {
        self.$set(self.$refs.rform.formData, "finanzas", {});
      } 

      self.initLineas = JSON.parse(JSON.stringify(res.lineas));

      self.clienteSelected = res.cliente && res.cliente.id;
      // self.initDatosFinanzas(res.finanzas.codCondicionesPago);
      if (!res.inspectores || res.inspectores == null) {
        self.$set(self.$refs.rform.formData, "inspectores", []);
      }
      // if (res.cliente) {
      //   self.changeCliente();
      // }
    },
    getHistory: function(linea, index) {
      var self = this;
      var app = self.$f7;
      if(!self.$refs.rform.history || !self.$refs.rform.history.lineas) {
        return undefined;
      }
      // Nos clonamos el histórico para no corromperlo
      var completeHistory = JSON.parse(JSON.stringify(self.$refs.rform.history));
      // vamos a sacar todos los cambios en las líneas para luego filtrar los de la indicada por el index 
      var history = completeHistory.lineas.cambios.map(c => ({date: c.date, username: c.username ,valor: c.valor[index]})).filter(c => c.valor);
      // camos a desdoblar las líneas para quedarnos con los campos modificados en cada una de ellas
      var historyLinea = {};
      history.forEach(h => {
        let campos = Object.keys(h.valor);
        campos.forEach(c => {
          if (!historyLinea[c]) {
            historyLinea[c] = {cambios: []};
          }
          historyLinea[c].cambios.push({date: h.date, username: h.username, valor: h.valor[c]});
        });
      });
      return historyLinea;
    },
    getSortedLineas: function(lineas) {
      var compare = function (a, b) {
        if (!a.estado || !a.estado.estado) {
          return -1;
        }
        if (!b.estado || !b.estado.estado) {
          return 1;
        }
        if (a.estado.estado == b.estado.estado) {
          return a.numero < b.numero ? -1 : 1; 
        }
        if (a.estado.estado == "CREADA_OFERTA") {
          return -1;
        }
        return 1;
      }
      return lineas.sort(compare);
    },
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          Pedido.delete({ itemId: self.id })
            .then(function(res) {
              if (res) {
                app.toast
                  .create({
                    icon: '<i class="f7-icons">checkmark</i>',
                    text: self.$t("rlist.registro_eliminado"),
                    position: "center",
                    destroyOnClose: true,
                    closeTimeout: 2000
                  })
                  .open();
                self.$f7router.back();
              }
            })
            .catch(function(error) {
              app.dialog.alert(error.message);
            });
        }
      );
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$set(self.$refs.rform, "formData", res);
      self.$refs.tabFinanzas.initDatosFinanzas(res.finanzas.codCondicionesPago);
      self.idData = res.id;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("pedidos.guardado_ok"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    getTextoBadge: function(linea, lineaEjecutable) {

      if (linea.tipoLinea == 'TASAS') {
        return "Línea de tasas";
      }
      else if (linea.tipoLinea == 'SUBCONTRATACION') {
        return "Línea de subcontratación";
      }
      else if (linea.tipoLinea == 'COMISION') {
        return "Línea de comisión";
      }
      else if (linea.estado && linea.estado.estado == 'PLANIFICADA') {
        return "Planificada";
      }
      else if (linea.estado && linea.estado.estado == 'EJECUTADA') {
        return "Ejecutada";
      }
      else if (linea.estado && linea.estado.estado == 'PROCESO_ENTREGA') {
        return "Proceso de entrega";
      }
      else if (linea.estado && linea.estado.estado == 'ENTREGADA') {
        return "Entregada";
      }
      else if (linea.estado && linea.estado.estado == 'FACTURADA') {
        return "Facturada";
      }
      else if (linea.estado && linea.estado.estado == 'COBRADA') {
        return "Cobrada";
      }
      else if (linea.estado && linea.estado.estado == 'SIN_PLANIFICAR') {
        return "Inspección creada sin planificar";
      }
      else if ((!linea.estado || linea.estado.estado != 'PLANIFICADA') && lineaEjecutable) {
        return "Línea preparada para planificar";
      }
      else if ((!linea.estado || linea.estado.estado != 'PLANIFICADA') && !lineaEjecutable) {
        return "Faltan datos para poder planificar la línea";
      }
      
    },
    getColorExpediente: function (linea) {

      if(linea.calificacion == 'FAVORABLE' || linea.calificacion =='FAVORABLE_CON_DEFECTOS' || linea.calificacion =='FAVORABLE_CON_REPARO' ) {
        return "green";
      }
      if(linea.calificacion == 'DESFAVORABLE') {
        return "orange";
      }
      if(linea.calificacion == 'NEGATIVO') {
        return "red";
      }

    },
    getColorBadge: function(linea, lineaEjecutable) {

      if (linea.tipoLinea == 'TASAS' || linea.tipoLinea == 'SUBCONTRATACION' || linea.tipoLinea == 'COMISION') {
        return "blue";
      }
      else if (linea.estado && linea.estado.estado == 'PLANIFICADA') {
        return "green";
      }
      else if (linea.estado && linea.estado.estado == 'EJECUTADA' || linea.estado.estado == 'PROCESO_ENTREGA' || linea.estado.estado == 'ENTREGADA'|| linea.estado.estado == 'FACTURADA' ||  linea.estado.estado == 'COBRADA') {
        return "teal";
      }
      else if ((!linea.estado || linea.estado.estado != 'PLANIFICADA') && lineaEjecutable) {
        return "orange";
      }
      else if ((!linea.estado || linea.estado.estado != 'PLANIFICADA') && !lineaEjecutable) {
        return "red";
      }
      
    },

    getColorBadgeExp: function(linea) {
      let estados = [{
          nombre: "GENERADO",
          valor: "lime"
        },
        {
          nombre: "INSPECCIONADO",
          valor: "green"
        },
        {
          nombre: "PLANIFICADO",
          valor: "blue"
        },
        {
          nombre: "AUDITADO",
          valor: "teal"
        },
        {
          nombre: "PDTE_FACTURAR",
          valor: "purple"
        },
        {
          nombre: "PDTE_CONFORMIDAD",
          valor: "deeppurple"
        },
        {
          nombre: "PDTE_ENVIO",
          valor: "yellow"
        },
        {
          nombre: "ENVIADO",
          valor: "orange"
        },
        {
          nombre: "CANCELADO",
          valor: "red"
        },
        {
          nombre: "PENDIENTE_REVISION_CALIDAD",
          valor: "pink"
        },
        {
          nombre: "CORREGIR_ERRORES_AUDITORIA_CALIDAD",
          valor: "darkblue"
        },
        {
          nombre: "PENDIENTE_FIRMA_INSPECTOR",
          valor: "deeppurple"
        },
        {
          nombre: "FIRMADO_INSPECTOR",
          valor: "lightblue"
        },
        {
          nombre: "NO_DEFINIDO",
          valor: "gray"
        }];
      return estados.filter(e => e.nombre == linea.estadoExpediente).map(e => e.valor)[0] || 'gray';
    },
    getTextoBadgeExp: function(linea) {

      let estados = [{
            nombre: "Creado",
            valor: "GENERADO"
          },
          {
            nombre: "Inspeccionado",
            valor: "INSPECCIONADO"
          },
          {
            nombre: "Planificado",
            valor: "PLANIFICADO"
          },
          {
            nombre: "Auditado",
            valor: "AUDITADO"
          },
          {
            nombre: "Pdte. Facturar",
            valor: "PDTE_FACTURAR"
          },
          {
            nombre: "Pdte. Confirmar",
            valor: "PDTE_CONFORMIDAD"
          },
          {
            nombre: "Pdte. Envío",
            valor: "PDTE_ENVIO"
          },
          {
            nombre: "Enviado",
            valor: "ENVIADO"
          },
          {
            nombre: "Cancelado",
            valor: "CANCELADO"
          },
          {
            nombre: "Pdte. de revisión (calidad)",
            valor: "PENDIENTE_REVISION_CALIDAD"
          },
          {
            nombre: "Corregir errores (calidad)",
            valor: "CORREGIR_ERRORES_AUDITORIA_CALIDAD"
          },
          {
            nombre: "Pdte. de firma",
            valor: "PENDIENTE_FIRMA_INSPECTOR"
          },
          {
            nombre: "Certificado firmado",
            valor: "FIRMADO_INSPECTOR"
          },
          {
            nombre: "No definido",
            valor: "NO_DEFINIDO"
          }];
      return estados.filter(e => e.valor == linea.estadoExpediente).map(e => e.nombre)[0] || '-';
      
    },    
    nuevaDocumentacionConNombre: function(indexLinea) {
      const self = this;
      const app = self.$f7;
      let documentosConNombre = self.$refs.rform.formData.lineas[indexLinea].documentosConNombre?self.$refs.rform.formData.lineas[indexLinea].documentosConNombre:[]
      documentosConNombre.push({nombre: '',editable: true});
      if(!self.$refs.rform.formData.lineas[indexLinea].documentosConNombre) {
        self.$set(self.$refs.rform.formData.lineas[indexLinea], "documentosConNombre", documentosConNombre);
      }
    },
    eliminarDocumentosConNombre: function(documentosConNombre, indexLinea, index) {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm("¿Está seguro?", "Eliminar documentos con nombre ("+documentosConNombre.nombre+")", function() {
        documentosConNombre = null;
        self.$refs.rform.formData.lineas[indexLinea].documentosConNombre.splice(index,1);
      });
    },
    crearDocumentosConNombre: function() {
      const self = this;
      const app = self.$f7;
      let indexLinea = self.indexLinea;
      Property.getList({filter: {nombre: 'lineapedido.documentosconnombre.nombres'} }).then(function(res) {
        // Recuperamos la lista de nombres de documentación a presentar.
        let documentosconnombrePredefinidos = JSON.parse(res.list[0].valor);
        // Vamos a mirar en todas las líneas si tienen los documentos con nombre predefinidos y los que falten se le agregarán.
        let linea = self.$refs.rform.formData.lineas[indexLinea];
        // Si no tiene documentos con nombre, se le crea el array.
        if(!linea.documentosConNombre) {
          self.$set(self.$refs.rform.formData.lineas[indexLinea], "documentosConNombre", {});
        }
        // Vamos a mirar si tiene todos los documentos con nombre predefinidos.
        documentosconnombrePredefinidos.forEach(function(documentoconnombrePredefinido) {
          // Si no tiene el documento con nombre predefinido, se le agrega.
          if(!linea.documentosConNombre[documentoconnombrePredefinido.codigo]) {
            if (!documentoconnombrePredefinido.documentos) {
              documentoconnombrePredefinido.documentos = [];
            }
            self.$set(linea.documentosConNombre, documentoconnombrePredefinido.codigo, documentoconnombrePredefinido);
          }
        });
        self.mostrarDocumentosConNombre = true;
      }).catch(function(error) { if (error.message) { app.dialog.alert(error.message); } else { app.dialog.alert(error); } });
      return self.mostrarDocumentosConNombre;
    },
    sourceItem: function() {
      var self = this;
      return function(query, render) {
        var results = [];
        let selfItem = self.$refs.item;
        for (var i = 0; i < selfItem.items.length; i++) {
          selfItem.items[i]['txtValue'] = selfItem.items[i]['codigo'] + ' - ' + selfItem.items[i]['nombre'];
          if (
            ( selfItem.items[i]['txtValue'].toLowerCase().indexOf(query.toLowerCase()) >= 0 )
          ) {
            results.push(selfItem.items[i]);
          }
        }
        render(results);
      };
    },
    calcularFiltroItem: function() {
      var self = this;
      //v-if="self.$refs.rformNew.formData.porCliente || $refs.rformNew.formData.direccionitem || $refs.rformNew.formData.identificador"
      let filtro = {filter: {}};
      if (self.$refs.rformNew.formData.porMateria) {
        filtro.filter['codigoMateria'] =  self.$refs.rformNew.formData.servicio.materia.codigo;
      }
      // Si nos dicen que filtre por cliente, lo añadimos.
      if (self.$refs.rformNew.formData.porCliente) {
        filtro.filter['codigoCliente'] = self.$refs.rform.formData.cliente.codigo;
      }
      // Si nos dicen que filtre por dirección, lo añadimos.
      if (self.$refs.rformNew.formData.porDireccion) {
        filtro.filter['cp'] = self.$refs.rformNew.formData.direccionInspeccion.cp;
      }
      // Si nos dicen que filtre por identificador, lo añadimos.
      if (self.$refs.rformNew.formData.identificador) {
        filtro.filter['codigo'] = self.$refs.rformNew.formData.identificador.replace('|', '\\|');
      }
      // self.$refs.rformNew.formData.item = null;
      return filtro;
    },
    onchangefilter: function(a, b, extraData) {
      if (!extraData || !extraData.fromMounted) {
        var self = this;
        if(self.$refs.item) self.$refs.item.setFilter(self.calcularFiltroItem());
      }
    },
    facturable: function(pedido) {
      var self = this;
      var app = self.$f7;
      let result = false;
      // registros de totales para comparar: Si alguna línea tiene importe total facturado con IVA menor que 
      // el total con IVA con tasas con descuento y con terceros, es facturable.
      let totalFacturadoConIVA = 0;
      let totalConIVAConTasasConDescuentoConTerceros = 0;
      if (pedido.lineas && pedido.numero) {
        pedido.lineas.forEach(linea => {
          totalFacturadoConIVA = linea.totalFacturadoConIVA?linea.totalFacturadoConIVA:0;
          totalConIVAConTasasConDescuentoConTerceros = linea.totalConIVAConTasasConDescuentoConTerceros?linea.totalConIVAConTasasConDescuentoConTerceros:0;
          if ((totalFacturadoConIVA + 1) < totalConIVAConTasasConDescuentoConTerceros) {
            result = true;
          } 
        });
      }
      console.log('facturable', result);
      return result;
    },
    facturar: function(quefacturar) {
      var self = this;
      var app = self.$f7;
      let lineasFacturar = [];
      let lineas = self.$refs.rform.formData.lineas;
      lineas.forEach((linea) => {
        let totalFacturadoConIVA = linea.totalFacturadoConIVA?linea.totalFacturadoConIVA:0;
        let totalConIVAConTasasConDescuentoConTerceros = linea.totalConIVAConTasasConDescuentoConTerceros?linea.totalConIVAConTasasConDescuentoConTerceros:0;
        if ((totalFacturadoConIVA + 1) < totalConIVAConTasasConDescuentoConTerceros) {
          lineasFacturar.push(    {
              "pedido": {"id": self.$refs.rform.formData.id},
              "numero": linea.numero,
              "tipoLinea": quefacturar
          });
        } 
      });
      // si tenemos algo qeu facturar, lo hacemos, si no, mostramos un mensaje.
      if (lineasFacturar.length == 0) {
        app.dialog.alert('No hay nada que facturar.');
      } else {
        // abrimos el popup de facturar
        self.$refs.facturar.openPopup(self.$refs.rform.formData);
      }
    },
    filasSeleccionadas: function() {
      const self = this;
      let lineasPedido = [];
      [self.$refs.rform.formData].map(p => {
        // desagregamos  las líneas para tener una sola línea dentro de cada pedido
        p.lineas.forEach(l => {
          let lineaDesagregada = {id: p.id};
          lineaDesagregada.numerosLinea = [l.numero];
          lineasPedido.push(lineaDesagregada);
        });
        
      });
      return lineasPedido;
    },
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
    self.idData = self.id;
    self.lineasBorrar = {idPedido: self.id, lineasNuevasPrimeras: [], lineasSegundas: []};
    CondicionPago.getList({filter:{activa:true}}).then(function(res){
      self.condicionesPagoActivas = res.list;
    });
    if(self.$refs.rform && self.$refs.rform.formData && !self.$refs.rform.formData.finanzas) {
      self.$set(self.$refs.rform.formData, "finanzas", {});
    }
    Combos.getComboById("formasCrearLinea").then(res => {
      self.formasCrearLinea = res.list.map(item => {
        return {
          name: item.nombre,
          value: item.valor
        };
      });
    });
  }
};
</script>
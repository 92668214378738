<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :title="tipoLinea == 'DIA' ? 'Resumen DIA' : tipoLinea == 'SEMANA' ? 'Resumen SEMANA' : 'Resumen MES'" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list 
        ref="rlist"
        :dbAdapter="ListadoCalendario"
        :tableCode="'LISTADO_CALENDARIO'"
        :tableMode="true"
        :virtualListParams="{ height: 32, rowsBefore: 60, rowsAfter: 60 }"
        class="card full-height data-table"
        classContent="card-content full-height"
        :massiveActions="false"
        :mandatoryFilter ="{tipoLinea: tipoLinea}"
        :filtersNoCounters="['tipoLinea']"
        :controlsWidth="60"
        @deleteItem="reload"
        :filterColumns="filterColumns"
      >
        <template v-slot:extendedActions>
          <f7-link v-roles="['ADMINISTRATIVO']"  v-tooltip="'Generar Resumen'" icon-f7="goforward" color="red" @click="popupCalcularResumen(tipoLinea)"></f7-link>
        </template>
        <template v-slot:optionsColumn="{ item }">
          <f7-link v-if="tipoLinea == 'MES'" v-tooltip="'Asignar consumo real'" icon-f7="bolt_fill" color="deeppurple" @click="asignaConsumoReal(item)"></f7-link>
          <f7-link v-tooltip="'Recalcular resumen de la línea'" icon-f7="goforward" color="orange" @click="calcular(item)"></f7-link>
        </template>
      </r-dynamic-list>
    </f7-page-content>
    <f7-popup class="popupcalcular" ref="popupcalcular" @popup:close="popupCalcularShow = false" swipeToClose :opened="true" v-if="popupCalcularShow">
      <r-form
        ref="formCalcular"
        @save="calcular"
        :defaultData="filter"
        :hidePreloaderOnSave="false"
      >
        <template v-slot="{rform}">
          <f7-page v-if="filter" >
            <f7-navbar>
              <f7-nav-left>
                <f7-link popup-close>Cerrar</f7-link>
              </f7-nav-left>
              <f7-nav-title>
                {{$t('listadoCalendario.calcularResumen') + ' ' + tipoLinea}}
              </f7-nav-title>
              <f7-nav-right></f7-nav-right>
            </f7-navbar>
            <f7-list no-hairlines-md>
              <f7-row class="list list-form no-hairlines">
                <f7-col width="66" medium="66">
                  <r-autocomplete
                    :dbAdapter="Empleado"
                    floating-label
                    outline
                    :label="$t('common.persona')"
                    :form="rform"
                    name="username"
                    idProp="username"
                    labelProp="nombre"
                    prefixProp="username"
                  ></r-autocomplete>
                </f7-col>
                <f7-col width="66" medium="66">
                  <r-input floating-label :label="$t('anio')" :form="rform" type="select" name="anio" :clearButton="false" outline>
                    <option v-for="i in 4" :value="filter.anio - 5 + i" :key="'anio-'+(filter.anio - 5 + i)">{{filter.anio - 5 + i}}</option>
                    <option :key="'anio-'+filter.anio" selected="true" :value="filter.anio">{{filter.anio}}</option>
                    <option :key="'anio-'+(filter.anio+1)" :value="filter.anio+1">{{filter.anio+1}}</option>
                  </r-input>
                </f7-col>
                <f7-col width="66" medium="66">
                  <r-input floating-label :label="$t('mes')" :form="rform" type="select" name="mes" :clearButton="false" outline>
                    <option></option>
                    <option v-for="m in Utils.meses()" :value="m.value" :key="'mes-'+m.value" :selected="m.value == filter.mes">{{m.text}}</option>
                  </r-input>
                </f7-col>
                <f7-col v-if="['SEMANA','DIA'].includes(tipoLinea)" width="66" medium="66">
                  <r-input floating-label :label="$t('semana')" :form="rform" type="number" name="semana" :clearButton="false" outline></r-input>
                </f7-col>
                <f7-col v-if="tipoLinea == 'DIA'" width="66" medium="66">
                  <r-input floating-label :label="$t('dia')" :form="rform" type="number" name="dia" :clearButton="false" outline></r-input>
                </f7-col>
              </f7-row>
            </f7-list>
            <f7-button
              fill
              color="primary"
              class="margin-horizontal"
              @click="$refs.formCalcular.saveItem()"
            >{{$t('listadoCalendario.calcularResumen')}}</f7-button>
          </f7-page>
        </template>
      </r-form>
    </f7-popup>
  </f7-page>
</template>
<style scoped>
.label-group {
  margin-top: 20px;
  margin-left: 20px;
  font-weight: bold;
}
</style>
<style>
.aurora .f7-icons,
.aurora .material-icons {
  font-size: 16px;
}
</style>
<script>
import rDynamicList from "../../components/rDynamicList.vue";
import ListadoCalendario from "../../js/db/ListadoCalendario.js";
import Empleado from "../../js/db/Empleado.js";
import Utils from "../../js/Utils.js";
import DateUtils from "../../js/DateUtils.js";
import { EventBus } from "../../js/EventBus.js";
import rInput from "./../../components/rInput.vue";
import rForm from "./../../components/rForm.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";

export default {
  components: {
    rDynamicList,
    rInput,
    rForm,
    rAutocomplete
  },
  data() {
    return {
      ListadoCalendario,
      Empleado,
      codigoListadoCalendario: null,
      Utils,
      DateUtils,
      popupCalcularShow: false,
      filter: null
    };
  },
  props: {
    tipoLinea: {
      type: String
    }
  },
  computed: {},
  methods: {
    filterColumns: function (columns) {
      const self = this;
      if (self.tipoLinea == 'DIA') {
        columns = columns.filter(column => {
          return column.pathName != 'totalConsumoReal';
        });
      } else if (self.tipoLinea == 'SEMANA') {
        columns = columns.filter(column => {
          return column.pathName != 'dia' && column.pathName != 'totalConsumoReal';
        });
      } else if(self.tipoLinea == 'MES') {
        columns = columns.filter(column => {
          return column.pathName != 'dia' && column.pathName != 'semana';
        });
      }
      return columns;
    },
    filasSeleccionadas: function () {
      let filas = [...Object.values(this.$refs.rlist.selected)].map(selected => {
        return { id: selected.id };
      });
      return filas;
    },
    reload: function () {
      if (this.$refs.rlist) {
        this.$refs.rlist.reload();
      }
    },
    popupCalcularResumen: function (tipoLinea) {
      const self = this;
      const app = self.$f7;
      // creamos el filter con tipoLinea indicado, el camopo anio con el año actual
      // - si tipoLinea es DIA, seteamos también mes y dia
      // - si tipoLinea es SEMANA, seteamos solo anio y semana
      let fecha = new Date();
      self.filter = {
        tipoLinea: tipoLinea,
        anio: fecha.getFullYear(),
        mes: fecha.getMonth() + 1
      };
      self.popupCalcularShow = true;
      
    },
    calcular: function (item) {
      const self = this;
      const app = self.$f7;
      app.preloader.show();
      ListadoCalendario.calcular({data: item}).then(() => {
        if (self.$refs.popupcalcular) {
          self.$refs.popupcalcular.close();
        }
        self.reload();
        app.preloader.hide();
      });
    },
    asignaConsumoReal: function (item) {
      // mostramos un dialog.prompt para que el usuario introduzca el consumo real
      this.$f7.dialog.prompt('Introducir el consumo real', 'Consumo Real', (value) => {
        item.totalConsumoReal = value.replace(',', '.');
        ListadoCalendario.asignaConsumoReal({data: item}).then(() => {
          this.reload();
        });
      });
    }
  },
  mounted: function () {
    const self = this;
    EventBus.$on("listadocalendarioGuardado", self.reload);
  }
};
</script>
import DatabaseModel from './DatabaseModel.js';

var ListadoCalendario = Object.create(DatabaseModel);

ListadoCalendario.model = 'listadoCalendario';
ListadoCalendario.modelFront = 'listadoCalendarios';

ListadoCalendario.calcular = function (params) {
    var self = this;
    params = params || {};
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/calcular/',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function (res) {
        return res;
    });
};

ListadoCalendario.asignaConsumoReal = function (params) {
    var self = this;
    params = params || {};
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/asignaConsumoReal/',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function (res) {
        return res;
    });
};

export default ListadoCalendario;
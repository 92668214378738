<template>
<f7-page :page-content="true">
  <r-form
    ref="rform"
    class="page"
    classContent="page-content full-height"
    :itemId="idData"
    @save="onSave"
    @loadItem="loadItem"
    :readonly="readonly"
    :dbAdapter="Oferta"
    :documentoAdapter="Documento"
    :defaultData="{tipoOferta: 'ORDINARIA', textos: [], logistica: {}, lineas:[], viaPago: {}, finanzas: {}, fechaVencimiento:addDays(new Date(), 180),  fechaOferta: new Date(), contratacion: {codEmpleado: Utils.getUser().username} }"
    :beforeSave="beforeSave"
  >
    <!-- Top Navbar -->
    <template v-slot:before="{rform}">
      <f7-navbar :back-link="$t('atras')">
        <f7-nav-title v-if="idData && !readonly" sliding>{{$t('ofertas.editarOferta')}}</f7-nav-title>
        <f7-nav-title v-else-if="idData" sliding>{{$t('ofertas.oferta')}}</f7-nav-title>
        <f7-nav-title v-else sliding>{{$t('ofertas.nuevo')}}</f7-nav-title>
        <f7-nav-right>
          <f7-button
            v-if="!readonly"
            fill
            icon-f7="floppy_disk"
            text="Guardar"
            class="display-flex"
            @click="rform.saveItem()"
          ></f7-button>
          <template
            slot="right"
            v-if="idData && readonly"
          >
            <div class="menu-item menu-item-dropdown bg-color-primary">
              <div class="menu-item-content"><i class="f7-icons">ellipsis_vertical</i></div>
              <div class="menu-dropdown menu-dropdown-right bg-color-primary">
                <div class="menu-dropdown-content bg-color-primary text-align-right">
                  <div class="menu-dropdown-item menu-close pointer" v-if="readonly && rform.formData && rform.formData.estado && rform.formData.estado.estado == 'ABIERTA' && !hayAlertas()" @click="cambiarEstado('TERMINADA')">{{$t('ofertas.finalizar')}}</div>
                  <!-- <div class="menu-dropdown-item menu-close pointer" v-if="readonly && rform.formData && rform.formData.estado && rform.formData.estado.estado == 'APROBADA'" @click="cambiarEstado('ENVIADA')">{{$t('ofertas.enviar')}}</div> -->
                  <div class="menu-dropdown-item menu-close pointer" v-if="readonly && rform.formData && rform.formData.estado && rform.formData.estado.estado == 'APROBADA'" @click="$refs.popupEnvioManual.open()">{{$t('ofertas.enviadaManual')}}</div>
                  <div class="menu-dropdown-item menu-close pointer" v-if="readonly && rform.formData && rform.formData.estado && (rform.formData.estado.estado == 'TERMINADA' || rform.formData.estado.estado == 'ENVIADA' || rform.formData.estado.estado == 'APROBADA' || rform.formData.estado.estado == 'RECHAZADA')" @click="cambiarEstado('ABIERTA')">{{$t('ofertas.volverEditar')}}</div>
                  <div class="menu-dropdown-item menu-close pointer" v-if="readonly && rform.formData && rform.formData.estado && rform.formData.estado.estado == 'TERMINADA'" @click="aprobarOferta()">{{$t('ofertas.aprobar')}}</div>
                  <div class="menu-dropdown-item menu-close pointer" v-if="readonly && rform.formData && rform.formData.estado && rform.formData.estado.estado == 'ENVIADA' && rform.formData.formaEnvio != 'AUTOMATICA'" @click="popupAceptacionOpened = true">{{$t('ofertas.adjuntarAceptacion')}}</div>
                  <div class="menu-dropdown-item menu-close pointer" v-if="readonly && rform.formData && rform.formData.estado && rform.formData.estado.estado == 'ENVIADA' && rform.formData.formaEnvio == 'AUTOMATICA'" @click="comprobarFirmaCliente()">{{$t('ofertas.comprobarFirmaCliente')}}</div>
                  <div class="menu-dropdown-item menu-close pointer" v-if="readonly && rform.formData && rform.formData.estado && (rform.formData.estado.estado == 'ACEPTADA' || rform.formData.estado.estado == 'EN_PROCESO' || rform.formData.estado.estado == 'CERRADA')" @click="popupAceptacionOpened = true">{{$t('ofertas.verAceptacion')}}</div>
                  <div class="menu-dropdown-item menu-close pointer" v-if="readonly && hayLineasSinPedido()" @click="$refs.popupPedido.open()">{{$t('ofertas.crearPedido')}}</div>
                  <div class="menu-dropdown-item menu-close pointer" v-if="readonly && rform.formData && rform.formData.estado && rform.formData.tipoOferta && rform.formData.tipoOferta == 'MARCO' && (rform.formData.estado.estado == 'ACEPTADA' || rform.formData.estado.estado == 'EN_PROCESO' || rform.formData.estado.estado == 'CERRADA')" @click="popupExtenderOpened = true">{{$t('ofertas.extenderPlazo')}}</div>
                  <div class="menu-dropdown-item menu-close pointer" v-if="readonly && rform.formData && rform.formData.estado && rform.formData.estado.estado == 'ENVIADA'" @click="lanzarPopupRechazo()">{{$t('ofertas.popupRechazo.rechazar')}}</div>
                  <div class="menu-dropdown-item menu-close pointer" v-if="readonly && rform.formData && rform.formData.estado && rform.formData.estado.estado == 'RECHAZADA'" @click="lanzarPopupRechazo()">{{$t('ofertas.popupRechazo.indicarMotivoRechazo')}}</div>
                  <div class="menu-dropdown-item menu-close pointer" v-if="readonly && rform.formData && rform.formData.estado && !'|ENVIADA|RECHAZADA|CANCELADA|CERRADA|EN_PROCESO|'.includes('|'+rform.formData.estado.estado+'|') " @click="lanzarPopupCancelar()">{{$t('ofertas.popupCancelar.cancelar')}}</div>
                  <div class="menu-dropdown-item menu-close pointer" v-if="readonly && rform.formData && rform.formData.estado && rform.formData.estado.estado == 'CANCELADA'" @click="lanzarPopupCancelar()">{{$t('ofertas.popupCancelar.verMotivoCancelacion')}}</div>
                  <div class="menu-dropdown-item menu-close pointer" v-if="readonly" @click.prevent="enviarEmail = true">{{$t('Enviar e-mail')}}</div>
                  <div class="menu-dropdown-item menu-close pointer" v-if="readonly" @click.prevent="downloadDocumento(rform.formData)">{{$t('ofertas.descargar')}}</div>
                  <div class="menu-dropdown-divider" v-if="readonly && rform.formData && rform.formData.estado && rform.formData.estado.estado == 'ABIERTA'"></div>
                  <div class="menu-dropdown-item menu-close pointer" v-if="readonly && rform.formData && rform.formData.estado && (rform.formData.estado.estado == 'ABIERTA' || Utils.getUser().roles.indexOf('ADMIN') > -1)" @click="readonly=false">{{$t('ofertas.editar')}}</div>
                  <!-- <div class="menu-dropdown-item menu-close pointer text-color-red" v-if="readonly && rform.formData && rform.formData.estado && rform.formData.estado.estado == 'ABIERTA'" @click="deleteItem">{{$t('ofertas.eliminar')}}</div> -->
                </div>
              </div>
            </div>
          </template>
        </f7-nav-right>
      </f7-navbar>
    </template>
    <!-- Tabs -->
    <template v-slot:default="{rform}">
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
        <div name="Datos Generales">
          <f7-link
            @click="showDetalle = !showDetalle"
            :tooltip="showDetalle ? 'Ocultar Detalle' : 'Mostrar Detalle'"
            :icon-f7="showDetalle ?'chevron_compact_up' : 'chevron_compact_down'"
            :title="showDetalle ? 'Ocultar Detalle' : 'Mostrar Detalle'"
            style="padding-left: 7px;font-size: 10px;float: right;"
          ></f7-link>
        </div>
        <f7-card-content v-show="showDetalle">
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="100">
              <f7-row>
                <r-input
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('ofertas.tipo')"
                  :form="rform"
                  type="select"
                  name="tipoOferta"
                  key="tipoOferta"
                  outline
                >
                  <option selected value="ORDINARIA">{{$t('ofertas.ordinaria')}}</option>
                  <option value="MARCO">{{$t('ofertas.marco')}}</option>
                </r-input>
                <f7-button
                  v-noroles="['ENAC', 'PRODUCCION']"
                  icon-f7="calendar_circle"
                  @click="defaultOfertaData = { numeroOferta: $refs.rform.formData.numero, estado: 'REALIZADA', fechaFecha: new Date(), fechaHora: new Date() }; popupTareaEditable = true; popupTareasOpened = true"
                  v-if="id && $refs.rform && $refs.rform.formData && $refs.rform.formData.numero"
                >Crear tarea
                </f7-button>
                <f7-button
                  v-if="readonly"
                  class="col-20"
                  v-noroles="['ENAC', 'PRODUCCION']"
                  icon-f7="rectangle_stack_person_crop_fill"
                  @click="crearOportunidad"
                >Crear oportunidad
                </f7-button>
                <div class="label-group col-100">Datos Generales</div>
                <r-input
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('ofertas.numero')"
                  :form="rform"
                  type="text"
                  name="numero"
                  key="numero"
                  v-if="idData"
                  :readonly="true"
                ></r-input>
                <r-input
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('ofertas.codigo')"
                  :form="rform"
                  type="text"
                  name="codigo"
                  key="codigo"
                  v-if="idData"
                  :readonly="true"
                ></r-input>
                <r-autocomplete
                  ref="cliente"
                  key="cliente"
                  :dbAdapter="Cliente"
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('ofertas.cliente')"
                  :form="rform"
                  name="cliente"
                  labelProp="codigoNombre"
                  :minLength="3"
                  :shownFields="['razonSocial', 'condicionesPago', 'direcciones', 'direccionDefectoFacturas', 'direccionDefectoEnvio', 'condPago', 'viaPagoDefecto', 'listaPrecios', 'contactos', 'grupoCliente', 'empleadoVentas', 'responsable', 'emailsFacturas', 'emailsCertificados', 'cuenta', 'activo','codigo']"
                  @change="changeCliente"
                  :readonly="readonly"
                  :async="true"
                  :filter="{filter:{activo: true}}"
                >
                </r-autocomplete>
                <r-autocomplete
                  v-if="clienteSelected"
                  key="contactoCliente"
                  :ref="'contactoCliente'"
                  :dbAdapter="ContactoCliente"
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('ofertas.contactocliente')"
                  :form="rform"
                  :name="'contactoCliente'"
                  labelProp="alias"
                  infoText="Cree un contacto nuevo para este cliente"
                  :shownFields="['codContacto']"
                  @change="changeContacto"
                  :filter="clienteSelected ? {clienteId: rform.formData.cliente.id} : undefined"
                >
                  <f7-link
                    v-if="(!rform.formData.contactoCliente || !rform.formData.contactoCliente.alias) && !readonly"
                    slot="inner-end"
                    popup-open=".popup-contacto"
                    class="input-suffix-btn"
                    icon-f7="plus_app_fill"
                  ></f7-link>
                </r-autocomplete>

                <r-autocomplete
                  key="contactoClienteDummy"
                  v-else
                  :dbItems="[]"
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('ofertas.contactocliente')"
                  :form="rform"
                  :name="'contactoCliente'"
                  labelProp="nombre"
                  infoText="Seleccione un cliente para elegir un contacto"
                >
                </r-autocomplete>
                <r-input
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('ofertas.emailcontacto')"
                  :form="rform"
                  type="text"
                  name="emailContacto"
                  key="emailContacto"
                  @change="changeEmailContacto"
                  :validation="{isValid: validator.isEmail, errorMessage: '* Introduzca un correo electrónico válido'}"
                ></r-input>
                <r-input
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('ofertas.telefono')"
                  :form="rform"
                  type="text"
                  name="telefono"
                  key="telefono"
                ></r-input>
                <r-input
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('ofertas.telefonoMovil')"
                  :form="rform"
                  type="text"
                  name="telefonoMovil"
                  key="telefonoMovil"
                ></r-input>
                <r-autocomplete
                  :dbAdapter="Empleado"
                  key="empleado"
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('ofertas.empleado')"
                  :form="rform"
                  name="contratacion.codEmpleado"
                  labelProp="nombre"
                  idProp="username"
                  prefixProp="username"
                  :shownFields="['datos']"
                  idFieldDetail="id"
                  @change="changeEmpleadoContratacion"
                  @loaded="loadedEmpleadoContratacion"
                ></r-autocomplete>

                <r-autocomplete
                  :dbAdapter="CentroTrabajo"
                  key="centroTrabajo"
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('ofertas.centrotrabajo')"
                  :form="rform"
                  name="contratacion.codCentroTrabajo"
                  labelProp="nombre"
                  idProp="codigo"
                  prefixProp="codigo"
                  idFieldDetail="id"
                ></r-autocomplete>

                <r-input
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('ofertas.fechaOferta')"
                  :form="rform"
                  type="datepicker"
                  name="fechaOferta"
                  key="fechaOferta"
                  :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>
                <r-input
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('ofertas.fechaVencimiento')"
                  :form="rform"
                  type="datepicker"
                  name="fechaVencimiento"
                  key="fechaVencimiento"
                  :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>
                <r-input
                  v-if="(rform.formData && rform.formData.motivoRechazo && rform.formData.motivoRechazo != '') || (rform.formData && rform.formData.estado && rform.formData.estado.estado == 'RECHAZADA')"
                  class="col-100 medium-33"
                  :class="{'medium-33':idData, 'medium-33': !idData}"
                  floating-label
                  :label="$t('ofertas.motivoRechazo')"
                  :form="rform"
                  type="textarea"
                  name="motivoRechazo"
                  key="motivoRechazo"
                  resizable
                />
                <r-input
                  v-if="idData"
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('ofertas.estado')"
                  :form="rform"
                  type="badge"
                  name="estado.estado"
                  v-tooltip="(rform.formData && rform.formData.estado) ? (DateUtils.dateFormat(rform.formData.estado.fecha, 'DD/MM/YYYY HH:mm') + (rform.formData.estado.comentarios ? '<br/>' + rform.formData.estado.comentarios : '') + '<br/>' + rform.formData.estado.username) : ''"
                  :readonly="true"
                  :overrideReadonly="true"
                  :text="getTextoBadgeEstado(rform.formData)"
                  :color="getColorBadgeEstado(rform.formData)"
                  :decorationConfig="{
                    icon: 'rectangle_on_rectangle_angled',
                    color:'blue',
                    tooltip: 'Histórico de estados',
                    action: mostrarHistoricoEstados
                  }"
                ></r-input>
                <r-input
                  v-noroles="['ENAC', 'PRODUCCION']"
                  class="col-100 medium-66"
                  :class="{'medium-66':idData, 'medium-66': !idData}"
                  floating-label
                  :label="$t('ofertas.comentarios')"
                  :form="rform"
                  type="text"
                  name="comentariosOferta"
                  key="comentariosOferta"
                ></r-input>
                <r-input
                  class="col-100 medium-33"
                  :class="{'medium-33':idData, 'medium-33': !idData}"
                  floating-label
                  :label="$t('ofertas.presupuestoConIVA')"
                  :form="rform"
                  type="check"
                  name="presupuestoConIVA"
                  key="presupuestoConIVA"
                ></r-input>
                <r-input
                  class="col-100 medium-33"
                  :label="$t('ofertas.moneda')"
                  :form="rform"
                  type="select"
                  name="codMoneda"
                  key="codMoneda"
                  :hidden="true"
                >
                  <option selected value="EUR">Euro</option>
                </r-input>
                <r-autocomplete
                  v-if="rform.formData.oportunidad"
                  :dbAdapter="Campania"
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('campania.campania')"
                  :form="rform"
                  name="oportunidad.campania"
                  labelProp="codigoNombre"
                  :minLength="3"
                  :shownFields="['codigoNombre', 'codigo', 'nombre', 'estrategia', 'fechaInicial', 'fechaFinal', 'responsable']"
                  :async="true"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-autocomplete>
                <r-autocomplete
                  v-if="rform.formData.oportunidad"
                  :dbAdapter="Oportunidad"
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('oportunidad.oportunidad')"
                  :form="rform"
                  name="oportunidad"
                  labelProp="codigo"
                  :shownFields="['codigo', 'campania', 'gestor']"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-autocomplete>
                <r-autocomplete
                  v-if="rform.formData.origenDeOportunidad"
                  :dbAdapter="Campania"
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('Origen de campaña')"
                  :form="rform"
                  name="origenDeOportunidad.campania"
                  labelProp="codigoNombre"
                  :minLength="3"
                  :shownFields="['codigoNombre', 'codigo', 'nombre', 'estrategia', 'fechaInicial', 'fechaFinal', 'responsable']"
                  :async="true"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-autocomplete>
                <r-autocomplete
                  v-if="rform.formData.origenDeOportunidad"
                  :dbAdapter="Oportunidad"
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('Origen de oportunidad')"
                  :form="rform"
                  name="origenDeOportunidad"
                  labelProp="codigo"
                  :shownFields="['codigo', 'campania', 'gestor']"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-autocomplete>
              </f7-row>
            </f7-col>
            <f7-col width="100" medium="33">
              <f7-row no-gap>
                <div class="label-group col-100 text-color-red">{{$t('ofertas.contratacion')}}</div>
                <f7-list-input
                  class="col-100 medium-33 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('ofertas.subtotal')"
                  type="number"
                  step=".01"
                  key="ofertas.subtotal"
                  :value="getTotalesOferta(rform.formData ? rform.formData.lineas : undefined)['subtotal']"
                ></f7-list-input>
                <f7-list-input
                  class="col-100 medium-33 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('ofertas.totalIVA')"
                  type="number"
                  step=".01"
                  key="ofertas.totalIVA"
                  :value="getTotalesOferta(rform.formData ? rform.formData.lineas : undefined)['iva']"
                ></f7-list-input>
                <f7-list-input
                  class="col-100 medium-33 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('ofertas.total')"
                  key="ofertas.total"
                  type="number"
                  step=".01"
                  :value="getTotalesOferta(rform.formData ? rform.formData.lineas : undefined)['total']"
                ></f7-list-input>
              </f7-row>
            </f7-col>
            <f7-col width="100" medium="33">
              <f7-row no-gap>
                <div class="label-group col-100 text-color-red">{{$t('ofertas.terceros')}}</div>
                <f7-list-input
                  class="col-100 medium-33 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('ofertas.subtotal')"
                  type="number"
                  key="ofertas.subtotal.proveedor"
                  step=".01"
                  :value="getTotalesOferta(rform.formData ? rform.formData.lineas : undefined)['subtotalTerceros']"
                ></f7-list-input>
                <f7-list-input
                  class="col-100 medium-33 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('ofertas.totalIVA')"
                  type="number"
                  key="ofertas.totalIVA.proveedor"
                  step=".01"
                  :value="getTotalesOferta(rform.formData ? rform.formData.lineas : undefined)['ivaTerceros']"
                ></f7-list-input>
                <f7-list-input
                  class="col-100 medium-33 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('ofertas.total')"
                  key="ofertas.total.proveedor"
                  type="number"
                  step=".01"
                  :value="getTotalesOferta(rform.formData ? rform.formData.lineas : undefined)['totalTerceros']"
                ></f7-list-input>
              </f7-row>
            </f7-col>
            <f7-col width="100" medium="33">
              <f7-row no-gap>
                <div class="label-group col-100 text-color-red">{{$t('ofertas.totales')}}</div>
                <f7-list-input
                  class="col-100 medium-25 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('ofertas.subtotal')"
                  type="number"
                  key="ofertas.subtotal.con.proveedor"
                  step=".01"
                  :value="getTotalesOferta(rform.formData ? rform.formData.lineas : undefined)['subtotalConTerceros']"
                ></f7-list-input>
                <f7-list-input
                  class="col-100 medium-25 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('ofertas.totalTasas')"
                  type="number"
                  key="ofertas.totalTasas"
                  step=".01"
                  :value="getTotalesOferta(rform.formData ? rform.formData.lineas : undefined)['totalTasas']"
                ></f7-list-input>
                <f7-list-input
                  class="col-100 medium-25 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('ofertas.totalIVA')"
                  type="number"
                  key="ofertas.totalIVA.con.proveedor"
                  step=".01"
                  :value="getTotalesOferta(rform.formData ? rform.formData.lineas : undefined)['ivaConTerceros']"
                ></f7-list-input>
                <f7-list-input
                  class="col-100 medium-25 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('ofertas.total')"
                  type="number"
                  key="ofertas.total.con.proveedor"
                  step=".01"
                  :value="getTotalesOferta(rform.formData ? rform.formData.lineas : undefined)['totalConTasasConTerceros']"
                ></f7-list-input>
              </f7-row>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <div class="margin-horizontal">
        <f7-segmented raised class="elevation-6 width-100">
          <f7-button fill tab-link="#tab-lineas" tab-link-active>Líneas</f7-button>
          <f7-button fill tab-link="#tab-direcciones">Direcciones</f7-button>
          <f7-button fill tab-link="#tab-finanzas">Pago y Finanzas</f7-button>
          <f7-button fill tab-link="#tab-textos">Textos Personalizados</f7-button>
          <f7-button fill tab-link="#tab-documentos">Documentación</f7-button>
          <f7-button v-noroles="['ENAC', 'PRODUCCION']" v-if="$refs.rform && $refs.rform.formData && $refs.rform.formData.numero" fill tab-link="#tab-tareas">Tareas</f7-button>
          <f7-button fill tab-link="#tab-emails">e-Mails</f7-button>
        </f7-segmented>
      </div>
      <f7-tabs class="full-height" style="overflow: auto;">
        <f7-tab id="tab-lineas" tab-active>
          <!-- Líneas -->
          <f7-block-title  v-if="$refs.rform && $refs.rform.formData" style="z-index: 30">
            <div v-if="!rform.formData.cliente || !rform.formData.cliente.id">** {{$t('ofertas.elegir_cliente_lineas')}}</div>
            {{$t('ofertas.lineas')}}
            <f7-link popup-open=".popupnew" v-if="!rform.readonly && rform.formData && rform.formData.cliente && rform.formData.cliente.id" >{{$t('ofertas.add')}}</f7-link>
          </f7-block-title>

          <div v-if="rform && rform.formData" style="z-index: 30" class="accordion-list">
            <ul class="padding-horizontal">
              <li
                class="accordion-item row margin-vertical-half border-3"
                :class="{'border-color-green' : item.pedido && item.pedido.id, 
                         'border-color-orange' : (!item.pedido || !item.pedido.id) && lineasPlanificable[index] && item.tipoLinea != 'SUBCONTRATACION' && item.tipoLinea != 'COMISION' && item.tipoLinea != 'TASAS' , 
                         'border-color-red' : (!item.pedido || !item.pedido.id) && !lineasPlanificable[index], 
                         'border-color-blue' : item.tipoLinea != 'LINEA'}"
                v-for="(item,index) in rform.formData.lineas"
                :key="'linea-'+index"
              >
                <div class="item-inner col-100">
                  <f7-card class="no-margin">
                    <div 
                      class="col-100 medium-25 item-content item-input item-input-with-value over-card"
                      >
                      <r-badge  :color="getColorBadge(item, lineasPlanificable[index])" :text="getTextoBadge(item, lineasPlanificable[index])"></r-badge>

                    </div>
                    <f7-list class="list-form">
                      <ul>
                        <f7-row no-gap class="padding-vertical-half first-col">
                          <f7-list-input
                            class="col-100 medium-5"
                            floating-label
                            :label="$t('ofertas.num')"
                            :value="item.numero"
                            v-tooltip="$t('ofertas.num')+'<br/>'+item.numero"
                            autocomplete="off"
                            :readonly="true"
                          >
                          </f7-list-input>
                          <f7-list-input
                            v-if="!rform.formData.tipoOferta || rform.formData.tipoOferta == 'ORDINARIA'"
                            class="col-100 medium-20"
                            floating-label
                            :label="$t('ofertas.item')"
                            :value="item.item ? item.item.nombre : ''"
                            v-tooltip="$t('ofertas.item')+'<br/>'+ (item.item ? item.item.nombre : '')"
                            autocomplete="off"
                            :readonly="true"
                          >
                          </f7-list-input>
                          <f7-list-input
                            class="col-100 medium-20"
                            floating-label
                            :label="$t('ofertas.servicio')"
                            :value="item.servicio ? item.servicio.nombre : ''"
                            v-tooltip="$t('ofertas.servicio')+'<br/>'+ (item.servicio ? item.servicio.nombre : '')"
                            autocomplete="off"
                            :readonly="true"
                          >
                          </f7-list-input>
                          <f7-list-input
                            class="col-100 medium-5"
                            floating-label
                            :label="$t('ofertas.cantidad')"
                            :value="item.cantidad"
                            v-tooltip="$t('ofertas.cantidad')+'<br/>'+item.cantidad"
                            autocomplete="off"
                            :readonly="true"
                          >
                          </f7-list-input>
                          <f7-list-input
                            v-if="rform.formData.tipoOferta && rform.formData.tipoOferta == 'MARCO'"
                            class="col-100 medium-10 text-color-red"
                            :readonly="true"
                            floating-label
                            :label="$t('ofertas.precioUnitario')"
                            type="number"
                            step=".01"
                            v-tooltip="$t('ofertas.precioUnitario')+'<br/>'+totalesLineas[index]['totalUnitario']"
                            :value="totalesLineas[index]['totalUnitario']"
                          ></f7-list-input>
                          <f7-list-input
                            class="col-100 medium-10 text-color-red"
                            :readonly="true"
                            floating-label
                            :label="$t('ofertas.contratacion')"
                            type="number"
                            step=".01"
                            v-tooltip="$t('ofertas.contratacion')+'<br/>'+totalesLineas[index]['subtotal']"
                            :value="totalesLineas[index]['subtotal']"
                          ></f7-list-input>
                          <f7-list-input
                            class="col-100 medium-10 text-color-red"
                            :readonly="true"
                            floating-label
                            :label="$t('ofertas.terceros')"
                            type="number"
                            step=".01"
                            v-tooltip="$t('ofertas.terceros')+'<br/>'+totalesLineas[index]['subtotalTerceros']"
                            :value="totalesLineas[index]['subtotalTerceros']"
                          ></f7-list-input>
                          <f7-list-input
                            class="col-100 medium-10 text-color-red"
                            :readonly="true"
                            floating-label
                            :label="$t('ofertas.totalTasas')"
                            type="number"
                            step=".01"
                            v-tooltip="$t('ofertas.totalTasas')+'<br/>'+totalesLineas[index]['totalTasas']"
                            :value="totalesLineas[index]['totalTasas']"
                          ></f7-list-input>
                          <f7-list-input
                            class="col-100 medium-10 text-color-red"
                            :readonly="true"
                            floating-label
                            :label="$t('ofertas.totalIVA')"
                            type="number"
                            step=".01"
                            v-tooltip="$t('ofertas.totalIVA')+'<br/>'+totalesLineas[index]['ivaConTerceros']"
                            :value="totalesLineas[index]['ivaConTerceros']"
                          ></f7-list-input>
                          <f7-list-input
                            class="col-100 medium-10 text-color-red"
                            :readonly="true"
                            floating-label
                            :label="$t('ofertas.subtotal')"
                            type="number"
                            step=".01"
                            v-tooltip="$t('ofertas.subtotal')+'<br/>'+totalesLineas[index]['totalSinTasasConTercerosSinIVa']"
                            :value="totalesLineas[index]['totalSinTasasConTercerosSinIVa']"
                          ></f7-list-input>
                        </f7-row>
                      </ul>
                    </f7-list>
                    <f7-link
                      v-tooltip="'Ver Pedido'"
                      v-if="item.pedido && item.pedido.id"
                      class="margin-half link-inline link-inline-0"
                      icon-f7="envelope_open_fill"
                      color="green"
                      :href="'/pedidos/view/' + item.pedido.id + '/'"
                    ></f7-link>
                    <f7-link
                      v-tooltip="'Ver Pedidos'"
                      v-if="item.pedidosMarco && item.pedidosMarco.length"
                      class="margin-half link-inline link-inline-0"
                      icon-f7="envelope_open_fill"
                      color="green"
                      @click="Array.isArray($refs.popoverpedidos) ? $refs.popoverpedidos[index].open($event.target) : $refs.popoverpedidos.open($event.target)"
                    ></f7-link>
                    <f7-popover ref="popoverpedidos" >
                      <f7-list class="limit-height">
                        <f7-list-input
                          v-for="(ped,idxPed) in item.pedidosMarco"
                          :key="'pedidoMarco-' + index + '-pedido-' + idxPed"
                          type="text"
                          placeholder="Pedido"
                          :readonly="true"
                          class="readonly"
                          :value="ped.codigo"
                        >
                          <slot name="media" slot="media">
                            <f7-link
                              class="margin-half link-inline link-inline-0"
                              icon-f7="arrow_up_right_square"
                              color="green"
                              external
                              :href="'/#!/pedidos/view/' + ped.id + '/'"
                            ></f7-link>
                          </slot>
                        </f7-list-input>
                      </f7-list>
                    </f7-popover>
                    <f7-link
                      v-tooltip="'Borrar Línea'"
                      v-if="!rform.readonly && rform.formData && item.estado && item.estado.estado == 'CREADA_OFERTA'"
                      @click="$delete(rform.formData.lineas,index);moverTextosAOferta();"
                      class="margin-half link-inline link-inline-0"
                      icon-f7="trash"
                    ></f7-link>
                    <f7-link
                      v-tooltip="'Duplicar Línea'"
                      v-if="!rform.readonly"
                      @click.prevent="rform.formData.lineas.push(
                        JSON.parse(JSON.stringify( Object.assign({}, rform.formData.lineas[index], {numero: getMaxNumeroLineas($refs.rform.formData.lineas), estado: {estado: 'CREADA_OFERTA'}, pedido: null, pedidosMarco: null, proveedor: Object.assign({}, rform.formData.lineas[index].proveedor)}))));moverTextosAOferta();"
                      class="margin-half link-inline link-inline-1"
                      icon-f7="plus_square_fill_on_square_fill"
                    ></f7-link>
                    <f7-link
                      v-tooltip="'Editar Línea'"
                      v-if="!rform.readonly && rform.formData && item.estado && item.estado.estado == 'CREADA_OFERTA'"
                      @click="$set($refs.rformNew, 'formData', item); codigoServicioLineaEditando = item.servicio ? item.servicio.codigo : null;"
                      class="margin-half link-inline link-inline-2"
                      icon-f7="pencil_circle_fill"
                      popup-open=".popupnew"
                    ></f7-link>
                    <f7-link
                      v-else
                      v-tooltip="'Ver Línea'"
                      @click="$set($refs.rformNew, 'formData', item)"
                      class="margin-half link-inline"
                      :class="{'link-inline-2': !rform.readonly, 'link-inline-1': rform.readonly}"
                      icon-f7="info_circle_fill"
                      popup-open=".popupnew"
                    ></f7-link>
                    <f7-link
                      v-if="!rform.readonly"
                      v-tooltip="'Editar textos'"
                      @click="$set($refs.rformTextos, 'formData', item );"
                      class="margin-half link-inline link-inline-3"
                      icon-f7="t_bubble_fill"
                      popup-open=".popuptextos"
                    ></f7-link>
                    <f7-link
                      v-if="rform.readonly"
                      v-tooltip="'Ver textos'"
                      @click="$set($refs.rformTextos, 'formData', item );"
                      class="margin-half link-inline link-inline-2"
                      icon-f7="t_bubble_fill"
                      popup-open=".popuptextos"
                    ></f7-link>
                  </f7-card>
              </div>
              <template  v-if="item.alertaItem && !item.ignoreAlertaItem">
                <div class="item-inner col-100">
                  <f7-card class="no-margin linea-texto linea-item-error">
                      ALERTA!!! : {{item.alertaItem}}
                      <f7-link
                        v-if="rform.readonly"
                        v-roles="['ADMIN', 'ENAC', 'PRODUCCION','DTYCAL']" 
                        class="link-inline-alerta"
                        v-tooltip="'Borrar Alerta'"
                        @click="$set(item,'ignoreAlertaItem',true);rform.saveItem();"
                        icon-f7="trash"
                      ></f7-link>
                  </f7-card>
                </div>
              </template>
              <template v-if="item.comentarios || item.comentariosLineaCerrada || getComentariosDireccion(item)">
                <div v-if ="getComentariosDireccion(item)" v-noroles="['ENAC', 'PRODUCCION']" class="item-inner col-100">
                  <f7-card class="no-margin linea-texto linea-comentario-direccion">
                      {{getComentariosDireccion(item)}}
                  </f7-card>
                </div>
                <div v-noroles="['ENAC', 'PRODUCCION']" class="item-inner col-100" 
                  v-for="(texto,index2) in getLineasTexto(item.comentarios)"
                  :key="'linea-'+index+'texto'+index2">
                  <f7-card class="no-margin linea-texto">
                      {{texto}}
                  </f7-card>
                </div>
                <div v-noroles="['ENAC', 'PRODUCCION']" class="item-inner col-100" 
                  v-for="(texto,index2) in getLineasTexto(item.comentariosLineaCerrada)"
                  :key="'linea-'+index+'texto'+index2">
                  <f7-card class="no-margin linea-texto linea-comentario-cerrada">
                      {{texto}}
                  </f7-card>
                </div>
              </template>
              <!-- </a> -->
              </li>
            </ul>
          </div>
          <!-- Líneas -->
          <f7-block-title  v-if="$refs.rform && $refs.rform.formData" style="z-index: 30">
            {{$t('ofertas.lineas')}}
            <f7-link popup-open=".popupnew" v-if="!rform.readonly && rform.formData && rform.formData.cliente && rform.formData.cliente.id" >{{$t('ofertas.add')}}</f7-link>
          </f7-block-title>
        </f7-tab>
        <f7-tab id="tab-direcciones">
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <f7-list class="list-form">
                <f7-row>
                  <div class="label-group col-100">Dirección Envío</div>
                  <r-input
                    class="col-100"
                    outline
                    floating-label
                    :label="'Alias'"
                    :form="rform"
                    type="text"
                    :readonly="true"
                    :overrideReadonly="true"
                    :clearButton="false"
                    :name="'logistica.direccionEnvio.alias'"
                  >
                  </r-input>
                  <r-direccion :form="rform" :name="'logistica.direccionEnvio'"></r-direccion>
                  <div class="label-group col-100">Dirección Facturación</div>
                  <r-input
                    class="col-100"
                    outline
                    floating-label
                    :label="'Alias'"
                    :form="rform"
                    type="text"
                    :readonly="true"
                    :overrideReadonly="true"
                    :clearButton="false"
                    :name="'logistica.direccionFacturacion.alias'"
                  >
                  </r-input>
                  <r-direccion :form="rform" :name="'logistica.direccionFacturacion'"></r-direccion>
                  <f7-block-title class="col-100">
                    Emails Envío Facturas
                    <f7-link v-if="!readonly" @click="addEmail('emailsFacturas')">{{$t('ofertas.add')}}</f7-link>
                  </f7-block-title>
                  <div class="form-caja margin-horizontal" v-if="rform.formData.cliente">
                    <div
                      class="col-100"
                      v-for="(email, index) in rform.formData.cliente.emailsFacturas"
                      :key="'emailFactura-' + index"
                    >
                      <r-input
                        class="col-100"
                        outline
                        floating-label
                        :label="'Correo electrónico'"
                        :form="rform"
                        type="text"
                        :clearButton="false"
                        :placeholder="'Introduzca un correo electrónico válido'"
                        :name="'cliente.emailsFacturas.' + index"
                        :validation="{isValid: validator.isEmail, errorMessage: '* Introduzca un correo electrónico válido'}"
                      >
                        <f7-link
                          v-if="!readonly"
                          v-tooltip="'Borrar'"
                          slot="inner-end"
                          @click="$delete(rform.formData.cliente.emailsFacturas, index);"
                          class="input-suffix-btn"
                          icon-f7="trash"
                          style="top: initial;right: 15px;"
                        ></f7-link>
                      </r-input>
                    </div>
                  </div>
                  <f7-block-title class="col-100">
                    Emails Envío Informes
                    <f7-link v-if="!readonly" @click="addEmail('emailsCertificados')">{{$t('ofertas.add')}}</f7-link>
                  </f7-block-title>
                  <div class="form-caja margin-horizontal" v-if="rform.formData.cliente">
                    <div
                      class="col-100"
                      v-for="(email, index) in rform.formData.cliente.emailsCertificados"
                      :key="'emailCertificado-' + index"
                    >
                      <r-input
                        class="col-100"
                        outline
                        floating-label
                        :label="'Correo electrónico'"
                        :form="rform"
                        type="text"
                        :clearButton="false"
                        :placeholder="'Introduzca un correo electrónico válido'"
                        :name="'cliente.emailsCertificados.' + index"
                        :validation="{isValid: validator.isEmail, errorMessage: '* Introduzca un correo electrónico válido'}"
                      >
                        <f7-link
                          v-if="!readonly"
                          v-tooltip="'Borrar'"
                          slot="inner-end"
                          @click="$delete(rform.formData.cliente.emailsCertificados, index);"
                          class="input-suffix-btn"
                          icon-f7="trash"
                          style="top: initial;right: 15px;"
                        ></f7-link>
                      </r-input>
                    </div>
                  </div>
                </f7-row>
              </f7-list>
            </f7-card-content>
          </f7-card>
        </f7-tab>
        <f7-tab id="tab-finanzas" v-if="rform.formData && rform.formData.finanzas">
          <!-- Pago y Finanzas -->
          <!-- <f7-block-title  v-if="$refs.rform && $refs.rform.formData" style="z-index: 30">
                {{$t('ofertas.f')}}
                <f7-link v-if="!rform.readonly && rform.formData" @click="rform.formData.contactos.push({activo:true})">{{$t('ofertas.add')}}</f7-link>
          </f7-block-title>-->
          <f7-block-title v-if="!rform.formData.cliente || !rform.formData.cliente.id" style="z-index: 30">
            <div >** {{$t('ofertas.elegir_cliente_condiciones_pago')}}</div>
          </f7-block-title>

          <tab-finanzas
            ref="tabFinanzas"
            :readonly="readonly"
            :rform="rform" 
          ></tab-finanzas>

          <!-- <f7-card v-else class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <f7-list class="list-form">
                <ul>
                  <f7-row>
                    <r-autocomplete
                      :dbAdapter="ViaPago"
                      class="col-100 medium-33"
                      floating-label
                      :label="$t('ofertas.viaPago')"
                      :form="rform"
                      :source="filterViasPago"
                      @change="gestionarCambioViaPago(rform.formData, $event)"
                      name="viaPago.codigo"
                      labelProp="nombre"
                      idProp="codigo"
                      idFieldDetail="id"
                    ></r-autocomplete>
                    <r-autocomplete
                      :dbAdapter="CondicionPago"
                      class="col-100 medium-33"
                      floating-label
                      :label="$t('ofertas.cuenta')"
                      :form="rform"
                      :source="filterCondicionesCuentas"
                      @change="gestionarCambioDatosPago(rform.formData, $event)"
                      :readonly="viaPagoSeleccionadaReadonly"
                      :shown-fields="['codigo', 'cuenta', 'plazoDias', 'viaPago.codigo']"
                      name="cuenta"
                      labelProp="cuenta"
                      idProp="cuenta"
                      idFieldDetail="id"
                      :showNavigationLink="false"
                      :disabled="rform.formData && rform.formData.viaPago && rform.formData.viaPago.codigo == '8'"
                    ></r-autocomplete>
                    <r-autocomplete
                      :dbAdapter="CondicionPago"
                      class="col-100 medium-33"
                      floating-label
                      :label="$t('ofertas.plazoDias')"
                      :form="rform"
                      :source="filterCondicionesPlazos"
                      @change="gestionarCambioDatosPago(rform.formData, $event)"
                      :readonly="viaPagoSeleccionadaReadonly"
                      :shown-fields="['codigo', 'cuenta', 'plazoDias', 'viaPago.codigo']"
                      name="plazoDias"
                      labelProp="plazoDias"
                      idProp="plazoDias"
                      idFieldDetail="id"
                      :showNavigationLink="false"
                    ></r-autocomplete>
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
          </f7-card> -->
        </f7-tab>
        <f7-tab id="tab-textos">
          <!-- Textos -->
          <f7-block-title  v-if="rform && rform.formData">
            {{$t('ofertas.textos')}}
            <f7-link v-if="!rform.readonly && rform.formData" @click="rform.formData.textos.push({})">{{$t('ofertas.add')}}</f7-link>
          </f7-block-title>
          <div v-if="rform && rform.formData" style="z-index: 30">

            <Container 
              @drop="onDrop(rform.formData.textos, $event)"
              drag-handle-selector=".column-drag-handle"
              lock-axis="y"
            >
              <Draggable
                v-for="(item,index) in rform.formData.textos"
                :key="'texto-'+index"
              >
                  <f7-card class="elevation-3 padding-vertical-half padding-right " :class="{'border-3 border-color-orange' : (item.camposPersonalizados && item.camposPersonalizados.movidosAOferta)}">
                    <f7-card-content>
                      <f7-list class="list-form">
                        <ul>
                          <f7-row no-gap>
                            <span 
                              class="column-drag-handle margin-left cursor-move col-5"
                              v-tooltip="$t('ofertas.titulo')"
                            >&uarr;&darr;</span>
                            <r-input 
                              class="col-15" 
                              :label="$t('textos.forzarPaginaNueva')" 
                              :form="rform" 
                              type="check"
                              :name="'textos.' + index + '.propiedades.forzarPaginaNueva'"
                            ></r-input>
                            <r-input 
                              class="col-15" 
                              :label="$t('textos.alInicio')" 
                              :form="rform" 
                              type="check"
                              :name="'textos.' + index + '.propiedades.alInicio'"
                            ></r-input>
                            <r-input
                              class="col-100"
                              floating-label
                              :label="$t('ofertas.titulo')"
                              :form="rform"
                              :name="'textos.' + index + '.nombre'"
                            ></r-input>
                            <r-rich-text-input
                              :class="'col-100'"
                              floating-label
                              :label="$t('ofertas.html')"
                              :form="rform"
                              :name="'textos.' + index + '.html'"
                            ></r-rich-text-input>
                          </f7-row>
                        </ul>
                      </f7-list>
                    </f7-card-content>
                    <f7-link
                      v-if="!rform.readonly && rform.formData"
                      @click="$delete(rform.formData.textos,index);moverTextosAOferta();"
                      class="margin-half"
                      style="position:absolute;right:0;top:0;"
                      icon-f7="trash"
                    ></f7-link>
                  </f7-card>
              </Draggable>
            </Container>
          </div>
          <f7-block-title  v-if="rform && rform.formData">
            {{$t('ofertas.textos')}}
            <f7-link v-if="!rform.readonly && rform.formData" @click="rform.formData.textos.push({})">{{$t('ofertas.add')}}</f7-link>
          </f7-block-title>
          <!-- Fin de tab de textos -->
        </f7-tab>
        <f7-tab id="tab-documentos">
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <div v-if="!rform.formData.documentos || (rform.formData.documentos && !rform.formData.documentos.length > 0)">La oferta no tiene documentos</div>
              <f7-list class="list-form">
                <ul>
                  <r-files 
                    :form="rform" 
                    name="documentos"
                    :documentoAdapter="Documento"
                  ></r-files>
                </ul>
              </f7-list>
            </f7-card-content>
          </f7-card>
        </f7-tab>
        <f7-tab id="tab-tareas" v-noroles="['ENAC', 'PRODUCCION']" v-if="$refs.rform && $refs.rform.formData && $refs.rform.formData.numero">
          <r-dynamic-list
            ref="rlist"
            :primary="['id']"
            :dbAdapter="Tarea"
            :tableCode="'TAREASOFERTA'"
            :tableMode="true"
            :virtualListParams="{ height: 32, rowsBefore: 60, rowsAfter: 60 }"
            class="card full-height data-table"
            classContent="card-content full-height"
            :controlsWidth="100"
            :searchOnInit="true"
            :shownFields="['numeroOferta']"
            :mandatoryFilter ="{ 'numeroOferta': $refs.rform.formData.numero }"
            :funcShowCheck="_ => { return false; }"
            :massiveActions="false"
          >
            <template v-slot:optionsColumn="{ item }">
                <f7-link icon-f7="eye" color="green" @click="popupTareaEditable = false; tareaId = item.id; popupTareasOpened = true"></f7-link>
                <f7-link icon-f7="square_pencil" color="blue" @click="popupTareaEditable = true; tareaId = item.id; popupTareasOpened = true"></f7-link>
                <f7-link icon-f7="trash" color="red" @click="$refs.rlist.deleteItem(item.id)"></f7-link>
            </template>
          </r-dynamic-list>
        </f7-tab>
        <f7-tab id="tab-emails" v-if="$refs.rform && $refs.rform.formData && $refs.rform.formData.codigo">
          <r-dynamic-list
            ref="rlist"
            :primary="['id']"
            :dbAdapter="RegistroEmail"
            :tableCode="'REGISTROEMAILTABS'"
            :tableMode="true"
            :virtualListParams="{ height: 32, rowsBefore: 60, rowsAfter: 60 }"
            class="card full-height data-table"
            classContent="card-content full-height"
            :controlsWidth="50"
            :searchOnInit="true"
            :shownFields="['to','datos']"
            :mandatoryFilter ="{ 'datos##codigoOferta': $refs.rform.formData.codigo }"
            :funcShowCheck="_ => { return false; }"
            :massiveActions="false"
            useView="vista_RegistroEmails"
          >
            <template v-slot:optionsColumn="{ item }">
                <f7-link icon-f7="eye" color="green" @click="popupEmailEditable = false; emailId = item.id; popupEmailOpened = true"></f7-link>
            </template>
          </r-dynamic-list>
        </f7-tab>
      </f7-tabs>
      <f7-popup ref="popupPedido" class="popup-pedido" swipe-to-close tablet-fullscreen @popup:open="onPopupPedidoOpen">
        <f7-page>
          <f7-navbar title="Creación de Pedido">
            <f7-nav-right>
              <f7-link popup-close>Cerrar</f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-block-title>
            Seleccione las líneas a pasar al Pedido
          </f7-block-title>
          <div class="item-content item-input item-input-with-value margin">
            <div class="item-inner">
              <div class="item-title item-label item-floating-label">Seleccionar / Deseleccionar todas:</div>
              <div class="item-input-wrap">
                <f7-toggle 
                  class="item-input-wrap"
                  @change="selectAllPopup($event.target.checked, rform.formData.lineas)"
                ></f7-toggle>
              </div>
            </div>
          </div>
          <div
            v-for="(item,index) in rform.formData.lineas"
            :key="'linea-'+index"
          >
            <f7-card 
              v-if="item.estado && item.estado.estado == 'CREADA_OFERTA' && lineasPlanificable[index]" 
              class="elevation-3 padding-vertical-half padding-right"
              :style="'z-index:'+(rform.formData.lineas.length - index)"
            >
              <f7-card-content>
                <f7-list class="list-form">
                  <ul>
                    <f7-row no-gap>
                      <r-input
                        class="col-100 medium-5"
                        :label="$t('ofertas.seleccionar')"
                        :form="rform"
                        type="check"
                        :readonly="false"
                        :overrideReadonly="true"
                        :name="'lineas.' + index + '.seleccionada'"
                      ></r-input>
                      <r-input
                        outline
                        floating-label
                        class="col-100 medium-10"
                        :label="$t('ofertas.cantidadCrear')"
                        :form="rform"
                        type="number"
                        step="1"
                        min="1"
                        :max="item.cantidad"
                        :readonly="false"
                        :overrideReadonly="true"
                        :name="'lineas.' + index + '.cantidadCrear'"
                        v-tooltip="$t('ofertas.cantidadCrear')+'<br/>'+ (item.cantidadCrear ? item.cantidadCrear : '0')"
                      ></r-input>
                      <f7-list-input
                        class="col-100 medium-5"
                        floating-label
                        :label="$t('ofertas.num')"
                        :value="item.numero"
                        autocomplete="off"
                        :readonly="true"
                        v-tooltip="$t('ofertas.num')+'<br/>'+item.numero"
                      >
                      </f7-list-input>
                      <f7-list-input
                        class="col-100 medium-20"
                        floating-label
                        :label="$t('ofertas.item')"
                        :value="item.item ? item.item.nombre : ''"
                        autocomplete="off"
                        :readonly="true"
                        v-tooltip="$t('ofertas.item') + '<br/>' + (item.item ? item.item.nombre : '')"
                      >
                      </f7-list-input>
                      <f7-list-input
                        class="col-100 medium-25"
                        floating-label
                        :label="$t('ofertas.servicio')"
                        :value="item.servicio ? (item.servicio.userText || (item.servicio.codigo + ' - ' + item.servicio.nombre)) : ''"
                        autocomplete="off"
                        :readonly="true"
                        v-tooltip="$t('ofertas.servicio')+'<br/>' + (item.servicio ? (item.servicio.userText || (item.servicio.codigo + ' - ' + item.servicio.nombre)) : '')"
                      >
                      </f7-list-input>
                      <f7-list-input
                        v-if="!rform.formData.tipoOferta || rform.formData.tipoOferta == 'ORDINARIA'"
                        class="col-100 medium-5"
                        floating-label
                        :label="$t('ofertas.cantidad')"
                        :value="item.cantidad"
                        autocomplete="off"
                        :readonly="true"
                        v-tooltip="$t('ofertas.cantidad')+'<br/>' + item.cantidad"
                      >
                      </f7-list-input>
                      <f7-list-input
                        class="col-100 medium-10 text-color-red"
                        :readonly="true"
                        floating-label
                        :label="$t('ofertas.linea')"
                        type="number"
                        step=".01"
                        :value="totalesLineas[index]['total']"
                        v-tooltip="$t('ofertas.linea')+'<br/>' + totalesLineas[index]['total']"
                      ></f7-list-input>
                      <f7-list-input
                        class="col-100 medium-10 text-color-red"
                        :readonly="true"
                        floating-label
                        :label="$t('ofertas.terceros')"
                        type="number"
                        step=".01"
                        :value="totalesLineas[index]['totalTerceros']"
                        v-tooltip="$t('ofertas.terceros')+'<br/>' + totalesLineas[index]['totalTerceros']"
                      ></f7-list-input>
                      <f7-list-input
                        class="col-100 medium-10 text-color-red"
                        :readonly="true"
                        floating-label
                        :label="$t('ofertas.total')"
                        type="number"
                        step=".01"
                        :value="totalesLineas[index]['totalConTerceros']"
                        v-tooltip="$t('ofertas.total')+'<br/>' + totalesLineas[index]['totalConTerceros']"
                      ></f7-list-input>
                    </f7-row>
                  </ul>
                </f7-list>
              </f7-card-content>
            </f7-card>
          </div>
          <f7-row>
            <f7-col width="100" medium="100">
              <f7-button
                fill
                icon-f7="save"
                text="Crear Pedido"
                class="display-flex margin-half"
                color="green"
                @click="crearPedido(rform.formData.lineas)"
              ></f7-button>
            </f7-col>
          </f7-row>
          <!-- </div> -->
        </f7-page>
      </f7-popup>
      <f7-popup ref="popupTarea" swipe-to-close :opened="true" v-if="popupTareasOpened" @popup:closed="popupTareasOpened = false; tareaId = null">
        <tarea-form :id="tareaId" :editable="popupTareaEditable" @close="$refs.popupTarea.close();" 
          :defaultData="defaultOfertaData" :esPopup="true"
        ></tarea-form>
      </f7-popup>
    </template>
  </r-form>
  <r-form
    ref="rformContacto"
    v-slot="{rform}"
    :itemId="null"
    @save="onSaveContacto"
    :readonly="readonly"
    :dbAdapter="ContactoCliente"
    :beforeSave="beforeSaveContacto"
    :defaultData="{activo:true, canalesComunicacion:[]}"
  >
    <f7-popup ref="popupContacto" class="popup-contacto" swipe-to-close>
      <f7-page>
        <f7-navbar title="Contacto Cliente">
          <f7-nav-right>
            <f7-link popup-close>Cerrar</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
        <f7-card class="elevation-3 padding-vertical-half padding-right">
          <f7-card-content>
            <f7-list class="list-form" style="z-index:30">
              <ul>
                <f7-row>
                  <r-input
                    class="col-50"
                    floating-label
                    :label="$t('clientes.alias')"
                    :form="rform"
                    :name="'alias'"
                  ></r-input>
                  <r-input
                    class="col-50"
                    floating-label
                    :label="$t('clientes.posicion')"
                    :form="rform"
                    :name="'posicion'"
                  ></r-input>
                  <r-input
                    class="col-50"
                    floating-label
                    :label="$t('clientes.nombre')"
                    :form="rform"
                    :name="'nombre'"
                  ></r-input>
                  <r-input
                    class="col-50"
                    floating-label
                    :label="$t('clientes.apellidos')"
                    :form="rform"
                    :name="'apellidos'"
                  ></r-input>
                  <r-direccion
                    class="col-100"
                    :form="rform"
                    :name="'direccion'"
                    :guardarCodigos="true"
                    autocomplete="off"
                  >
                  </r-direccion>
                </f7-row>
              </ul>
            </f7-list>
            <!-- Canales Comunicacion -->
            <f7-block-title style="z-index: 20">
              {{$t('clientes.canalescomunicacion')}}
              <f7-link
                v-if="!rform.readonly && rform.formData"
                @click="rform.formData.canalesComunicacion.push({tipo: '', nombre: '', valor:''});"
              >{{$t('centrostrabajo.add')}}</f7-link>
            </f7-block-title>

            <div style="z-index: 20">
              <f7-card
                class="elevation-3 padding-vertical-half padding-right"
                v-for="(item,index) in rform.formData.canalesComunicacion"
                :key="'contactoCliente-canalcomunicacion-'+index"
                :style="'z-index:'+(rform.formData.canalesComunicacion.length - index)"
              >
                <f7-card-content>
                  <f7-list class="list-form">
                    <ul>
                      <f7-row>
                        <r-input
                          class="col-33"
                          floating-label
                          :label="$t('clientes.tipo')"
                          :form="rform"
                          type="select"
                          :name="'canalesComunicacion.' + index + '.tipo'"
                        >
                          <option selected></option>
                          <option value="TELEFONO">{{$t('clientes.telefono')}}</option>
                          <option value="TELEFONO_MOVIL">{{$t('clientes.telefono_movil')}}</option>
                          <option value="FAX">{{$t('clientes.fax')}}</option>
                          <option value="EMAIL">{{$t('clientes.email')}}</option>
                          <option value="WEB">{{$t('clientes.web')}}</option>
                        </r-input>
                        <r-input
                          class="col-33"
                          floating-label
                          :label="$t('clientes.nombre')"
                          :form="rform"
                          :name="'canalesComunicacion.' + index + '.nombre'"
                        ></r-input>
                        <r-input
                          class="col-33"
                          floating-label
                          :label="$t('clientes.valor')"
                          :form="rform"
                          :name="'canalesComunicacion.' + index + '.valor'"
                        ></r-input>
                      </f7-row>
                    </ul>
                  </f7-list>
                </f7-card-content>
                <f7-link
                  v-if="!rform.readonly && rform.formData"
                  @click="$delete(rform.formData.canalesComunicacion,index);"
                  class="margin-half"
                  style="position:absolute;right:0;top:0;"
                  icon-f7="trash"
                ></f7-link>
              </f7-card>
            </div>
          </f7-card-content>
          <f7-row>
            <f7-col width="100" medium="100">
              <f7-button
                fill
                icon-f7="save"
                text="Guardar Contacto"
                class="display-flex margin-left"
                color="green"
                @click="rform.saveItem()"
              ></f7-button>
            </f7-col>
          </f7-row>
        </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>
  </r-form>
  <r-form
    ref="rformInstalacion"
    v-slot="{rform}"
    :itemId="null"
    @save="onSaveInstalacion"
    :readonly="readonly"
    :dbAdapter="Instalacion"
    :beforeSave="beforeSaveInstalacion"
  >
    <f7-popup ref="popupInstalacion" class="popup-instalacion" swipe-to-close>
      <f7-page>
        <f7-navbar title="Instalación">
          <f7-nav-right>
            <f7-link popup-close>Cerrar</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
        <f7-card class="elevation-3 padding-vertical-half padding-right">
          <f7-card-content>
            <f7-list class="list-form">
              <ul>
                <f7-row>
                  <f7-col width="100" medium="100">
                    <r-input
                      class="col-50"
                      floating-label
                      :label="$t('clientes.codigo')"
                      :form="rform"
                      type="text"
                      name="codigo"
                    ></r-input>
                    <r-input
                      class="col-50"
                      floating-label
                      :label="$t('clientes.nombre')"
                      :form="rform"
                      type="text"
                      name="nombre"
                    ></r-input>
                  </f7-col>
                </f7-row>
              </ul>
            </f7-list>
          </f7-card-content>
          <f7-row>
            <f7-col width="100" medium="100">
              <f7-button
                fill
                icon-f7="save"
                text="Guardar Instalación"
                class="display-flex margin-left"
                color="green"
                @click="$refs.rformInstalacion.saveItem()"
              ></f7-button>
            </f7-col>
          </f7-row>
        </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>
  </r-form>
  <r-form
    ref="rformItem"
    v-slot="{rform}"
    :itemId="null"
    @save="onSaveItem"
    :readonly="readonly"
    :dbAdapter="Item"
    :beforeSave="beforeSaveItem"
  >
    <f7-popup ref="popupItem" class="popup-item" swipe-to-close>
      <f7-page>
        <f7-navbar title="Item">
          <f7-nav-right>
            <f7-link popup-close>Cerrar</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
        <f7-card class="elevation-3 padding-vertical-half padding-right">
          <f7-card-content>
            <f7-list class="list-form">
              <ul>
                <f7-row>
                  <f7-col width="100" medium="100">
                    <r-input
                      floating-label
                      :label="$t('clientes.codigo')"
                      :form="rform"
                      type="text"
                      name="codigo"
                    ></r-input>
                    <r-input
                      floating-label
                      :label="$t('clientes.nombre')"
                      :form="rform"
                      type="text"
                      name="nombre"
                    ></r-input>
                  </f7-col>
                </f7-row>
              </ul>
            </f7-list>
          </f7-card-content>
          <f7-row>
            <f7-col width="100" medium="100">
              <f7-button
                fill
                icon-f7="save"
                text="Guardar Item"
                class="display-flex margin-left"
                color="green"
                @click="$refs.rformItem.saveItem()"
              ></f7-button>
            </f7-col>
          </f7-row>
        </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>
  </r-form>
  <r-form
    v-if="popupAceptacionOpened && $refs.rform && $refs.rform.formData && $refs.rform.formData.estado && (($refs.rform.formData.estado.estado == 'ENVIADA' && $refs.rform.formData.formaEnvio != 'AUTOMATICA') || $refs.rform.formData.estado.estado == 'ACEPTADA' || $refs.rform.formData.estado.estado == 'EN_PROCESO' || $refs.rform.formData.estado.estado == 'CERRADA')"
    ref="rformAceptacion"
    v-slot="{rform}"
    @save="onSaveAceptacion"
    :readonly="$refs.rform.formData.estado.estado != 'ENVIADA' && Utils.getUser().roles.indexOf('ADMIN') <= -1"
    :dbAdapter="null"
    :defaultData="{
      documentos: $refs.rform.formData.documentosAceptacion, 
      iban: ($refs.rform.formData.cliente && $refs.rform.formData.cliente.cuenta) ? $refs.rform.formData.cliente.cuenta.iban : '',
      direccionFacturacion: ($refs.rform.formData.logistica && $refs.rform.formData.logistica.direccionFacturacion) ? $refs.rform.formData.logistica.direccionFacturacion : {}
    }"
    :documentoAdapter="Documento"
  >
    <f7-popup ref="popupAceptacion" class="popup-aceptacion"  v-if="popupAceptacionOpened" :opened="true" @popup:closed="popupAceptacionOpened = false" swipe-to-close>
      <f7-page>
        <f7-navbar title="Documento de Aceptación">
          <f7-nav-right>
            <f7-link popup-close>Cerrar</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
      >
          <f7-card-content>
            <f7-list class="list-form">
              <ul>
                <f7-row>
                  <f7-col width="100" medium="100">
                    <r-files 
                      :form="rform" 
                      :name="'documentos'"
                      :documentoAdapter="Documento"
                    ></r-files>
                  </f7-col>
                  <r-input
                    v-if="$refs.rform.formData && $refs.rform.formData.viaPago && $refs.rform.formData.viaPago.codigo == '8'"
                    class="col-100"
                    floating-label
                    outline
                    :label="$t('IBAN Cliente')"
                    :form="rform"
                    type="text"
                    :name="'iban'"
                    :validation="{isValid: validator.isIBAN, errorMessage: '* Introduzca un IBAN válido'}"
                  ></r-input>
                  <r-input
                    v-if="$refs.rform.formData.cliente && !$refs.rform.formData.cliente.nif"
                    class="col-100"
                    floating-label
                    outline
                    :label="$t('NIF Cliente')"
                    :form="rform"
                    type="text"
                    :name="'nif'"
                    :validation="{isValid: ValidationUtils.validateSpanishID, errorMessage: '* Introduzca un NIF válido'}"
                  ></r-input>
                  <div class="label-group col-100">Dirección Facturación</div>
                  <r-direccion 
                    :form="rform" 
                    :name="'direccionFacturacion'"
                    classPoblacion="col-100 medium-50"
                    classProvincia="col-100 medium-50"
                    classComunidad="col-100 medium-50"
                    classPais="col-100 medium-50"
                  >
                  </r-direccion>
                </f7-row>
              </ul>
            </f7-list>
          </f7-card-content>
          <f7-row>
            <f7-col width="100" medium="100">
              <f7-button
                  v-if="($refs.rform.formData.estado.estado == 'ENVIADA' || Utils.getUser().roles.indexOf('ADMIN') > -1) && rform.formData.documentos && rform.formData.documentos.length"
                  fill
                  icon-f7="cloud_upload_fill"
                  text="Adjuntar Aceptación"
                  class="display-flex margin-vertical margin-left"
                  color="green"
                  @click="rform.saveItem()"
                ></f7-button>
            </f7-col>
          </f7-row>
      </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>
  </r-form>
  <r-form
    v-if="popupExtenderOpened && $refs.rform && $refs.rform.formData && $refs.rform.formData.estado && ($refs.rform.formData.estado.estado == 'ACEPTADA' || $refs.rform.formData.estado.estado == 'EN_PROCESO' || $refs.rform.formData.estado.estado == 'CERRADA')"
    ref="rformExtender"
    v-slot="{rform}"
    @save="onSaveExtender"
    :dbAdapter="null"
    :defaultData="{
      fechaVencimiento: $refs.rform.formData.fechaVencimiento
    }"
    :documentoAdapter="Documento"
  >
    <f7-popup ref="popupExtender" class="popup-extender"  v-if="popupExtenderOpened" :opened="true" @popup:closed="popupExtenderOpened = false" swipe-to-close>
      <f7-page>
        <f7-navbar title="Documento de aceptación de la extensión">
          <f7-nav-right>
            <f7-link popup-close>Cerrar</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
      >
          <f7-card-content>
            <f7-list class="list-form">
              <ul>
                <f7-row>
                  <f7-col width="100" medium="100">
                    <r-files
                      :form="rform"
                      :name="'documentos'"
                      :documentoAdapter="Documento"
                    ></r-files>
                  </f7-col>
                  <r-input
                    class="col-100 medium-33"
                    floating-label
                    :label="$t('ofertas.fechaVencimiento')"
                    :form="rform"
                    type="datepicker"
                    name="fechaVencimiento"
                    :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                  ></r-input>
                </f7-row>
              </ul>
            </f7-list>
          </f7-card-content>
          <f7-row>
            <f7-col width="100" medium="100">
              <f7-button
                  v-if="rform.formData.documentos && rform.formData.documentos.length && rform.formData.fechaVencimiento"
                  fill
                  icon-f7="cloud_upload_fill"
                  text="Adjuntar Aceptación"
                  class="display-flex margin-vertical margin-left"
                  color="green"
                  @click="rform.saveItem()"
                ></f7-button>
            </f7-col>
          </f7-row>
      </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>
  </r-form>

  <r-form
    v-if="$refs.rform && $refs.rform.formData && $refs.rform.formData.estado && $refs.rform.formData.estado.estado == 'APROBADA'"
    ref="rformEnvioManual"
    v-slot="{rform}"
    @save="onSaveEnvioManual"
    :dbAdapter="null"
  >
    <f7-popup ref="popupEnvioManual" class="popup-envio-manual" swipe-to-close>
      <f7-page>
        <f7-navbar title="Oferta enviada a cliente">
          <f7-nav-right>
            <f7-link popup-close>Cerrar</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
      >
          <f7-card-content>
            <f7-list class="list-form">
              <ul>
                <f7-row>
                  <f7-col width="100" medium="100">
                    <r-autocomplete
                      outline
                      floating-label
                      :dbAdapter="null"
                      label="Forma de Envío"
                      class="col-100"
                      :form="rform"
                      :name="'formaEnvio'"
                      labelProp="nombre"
                      idProp="codigo"
                      :dbItems="[
                        {codigo: 'EMAIL', nombre: 'Email'},
                        {codigo: 'CORREO_POSTAL', nombre: 'Correo Postal'},
                        {codigo: 'OTRAS', nombre: 'Otras'}
                      ]"
                    >
                    </r-autocomplete>
                  </f7-col>
                </f7-row>
              </ul>
            </f7-list>
          </f7-card-content>
          <f7-row>
            <f7-col width="100" medium="100">
              <f7-button
                  fill
                  icon-f7="disk"
                  text="Guardar"
                  class="display-flex margin-vertical"
                  color="green"
                  @click="rform.saveItem()"
                ></f7-button>
            </f7-col>
          </f7-row>
      </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>
  </r-form>
  
  <f7-popup ref="popupRechazo" class="popup-rechazo" swipe-to-close>
    <f7-page>
      <f7-navbar title="Oferta rechazada por el cliente">
        <f7-nav-right>
          <f7-link popup-close>{{$t('common.cerrar')}}</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <r-form 
        ref="rformRechazo" 
        @save="onSaveRechazo"
        :dbAdapter="null"
        classContent="full-height"
        :readonly="false"
        :defaultData="{motivoRechazo:''}"
      >
        <template v-slot:default="{ rform }">
          <f7-card
            class="elevation-3 padding-vertical-half padding-right"
          >
            <f7-card-content>
              <f7-list class="list-form">
                <ul>
                  <f7-row>
                    <f7-col width="100" medium="100">
                      <r-input
                        floating-label
                        :label="$t('ofertas.motivoRechazo')"
                        :form="rform"
                        type="textarea"
                        name="motivoRechazo"
                        :readonly="false"
                        :overrideReadonly="true"
                      />
                    </f7-col>
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
            <f7-row>
              <f7-col width="100" medium="100">
                <f7-button
                    fill
                    icon-f7="disk"
                    :text="$t('common.guardar')"
                    class="display-flex margin-vertical"
                    color="green"
                    @click="rform.saveItem()"
                  ></f7-button>
              </f7-col>
            </f7-row>
          </f7-card>
        </template>
      </r-form>
    </f7-page>
  </f7-popup>
  <f7-popup ref="popupCancelar" class="popup-cancelar" swipe-to-close>
    <f7-page>
      <f7-navbar title="Oferta cancelada">
        <f7-nav-right>
          <f7-link popup-close>{{$t('common.cerrar')}}</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <r-form 
        ref="rformCancelar" 
        @save="onSaveCancelar"
        :dbAdapter="null"
        classContent="full-height"
        :readonly="false"
        :defaultData="{motivoCancelacion:''}"
      >
        <template v-slot:default="{ rform }">
          <f7-card
            class="elevation-3 padding-vertical-half padding-right"
          >
            <f7-card-content>
              <f7-list class="list-form">
                <ul>
                  <f7-row>
                    <f7-col width="100" medium="100">
                      <r-input
                        :clearButton="false"
                        floating-label
                        :label="$t('ofertas.motivoCancelacion')"
                        :form="rform"
                        type="textarea"
                        name="motivoCancelacion"
                        :readonly="estaCancelada()"
                        :overrideReadonly="true"
                      />
                    </f7-col>
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
            <f7-row>
              <f7-col width="100" medium="100">
                <f7-button
                    v-if="!estaCancelada()"
                    fill
                    icon-f7="disk"
                    :text="$t('common.guardar')"
                    class="display-flex margin-vertical"
                    color="green"
                    @click="rform.saveItem()"
                  ></f7-button>
              </f7-col>
            </f7-row>
          </f7-card>
        </template>
      </r-form>
    </f7-page>
  </f7-popup>
  <f7-popup 
    v-if="popupHistoricoEstadosOpened" :opened="true" @popup:closed="popupHistoricoEstadosOpened = false"
    ref="popupHistoricoEstados" 
    class="popupHistoricoEstados"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>{{$t('ofertas.cerrar')}}</f7-link>
        </f7-nav-left>
        <f7-nav-title>
          {{$t('ofertas.popuHistoricoEstados.historicoEstados')}}
        </f7-nav-title>
      </f7-navbar>
      <template>
        <f7-list class="">
          <f7-row no-gap style="text-align:center;font-weight: bold;" class="list_popup">
            <span class="col-100 medium-20">{{$t('ofertas.estado')}}</span>
            <span class="col-100 medium-40">{{$t('ofertas.comentarios')}}</span>
            <span class="col-100 medium-15">{{$t('ofertas.popuHistoricoEstados.usuario')}}</span>
            <span class="col-100 medium-25">{{$t('ofertas.popuHistoricoEstados.fecha')}}</span>
          </f7-row>
          <f7-row 
            no-gap
            v-for="(item,index) in getHistoricoEstados()"
            :key="'historicoEstados-'+index"  
            class="list_popup"
          >
            <r-input
              class="col-100 medium-20"
              type="badge"
              name="estado"
              :readonly="true"
              :overrideReadonly="true"
              :text="getTextoBadgeEstado({estado: item})"
              :color="getColorBadgeEstado({estado: item})"
            ></r-input>
            <f7-list-input
              v-noroles="['ENAC', 'PRODUCCION']"
              :value="item.comentarios"
              v-tooltip="item.comentarios"
              :readonly="true"
              class="col-100 medium-40"
              type="text"

            />
            <f7-list-input
              :value="item.username"
              v-tooltip="item.username"
              :readonly="true"
              class="col-100 medium-15"
              type="text"
            />
            <f7-list-input
              :value="DateUtils.dateFormat(item.fecha, 'DD/MM/YYYY HH:mm')"
              v-tooltip="DateUtils.dateFormat(item.fecha, 'DD/MM/YYYY HH:mm')"
              :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
              :readonly="true"
              class="col-100 medium-25"
              type="text"
            />
          </f7-row>
        </f7-list>
      </template>
    </f7-page>
  </f7-popup>
  <f7-popup ref="popuptextos" class="popuptextos" tablet-fullscreen>
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>{{$t('ofertas.cerrar')}}</f7-link>
        </f7-nav-left>
        <f7-nav-title>
          {{$t('ofertas.textos')}}
        </f7-nav-title>
      </f7-navbar>
      <r-form 
        v-if="clienteSelected"
        ref="rformTextos" 
        classContent="full-height"
        :readonly="readonly"
        :defaultData="{textos:[]}"
      >
        <template v-slot:default="{rform}">

        <!-- Textos -->
          <f7-block-title  v-if="rform && rform.formData">
            {{$t('ofertas.textos')}}
            <f7-link v-if="!rform.readonly && rform.formData" @click="rform.formData.textos.push({origenServicio: false, obligatorio: false, soloLectura: false, activo: true})">{{$t('ofertas.add')}}</f7-link>
          </f7-block-title>
          <div v-if="rform && rform.formData" style="z-index: 30">

          <Container 
            @drop="onDrop(rform.formData.textos, $event)"
            drag-handle-selector=".column-drag-handle"
            lock-axis="y"
          >
            <Draggable
              v-for="(item,index) in rform.formData.textos"
              :key="'texto-'+index"
            >

            <f7-card
              class="elevation-3 padding-vertical-half padding-right"
              :class="{'border-3 border-color-orange' : (item.camposPersonalizados && item.camposPersonalizados.movidosAOferta)}"
            >
              <f7-card-content>
                <f7-list 
                  class="list-form"
                >
                  <ul>
                    <f7-row no-gap>
                      <span 
                        v-if="!readonly"
                        class="column-drag-handle margin-left cursor-move col-5"
                        v-tooltip="$t('common.move')"
                      >&uarr;&darr;</span>
                      <r-input v-if="!item.obligatorio"
                        class="col-50"
                        :label="item.nombre"
                        :form="rform"
                        type="check"
                        :name="'textos.' + index + '.activo'"
                      ></r-input>
                      <r-input 
                        class="col-15" 
                        :label="$t('textos.forzarPaginaNueva')" 
                        :form="rform" 
                        type="check"
                        :name="'textos.' + index + '.propiedades.forzarPaginaNueva'"
                      ></r-input>
                      <r-input 
                        class="col-15" 
                        :label="$t('textos.alInicio')" 
                        :form="rform" 
                        type="check"
                        :name="'textos.' + index + '.propiedades.alInicio'"
                      ></r-input>
                    </f7-row>
                    <f7-row no-gap v-if="item.obligatorio || item.activo">
                      <r-input
                        class="col-50"
                        floating-label
                        :readonly="readonly || item.soloLectura"
                        :overrideReadonly="true"
                        :label="$t('ofertas.titulo')"
                        :form="rform"
                        :name="'textos.' + index + '.nombre'"
                      ></r-input>
                      <r-rich-text-input
                        class="col-100"
                        floating-label
                        :readonly="readonly || item.soloLectura"
                        :overrideReadonly="true"
                        :label="$t('ofertas.html')"
                        :form="rform"
                        :name="'textos.' + index + '.html'"
                        @blur="moverTextosAOferta"
                      ></r-rich-text-input>
                    </f7-row>
                  </ul>
                </f7-list>
              </f7-card-content>
              <f7-link
                v-if="!rform.readonly && rform.formData && !item.origenServicio"
                @click="$delete(rform.formData.textos,index);moverTextosAOferta();"
                class="margin-half"
                style="position:absolute;right:0;top:0;"
                icon-f7="trash"
              ></f7-link>
            </f7-card>


            </Draggable>
          </Container>
          </div>
          <f7-block-title  v-if="rform && rform.formData">
            {{$t('ofertas.textos')}}
            <f7-link v-if="!rform.readonly && rform.formData" @click="rform.formData.textos.push({origenServicio: false, obligatorio: false, soloLectura: false, activo: true})">{{$t('ofertas.add')}}</f7-link>
          </f7-block-title>
        </template>
      </r-form>
    </f7-page>
  </f7-popup>
  <f7-popup ref="popupnew" class="popupnew" @popup:close="resetNewLine(); codigoServicioLineaEditando = null;" @popup:open="resetNewLine">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>{{$t('ofertas.cerrar')}}</f7-link>
        </f7-nav-left>
        <f7-nav-title>
          {{$t('ofertas.crearLinea')}} - {{step}}/{{maxStep}}
        </f7-nav-title>
      </f7-navbar>
      <r-form 
        v-if="clienteSelected"
        ref="rformNew" 
        classContent="full-height"
        @save="onNewLine"
        :readonly="readonly"
        :defaultData="{ ...{ numero: getMaxNumeroLineas($refs.rform.formData.lineas), emailComunicaciones: $refs.rform.formData.emailContacto, contacto: $refs.rform.formData.contactoCliente ? $refs.rform.formData.contactoCliente.alias : '', telefono1: ($refs.rform.formData.contactoCliente && $refs.rform.formData.contactoCliente.canalesComunicacion)? $refs.rform.formData.contactoCliente.canalesComunicacion.filter(c => c.tipo == 'TELEFONO').map(c => c.valor)[0] : '', telefono2: ($refs.rform.formData.contactoCliente && $refs.rform.formData.contactoCliente.canalesComunicacion)? $refs.rform.formData.contactoCliente.canalesComunicacion.filter(c => c.tipo == 'TELEFONO_MOVIL').map(c => c.valor)[0] : '' } ,...nuevaLineaDefaultData}"
      >
        <template v-slot:default="{rform}">
          <div class="display-inline-block text-align-center margin-top">
            <f7-button
              v-if="verBotonAnterior(rform)"
              fill
              color="red"
              class="display-inline-block margin-horizontal"
              @click="step--"
            >{{$t('ofertas.anterior')}}</f7-button>
            <f7-button
              v-if="verBotonSiguiente(rform)"
              fill
              color="primary"
              class="display-inline-block margin-horizontal"
              @click="step++"
            >{{$t('ofertas.siguiente')}}</f7-button>
            <f7-button
              v-if="verBotonGuardar(rform)"
              fill
              color="primary"
              class="display-inline-block margin-horizontal"
              @click="guardarLinea(rform)"
            >{{$t('ofertas.guardar')}}</f7-button>
            <f7-button
              v-if="verBotonCerrar(rform)"
              fill
              color="primary"
              class="display-inline-block margin-horizontal"
              popup-close=".popupnew"
            >{{$t('ofertas.cerrar')}}</f7-button>
          </div>
          <template v-if="step == 1">
            <f7-block-title>{{$t('ofertas.titular')}}</f7-block-title>
            <div class="list">
              <ul class="row">
                <r-input
                  class="col-50 medium-50"
                  floating-label
                  :label="$t('clientes.nif')"
                  :form="rform"
                  :name="'nifTitular'"
                ></r-input>
                <r-input
                  class="col-50 medium-50"
                  floating-label
                  :label="$t('ofertas.titular')"
                  :form="rform"
                  :name="'titular'"
                ></r-input>
              </ul>
            </div>
            <f7-block-title>{{$t('ofertas.direccionInspeccion')}}</f7-block-title>
            <div class="list">
              <ul class="row">
                <r-direccion
                  :form="rform"
                  :name="'direccionInspeccion'"
                  :guardarCodigos="true"
                  autocomplete="off"
                >
                </r-direccion>
              </ul>
            </div>
            <f7-block-title>{{ $t('Contacto') }}</f7-block-title>
            <div class="list">
              <ul class="row">
                <r-input
                  class="col-100 medium-100"
                  floating-label
                  :label="$t('Persona de contacto para la inspección')"
                  :form="rform"
                  :name="'contacto'"
                ></r-input>
                <r-input
                  class="col-100 medium-100"
                  floating-label
                  :label="$t('Teléfono 1')"
                  :form="rform"
                  :name="'telefono1'"
                ></r-input>
                <r-input
                  class="col-100 medium-100"
                  floating-label
                  :label="$t('Teléfono 2')"
                  :form="rform"
                  :name="'telefono2'"
                ></r-input>
                <r-input
                  class="col-100 medium-100"
                  floating-label
                  :label="$t('El titular ha comunicado que quiere presenciar la inspección')"
                  :form="rform"
                  type="check" 
                  :name="'titularPresencial'"
                ></r-input>
              </ul>
            </div>
            <f7-block-title>{{$t('ofertas.emailComunicaciones')}}</f7-block-title>
            <div class="list">
              <ul class="row">
                <r-input
                  class="col-100 medium-100"
                  floating-label
                  :label="$t('ofertas.emailComunicaciones')"
                  :form="rform"
                  :name="'emailComunicaciones'"
                ></r-input>
              </ul>
            </div>
          </template>
          <template v-if="step == 2">
            <f7-block-title>{{$t('ofertas.servicio')}}</f7-block-title>
            <div class="list" style="z-index: 2;">
              <ul>
                <r-autocomplete
                  key="servicio"
                  :dbAdapter="Servicio"
                  class="col-100"
                  :form="rform"
                  :name="'servicio'"
                  labelProp="nombre"
                  prefixProp="codigo"
                  :shownFields="['codigo', 'materia', 'precios', 'tasas', 'subservicios', 'tasaDefecto', 'codCuentaMayor', 'tiempoEstimadoCampo', 'tiempoEstimadoOficina', 'activo', 'userText','porcSegunda','porcMinSegunda','blocPorcSegundas','unidadInspeccion','idServicioUnidadExtra']"
                  :filter="{filter:{activo: true}}"
                  @change="changeServicio(rform.formData)"
                ></r-autocomplete>
                <r-input
                  v-if="!readonly && tieneSubservicios(rform.formData)"
                  class="col-100 medium-10"
                  floating-label
                  :label="$t('lineas.subservicio')"
                  :form="rform"
                  type="select"
                  name="subservicio.nombre"
                  @_change="changeSubservicio(rform.formData, $event)"
                >
                  <option selected disabled></option>
                  <option v-for="(ss,iss) in rform.formData.servicio.subservicios"
                    :key="'subservicio-' +iss"
                    :value="ss.nombre"
                  >{{ss.nombre}}</option>
                </r-input>
                <r-input
                  v-else-if="readonly && tieneSubservicios(rform.formData)"
                  class="col-100 medium-10"
                  floating-label
                  :label="$t('lineas.subservicio')"
                  :form="rform"
                  type="text"
                  name="subservicio.nombre"
                ></r-input>
              </ul>
            </div>
            <f7-block-title>{{$t('ofertas.tiempos')}}</f7-block-title>
            <div class="list">
              <ul class="row">
                <r-input
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('ofertas.tiempoEstimadoCampo')"
                  :form="rform"
                  :name="'tiempoEstimadoCampo'"
                  type="number"
                ></r-input>
                <r-input
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('ofertas.tiempoEstimadoOficina')"
                  :form="rform"
                  :name="'tiempoEstimadoOficina'"
                  type="number"
                ></r-input>
              </ul>
            </div>
            <f7-block-title>{{$t('ofertas.gastos.previsionGastos')}}</f7-block-title>
            <div class="list">
              <ul class="row">
                <r-input
                  class="col-100 medium-30"
                  floating-label
                  :label="$t('ofertas.gastos.kilometros')"
                  :form="rform"
                  :name="'previsionGastos.kilometros'"
                  type="number"
                ></r-input>
                <r-input
                  class="col-100 medium-30"
                  floating-label
                  :label="$t('ofertas.gastos.peajes')"
                  :form="rform"
                  :name="'previsionGastos.peajes'"
                  type="number"
                ></r-input>
                <r-input
                  class="col-100 medium-30"
                  floating-label
                  :label="$t('ofertas.gastos.parking')"
                  :form="rform"
                  :name="'previsionGastos.parking'"
                  type="number"
                ></r-input>
              </ul>
              <ul class="row">
                <r-input
                  class="col-100 medium-30"
                  floating-label
                  :label="$t('ofertas.gastos.desayuno')"
                  :form="rform"
                  :name="'previsionGastos.desayuno'"
                  type="number"
                ></r-input>
                <r-input
                  class="col-100 medium-30"
                  floating-label
                  :label="$t('ofertas.gastos.comida')"
                  :form="rform"
                  :name="'previsionGastos.comida'"
                  type="number"
                ></r-input>
                <r-input
                  class="col-100 medium-30"
                  floating-label
                  :label="$t('ofertas.gastos.cena')"
                  :form="rform"
                  :name="'previsionGastos.cena'"
                  type="number"
                ></r-input>
              </ul>
              <ul class="row">
                <r-input
                  class="col-100 medium-30"
                  floating-label
                  :label="$t('ofertas.gastos.hotel')"
                  :form="rform"
                  :name="'previsionGastos.hotel'"
                  type="number"
                ></r-input>
                <r-input
                  class="col-100 medium-30"
                  floating-label
                  :label="$t('ofertas.gastos.finDeSemana')"
                  :form="rform"
                  :name="'previsionGastos.finDeSemana'"
                  :key="'previsionGastos.finDeSemana'"
                  type="check"
                ></r-input>
                <r-input
                  class="col-100 medium-30"
                  floating-label
                  :label="$t('ofertas.gastos.horasNocturnas')"
                  :form="rform"
                  :name="'previsionGastos.horasNocturnas'"
                  :key="'previsionGastos.horasNocturnas'"
                  type="check"
                ></r-input>
              </ul>
            </div>
            <f7-block-title>Características principales del trabajo a realizar</f7-block-title>
            <div class="list">
              <ul class="row">
                <r-input
                  class="col-100 medium-100"
                  floating-label
                  label="Caract. Pral. Trabajo Realizar"
                  :form="rform"
                  :name="'trabajoRealizar'"
                ></r-input>
              </ul>
            </div>
            <f7-block-title v-if="$refs.rformNew && $refs.rformNew.formData && $refs.rformNew.formData.servicio && $refs.rformNew.formData.servicio.unidadInspeccion">{{$refs.rformNew.formData.servicio.unidadInspeccion}}</f7-block-title>
            <div class="list" v-if="$refs.rformNew && $refs.rformNew.formData && $refs.rformNew.formData.servicio && $refs.rformNew.formData.servicio.unidadInspeccion">
              <ul class="row">
                <r-input
                  class="col-50 medium-50"
                  floating-label
                  :label="'Nº '+$refs.rformNew.formData.servicio.unidadInspeccion+' OFERTA'"
                  :form="rform"
                  type="number"
                  :name="'numUnidadesOfertadas'"
                ></r-input>
                <r-input
                  class="col-50 medium-50"
                  floating-label
                  :label="'Nº '+$refs.rformNew.formData.servicio.unidadInspeccion+' INSPECCIÓN'"
                  :form="rform"
                  type="number"
                  :name="'numUnidadesInspeccionadas'"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-input>
              </ul>
            </div>
            
            <f7-block-title >RAE</f7-block-title>
            <div class="list no-hairlines">
              <ul class="row">
                <r-input
                  class="col-50 medium-50"
                  floating-label
                  :label="'Nº RAE'"
                  :form="rform"
                  :name="'rae'"
                ></r-input>
              </ul>
            </div>
            
          </template>
          <template v-if="step == 3">
            <!-- <f7-block-title>
              {{$t('ofertas.instalacion')}}
              <span
                v-if="!rform.formData.codigoInstalacion"
              >
               - 
              </span>
              <f7-link
                v-if="!rform.formData.codigoInstalacion"
                popup-open=".popup-instalacion"
              >{{$t('ofertas.nuevaInstalacion')}}</f7-link>
            </f7-block-title>
            <div class="list" style="z-index: 3;">
              <ul>
                <r-autocomplete
                  key="instalacion"
                  :ref="'instalacion'"
                  :dbAdapter="Instalacion"
                  class="col-100"
                  :form="rform"
                  :name="'codigoInstalacion'"
                  labelProp="nombre"
                  prefixProp="codigo"
                  idProp="codigo"
                  :readonly="!$refs.rform || !$refs.rform.formData || !$refs.rform.formData.cliente || !$refs.rform.formData.cliente.id"
                  :filter="{clienteId: $refs.rform.formData.cliente ? $refs.rform.formData.cliente.id: null}"
                  @change="onChangeInstalacion(rform.formData.item)"
                >
                </r-autocomplete>
              </ul>
            </div> -->
            <f7-block-title v-if="!rform.readonly">Prefiltro de items</f7-block-title>
            <div class="list" style="z-index: 3;">
              <ul>
                <r-input
                  v-if="!rform.readonly"
                  fill
                  class="col-25 medium-25 small-100"
                  label="Buscar item por materia"
                  :form="rform"
                  type="check"
                  name="porMateria"
                  @change="onchangefilter"
                ></r-input>
                <r-input
                  v-if="!rform.readonly"
                  fill
                  class="col-25 medium-25 small-100"
                  label="Buscar por el cliente de la oferta"
                  :form="rform"
                  type="check"
                  name="porCliente"
                  @change="onchangefilter"
                ></r-input>
                <r-input
                  v-if="!rform.readonly"
                  fill
                  class="col-25 medium-25 small-100"
                  label="Buscar por dirección de inspección"
                  :form="rform"
                  type="check"
                  name="porDireccion"
                  @change="onchangefilter"
                ></r-input>
                <!-- <r-direccion
                  class="col-100"
                  :form="rform"
                  :name="'direccionitem'"
                  :guardarCodigos="true"
                  autocomplete="off"
                >
                </r-direccion> -->
                <r-input
                  v-if="!rform.readonly"
                  class="col-100"
                  floating-label
                  label="Identificador del elemento a inspeccionar"
                  :form="rform"
                  type="text"
                  name="identificador"
                  @blur="onchangefilter"
                  @keyup.enter.native="onchangefilter"
                ></r-input>
              </ul>
            </div>
            <f7-block-title>
              {{$t('ofertas.item')}}
              <!-- <span
                v-if="rform.formData.codigoInstalacion && (!rform.formData.item || !rform.formData.item.id)"
              >
               - 
              </span> -->
              <!-- <f7-link
                v-if="rform.formData.codigoInstalacion && (!rform.formData.item || !rform.formData.item.id)"
                popup-open=".popup-item"
              >{{$t('ofertas.nuevoItem')}}</f7-link>   -->
            </f7-block-title>
            <div class="list" style="z-index: 2;">
              <ul>
                <r-autocomplete
                  v-if="rform.readonly || (rform.formData.item && rform.formData.item.id) || rform.formData.porCliente || rform.formData.porDireccion || rform.formData.identificador || rform.formData.porMateria"
                  key="item"
                  :ref="'item'"
                  :dbAdapter="Item"
                  class="col-100"
                  floating-label
                  :form="rform"
                  :name="'item'"
                  labelProp="nombre"
                  prefixProp="codigo"
                  :shownFields="['codigo', 'codigoInstalacion', 'direccion', 'administrador', 'instalador', 'mantenedor', 'asociacion']"
                  :readonly="!$refs.rform || !$refs.rform.formData || !$refs.rform.formData.cliente || !$refs.rform.formData.cliente.id"
                  :filter="calcularFiltroItem()"
                  :source="sourceItem"
                  @change="changeItem(rform.formData)"
                  :beforeChange="beforeChangeItem"
                >
                </r-autocomplete>
              </ul>
            </div>
          </template>
          <template v-if="step == 4">
            <f7-block-title>{{$t('ofertas.importes')}}</f7-block-title>
            <div class="list">
              <ul class="row">
                <r-input
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('ofertas.precio')"
                  :form="rform"
                  :name="'precioUnitario'"
                  type="number"
                  step=".01"
                ></r-input>
                <r-input
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('pedidos.cantidad')"
                  :form="rform"
                  :name="'cantidad'"
                  type="number"
                  v-if="$refs.rform.formData.tipoOferta !='MARCO'"
                ></r-input>
                <r-input
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('ofertas.porcDescuento')"
                  :form="rform"
                  :name="'porcDescuento'"
                  type="number"
                ></r-input>
                <r-input
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('ofertas.porcSegunda')" 
                  :form="rform"
                  :name="'porcSegunda'"
                  type="number"
                  @blur="checkPorcSegunda"
                  :min="rform.formData.porcMinSegunda ? rform.formData.porcMinSegunda : 0"
                  :disabled="rform.formData.blocPorcSegundas || deshabilitar(rform.formData.importeSegunda)"
                ></r-input>
                <r-input
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('ofertas.importeSegunda')" 
                  :form="rform"
                  :disabled="rform.formData.blocPorcSegundas || deshabilitar(rform.formData.porcSegunda)"
                  :name="'importeSegunda'"
                  type="number"
                  step=".01"
                ></r-input>
              </ul>
            </div>
            <f7-block-title>{{$t('ofertas.impuestos')}}</f7-block-title>
            <div class="list">
              <ul class="row">
                <r-input
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('ofertas.tasas')"
                  :form="rform"
                  :name="'tasas'"
                  type="number"
                  step=".01"
                ></r-input>
                <r-input
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('ofertas.porcIVA')"
                  :form="rform"
                  :name="'porcIVA'"
                  type="number"
                ></r-input>
              </ul>
            </div>
            <f7-block-title class="disabled">{{$t('ofertas.importe')}}</f7-block-title>
            <f7-list>
              <f7-list-input
                class="col-100 medium-25 text-color-red disabled"
                :readonly="true"
                type="number"
                step=".01"
                :value="getTotalesLinea(rform.formData)['total']"
              ></f7-list-input>
            </f7-list>
          </template>
          <template v-if="step == 5">
            <p v-roles="['ENAC', 'PRODUCCION']" class="margin-half">Sección en desarrollo</p>
            <div v-noroles="['ENAC', 'PRODUCCION']">
              <f7-block-title>{{$t('ofertas.colaboradores')}}</f7-block-title>
              <div class="list">
                <ul class="row">
                  <r-autocomplete
                    :dbAdapter="Cliente"
                    class="col-100"
                    floating-label
                    :label="$t('ofertas.administrador')"
                    :form="rform"
                    :name="'administrador'"
                    labelProp="codigoNombre"
                    :idProp="null"
                    idFieldDetail="id"
                    :shownFields="['codigo', 'razonSocial', 'activo']"
                    :minLength="3"
                    :async="true"
                    :filter="{filter:{activo: true}}"
                  ></r-autocomplete>
                  <r-autocomplete
                    :dbAdapter="Cliente"
                    class="col-100"
                    floating-label
                    :label="$t('ofertas.instalador')"
                    :form="rform"
                    :name="'instalador'"
                    labelProp="codigoNombre"
                    :idProp="null"
                    idFieldDetail="id"
                    :shownFields="['codigo', 'razonSocial', 'activo']"
                    :minLength="3"
                    :async="true"
                    :filter="{filter:{activo: true}}"
                  ></r-autocomplete>
                  <r-autocomplete
                    :dbAdapter="Cliente"
                    class="col-100"
                    floating-label
                    :label="$t('ofertas.mantenedor')"
                    :form="rform"
                    :name="'mantenedor'"
                    labelProp="codigoNombre"
                    :idProp="null"
                    idFieldDetail="id"
                    :shownFields="['codigo', 'razonSocial', 'activo']"
                    :minLength="3"
                    :async="true"
                    :filter="{filter:{activo: true}}"
                  ></r-autocomplete>
                  <r-autocomplete
                    :dbAdapter="Cliente"
                    class="col-100"
                    floating-label
                    :label="$t('ofertas.asociacion')"
                    :form="rform"
                    :name="'asociacion'"
                    labelProp="codigoNombre"
                    :idProp="null"
                    idFieldDetail="id"
                    :shownFields="['codigo', 'razonSocial', 'activo']"
                    :minLength="3"
                    :async="true"
                    :filter="{filter:{activo: true}}"
                  ></r-autocomplete>
                </ul>
              </div>
            </div>
          </template>
          <template v-if="step == 6">
            <p v-roles="['ENAC', 'PRODUCCION']" class="margin-half">Sección en desarrollo</p>
            <div v-noroles="['ENAC', 'PRODUCCION']">
              <f7-block-title>{{$t('ofertas.comision')}}</f7-block-title>
              <div class="list">
                <ul class="row">
                  <r-autocomplete
                    :dbAdapter="Cliente"
                    class="col-100 medium-100"
                    floating-label
                    :label="$t('ofertas.comisionproveedor')"
                    :form="rform"
                    :name="'comision.codigo'"
                    labelProp="codigoNombre"
                    idProp="codigo"
                    idFieldDetail="id"
                    :shownFields="['codigo', 'razonSocial']"
                    :minLength="3"
                    :async="true"
                  ></r-autocomplete>
                  <r-input
                    class="col-100 medium-50"
                    floating-label
                    :label="$t('ofertas.comisionunitaria')"
                    :form="rform"
                    type="number"
                    step=".01"
                    :name="'comision.precio'"
                  ></r-input>
                  <r-input
                    class="col-100 medium-50"
                    floating-label
                    :label="$t('ofertas.porcIVA')"
                    :form="rform"
                    type="number"
                    :name="'comision.porcIVA'"
                  ></r-input>
                </ul>
              </div>
              <f7-block-title class="disabled">{{$t('ofertas.totalComision')}}</f7-block-title>
              <f7-list>
                <f7-list-input
                  class="col-100 medium-25 text-color-red disabled"
                  :readonly="true"
                  type="number"
                  step=".01"
                  :value="getTotalesLinea(rform.formData)['totalComision']"
                ></f7-list-input>
              </f7-list>
              <f7-block-title>{{$t('ofertas.subcontratacion')}}</f7-block-title>
              <div class="list">
                <ul class="row">
                  <r-autocomplete
                    :dbAdapter="Cliente"
                    class="col-100 medium-50"
                    floating-label
                    :label="$t('ofertas.proveedor')"
                    :form="rform"
                    :name="'proveedor.codigo'"
                    labelProp="codigoNombre"
                    idProp="codigo"
                    idFieldDetail="id"
                    :shownFields="['codigo', 'razonSocial']"
                    :minLength="3"
                    :async="true"
                  ></r-autocomplete>
                  <r-input
                    class="col-100 medium-50"
                    floating-label
                    :label="$t('ofertas.codigoOferta')"
                    :form="rform"
                    :name="'proveedor.codigoOferta'"
                  ></r-input>
                  <r-input
                    class="col-100 medium-50"
                    floating-label
                    :label="$t('ofertas.precio')"
                    :form="rform"
                    type="number"
                    step=".01"
                    :name="'proveedor.precio'"
                  ></r-input>
                  <r-input
                    class="col-100 medium-50"
                    floating-label
                    :label="$t('ofertas.porcIVA')"
                    :form="rform"
                    type="number"
                    :name="'proveedor.porcIVA'"
                  ></r-input>
                </ul>
              </div>
              <f7-block-title class="disabled">{{$t('ofertas.totalSubcontratacion')}}</f7-block-title>
              <f7-list>
                <f7-list-input
                  class="col-100 medium-25 text-color-red disabled"
                  :readonly="true"
                  type="number"
                  step=".01"
                  :value="getTotalesLinea(rform.formData)['totalProveedor']"
                ></f7-list-input>
              </f7-list>
            </div>
          </template>
          <template v-if="step == 7">
            <p v-roles="['ENAC', 'PRODUCCION']" class="margin-half">Sección en desarrollo</p>
            <div v-noroles="['ENAC', 'PRODUCCION']">
              <f7-block-title>{{$t('ofertas.comentarios')}}</f7-block-title>
              <f7-block-title>Para que salgan lineas de texto separadas en sap, separar por ##</f7-block-title>
              <div class="list">
                <ul class="row">
                  <r-input
                    class="col-100"
                    :form="rform"
                    :name="'comentarios'"
                    type="textarea"
                  ></r-input>
                </ul>
              </div>
              <f7-block-title v-noroles="['ENAC', 'PRODUCCION']">{{$t('pedidos.comentariosLineaCerrada')}}</f7-block-title>
              <div class="list" v-noroles="['ENAC', 'PRODUCCION']">
                <ul class="row">
                  <r-input
                    class="col-100"
                    :form="rform"
                    :name="'comentariosLineaCerrada'"
                    type="textarea"
                    :readonly="false"
                  ></r-input>
                </ul>
              </div>
            </div>
          </template>
          <template v-if="step == 8">
            <p v-roles="['ENAC', 'PRODUCCION']" class="margin-half">Sección en desarrollo</p>
            <div v-noroles="['ENAC', 'PRODUCCION']">
              <f7-block-title>{{$t('ofertas.resumen')}}</f7-block-title>
              <f7-block-title class="disabled">{{$t('ofertas.contratacion')}}</f7-block-title>
              <div class="list">
                <ul class="row">
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.subtotal')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['subtotal']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.totalIVA')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['iva']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.total')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['total']"
                  ></f7-list-input>
                </ul>
              </div>
              <f7-block-title class="disabled"></f7-block-title>
              <div class="list">
                <ul class="row">
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.totalTasas')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['totalTasas']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.porcSegunda')"
                    type="number"
                    step=".01"
                    :value="rform.formData.porcSegunda"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.importeSegunda')"
                    type="number"
                    step=".01"
                    :value="rform.formData.importeSegunda"
                  ></f7-list-input>
                  
                </ul>
              </div>
              <f7-block-title class="disabled">{{$t('ofertas.subcontratacion')}}</f7-block-title>
              <div class="list">
                <ul class="row">
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.subtotal')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['subtotalProveedor']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.totalIVA')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['ivaProveedor']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.total')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['totalProveedor']"
                  ></f7-list-input>
                </ul>
              </div>
              <f7-block-title class="disabled">{{$t('ofertas.comision')}}</f7-block-title>
              <div class="list">
                <ul class="row">
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.subtotal')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['subtotalComision']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.totalIVA')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['ivaComision']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.total')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['totalComision']"
                  ></f7-list-input>
                </ul>
              </div>
              <f7-block-title class="disabled">{{$t('ofertas.totales')}}</f7-block-title>
              <div class="list">
                <ul class="row">
                  <f7-list-input
                    class="col-100 medium-25 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.subtotal')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['subtotalConTerceros']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-25 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.totalTasas')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['totalTasas']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-25 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.totalIVA')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['ivaConTerceros']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-25 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.total')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['totalConTasasConTerceros']"
                  ></f7-list-input>
                </ul>
              </div>
            </div>
          </template>
          <div class="display-inline-block text-align-center">
            <f7-button
              v-if="verBotonAnterior(rform)"
              fill
              color="red"
              class="display-inline-block margin-horizontal"
              @click="step--"
            >{{$t('ofertas.anterior')}}</f7-button>
            <f7-button
              v-if="verBotonSiguiente(rform)"
              fill
              color="primary"
              class="display-inline-block margin-horizontal"
              @click="step++"
            >{{$t('ofertas.siguiente')}}</f7-button>
            <f7-button
              v-if="verBotonGuardar(rform)"
              fill
              color="primary"
              class="display-inline-block margin-horizontal"
              @click="guardarLinea(rform)"
            >{{$t('ofertas.guardar')}}</f7-button>
            <f7-button
              v-if="verBotonCerrar(rform)"
              fill
              color="primary"
              class="display-inline-block margin-horizontal"
              popup-close=".popupnew"
            >{{$t('ofertas.cerrar')}}</f7-button>
          </div>
        </template>
      </r-form>
    </f7-page>
  </f7-popup>
  <f7-popup ref="popupEmail" swipe-to-close :opened="true" v-if="popupEmailOpened" @popup:closed="popupEmailOpened = false; emailId = null">
    <registro-email-form :id="emailId" :editable="popupEmailEditable" @close="$refs.popupEmail.close();"  
      :defaultData="{ codigoOferta: $refs.rform.formData.codigo, estado: 'REALIZADA', fechaFecha: new Date(), fechaHora: new Date() }" :esPopup="true"
    ></registro-email-form>
  </f7-popup>
  <envio-mail
    v-if="enviarEmail"
    ref="enviarEmail"
    :selected="filasSeleccionadas()"
    @email-enviado="enviarEmail = false"
  ></envio-mail>

</f7-page>
</template>
<style>
.over-card label.disabled {
  opacity: 1 !important;
}
.form-caja .item-input-wrap {
  margin-right: 20px;
}
</style>
<style scoped>
.linea-texto {
  border: 2px solid gray;
  padding-left: 10px;
}
.linea-comentario-cerrada {
  border: 2px solid rgb(102, 71, 26);
}
.linea-comentario-direccion {
  border: 2px solid rgb(245, 191, 111);
}
.linea-item-error {
  background-color: rgb(249, 182, 182);
  border: 2px solid rgb(249, 0, 0);
}
.form-caja {
  min-height: 30px;
  border: 1px solid gray;
  border-radius: var(--f7-input-outline-border-radius);
  padding-bottom: 7px;
}
.form-caja div {
  font-size: 12px;
  color: gray;
}
.menu-item-dropdown .menu-item-content:after {
  content: inherit !important;
}
.over-card {
  position: absolute;
  top: -13px;
  z-index: 3000;
  left: 12px;
}
li.accordion-item .card, li.accordion-item .card ul, .border-3, li.accordion-item .accordion-item-content {
  border-radius: 20px;
}
.border-3 {
  border: 3px solid;
}
.label-group {
  margin-top: 12px;
  margin-left: 20px;
  font-weight: bold;
}
.label-group-inspeccion {
  margin-top: 30px;
}
label.btn {
  padding: 1px;
  cursor: pointer;
  border: 1px solid;
}
.bold {
  font-weight: bold;
}
.link-inline {
  position: absolute;
  top:0;
}
.link-inline-0 {
  right:0;
}
.link-inline-1 {
  right:25px;
}
.link-inline-2 {
  right:50px;
}
.link-inline-3 {
  right:75px;
}
.link-inline-alerta {
  right: 0;
  float: right;
  margin-right: 3px;
}
</style>
<style scoped>
.tab-link-active .item-media,
.accordion-item-opened .accordion-item-toggle .item-media {
  color: var(--f7-theme-color) !important;
}

.label-radio {
  width: var(--f7-radio-size);
  text-align: center;
}
label.radio.disabled,
label.item-checkbox.disabled {
  opacity: 1 !important;
}
.radio input[type="radio"]:not(:checked) ~ * {
  opacity: 0.2;
}

.page-content::after {
  content: "";
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
.list ul {
  background: rgba(255, 255, 255, 0.7);
}

.tab-link-active {
  background-color: var(--f7-theme-color-shade) !important;
}

.accordion-item {
  background-color: #f3f3f3;
}
.accordion-item-content {
  background-color: white;
}
div.row.list_popup {
  justify-content: normal;
  margin: 0;
}
</style>
<script>
import rForm from "./../../components/rForm.vue";
import rDynamicList from "./../../components/rDynamicList.vue";
import rInput from "./../../components/rInput.vue";
import rBadge from './../../components/rBadge';
import rRichTextInput from "./../../components/rRichTextInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rDireccion from "./../../components/rDireccion.vue";
import rCuenta from "./../../components/rCuenta.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import tabFinanzas from "../../components/tabFinanzas.vue";
import EnvioMail from "./components/envio-mail.vue";
import Oferta from "./../../js/db/Oferta.js";
import Pedido from "./../../js/db/Pedido.js";
import Item from "./../../js/db/Item.js";
import Empleado from "./../../js/db/Empleado.js";
import Empresa from "./../../js/db/Empresa.js";
import Delegacion from "./../../js/db/Delegacion.js";
import CentroTrabajo from "./../../js/db/CentroTrabajo.js";
import Departamento from "./../../js/db/Departamento.js";
import LineaNegocio from "./../../js/db/LineaNegocio.js";
import ListaPrecio from "./../../js/db/ListaPrecio.js";
import CondicionPago from "./../../js/db/CondicionPago.js";
import ViaPago from "./../../js/db/ViaPago.js";
import Instalacion from "./../../js/db/Instalacion.js";
import ContactoCliente from "./../../js/db/ContactoCliente.js";
import Comunidad from "./../../js/db/Comunidad.js";
import Servicio from "./../../js/db/Servicio.js";
import Cliente from "./../../js/db/Cliente.js";
import Documento from "./../../js/db/Documento.js";
import Campania from "./../../js/db/Campania.js";
import Oportunidad from "./../../js/db/Oportunidad.js";
import Utils from "./../../js/Utils.js";
import validator from "validator";
import DateUtils from "./../../js/DateUtils.js";
import Tarea from "./../../js/db/Tarea.js";
import TareaForm from "./../tareas/tarea-form.vue";
import RegistroEmail from "../../js/db/RegistroEmail.js";
import RegistroEmailForm from "./../registroemail/registroemail-form.vue";
import ValidationUtils from "./../../js/ValidationUtils.js";
import { Container, Draggable } from "vue-smooth-dnd";
import Inspeccion from '../../js/db/Inspeccion';

const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;
  console.log(arr, dragResult);
  //const result = [...arr];
  if (!arr) arr = [];
  var result = arr;

  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  //return result;
};

export default {
  components: {
    rForm,
    rInput,
    rBadge,
    rRichTextInput,
    rTimepicker,
    tabFinanzas,
    EnvioMail,
    rFiles,
    rAutocomplete,
    rDireccion,
    rCuenta,
    Container,
    Draggable,
    TareaForm,
    RegistroEmailForm,
    rDynamicList
  },
  data: function() {
    var self = this;
    return {
      Utils,
      Oferta,
      Pedido,
      Item,
      DateUtils,
      ValidationUtils,
      validator,
      Empleado,
      Empresa,
      Delegacion,
      CentroTrabajo,
      Departamento,
      LineaNegocio,
      ListaPrecio,
      CondicionPago,
      ViaPago,
      Instalacion,
      ContactoCliente,
      Comunidad,
      Servicio,
      Tarea,
      RegistroEmail,
      Cliente,
      Documento,
      Campania,
      Oportunidad,
      readonly: !this.editable,
      idData: null,
      step: 1,
      maxStep: 8,
      popupAceptacionOpened: false,
      clienteSelected: false,
      popupHistoricoEstadosOpened: false,
      nuevaLineaDefaultData: {estado: {estado: 'CREADA_OFERTA'}, tipoLinea:'LINEA', cantidad: 1, porcIVA:21, porcDescuento:0, precioUnitario:0, tasas:0, proveedor: {precio:0, porcIVA:21}, comision: {precio:0, porcIVA:21}},
      condicionesPagoActivas: [],
      viaPagoSeleccionada: false,
      rform: null,
      mailViejo: '',
      popupTareasOpened: false,
      tareaId: null,
      popupTareaEditable: false,
      popupEmailOpened: false,
      emailId: null,
      popupEmailEditable: false,
      enviarEmail: false,
      defaultOfertaData: null,
      codigoServicioLineaEditando: null,
      showDetalle: true,
      popupExtenderOpened: false,
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    totalesLineas: function() {
      var self = this;
      if(!self.$refs.rform || !self.$refs.rform.formData){
        return [];
      }
      return self.$refs.rform.formData.lineas.map(linea => {
        var totales = self.getTotalesLinea(linea);
        return totales;
      });
    },
    lineasPlanificable: function() {
      var self = this;
      if(!self.$refs.rform || !self.$refs.rform.formData){
        return [];
      }
      return self.$refs.rform.formData.lineas.map((linea, idx) => {
        var res = true;
        // Debe tener Servicio
        if (!linea.servicio || !linea.servicio.id) {
          res = false;
        }
        // Debe tener cantidad
        if ((!self.$refs.rform.formData.tipoOferta || self.$refs.rform.formData.tipoOferta == 'ORDINARIA') && linea.cantidad <= 0) {
          res = false;
        }
        // Debe tener precioUnitario al menos con 0
        // if (linea.precioUnitario <= 0) {
        //   res = false;
        // }
        return res;
      });
    }
  },

  beforeDestroy() {},

  methods: {
    tieneSubservicios: function(linea) {
      return linea.servicio && linea.servicio.subservicios && linea.servicio.subservicios.length > 0;
    },
    crearOportunidad() {
      const self = this;
      self.$f7.dialog.confirm('¿Está seguro de que desea crear una oportunidad?', 'Crear oportunidad', () => {
        Oportunidad.save({
          data: {oferta: {id: self.$refs.rform.formData.id}, gestor: Utils.getUser().username}
        }).then(res => {
          self.$f7.dialog.alert("Creada oportunidad " + res.codigo);
          window.open('#!/oportunidades/view/' + res.id + '/', "_blank");
        });
      });
    },
    getComentariosDireccion: function(item) {
      const self = this;
      let text = "";
      if (item.titular) {
        text = item.titular;
      }
      if (item.nifTitular) {
        text = text + ' NIF: ' + item.nifTitular;
      }
      if (item.direccionInspeccion) {
        text = text + " (" + self.getDireccionStr(item.direccionInspeccion) + ")";
      }
      return text;
    },
    getDireccionStr(direccion) {
      if (direccion) {
        if (direccion.direccionStr) {
          return direccion.direccionStr;
        }
        return (direccion.direccion || '') + ' ' + (direccion.cp || '') + ' ' + (direccion.poblacion || '') + ' (' + (direccion.pais || '') + ')';
      } else {
        return '';
      }
    },
    estaCancelada: function() {
      var self = this;
      return self.$refs.rform.formData.estado && self.$refs.rform.formData.estado.estado == 'CANCELADA';
    },
    hayLineasSinPedido: function() {
      var self = this;
      return self.$refs.rform && self.$refs.rform.formData && self.$refs.rform.formData.estado && self.$refs.rform.formData.estado.estado && (self.$refs.rform.formData.estado.estado == "ACEPTADA" || self.$refs.rform.formData.estado.estado == "EN_PROCESO" || self.$refs.rform.formData.estado.estado == "CERRADA") && self.$refs.rform.formData.lineas && self.$refs.rform.formData.lineas.filter(l => !l.pedido || !l.pedido.id).length > 0;
    },
    changeEmpleadoContratacion: function(emp) {
      var self = this;
      if (emp && emp.datos && emp.datos.codCentroTrabajo) {
        self.$set(self.$refs.rform.formData.contratacion, "codCentroTrabajo", emp.datos.codCentroTrabajo);
      }
    },
    loadedEmpleadoContratacion: function(list) {
      var self = this;
      var emp = list.find(l => l.username == self.$refs.rform.formData.contratacion.codEmpleado)
      self.changeEmpleadoContratacion(emp);
    },
    getLineasTexto: function(comentarios) {
      if(!comentarios) {
        return [];
      }
      return comentarios.split("##").map(c => c.trim());
    },
    addEmail: function(listName) {
      var self = this;
      var arr = self.$refs.rform.formData.cliente[listName] || [];
      arr.push("");
      self.$set(self.$refs.rform.formData.cliente, listName, arr);
    },
    getMaxNumeroLineas: function(lineas) {
      var maxNumero = -1;
      if (lineas && lineas.length) {
        lineas.forEach(l => {
          if (l.numero > maxNumero) {
            maxNumero = l.numero;
          }
        });
      }
      return maxNumero + 1;
    },
    onPopupPedidoOpen: function() {
      const self = this;
      if (self.$refs.rform && self.$refs.rform.formData && self.$refs.rform.formData.lineas) {
        self.$refs.rform.formData.lineas.forEach((linea, idx) => {
          if (linea.cantidad == 1) {
            self.$set(self.$refs.rform.formData.lineas[idx], "cantidadCrear", 1);
          } else {
            self.$set(self.$refs.rform.formData.lineas[idx], "cantidadCrear", 0);
          }
        });
      }
    },
    resetNewLine: function() {
      const self = this;
      self.step = 1; 
      if (self.$refs.rformNew) {
        self.$refs.rformNew.clear();
      }
    },
    onSaveAceptacion: function(res) {
      const self = this;
      const app = self.$f7;
      app.preloader.show();
      Oferta.aceptacion({itemId: self.idData, data: res})
        .then(function(res) {
          self.$refs.rform.formData = res;
          if (self.$refs.popupAceptacion) {
            self.$refs.popupAceptacion.close();
          }
          app.toast
          .create({
            icon: '<i class="f7-icons">checkmark</i>',
            text: self.$t("ofertas.aceptacion_ok"),
            position: "center",
            destroyOnClose: true,
            closeTimeout: 2000
          })
          .open();
        }).catch(function(e) {
          if (typeof e == "string") {
            e = {
              message: e
            };
          }
          app.dialog.alert(e.message);
        }).finally(function(e) {
          app.preloader.hide();
        });
    },
    onSaveExtender: function(res) {
      const self = this;
      const app = self.$f7;
      app.preloader.show();
      Oferta.extension({itemId: self.idData, data: res})
        .then(function(res) {
          self.$refs.rform.formData = res;
          if (self.$refs.popupExtender) {
            self.$refs.popupExtender.close();
          }
          app.toast
          .create({
            icon: '<i class="f7-icons">checkmark</i>',
            text: self.$t("ofertas.extension_ok"),
            position: "center",
            destroyOnClose: true,
            closeTimeout: 2000
          })
          .open();
        }).catch(function(e) {
          if (typeof e == "string") {
            e = {
              message: e
            };
          }
          app.dialog.alert(e.message);
        }).finally(function(e) {
          app.preloader.hide();
        });
    },
    onSaveEnvioManual: function(res) {
      const self = this;
      const app = self.$f7;
      if (!res.formaEnvio) {
        app.dialog.alert("Forma Envío Obligatoria");
        return;
      }
      self.cambiarEstado('ENVIADA', res.formaEnvio);
    },
    onSaveRechazo: function(res) {
      const self = this;
      const app = self.$f7;
      if (!res.motivoRechazo) {
        app.dialog.alert("Es necesario indicar el motivo de rechazo de la oferta.");
        return;
      }
      self.cambiarEstado('RECHAZADA',null, res.motivoRechazo);
      self.$refs.popupRechazo.close();
    },
    lanzarPopupRechazo: function() {
      const self = this;
      self.$refs.popupRechazo.open();
      self.$refs.rformRechazo.formData.motivoRechazo = self.$refs.rform.formData.motivoRechazo;
    },
    onSaveCancelar: function(res) {
      const self = this;
      const app = self.$f7;
      if (!res.motivoCancelacion) {
        app.dialog.alert("Es necesario indicar el motivo de cancelación de la oferta.");
        return;
      }
      self.cambiarEstado('CANCELADA',null, res.motivoCancelacion);
      self.$refs.popupCancelar.close();
    },
    lanzarPopupCancelar: function() {
      const self = this;
      self.$refs.popupCancelar.open();
      self.$refs.rformCancelar.formData.motivoCancelacion = self.$refs.rform.formData.motivoCancelacion;
    },
    comprobarFirmaCliente: function() {
      const self = this;
      const app = self.$f7;
      app.preloader.show();
      Oferta.refreshEnvio({itemId: self.idData})
        .then(function(res) {
          self.$refs.rform.formData = res;
          if (res.estado.estado == 'ENVIADA') {
            app.dialog.alert("El cliente aún no ha entrado a firmar la Oferta");
          } else if (res.estado.estado == 'ACEPTADA') {
            app.dialog.alert("<b><div class='text-color-green'>¡¡OFERTA ACEPTADA POR EL CLIENTE!!</b></div>");
          } else if (res.estado.estado == 'RECHAZADA') {
            app.dialog.alert("<b><div class='text-color-red'>:( El cliente ha rechazado la oferta</b></div>");
          }
        }).catch(function(e) {
          if (typeof e == "string") {
            e = {
              message: e
            };
          }
          app.dialog.alert(e.message);
        }).finally(function(e) {
          app.preloader.hide();
        });
    },
    aprobarOferta: function() {
      const self = this;
      const app = self.$f7;
      app.preloader.show();
      Oferta.aprobacion({itemId: self.idData})
        .then(function(res) {
          self.$refs.rform.formData = res;
          app.toast
          .create({
            icon: '<i class="f7-icons">checkmark</i>',
            text: self.$t("ofertas.aprobadaOK"),
            position: "center",
            destroyOnClose: true,
            closeTimeout: 2000
          })
          .open();
        }).catch(function(e) {
          if (typeof e == "string") {
            e = {
              message: e
            };
          }
          app.dialog.alert(e.message);
        }).finally(function(e) {
          app.preloader.hide();
        });
    },
    cambiarEstado: function(estado, formaEnvio, comentarios) {
      const self = this;
      const app = self.$f7;
      app.preloader.show();
      Oferta.cambioEstado({itemId: self.idData, data: {estado: {estado: estado, comentarios: comentarios}, formaEnvio: formaEnvio}})
        .then(function(res) {
          self.$refs.rform.formData = res;
          if (self.$refs.popupEnvioManual) {
            self.$refs.popupEnvioManual.close();
          }
          app.toast
          .create({
            icon: '<i class="f7-icons">checkmark</i>',
            text: self.$t("ofertas.cambio_estado_ok"),
            position: "center",
            destroyOnClose: true,
            closeTimeout: 2000
          })
          .open();
          if (estado == 'TERMINADA') {
            self.defaultOfertaData = { tipo: 'T0029', descripcion: "SEGUIMIENTO DE OFERTA", numeroOferta: res.numero, estado: 'PLANIFICADA', fechaFecha: self.getFechaTareaFinalizacion(), fechaHora: self.getFechaTareaFinalizacion(), tiempoEstimado: 5, username: Utils.getUser().username }
            self.popupTareaEditable = true; 
            self.popupTareasOpened = true;
          }
        }).catch(function(e) {
          if (typeof e == "string") {
            e = {
              message: e
            };
          }
          app.dialog.alert(e.message);
        }).finally(function(e) {
          app.preloader.hide();
        });
    },
    mostrarHistoricoEstados: function() {
      const self = this;
      self.popupHistoricoEstadosOpened = true;
    },
    getFechaTareaFinalizacion() {
      const self = this;
      let day = self.addDays(new Date(), 7);
      if (day.getDay() == 0) {
        day = self.addDays(day, -2);
      } else if (day.getDay() == 6) {
        day = self.addDays(day, -1);
      }
      return day;
    },
    getHistoricoEstados: function() {
      const self = this;
      return self.$refs.rform && self.$refs.rform.formData?self.$refs.rform.formData.historicoEstados:[];
    },
    onNewLine: function(nuevaLinea) {
      const self = this;
      if (self.$refs.rform.formData.tipoOferta && self.$refs.rform.formData.tipoOferta == 'MARCO') {
        nuevaLinea.cantidad = 0;
      }
      if (self.$refs.rform.formData.lineas.filter(l => l.numero == nuevaLinea.numero).length) {
        var linea = self.$refs.rform.formData.lineas.filter(l => l.numero == nuevaLinea.numero)[0];
        self.$set(self.$refs.rform.formData.lineas, self.$refs.rform.formData.lineas.indexOf(linea), nuevaLinea);
      } else {
        self.$refs.rform.formData.lineas.push(nuevaLinea);
        
      }
      if (self.$refs.popupnew) {
        self.$refs.popupnew.close();
      }
      self.moverTextosAOferta();
    },
    verBotonSiguiente: function(rform) {
      const self = this;

      if (self.step >= self.maxStep || !rform || !rform.formData) {
        return false;
      }
      if (self.readonly) {
        return true;
      }
      if (self.step == 1) {
        return rform.formData.direccionInspeccion && rform.formData.direccionInspeccion.cp;
      }
      if (self.step == 2) {
        return rform.formData.servicio && rform.formData.servicio.id && (!rform.formData.servicio.subservicios || rform.formData.servicio.subservicios.length == 0 || rform.formData.subservicio);
      }
      if (self.step == 3) {
        return true;
      }
      if (self.step == 4) {
        return typeof rform.formData.precioUnitario != 'undefined' && (typeof rform.formData.precioUnitario != 'string' || rform.formData.precioUnitario != "") && rform.formData.precioUnitario >= 0 ;
      }

      if (self.step == 6) {

        return !!!rform.formData.comision || !!!rform.formData.proveedor || ((!!rform.formData.comision.codigo == !!rform.formData.comision.precio) && (!!rform.formData.proveedor.codigo == !!rform.formData.proveedor.precio));

      }

      return true;
    },
    verBotonAnterior: function(rform) {
      const self = this;

      if (self.step <= 1 || !rform || !rform.formData) {
        return false;
      }
      // if (self.step == 3) {
      //   return !rform.formData.firma;
      // }

      return true;
    },
    verBotonGuardar: function(rform) {
      const self = this;

      return self.step == self.maxStep && rform && rform.formData && !rform.readonly;
    },
    verBotonCerrar: function(rform) {
      const self = this;

      return self.step == self.maxStep && rform && rform.readonly;
    },
    downloadDocumento: function (documento){
      var self = this;
      var app = self.$f7;
      app.preloader.show();
      Oferta.downloadDocumento(documento).catch(e => {
        if (typeof e == "string") {
          e = { message: e };
        }
        if(e.message) {
          app.dialog.alert(e.message);
        } else {
          var e = JSON.parse(new TextDecoder().decode(new Uint8Array(e.response)));
          var msg = e.error.message;
          app.dialog.alert(msg);
        }
      }).finally(_=>app.preloader.hide());
    },
    addDays: function(date, days) {
      date.setDate(date.getDate() + days);
      return date;
    },
    beforeSaveItem: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var formData = data;
      formData.clienteId = self.$refs.rform.formData.cliente.id;
      formData.codigoInstalacion = self.$refs.rformNew.formData.codigoInstalacion;
      formData.direccionInspeccion = self.$refs.rformNew.formData.direccionInspeccion;
      if (self.$refs.rformNew.formData.item) {
        formData.asociacion = self.$refs.rformNew.formData.item.asociacion;
        formData.instalador = self.$refs.rformNew.formData.item.instalador;
        formData.mantenedor = self.$refs.rformNew.formData.item.mantenedor;
        formData.administrador = self.$refs.rformNew.formData.item.administrador;
      }
      resolve(formData);
    },
    onSaveItem: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rformItem.clear();
      if (self.$refs.popupItem) {
        self.$refs.popupItem.close();
      }
      if (Array.isArray(self.$refs.item)) {
        self.$refs.item.forEach(i => i.reload());
      } else if (self.$refs.item) {
        self.$refs.item.reload();
      }
      if (self.$refs.rformNew && self.$refs.rformNew.formData) {
        self.$set(self.$refs.rformNew.formData, 'item', res);
      }
      app.toast
        .create({
          text: self.$t("ofertas.item_creado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    beforeSaveContacto: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var formData = data;
      formData.clienteId = self.$refs.rform.formData.cliente.id;
      resolve(formData);
    },
    onSaveContacto: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rformContacto.clear();
      if (self.$refs.popupContacto) {
        self.$refs.popupContacto.close();
      }
      if (Array.isArray(self.$refs.contactoCliente)) {
        self.$refs.contactoCliente.forEach(i => i.reload());
      } else if (self.$refs.contactoCliente) {
        self.$refs.contactoCliente.reload();
      }
      if (self.$refs.rform && self.$refs.rform.formData) {
        self.$set(self.$refs.rform.formData, 'contactoCliente', res);
        if (res && res.canalesComunicacion) {
          res.canalesComunicacion.filter(canal=>canal.tipo == "EMAIL").forEach(canal => {
            self.$set(self.$refs.rform.formData, 'emailContacto', canal.valor);
          });
          res.canalesComunicacion.filter(canal=>canal.tipo == "TELEFONO").forEach(canal => {
            self.$set(self.$refs.rform.formData, 'telefono', canal.valor);
          });
          res.canalesComunicacion.filter(canal=>canal.tipo == "TELEFONO_MOVIL").forEach(canal => {
            self.$set(self.$refs.rform.formData, 'telefonoMovil', canal.valor);
          });
        }
      }
      
      app.toast
        .create({
          text: self.$t("ofertas.contacto_creado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    beforeSaveInstalacion: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var formData = data;
      formData.clienteId = self.$refs.rform.formData.cliente.id;
      resolve(formData);
    },
    onSaveInstalacion: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rformInstalacion.clear();
      if (self.$refs.popupInstalacion) {
        self.$refs.popupInstalacion.close();
      }
      if (Array.isArray(self.$refs.instalacion)) {
        self.$refs.instalacion.forEach(i => i.reload());
      } else if (self.$refs.instalacion) {
        self.$refs.instalacion.reload();
      }
      if (self.$refs.rformNew && self.$refs.rformNew.formData) {
        self.$set(self.$refs.rformNew.formData, 'codigoInstalacion', res.codigo);
      }
      app.toast
        .create({
          text: self.$t("ofertas.instalacion_creada"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    selectAllPopup: function(checked, lineas) {
      var self = this;
      for (let index = 0; index < lineas.length; index++) {
        const linea = lineas[index];
        if (linea.estado && linea.estado.estado == "CREADA_OFERTA") {
          self.$set(
            self.$refs.rform.formData.lineas[index],
            "seleccionada",
            checked
          );
        }
        
      }
    },
    getTotalesOferta: function(lineas) {
      var self = this;
      var totales = {
        descuento: 0,
        totalSinDto: 0,
        subtotal: 0,
        iva: 0,
        totalTasas: 0,
        total: 0,
        subtotalProveedor: 0,
        ivaProveedor: 0,
        totalProveedor: 0,

        subtotalComision: 0,
        ivaComision: 0,
        totalComision: 0,

        subtotalTerceros: 0,
        ivaTerceros: 0,
        totalTerceros: 0,

        subtotalConTerceros: 0,
        ivaConTerceros: 0,
        totalConTerceros: 0,
        totalConTasasConTerceros: 0,
        totalSinTasasConTercerosSinIVa: 0
      };
      if(!lineas || lineas.length <= 0) {
        return totales;
      }
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false
      });
      lineas.forEach(linea => {
        var totalesLinea = self.getTotalesLinea(linea);
        for (const t in totales) {
          totales[t] = formatter.format(parseFloat(totales[t]) + parseFloat(totalesLinea[t]));
        }
      });
      return totales;
    },
    getTotalesLinea: function(linea) {
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false
      });
      var cantidad = linea.cantidad;
      var dtoUnitario = linea.precioUnitario / 100 * linea.porcDescuento;
      var totalUnitario = linea.precioUnitario - dtoUnitario;
      var descuento = dtoUnitario * cantidad;
      var totalSinDto = linea.precioUnitario * cantidad;
      var totalTasas = linea.tasas * cantidad;
      var subtotal = totalSinDto - descuento;
      var iva = subtotal / 100 * linea.porcIVA;
      var total = subtotal + iva;
      
      // Proveedor
      var proveedor = linea.proveedor || {precio: 0, porcIVA: 21};
      var subtotalProveedor = proveedor.precio * cantidad;
      var ivaProveedor = subtotalProveedor / 100 * proveedor.porcIVA;
      var totalProveedor = subtotalProveedor + ivaProveedor;
      // Comision
      var comision = linea.comision || {precio: 0, porcIVA: 21};
      var subtotalComision = comision.precio * cantidad;
      var ivaComision = subtotalComision / 100 * comision.porcIVA;
      var totalComision = subtotalComision + ivaComision;

      var subtotalTerceros = subtotalProveedor + subtotalComision;
      var ivaTerceros = ivaProveedor + ivaComision;
      var totalTerceros = totalProveedor + totalComision;


      var totales = {
        totalUnitario: formatter.format(totalUnitario),
        descuento: formatter.format(descuento),
        totalSinDto: formatter.format(totalSinDto),
        subtotal: formatter.format(subtotal),
        iva: formatter.format(iva),
        totalTasas: formatter.format(totalTasas),
        total: formatter.format(total),
        subtotalProveedor: formatter.format(subtotalProveedor),
        ivaProveedor: formatter.format(ivaProveedor),
        totalProveedor: formatter.format(totalProveedor),

        subtotalComision: formatter.format(subtotalComision),
        ivaComision: formatter.format(ivaComision),
        totalComision: formatter.format(totalComision),

        subtotalTerceros: formatter.format(subtotalTerceros),
        ivaTerceros: formatter.format(ivaTerceros),
        totalTerceros: formatter.format(totalTerceros),

        subtotalConTerceros: formatter.format(subtotal + subtotalProveedor + subtotalComision  ),
        ivaConTerceros: formatter.format(iva + ivaProveedor + ivaComision),
        totalConTerceros: formatter.format(total + totalProveedor + totalComision),
        totalConTasasConTerceros: formatter.format(total + totalProveedor + totalComision + totalTasas),
        totalSinTasasConTercerosSinIVa: formatter.format(total + totalProveedor + totalComision - iva - ivaProveedor - ivaComision)
      };
      
      return totales;
    },
    beforeSave: function(data, resolve, reject) {
      var self = this;
      for (const idx in data.lineas) {
        var linea = data.lineas[idx];
        linea.importe = self.totalesLineas[idx]["totalSinTasas"];
      }
      resolve(data);
    },
    changeCliente: function() {
      var self = this;
      var app = self.$f7;
      var cliente = self.$refs.cliente.selected;
      self.clienteSelected = cliente && cliente.id;
      if (cliente) {
        
          self.$set(self.$refs.rform.formData.logistica, 'direccionFacturacion', cliente.direccionDefectoFacturasDTO);
        
        
          self.$set(self.$refs.rform.formData.logistica, 'direccionEnvio', cliente.direccionDefectoEnvioDTO);
        
        if(cliente.condicionesPago){
          var condicionesPagoActivas = self.condicionesPagoActivas,
              condicionesPagoClienteActivas = cliente.condicionesPago.filter(condicion => condicionesPagoActivas.some(condicionActiva => condicionActiva.codigo == condicion.codigo)),
              condicionesPrincipales = condicionesPagoClienteActivas.filter(condicion => condicion.principal),
              condicionDefecto = condicionesPrincipales.length ? condicionesPrincipales[0] : null;
          if(!condicionDefecto && condicionesPagoClienteActivas.length > condicionesPrincipales.length) {
            // Se entra aquí si todas las condiciones principales han sido descartadas por estar desactivadas, pero hay alguna condición de pago que no está marcada como principal
            var condicionesNoPrincipales = condicionesPagoClienteActivas.filter(condicion => !condicion.principal);
            condicionDefecto = condicionesNoPrincipales.length ? condicionesNoPrincipales[0] : null;
          }
          if(condicionDefecto){
            self.$set(self.$refs.rform.formData.finanzas, 'codCondicionesPago', condicionDefecto.codigo);
            self.$set(self.$refs.rform.formData.finanzas, 'codFormaPago', condicionDefecto.viaPago.codigo);
            self.$refs.tabFinanzas.initDatosFinanzas(condicionDefecto.codigo);
          }
        } else {
            self.$set(self.$refs.rform.formData.finanzas, 'codCondicionesPago', undefined);
            self.$set(self.$refs.rform.formData.finanzas, 'codFormaPago', undefined);
            self.$refs.tabFinanzas.initDatosFinanzas();
        }
      
        delete self.$refs.rform.formData.contactoCliente;
        delete self.$refs.rform.formData.emailContacto;
        delete self.$refs.rform.formData.telefono;
        delete self.$refs.rform.formData.telefonoMovil;
        self.$refs.rform.formData.lineas.forEach(linea => {
          delete linea.item;
          delete linea.codigoInstalacion;
        });
      }
    },
    changeContacto: function() {
      var self = this;
      var app = self.$f7;
      var contactocliente = self.$refs.contactoCliente.selected;
      if (contactocliente && contactocliente.canalesComunicacion) {
        contactocliente.canalesComunicacion.filter(canal=>canal.tipo == "EMAIL").forEach(canal => {
          self.$set(self.$refs.rform.formData, 'emailContacto', canal.valor);
          self.changeEmailContacto(null, canal.valor);
        });
        contactocliente.canalesComunicacion.filter(canal=>canal.tipo == "TELEFONO").forEach(canal => {
          self.$set(self.$refs.rform.formData, 'telefono', canal.valor);
        });
        contactocliente.canalesComunicacion.filter(canal=>canal.tipo == "TELEFONO_MOVIL").forEach(canal => {
          self.$set(self.$refs.rform.formData, 'telefonoMovil', canal.valor);
        });
      }
    },
    changeEmailContacto: function(event, valorAMano) {
      var self = this;
      var app = self.$f7;
      if ((event && event.type && event.type == 'change') || (!event && valorAMano && typeof valorAMano == 'string')) {
        var emailContacto = valorAMano || self.$refs.rform.formData.emailContacto;
        if (self.mailViejo) {
          if (self.$refs.rform.formData.cliente.emailsCertificados) {
            let i = self.$refs.rform.formData.cliente.emailsCertificados.indexOf(self.mailViejo);
            if (i >= 0) {
              self.$refs.rform.formData.cliente.emailsCertificados.splice(i,1);
            }
          }
          if (self.$refs.rform.formData.cliente.emailsFacturas) {
            let i = self.$refs.rform.formData.cliente.emailsFacturas.indexOf(self.mailViejo);
            if (i >= 0) {
              self.$refs.rform.formData.cliente.emailsFacturas.splice(i,1);
            }
          }
        }
        if (emailContacto) {
          if (!self.$refs.rform.formData.cliente.emailsCertificados) {
            self.$set(self.$refs.rform.formData.cliente, 'emailsCertificados', []);
          }
          if (!self.$refs.rform.formData.cliente.emailsFacturas) {
            self.$set(self.$refs.rform.formData.cliente, 'emailsFacturas', []);
          }
          if (!self.$refs.rform.formData.cliente.emailsCertificados.includes(emailContacto)) {
            self.$set(self.$refs.rform.formData.cliente.emailsCertificados, self.$refs.rform.formData.cliente.emailsCertificados.length+'', emailContacto);
          }
          if (!self.$refs.rform.formData.cliente.emailsFacturas.includes(emailContacto)) {
            self.$set(self.$refs.rform.formData.cliente.emailsFacturas, self.$refs.rform.formData.cliente.emailsFacturas.length+'', emailContacto);
          }

        }
        self.mailViejo = emailContacto;
      }
    },
    crearPedido: function(lineas) {
      var self = this;
      var app = self.$f7;
      var msgError = undefined;
      lineas.forEach(linea => {
        if (linea.seleccionada && (!linea.cantidadCrear || linea.cantidadCrear == 0 || (self.$refs.rform.formData.tipoLinea == 'ORDINARIA' && linea.cantidadCrear > linea.cantidad))) {
          msgError = "Las cantidades a crear pedido deben estar rellenas correctamente";
          return;
        }
      });
      if (msgError) {
        app.dialog.alert(msgError);
        return;
      }
      var lineasSeleccionadas = lineas.filter(l=>l.seleccionada).map(l => {
        return {numero: l.numero, cantidad: l.cantidadCrear}
      });
      app.dialog.confirm(
        "¿Seguro que desea crear pedido con las líneas seleccionadas?",
        "Creación de pedido",
        function() {
          app.preloader.show();
          Pedido.save({
            data: { idOferta: self.idData , numerosLinea: lineasSeleccionadas},
            itemId: null
          })
            .then(function(res) {
              app.dialog.alert("Pedido Creado correctamente");
              app.views.current.router.navigate(
                "/pedidos/view/" + res.id + "/"
              );
              if (self.$refs.popupPedido) {
                self.$refs.popupPedido.close();
              }
            })
            .catch(function(e) {
              if (typeof e == "string") {
                e = {
                  message: e
                };
              }
              app.dialog.alert(e.message);
            })
            .finally(function() {
              app.preloader.hide();
            });
        }
      );
    },
    changeServicio: function(linea) {
      var self = this;
      if (!self.readonly && (!self.codigoServicioLineaEditando && linea.servicio || self.codigoServicioLineaEditando && linea.servicio && linea.servicio.codigo != self.codigoServicioLineaEditando)) {
        Servicio.get({ itemId: linea.servicio.id }).then(serv => {
          linea.servicio.textos = serv.textos;
          self.$set(linea, 'subservicio', undefined);
          var precio = undefined;
          if (serv.porcSegunda && !linea.porcSegunda && !linea.importeSegunda) {
            self.$set(linea, 'porcSegunda', linea.servicio.porcSegunda);
          }
          self.$set(linea, 'porcMinSegunda', linea.servicio.porcMinSegunda);
          self.$set(linea, 'blocPorcSegundas', linea.servicio.blocPorcSegundas);
          if (linea.servicio.precios && linea.servicio.precios.length > 0) {
            var listaPrecios = self.$refs.cliente.selected
              ? self.$refs.cliente.selected.listaPrecios
              : undefined;
            if (listaPrecios) {
              precio = linea.servicio.precios.filter(
                s => s.listaPrecios == listaPrecios
              )[0];
            }
            if (!precio) {
              precio = linea.servicio.precios[0];
            }
          }
          if (precio) {
            self.$set(
              linea,
              "precioUnitario",
              precio.precioUnidad
            );
          }
          var tasa = undefined;
          if (linea.servicio.tasas && linea.servicio.tasas.length > 0) {

            var comunidad = linea.direccionInspeccion ? linea.direccionInspeccion.comunidad : undefined;
            if (comunidad) {
              var tasaDTO = linea.servicio.tasas.filter(s => s.comunidad == comunidad)[0];
              tasa = tasaDTO ? tasaDTO.valor : undefined;
            }
            if (!tasa) {
              tasa = linea.servicio.tasaDefecto;
            }
          } else {
            tasa = linea.servicio.tasaDefecto;
          }
          if (tasa) {
            self.$set(linea, 'tasas', tasa);
          }
          self.$set(linea, 'textos', linea.servicio.textos);
          if (
            linea.servicio.tiempoEstimadoCampo
          ) {
            self.$set(
              linea,
              "tiempoEstimadoCampo",
              linea.servicio.tiempoEstimadoCampo
            );
          }
          if (
            linea.servicio.tiempoEstimadoOficina
          ) {
            self.$set(
              linea,
              "tiempoEstimadoOficina",
              linea.servicio.tiempoEstimadoOficina
            );
          }
          if (
            linea.servicio.codCuentaMayor
          ) {
            self.$set(
              linea,
              "codCuentaMayor",
              linea.servicio.codCuentaMayor
            );
          }
          self.codigoServicioLineaEditando = linea.servicio.codigo;
        }).catch(function (error) {
          app.dialog.alert(error.message);
        });
      }
    },
    checkPorcSegunda() {
      const newLine = this.$refs.rformNew.formData;
      if (newLine.porcMinSegunda && newLine.porcSegunda < newLine.porcMinSegunda) {
        newLine.porcSegunda = newLine.porcMinSegunda;
      }
    },
    changeSubservicio: function(linea, evento) {
      const self = this;
      let subservicio = linea.servicio.subservicios.filter(s => s.nombre == evento)[0];
      self.$set(linea, 'subservicio', subservicio);
    },
    onChangeInstalacion: function (item) {
      var self = this;
      if (typeof item != 'undefined') {
        self.$set(item, 'codigoInstalacion', null);
        self.$set(item, 'codigo', null);
        self.$set(item, 'nombre', null);
        self.$set(item, 'id', null);
      }

    },
    beforeChangeItem: function (itemNuevo) {
      var self = this;
      var itemViejo = self.$refs.rformNew.formData.item;
      if (typeof itemViejo != 'undefined' && itemViejo != null){
        itemViejo.codigo = null;
        itemViejo.nombre = null;
        itemViejo.codInstalacion = null;
        itemViejo.id = null;
        itemNuevo = Object.assign({}, itemViejo, itemNuevo);
      }
      return itemNuevo;

    },
    changeItem: function(linea) {
      var self = this;
      if (!linea.direccionInspeccion && linea.item && linea.item.direccion) {
          self.$set(self.$refs.rformNew.formData, 'direccionInspeccion', linea.item.direccion);
      }
      if (!linea.codigoInstalacion && linea.item && linea.item.codigoInstalacion) {
          self.$set(self.$refs.rformNew.formData, 'codigoInstalacion', linea.item.codigoInstalacion);
      }
      if (linea.item && linea.item.administrador) {
          self.$set(self.$refs.rformNew.formData, 'administrador', linea.item.administrador);
      }
      if (linea.item && linea.item.instalador) {
          self.$set(self.$refs.rformNew.formData, 'instalador', linea.item.instalador);
      }
      if (linea.item && linea.item.mantenedor) {
          self.$set(self.$refs.rformNew.formData, 'mantenedor', linea.item.mantenedor);
      }
      if (linea.item && linea.item.asociacion) {
          self.$set(self.$refs.rformNew.formData, 'asociacion', linea.item.asociacion);
      }
    },
    loadItem: function(res) {
      const self = this;
      self.$set(self.$refs.rform, "formData", res);
      if (!res.lineas || res.lineas == null) {
        self.$set(self.$refs.rform.formData, "lineas", []);
      } else {
        var sortedLineas = self.getSortedLineas(res.lineas);
        sortedLineas.forEach(l => {
          if (!l.textos || l.textos == null) {
            l.textos = [];
          }
        });
        self.$set(self.$refs.rform.formData, "lineas", sortedLineas);
        self.moverTextosAOferta();
      }

      // cargamos las finanzas, aunque si metemos todo el res al formData no lo veo necesario.
      if(!res.finanzas) {
        self.$set(self.$refs.rform.formData, "finanzas", {});
      } 

      if (!res.textos || res.textos == null) {
        self.$set(self.$refs.rform.formData, "textos", []);
      }
      self.clienteSelected = res.cliente && res.cliente.id;
      let codCondPago = res.finanzas.codCondicionesPago;
      if (!codCondPago && self.clienteSelected) {
        Cliente.get({itemId: res.cliente.id}).then(cliente => {
          if(cliente.condicionesPago){
            var condicionesPagoActivas = self.condicionesPagoActivas,
                condicionesPagoClienteActivas = cliente.condicionesPago.filter(condicion => condicionesPagoActivas.some(condicionActiva => condicionActiva.codigo == condicion.codigo)),
                condicionesPrincipales = condicionesPagoClienteActivas.filter(condicion => condicion.principal),
                condicionDefecto = condicionesPrincipales.length ? condicionesPrincipales[0] : null;
            if(!condicionDefecto && condicionesPagoClienteActivas.length > condicionesPrincipales.length) {
              // Se entra aquí si todas las condiciones principales han sido descartadas por estar desactivadas, pero hay alguna condición de pago que no está marcada como principal
              var condicionesNoPrincipales = condicionesPagoClienteActivas.filter(condicion => !condicion.principal);
              condicionDefecto = condicionesNoPrincipales.length ? condicionesNoPrincipales[0] : null;
            }
            if(condicionDefecto){
              self.$set(self.$refs.rform.formData.finanzas, 'codCondicionesPago', condicionDefecto.codigo);
              self.$set(self.$refs.rform.formData.finanzas, 'codFormaPago', condicionDefecto.viaPago.codigo);
              self.$refs.tabFinanzas.initDatosFinanzas(condicionDefecto.codigo);
            }
          } else {
              self.$set(self.$refs.rform.formData.finanzas, 'codCondicionesPago', undefined);
              self.$set(self.$refs.rform.formData.finanzas, 'codFormaPago', undefined);
              self.$refs.tabFinanzas.initDatosFinanzas();
          }
        })
      } else {
        self.$nextTick(() => {
          self.$refs.tabFinanzas.initDatosFinanzas(codCondPago);
        })
      }
    },
    getSortedLineas: function(lineas) {
      var compare = function (a, b) {
        if (!a.estado || !a.estado.estado) {
          return -1;
        }
        if (!b.estado || !b.estado.estado) {
          return 1;
        }
        if (a.estado.estado == b.estado.estado) {
          return a.numero < b.numero ? -1 : 1;
        }
        if (a.estado.estado == "CREADA_OFERTA") {
          return -1;
        }
        return 1;
      }
      return lineas.sort(compare);
    },
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          Oferta.delete({ itemId: self.idData })
            .then(function(res) {
              if (res) {
                app.toast
                  .create({
                    icon: '<i class="f7-icons">checkmark</i>',
                    text: self.$t("rlist.registro_eliminado"),
                    position: "center",
                    destroyOnClose: true,
                    closeTimeout: 2000
                  })
                  .open();
                self.$f7router.back();
              }
            })
            .catch(function(error) {
              app.dialog.alert(error.message);
            });
        }
      );
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$set(self.$refs.rform, "formData", res);
      self.$refs.tabFinanzas.initDatosFinanzas(res.finanzas.codCondicionesPago);
      if (!self.idData) {
        app.views.current.router.navigate(
                "/ofertas/view/" + res.id + "/"
              );
      }
      self.idData = res.id;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("ofertas.guardado_ok"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    filterViasPago: function(autocomplete){
      var self = this;
      return function(query, render) {
        var results = autocomplete.items.filter(item => self.condicionesPagoActivas.some(condicion => condicion.viaPago.codigo == item.codigo))
          .map(condicion => {
            condicion['txtValue'] = condicion[autocomplete.searchProp];
            return condicion;
          });
        render(results);
      };
    },
    filterCondicionesCuentas: function(autocomplete){
      var self = this;
      return function(query, render) {
        var options = self.condicionesPagoActivas.filter(condicion => self.getCondicionesPagoPosibles().some(condicionPosible => condicionPosible.id == condicion.id && condicion.cuenta))
          .map(condicion => {
            if(condicion["cuenta"] != undefined){
              condicion['txtValue'] = ""+condicion["cuenta"].trim();
            }
            return condicion;
          });
        options.sort((a,b) => a.cuenta > b.cuenta ? 1 : -1);
        var results = [];
        options.forEach(option => {
          if(!results.some(result => result.txtValue == option.txtValue) && option.txtValue){
            results.push(option);
          }
        });

        render(results);
      };
    },
    filterCondicionesPlazos: function(autocomplete){
      var self = this;
      return function(query, render) {
        var options = self.condicionesPagoActivas.filter(condicion => self.getCondicionesPagoPosibles().some(condicionPosible => condicionPosible.id == condicion.id && condicion.plazoDias != undefined))
          .map(condicion => {
            if(condicion["plazoDias"] != undefined){
              condicion['txtValue'] = ""+condicion["plazoDias"];
            }
            return condicion;
          });
        options.sort((a,b) => a.plazoDias > b.plazoDias ? 1 : -1);
        var results = [];
        options.forEach(option => {
          if(!results.some(result => result.txtValue == option.txtValue) && option.txtValue){
            results.push(option);
          }
        });

        render(results);
      };
    },
    onDrop(arr, dropResult) {
      var self = this;
      applyDrag(arr, dropResult);
    },
    getTextoBadge: function(linea, lineaPlanificable) {

      if (linea.tipoLinea == 'TASAS') {
        return "Línea de tasas";
      }
      else if (linea.tipoLinea == 'SUBCONTRATACION') {
        return "Línea de subcontratación";
      }
      else if (linea.tipoLinea == 'COMISION') {
        return "Línea de comisión";
      }
      else if (linea.pedido && linea.pedido.id) {
        return "Pasada a pedido";
      }
      else if ((!linea.estado || linea.estado.estado != 'PLANIFICADA') && lineaPlanificable) {
        return "Preparada para crear pedido";
      }
      else if ((!linea.estado || linea.estado.estado != 'PLANIFICADA') && !lineaPlanificable) {
        return "Faltan datos por rellenar para poder crear pedido";
      }
    },

    getTextoBadgeEstado: function(formData) {

      if (!formData || !formData.estado || !formData.estado.estado) {
        return "Sin Estado";
      }

      const estado = formData.estado.estado;

      if (estado == 'ABIERTA') {
        return "Abierta";
      }
      else if (estado == 'TERMINADA') {
        return "Terminada";
      }
      else if (estado == 'APROBADA') {
        return "Aprobada";
      }
      else if (estado == 'ENVIADA') {
        return "Enviada";
      }
      else if (estado == 'ACEPTADA') {
        return "Aceptada";
      }
      else if (estado == 'RECHAZADA') {
        return "Rechazada";
      }
      else if (estado == 'CADUCADA') {
        return "Caducada";
      }
      else if (estado == 'EN_PROCESO') {
        return "En proceso";
      }
      else if (estado == 'CERRADA') {
        return "Cerrada";
      }
      else if (estado == 'CANCELADA') {
        return "Cancelada";
      }
      
    },
      
    getColorBadge: function(linea, lineaPlanificable) {

      if (linea.tipoLinea == 'TASAS' || linea.tipoLinea == 'SUBCONTRATACION' || linea.tipoLinea == 'COMISION') {
        return "blue";
      }
      else if (linea.pedido && linea.pedido.id) {
        return "green";
      }
      else if ((!linea.estado || linea.estado.estado != 'PLANIFICADA') && lineaPlanificable) {
        return "orange";
      }
      else if ((!linea.estado || linea.estado.estado != 'PLANIFICADA') && !lineaPlanificable) {
        return "red";
      }
      
      
      
    },

    getColorBadgeEstado: function(formData) {

      if (!formData || !formData.estado || !formData.estado.estado) {
        return "gray";
      }
      
      const estado = formData.estado.estado;

      if (estado == 'ABIERTA') {
        return "blue";
      }
      else if (estado == 'TERMINADA') {
        return "purple";
      }
      else if (estado == 'APROBADA') {
        return "lime";
      }
      else if (estado == 'ENVIADA') {
        return "green";
      }
      else if (estado == 'ACEPTADA') {
        return "teal";
      }
      else if (estado == 'RECHAZADA') {
        return "red";
      }
      else if (estado == 'CADUCADA') {
        return "yellow";
      }
      else if (estado == 'EN_PROCESO') {
        return "deeporange";
      }
      else if (estado == 'CERRADA') {
        return "gray";
      }
      else if (estado == 'CANCELADA') {
        return "pink";
      }
      
      
      
    },
    deshabilitar (estaInformado) {
      if (estaInformado === 0 || estaInformado > 0) {
        return true;
      } else {
        return false;
      }
    },
    sourceItem: function() {
      var self = this;
      return function(query, render) {
        var results = [];
        let selfItem = self.$refs.item;
        for (var i = 0; i < selfItem.items.length; i++) {
          selfItem.items[i]['txtValue'] = selfItem.items[i]['codigo'] + ' - ' + selfItem.items[i]['nombre'];
          if (
            ( selfItem.items[i]['txtValue'].toLowerCase().indexOf(query.toLowerCase()) >= 0 )
          ) {
            results.push(selfItem.items[i]);
          }
        }
        render(results);
      };
    },
    calcularFiltroItem: function() {
      var self = this;
      //v-if="self.$refs.rformNew.formData.porCliente || $refs.rformNew.formData.direccionitem || $refs.rformNew.formData.identificador"
      let filtro = {filter: {}};
      if (self.$refs.rformNew.formData.porMateria) {
        filtro.filter['codigoMateria'] =  self.$refs.rformNew.formData.servicio.materia.codigo;
      }
      // Si nos dicen que filtre por cliente, lo añadimos.
      if (self.$refs.rformNew.formData.porCliente) {
        filtro.filter['codigoCliente'] = self.$refs.rform.formData.cliente.codigo;
      }
      // Si nos dicen que filtre por dirección, lo añadimos.
      if (self.$refs.rformNew.formData.porDireccion) {
        filtro.filter['cp'] = self.$refs.rformNew.formData.direccionInspeccion.cp;
      }
      // Si nos dicen que filtre por identificador, lo añadimos.
      if (self.$refs.rformNew.formData.identificador) {
        filtro.filter['codigo'] = self.$refs.rformNew.formData.identificador.replace('|', '\\|');
      }
      // self.$refs.rformNew.formData.item = null;
      return filtro;
    },
    onchangefilter: function(a, b, extraData) {
      if (!extraData || !extraData.fromMounted) {
        var self = this;
        if(self.$refs.item) self.$refs.item.setFilter(self.calcularFiltroItem());
      }
    },
    guardarLinea: function(rformNew) {
      var self = this;
      // miramos si hay alguna inspeccion voluntaria aún en vigor para el item seleccionado.
      var data = {
        codigoCliente: self.$refs.rform.formData.cliente.codigo,
        codigoItem: rformNew.formData.item?rformNew.formData.item.codigo:null,
        idServicio: rformNew.formData.servicio?rformNew.formData.servicio.id:null,
        fechaInsp: new Date(),
        direccionInspeccion: rformNew.formData.direccionInspeccion
      };
      Inspeccion.hayServiciosExcluidos(data).then(function(res) {
        if (res['hayServiciosExcluidos']) {
          // Si la hay, informaremos el campo ignoreAlertaItem con el texto de la alerta y pondremos el campo de la línea ignoreAlertaItem a false.
          rformNew.formData.ignoreAlertaItem = false;
          rformNew.formData.alertaItem = res.textoAlerta;
          rformNew.saveItem();
        } else {
          // Si no la hay, anulamos la alarma y guardaremos la línea.
          rformNew.formData.ignoreAlertaItem = null;
          rformNew.formData.alertaItem = null;
          rformNew.saveItem();
        }
      });
    },
    moverTextosAOferta: function() {
      var self = this;
      var app = self.$f7;
      var textos = self.$refs.rform.formData.textos;
      let oferta = self.$refs.rform.formData;
      if(oferta && !oferta.lineas) {self.$set(oferta, 'lineas', []);}
      if(oferta.lineas.length > 1) {
        // Miraremos si hay textos en la línea, que coinciden con los del resto de líneas de la oferta, y si es así, los moveremos a la oferta.
        // también eliminaremos de la oferta los textos que ya no estén en todas las líneas.
        // Marcaremos los textos de la oferta que procedan de las líneas con un flag y los de la línea que se hayan copiado a la oferta también.
        let textosIguales = [];
        let contador = 0;
        oferta.lineas.forEach(linea => {
          // si es la primera línea que comprobamos, copiamos los textos a textosIguales.
          if(contador++ == 0) {
            textosIguales = JSON.parse(JSON.stringify(linea.textos.filter(texto => (texto.activo || texto.obligatorio) && (!texto.nombre || texto.nombre != 'PRESUPUESTO') && texto.html && self.convertirHtmlATextoPlano(texto.html) != 'SIN DEFINIR')));
            // generamos un hash con el nombre y el html de los textos para poder compararlos.
            textosIguales.forEach(texto => {
              if(!texto.camposPersonalizados) { texto.camposPersonalizados = {}; }
              texto.camposPersonalizados['movidosAOferta'] = true;
              texto['hash'] = self.hash(texto.nombre + self.convertirHtmlATextoPlano(texto.html));
            });
          } else {
            let textosIgualesFiltrados = [];
            // si no es la primera línea, comprobamos si los textos de la línea son iguales a los de textosIguales.
            textosIguales.forEach(ti => {
              linea.textos.forEach(texto => {
                if(!texto.camposPersonalizados) { texto.camposPersonalizados = {}; }
                texto['hash'] = self.hash(texto.nombre + self.convertirHtmlATextoPlano(texto.html));
              });
              if(linea.textos.filter(texto => (texto.activo || texto.obligatorio) && (!texto.nombre || texto.nombre != 'PRESUPUESTO') && texto.html && self.convertirHtmlATextoPlano(texto.html) != 'SIN DEFINIR' && texto.hash == ti.hash).length > 0) {
                textosIgualesFiltrados.push(ti);
              }
            });
            // si no lo son, eliminamos los textos de textosIguales que no estén en la línea.
            // si lo son, no hacemos nada.
            textosIguales = textosIgualesFiltrados;
          }
        });
        // Actualizamos los textos de la oferta mezclando sus textos con la lista de textosIguales.
        // - eliminamos los textos de la oferta migrados de las líneas.
        // - añadimos los textos de textosIguales que en la oferta y los marcamos tanto en oferta como en líneas como camposPersonalizados.movidosAOferta = true.
        // oferta.textos = oferta.textos.filter(texto => !texto.camposPersonalizados || !texto.camposPersonalizados.movidosAOferta);
        // textosIguales.forEach(textoIgual => {
        //   if(!textoIgual.camposPersonalizados) { textoIgual.camposPersonalizados = {}; }
        //   textoIgual.camposPersonalizados.movidosAOferta = true;
        //   oferta.textos.push(textoIgual);
        // });

        oferta.textos.forEach(texto => texto['hash'] = self.hash(texto.nombre + self.convertirHtmlATextoPlano(texto.html)));
        textosIguales.forEach(textoIgual => {
          if(!textoIgual.camposPersonalizados) { textoIgual.camposPersonalizados = {}; }
          textoIgual.camposPersonalizados.movidosAOferta = true;
          let textoActual = oferta.textos.filter(texto => texto.hash == textoIgual.hash)[0];
          if (!textoActual) {
            oferta.textos.push(textoIgual);
          }
        });

        oferta.textos = oferta.textos.filter(texto => !texto.camposPersonalizados || !texto.camposPersonalizados.movidosAOferta || textosIguales.filter(t => t.hash == texto.hash).length);


        // Actualizamos las líneas de la oferta.
        oferta.lineas.forEach(linea => {
          // desmarcaremos todos los textos de la línea, que no estén en textosIguales.
          linea.textos.forEach(texto => {
            texto['hash'] = self.hash(texto.nombre + self.convertirHtmlATextoPlano(texto.html));
            if(textosIguales.filter(ti=>ti.hash == texto.hash).length == 0) {
              if(texto.camposPersonalizados) {
                delete texto.camposPersonalizados.movidosAOferta;
              }
            } else {
              if(!texto.camposPersonalizados) { texto.camposPersonalizados = {}; }
              texto.camposPersonalizados.movidosAOferta = true;
            }
          });
        });
      } else {
        // limpiamos los textos de la marca de movidos a oferta y los eliminamos de la oferta.
        oferta.textos = oferta.textos.filter(texto => !texto.camposPersonalizados || !texto.camposPersonalizados.movidosAOferta);
        // Si hay una línea en la oferta:
        if(oferta.lineas.length > 0) {
          // eliminamos los textos de la línea de la marca de movidos a oferta.
          oferta.lineas.forEach(linea => {
            linea.textos.forEach(texto => {
              if(texto.camposPersonalizados) {
                delete texto.camposPersonalizados.movidosAOferta;
              }
            });
          });
        }
      }
    },
    convertirHtmlATextoPlano: function (html) {
      var elementoTemporal = document.createElement("div");
      elementoTemporal.innerHTML = html;
      return elementoTemporal.textContent || elementoTemporal.innerText || "";
    },
    hash: function(texto) {
        var hash = 0;
        for (var i = 0; i < texto.length; i++) {
            var caracter = texto.charCodeAt(i);
            hash = ((hash << 5) - hash) + caracter;
            hash = hash & hash; // Convertir a un número de 32 bits
        }
        return hash;
    },
    hayAlertas: function() {
      var self = this;
      var hayAlertas = false;
      self.$refs.rform.formData.lineas.forEach(function(linea) {
        if (linea.alertaItem && !linea.ignoreAlertaItem) {
          hayAlertas = true;
        }
      });
      return hayAlertas;
    },
    filasSeleccionadas: function() {
      const self = this;
      let filas = [self.$refs.rform.formData].map(selected => {
        return {id: selected.id};
      });
      return filas;
    }

  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
    self.idData = self.id;
    CondicionPago.getList({filter:{activa:true}}).then(function(res){
      self.condicionesPagoActivas = res.list;
    });  },
  mounted: function(){
    var self = this;
    self.rform = self.$refs.rform.formData;
  }
};
</script>
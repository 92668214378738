<template>
  <div>
    <div v-if="type == 'badge'"  class="item-content item-input item-input-with-value">
      <div class="item-inner">
        <div v-if="label" class="item-title item-label item-floating-label">
          {{label}}
        </div>
        <slot name="label"></slot>
        <div class="item-input-wrap">
          <r-badge :text="text" :color="color"></r-badge>
        </div>
        <f7-link
          v-if="getShowDecorationLink()"
          class="input-suffix-btn r-input-little r-input-top"
          :icon-f7="decorationConfig.icon"
          :color="decorationConfig.color"
          v-tooltip="decorationConfig.tooltip"
          @click="decorationConfig.action()"
        ></f7-link>
        <f7-link
          v-if="hasHistory && Utils.posterior2Dic2024(form.formData.fechaInsp)"
          class="input-suffix-btn r-input-little r-input-top history-link"
          :icon-f7="'archivebox'"
          v-tooltip="'Historico de valores'"
          @click="showHistory"
        ></f7-link>
        <span v-if="htmlError" v-html="htmlError()" @click.prevent="mostrarError()" style="z-index: 100;"></span>
      </div>
    </div>
    <div v-else-if="type == 'check' && !simple" class="item-content item-input item-input-with-value">
      <div class="item-inner">
        <div v-if="label" class="item-title item-label item-floating-label">
          {{label}}
        </div>
        <slot name="label"></slot>
        <div class="item-input-wrap">
          <f7-link
            v-if="hasHistory && Utils.posterior2Dic2024(form.formData.fechaInsp)"
            class="input-suffix-btn r-input-little r-input-top history-link"
            :icon-f7="'archivebox'"
            v-tooltip="'Historico de valores'"
            @click="showHistory"
          ></f7-link>
          <f7-toggle 
            class="item-input-wrap"
            :checked="getValue()"
            @change="setValue($event.target.checked, $event.target, true)"
            :disabled="overrideReadonly ? readonly : readonly || form.readonly"
            v-on="$listeners"
          ></f7-toggle>
        </div>
        <span v-if="htmlError" v-html="htmlError()" @click.prevent="mostrarError()" style="z-index: 100;"></span>
      </div>
      <slot name="content"></slot>
    </div>
    <div v-else-if="type == 'check'">
      <f7-list>
        <f7-link
          v-if="hasHistory && Utils.posterior2Dic2024(form.formData.fechaInsp)"
          class="input-suffix-btn r-input-little r-input-top history-link"
          :icon-f7="'archivebox'"
          v-tooltip="'Historico de valores'"
          @click="showHistory"
        ></f7-link>
        <f7-list-item
          checkbox
          v-on="$listeners"
          :disabled="overrideReadonly ? readonly : readonly || form.readonly"
          :title="label"
          :checked="getValue()"
          @change="setValue($event.target.checked, $event.target, true)"
        ></f7-list-item>
      </f7-list>
      <span v-if="htmlError" v-html="htmlError()" @click.prevent="mostrarError()" style="z-index: 100;"></span>
      <slot name="content"></slot>
    </div>
    <div v-else-if="type == 'checks'" class="item-content item-input item-input-with-value">
      <div class="item-inner item-checks">
        <div v-if="label" class="item-title item-label item-label-up item-floating-label">
          {{label}}
        </div>
        <slot name="label"></slot>
        <div class="item-input-wrap">
          <f7-list>
            <f7-list-item 
              v-for="(itm, idx) in dbItems"
              :key="name + 'check-' + idx"
              @change="setValue($event.target.checked, $event.target, true)"
              :checked="isCheckedChecks(itm.value)"
              checkbox 
              :name="name"
              :title="itm.name"
              :value="itm.value"
            ></f7-list-item>
            <f7-link
              v-if="hasHistory && Utils.posterior2Dic2024(form.formData.fechaInsp)"
              class="input-suffix-btn r-input-little r-input-top history-link"
              :icon-f7="'archivebox'"
              v-tooltip="'Historico de valores'"
              @click="showHistory"
            ></f7-link>
          </f7-list>
          <span v-if="htmlError" v-html="htmlError()" @click.prevent="mostrarError()" style="z-index: 100;"></span>
        </div>
      </div>
      <slot name="content"></slot>
    </div>
      <!-- @change="type == 'date' || type == 'datetime-local' ? undefined : setValue($event.target.value, $event.target)" -->
      <f7-list-input
        v-else
        :value="getValue()"
        v-bind="{...$attrs,...$props}"
        autocomplete="no"
        @input="setValue($event.target.value, $event.target)"
        @change="type == 'date' || type == 'datetime-local' ? null : setValue($event.target.value, $event.target, true)"
        @calendar:change="type == 'date' || type == 'datetime-local' ? null : setValue($event, 'calendar')"
        v-on="$listeners"
        :error-message-force="!!form.errors[name] || !!errorForce"
        :error-message="form.errors[name] || errorForce"
        :readonly="overrideReadonly ? readonly : readonly || form.readonly"
        :class="{readonly:overrideReadonly ? readonly : readonly || form.readonly, inputDate:type == 'date' || type == 'datetime-local', inputDate:allMayus?'allMayus':'','no-list-decorator':!listDecorator}"
        v-tooltip="viewTooltip ? (labelTooltip ? labelTooltip +':<br>' : (label ? label + ':<br>' : '')) + (getValue() ? (type == 'datepicker' || type == 'date' || type == 'datetime-local' ? dateToString(getValue()) : getValue()) : '') : ''"
        :clear-button="type == 'date' || type == 'datetime-local' ? false : (!(overrideReadonly ? readonly : readonly || form.readonly) && clearButton)"
        @input:clear="setValue(null)"
        :type="type == 'integer' ? 'number' : type"
        :resizable="resizable"
      >
        <slot name="label" slot="label"></slot>
        <slot name="info" slot="info"></slot>
        <slot></slot>
        <slot name="media" slot="media"></slot>
        <slot name="content-start" slot="content-start"></slot>
        <slot name="content" slot="content"></slot>
        <slot name="inner-end" slot="inner-end">
          <f7-link
            v-if="getShowDecorationLink()"
            class="input-suffix-btn r-input-little r-input-top"
            :icon-f7="decorationConfig.icon"
            :color="decorationConfig.color"
            v-tooltip="decorationConfig.tooltip"
            @click="decorationConfig.action()"
          ></f7-link>
          <f7-link
            v-if="hasHistory && Utils.posterior2Dic2024(form.formData.fechaInsp)"
            class="input-suffix-btn r-input-little r-input-top history-link"
            :icon-f7="'archivebox'"
            v-tooltip="'Historico de valores'"
            @click="showHistory"
          ></f7-link>
        </slot>
        <slot name="content-end" slot="content-end"></slot>
        <slot name="root-end" slot="root-end"></slot>
        <slot name="inner-start" slot="root-end"></slot>
        <slot name="error-message" slot="error-message"></slot>
      </f7-list-input>
      <span v-if="htmlError" v-tooltip="tooltipError" v-html="htmlError()" @click.prevent="mostrarError()" style="z-index: 100;"></span>

      <f7-popover class="history-popover" :closeByBackdropClick="false" :backdrop="true" closeOnEscape ref="popoverHistory" style="width: auto; max-width: 90%;">
        <div style="min-width: 250px;">
          <f7-navbar :title="$t('Historico de cambios')">
            <f7-nav-right>
              <f7-link popover-close icon-f7="multiply"></f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-block>
            <div class="scrollable-content">
              <table>
                <thead>
                  <tr style="background-color: #22355b;color: #FFF;">
                    <th>Valor</th>
                    <th>Fecha</th>
                    <th>Usuario</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(itm, idx) in history" :key="'history-' + idx" :class="(idx%2?'fila-impar':'fila-par')">
                    <td class="datos-valor" :title="itm && itm.msgHistory?itm.msgHistory:''">{{itm.valor}}</td>
                    <td class="datos-auditoria">{{itm.date}}</td>
                    <td class="datos-auditoria">{{itm.username || ' - '}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </f7-block>
        </div>
      </f7-popover>
  </div>
</template>
<style scoped>
</style>
<style>
input[type="date"]:not(:focus):in-range::-webkit-datetime-edit-year-field,
input[type="date"]:not(:focus):in-range::-webkit-datetime-edit-month-field,
input[type="date"]:not(:focus):in-range::-webkit-datetime-edit-day-field,
input[type="date"]:not(:focus):in-range::-webkit-datetime-edit-text {
  color: transparent;
}
.readonly input,
.readonly select {
  pointer-events: none;
}

.item-checks .list {
  max-height: 170px;
  overflow: auto;
}
input[type="number"] + .input-clear-button, select + .input-clear-button{
  right: 20px;
}
span.input-clear-button {
  right: 2px;
  width: 14px;
  height: 14px;
}
</style>
<script>
import DateUtils from "./../js/DateUtils";
import EntityUtils from "./../js/EntityUtils";
import Utils from "./../js/Utils";
import rBadge from './rBadge.vue';
import JSONPath from 'jsonpath';

export default {
  components: { rBadge },
  data() {
    return {
      self: this,
      target: process.env.TARGET,
      history: [],
      DateUtils,
      EntityUtils,
      Utils,
      JSONPath
    };
  },
  props: {
    name: {
      required: true,
      type: String
    },
    altername: {
      required: false,
      type: String,
      default: ''
    },
    simple: {
      type: Boolean,
      default: false
    },
    resizable: {
      type: Boolean,
      default: false
    },
    allMayus: {
      type: Boolean,
      default: false
    },
    form: {
      required: false,
      type: Object,
      default: function() {
        return {};
      }
    },
    readonly: {
      type: Boolean,
      default: false
    },
    overrideReadonly: {
      type: Boolean,
      default: false
    },
    viewTooltip: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: "text"
    },
    label: {
      type: String,
      default: ""
    },
    labelTooltip: {
      type: String,
      default: ""
    },
    formatDate: {
      type: String,
      default: "DD/MM/YYYY"
    },
    clearButton: {
      type: Boolean,
      default: true
    },
    validation: {
      type: Object
    },
    dbItems: {
      type: Array
    },
    saveAsString: {
      type: Boolean,
      default: false
    },
    listDecorator: {
      type: Boolean,
      default: true
    },
    text: {
      type: String
    },
    errorForce: {
      type: String
    },
    color: {
      type: String
    },
    decorationConfig: {
      type: Object,
      default: null
    }
  },
  computed: {
    hasHistory: function () {
      return this.form?.history && this.form?.history[this.name];
    },
  },
  methods: {
    mostrarError: function() {
      const self = this;
      var app = self.$f7;
      let expediente = self.form.formData;
      let htmlError = expediente.listErrorsForLabel.filter(e => e.valor == (self.altername || self.name))
                .flatMap(e => e.errores)
                .map(e => '<div class="regla regla-'+e.tipoRegla+'" ><span style="color: black;">'+e.nombre + '.- </span>' + e.error+'</div>').join(' ');
      let msg = '<div>' + htmlError + '</div>';
      app.dialog.alert(msg,'Errores del campo');
    },
    htmlError: function() {
      const self = this;
      let expediente = self.form.formData;
      let msgError;
      if(expediente && expediente.listErrorsForLabel && expediente.listErrorsForLabel.filter(e => e.valor == (self.altername || self.name)).length)
      {
        msgError = EntityUtils.formateoError(expediente, self.altername || self.name);
      }
      return msgError;
    },
    tooltipError: function() {
      const self = this;
      let expediente = self.form.formData;
      let msgError;
      if(expediente && expediente.listErrorsForLabel && expediente.listErrorsForLabel.filter(e => e.valor == (self.altername || self.name)).length)
      {
        msgError = EntityUtils.tooltipError(expediente, (self.altername ||self.name));
      }
      return msgError;
    },
    isCheckedChecks: function (val) {
      const self = this;
      const arr = self.getValue();
      if (arr && arr.length && (arr.indexOf(val) > -1)) {
        return true;
      }
      return false;
    },
    dateToString: function(date) {
      const self = this;
      
      if (!date.length) {
        return "";
      } else if( typeof date == 'string' && /^\d{4}-\d{2}-\d{2}[T\s]\d{2}:\d{2}:\d{2}$/i.test(date)) {
        date = new Date(date);
        return DateUtils.dateFormat(date, self.formatDate);
      }
      return DateUtils.dateFormat(date[0], self.formatDate);
    },
    getValue: function(historico = false) {
      const self = this;
      var namespaces = self.name.split(".");
      var context = historico ? self.form.history : self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined") {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        if (typeof context == "undefined" || context == null) context = {};
        context = context[namespaces[i]];
      }
      if (self.type == "checks") {
        if (context) {
          if (self.saveAsString) {
            context = context.split(",");
          }
        } else {
          context = [];
        }
      } else if (self.type == "check") {
        context = context == "true" || context == true;
      } else if (self.type == "datepicker") {
        if (context) {
          var previousVal = context;
          if (typeof context == "string") {
            context = new Date(previousVal);
            if (isNaN(context.getTime())) {
              var dateParts = previousVal.split("/");
              // month is 0-based, that's why we need dataParts[1] - 1
              while (dateParts.length < 3) {
                dateParts.unshift(1);
              }
              context = new Date(dateParts[2], dateParts[1] - 1, + dateParts[0]); 
            }
          }
          context = [context];
        } else {
          context = [];
        }
      } else if (self.type == "date") {
        if (context) {
          context = DateUtils.dateFormat(context, 'YYYY-MM-DD');
        } 
      } else if (self.type == "datetime-local") {
        if (context) {
          context = DateUtils.dateFormat(context, 'YYYY-MM-DDTHH:mm');
        } 
      }
      return context;
    },
    showHistory: function(event) {
      const self = this;
      self.history = self.form.history[self.name];
      // vamos a formatear los campos de fecha
      for (var i = 0; i < self.history.length; i++) {
        if(self.history[i].valor === undefined) {
          self.history[i].valor = '';
        }
        if(self.history[i].valorAnterior === undefined) {
          self.history[i].valorAnterior = '';
        }

        switch(self.type) {
          case 'check':
            if(self.history[i].valor === true) {
              self.history[i].valor = 'Si';
            } else if(self.history[i].valor === false) {
              self.history[i].valor = 'No';
            }
            if(self.history[i].valorAnterior === true) {
              self.history[i].valorAnterior = 'Si';
            } else if(self.history[i].valorAnterior === false) {
              self.history[i].valorAnterior = 'No';
            }
            break;
          case 'date':
          case 'datetime-local':
          case 'datepicker':
            self.history[i].valor = DateUtils.isDate(self.history[i].valor)?self.history[i].valor:DateUtils.dateFormat(self.history[i].valor, self.formatDate);
            self.history[i].valorAnterior = DateUtils.isDate(self.history[i].valorAnterior)?self.history[i].valorAnterior:DateUtils.dateFormat(self.history[i].valorAnterior, self.formatDate);
            break;
        }

        self.history[i].date = DateUtils.dateFormat(self.history[i].date, 'DD/MM/YYYY HH:mm:ss');
        self.history[i].username = self.history[i].username || '-';
      }
      if(event.target) self.$refs.popoverHistory.open(event.target);
    },
    setValue: function(val, a, desdeChange = false, extraDataEmit = false) {
      console.log("setValue", val, a, desdeChange, extraDataEmit);
      const self = this;
      if (self.type == "select" && !val) {
        val = null;
      } else if (self.type == "datepicker") {
        if (typeof val != 'undefined' && val != null && (!a || a != "calendar")) return;
        else if (val) {
          val = val[0];
          if (val) {
            var previousVal = self.getValue();
            if (previousVal[0]) {
              val.setHours(previousVal[0].getHours());
              val.setMinutes(previousVal[0].getMinutes());
            }
          }
        }
      } else if (self.type == "date" || self.type == "datetime-local") {
        
        if (val) {
          val = new Date(val);
        }
      } else if (self.type == "number" || self.type == "integer") {
        if (typeof val != undefined && val != null && val != "" && !isNaN(val)) {
          val = parseFloat(val);
        } else if (typeof val != undefined && val != null && val != "") {
          if (val.indexOf(",") > -1) {
            val = val.replace(",", ".");
          }
          if (!isNaN(val)) {
            val = parseFloat(val);
          } else {
            val = "";
          }
        } else {
          val = "";
        }
        if (self.saveAsString) {
          val = val + '';
        }
      } else if (self.type == "checks") {
        var values = self.getValue();
        if (val) {
          values.push(a.value);
        } else {
          values.splice(values.indexOf(a.value), 1);
        }
        if (self.saveAsString) {
          val = "";
          if (values && values.length) {
            for (var i = 0; i < values.length; i++) {
              if (i > 0) {
                val = val + ",";
              }
              val = val + values[i];
            }
          }
        } else {
          val = values;
        }
      } else if (self.type == "check" && self.saveAsString) {
        val = val + "";
      } else if (val != undefined && self.allMayus && desdeChange){
        val = val.toUpperCase();
      }

      if (val != undefined && val === '') {
        if (self.type != "number") {
          val = undefined;
        }
      }

      var namespaces = self.name.split(".");
      var context = self.form.formData;
      console.log("namespaces", namespaces)
      for (var i = 0; i < namespaces.length; i++) {
        if (i == namespaces.length - 1) {
          self.$set(context, namespaces[i], val);
          self.$emit("change", val, self, extraDataEmit);
        } else if (context[namespaces[i]] == null) {
          self.$set(context, namespaces[i], {});
          self.$emit("change", {}, self, extraDataEmit);
        } else if (typeof context[namespaces[i]] == "undefined") {
          let n = namespaces[i + 1];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            self.$set(context, namespaces[i], []);
            self.$emit("change", [], self, extraDataEmit);
          } else {
            self.$set(context, namespaces[i], {});
            self.$emit("change", {}, self, extraDataEmit);
          }
        }
        context = context[namespaces[i]];
      }
    },
    getShowDecorationLink: function() {
      const self = this;
      if (self.decorationConfig) {
        if (self.decorationConfig.showDecoration) {
          return self.decorationConfig.showDecoration()
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  },
  created() {
    var self = this;
    self.$emit("created", self);
  },
  mounted() {
    var $ = this.$$;
    var $el = $(this.$el);
    var self = this;
    var actualVal = self.getValue();
    let extraDataEmit = {
      fromMounted: true
    };
    if (self.validation) {
      self.form.validateFields[self.name] = self.validation;
    }
    if (self.type == 'select') {
      if (!actualVal) {
        var selected = self.$options._renderChildren.filter(function(c){
          return c.data && c.data.attrs && c.data.attrs.selected != undefined
        });
        if (selected && selected.length > 0) {
          var value = selected[0].data.attrs.value;
          if (value && value != null && value != '') {
            self.setValue(selected[0].data.attrs.value, self, false, extraDataEmit);
          }
        } else {
          self.setValue(null, self, false, extraDataEmit);
        }
      }
    } else if (self.type == 'check') {
      if (!actualVal) {
        self.setValue(false, self, false, extraDataEmit);
      }
    }
    self.$emit("mounted", self);
    /*self
      .$$(this.$el)
      .find("select")
      .on("mousedown", function(e) {
        if (self.readonly || self.form.readonly) {  
          e.preventDefault();
          this.blur();
          window.focus();
        }
      });*/
  },
  destroyed() {
    var self = this;
    if (self.validation) {
      delete self.form.validateFields[self.name];
    }
  }
};
</script>
<style scope>
a.input-suffix-btn.r-input-little {
  height: 100%;
  top: 0;
}
a.r-input-little i.f7-icons {
  font-size: 8pt;
}
a.r-input-top {
  margin-top: 0;
}
a.r-input-top i.f7-icons {
  vertical-align: top;
}
.no-list-decorator {
  list-style: none;
}
</style>

<template>
  <f7-page page="main-menu">
    <f7-list media-list class="no-margin">
      <f7-list-item
        :title="getNombreUser()"
        :header="getUsername()"
        class="padding-vertical"
      >
        <div slot="footer">
          <f7-link href="/perfil/" view=".view-main">Mi perfil</f7-link>|
          <f7-link @click="closeSession()">Cerrar sesión</f7-link>
        </div>
        <img
          v-if="0"
          slot="media"
          src="https://cdn.framework7.io/placeholder/fashion-88x88-3.jpg"
          width="44"
        />
        <f7-icon
          v-else
          slot="media"
          f7="person_crop_circle"
          style="font-size:3rem;"
          class="text-color-gray"
        />
      </f7-list-item>
    </f7-list>

    <f7-toolbar bottom>
      <span class="text-color-gray">{{version}}</span>
    </f7-toolbar>

    <f7-list>
      <ul>
        <div class="div-menu-item" v-for="(item,index)  in menu.items"
          :key="'menu-item-' + index">
          <f7-list-item 
            v-if="(!!!item.target || target === item.target) && (!item.hijos || !item.hijos.length)"
            :link="item.link" 
            :title="$t(item.title)"
            :color="item.color||''"
            view=".view-main" 
            panel-close
          >
            <f7-icon v-if="item.icon" slot="media" :f7="item.icon" :color="item.color||''"></f7-icon>
          </f7-list-item>
          <f7-list-item 
            v-else-if="(!!!item.target || target === item.target) && item.hijos && item.hijos.length"
            :title="$t(item.title)"
            :color="item.color||''"
            view=".view-main"
            accordion-item
          >
            <f7-icon v-if="item.icon" slot="media" :f7="item.icon" :color="item.color||''"></f7-icon>
            <f7-accordion-content  :style="'border-left: 10px solid '+item.color+';border-right: 1px solid '+item.color+';'">
              <ul v-for="(hijo,indexHijo)  in item.hijos"
                :key="'menu-item-hijo' + index + '-' + indexHijo">
                <f7-list-item 
                  :link="hijo.link" 
                  :title="$t(hijo.title)"
                  :color="hijo.color||''"
                  view=".view-main" 
                  panel-close
                >
                  <f7-icon v-if="hijo.icon" slot="media" :f7="hijo.icon"></f7-icon>
                </f7-list-item>
              </ul>
            </f7-accordion-content>
          </f7-list-item>
        </div>
      </ul>
    </f7-list>

    <div class="block"></div>
  </f7-page>
</template>
<style>
.panel-left.panel-in-breakpoint:before {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background: rgba(0, 0, 0, 0.1);
  content: "";
  z-index: 6000;
}
</style>
<style scoped>

</style>
<script>
import Flash from '../js/FlashUtils';
import Utils from '../js/Utils';
export default {
  data() {
    return {
      version: process.env.BUILDDATE,
      target: process.env.TARGET,
      Utils
    };
  },
  computed: {
    menu: function() {
      return !!Utils.getMenu() ? Utils.getMenu() : {items:[]};
    }
  },
  methods: {
    getNombreUser: function() {
      const user = Utils.getUser();
      return user ? user.nombre : "";
    },
    getUsername: function() {
      const user = Utils.getUser();
      return user ? user.username : "";
    },
    closeSession: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm("¿Cerrar sesión?", function() {
        app.methods.removeSession();
        Flash.set("logged-out", true);
        app.views.main.router.navigate("/");
        app.panel.close("left");
      });
    }
  }
};
</script>